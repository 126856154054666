import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UserMenu } from '../user/user-menu';


@Injectable()
export class SubMenuService {

  private topY = new ReplaySubject<number>(1);
  topY$ = this.topY.asObservable();   // $ is onnvention to indicate

  private leftX = new ReplaySubject<number>(1);
  leftX$ = this.leftX.asObservable();   // $ is onnvention to indicate

  private toggleView = new ReplaySubject<boolean>(1);
  toggleView$ = this.toggleView.asObservable();

  private menu = new ReplaySubject<UserMenu[]>(1);
  menu$ = this.menu.asObservable();

  private lastMenuName = '';
  private isShown: boolean;

  constructor() {
    this.isShown = true;
    this.toggleView.next(true);
    this.updateMenu('', 0, 179, null);
  }

  updateMenu(name: string, y: number, x: number, subMenu: UserMenu[]) {
    if (this.lastMenuName === name && this.isShown) {
      this.isShown = false;
    } else {
      this.isShown = true;
    }

    this.toggleView.next(this.isShown);
    this.topY.next(y);
    this.leftX.next(x);
    this.menu.next(subMenu);
    this.lastMenuName = name; // keep track of last menu to be selected so we know wether to hide or not
  }

  hideMenu() {
    this.isShown = false;
    this.toggleView.next(this.isShown);
  }
}
