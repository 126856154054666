

import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BadDataTicket } from './badDataTicket';
import { User } from '../../user/user';
import { UserService } from '../../core/user.service';

@Injectable({
  providedIn: 'root'
})
export class BadDataService {

  private ticketUrl = environment.cubCloudUrl + 'baddatatickets';

  private ticketList = new ReplaySubject<BadDataTicket[]>(1);
  tickets$ = this.ticketList.asObservable();   // $ is connvention to indicate observable
  private selectedId = new ReplaySubject<string>(1);
  selectedId$ = this.selectedId.asObservable();
  private listSettings = new ReplaySubject<any>(1);
  listSettings$ = this.listSettings.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getTickets(): Observable<BadDataTicket[]> {
    let url = `${this.ticketUrl}`;
    return this.http.get<BadDataTicket[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTicketsForSystem(system: string, key: number): Observable<BadDataTicket[]> {
    let url = `${this.ticketUrl}/list/${key}`;
    return this.http.post<BadDataTicket[]>(url, JSON.stringify(system), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTicketCount(system: string, key: number): Observable<number> {
    let url = `${this.ticketUrl}/count/${key}`;
    return this.http.post<number>(url, JSON.stringify(system), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTicket(id: string): Observable<BadDataTicket> {
    let url = `${this.ticketUrl}/${id}`;
    let detail = this.http.get<BadDataTicket>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return detail;
  }

  openTicket(ticket: BadDataTicket): Observable<BadDataTicket> {
    let url = `${this.ticketUrl}/open`;
    return this.http.post<BadDataTicket>(url, JSON.stringify(ticket), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  resolveTicket(id: string, ticket: BadDataTicket): Observable<BadDataTicket> {
    let url = `${this.ticketUrl}/${id}/resolve`;
    return this.http.post<BadDataTicket>(url, JSON.stringify(ticket), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  delete(id: string, ticket: BadDataTicket): Observable<boolean> {
    let url = `${this.ticketUrl}/${id}/delete`;
    // console.log('url: ', url);
    return this.http.post<boolean>(url, JSON.stringify(ticket), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

}
