<mat-form-field style="width: 100%;">
  <mat-label>{{ label }}</mat-label>

  <input matInput
         *ngIf="dataType === 'string' || dataType === 'varchar'"
         name="mdInp"
         type="text"
         (blur)="onBlur()"
         (paste)="onPaste($event)"
         [maxlength]="dataLength === 0 ? 255 : dataLength"
         [readonly]="readOnly"
         [class.disabled]="readOnly"
         [(ngModel)]="fieldValue">

  <input matInput
         *ngIf="dataType === 'int' || dataType === 'double'"
         name="mdInp"
         type="text"
         (blur)="onBlur()"
         [ngModel]="fieldValue"
         [readonly]="readOnly"
         [class.disabled]="readOnly"
         (ngModelChange)="onModelChange($event)">

  <span matPrefix
        *ngIf="dataType === 'money'"><i
       [ngClass]="{'fa': true, 'fa-dollar': true, 'icon-color-enabled': true, 'icon-color-disabled': readOnly}"></i>
    &nbsp;</span>
  <input matInput
         *ngIf="dataType === 'money'"
         name="mdInp"
         type="text"
         (blur)="onBlur()"
         [ngModel]="fieldValue"
         [readonly]="readOnly"
         [class.disabled]="readOnly"
         (ngModelChange)="onModelChange($event)">

  <input matInput
         *ngIf="dataType === 'percent'"
         name="mdInp"
         type="text"
         (blur)="onBlur()"
         [ngModel]="fieldValue"
         [readonly]="readOnly"
         [class.disabled]="readOnly"
         (ngModelChange)="onModelChange($event)">
  <span matSuffix
        *ngIf="dataType === 'percent'"><i
       [ngClass]="{'fa': true, 'fa-percent': true, 'icon-color-enabled': true, 'icon-color-disabled': readOnly}"></i>
    &nbsp;</span>

</mat-form-field>
