<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input matInput
         [matDatepicker]="picker"
         [readonly]="readOnly"
         [(ngModel)]="fieldValue"
         (dateChange)="onChange($event)">
  <mat-datepicker-toggle *ngIf="!readOnly"
                         matSuffix
                         [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
