import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-investment-new-dialog',
    templateUrl: './investment-new-dialog.component.html',
    styleUrls: ['./investment-new-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class InvestmentNewDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<InvestmentNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

}
