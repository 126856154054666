
import {map,  debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fromEvent as observableFromEvent ,  Subject } from 'rxjs';

import { Component, Input, forwardRef, OnChanges, SimpleChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';

import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { AlertService } from '../../core/alert.service';

@Component({
    selector: 'cub-input-auto-save-camo',
    templateUrl: './input-auto-save-camo.component.html',
    styleUrls: ['./input-auto-save-camo.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputAutoSaveCamoComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputAutoSaveCamoComponent), multi: true }
    ],
    animations: [
        trigger('borderState', [
            transition('normal => success', [
                animate(900, keyframes([
                    style({ border: 'solid 1px darkgreen' }),
                    style({ border: 'solid 1px #fff' })
                ]))
            ]),
            transition('normal => error', animate('100ms ease-in')),
        ])
    ],
    standalone: true,
    imports: [FormsModule]
})
export class InputAutoSaveCamoComponent implements OnChanges, ControlValueAccessor {

  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() itemKey: number;
  @Input() dataType: string;
  @Input() dataLength: number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fieldValue') _fieldValue: string;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();
  @ViewChild('input', { static: true }) input;

  private delay = 400;
  public keyUp = new Subject<string>();
  private origValue = '';
  cssClass = '';
  status = 'normal';
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    elementRef: ElementRef,
    private saveService: SaveService) {
    const eventStream = observableFromEvent(elementRef.nativeElement, 'keyup').pipe(
        map(() => this._fieldValue))
        .pipe(
          debounceTime(this.delay),
          distinctUntilChanged()
        );

    eventStream.subscribe(() => {
      // TODO - Do we need this subscription?
      // this.save(input);  //uncomment if we want to use keydown delayed saving
    });
  }

  get fieldValue() {
    return this._fieldValue;
  }

  set fieldValue(val) {
    this._fieldValue = val;
    if (this.origValue === '') {
      this.origValue = val;
    }
    this.propagateChange(val);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._fieldValue !== undefined) {
      this.origValue = changes._fieldValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
        this.fieldValue = value;
    } else {
      this.fieldValue = '';
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  onFocus() {
    this.status = 'normal';
  }

  onBlur() {
    if (this.origValue.toString() !== this._fieldValue.toString()) {
      this.save(this._fieldValue);
    }
  }

  save(value: string): void {
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.fieldName, this.itemKey, this.origValue, value, value, this.dataType, this.dataLength);
    this.saveService.saveChange(changePacket)
      .then(() => {
        this.status = 'success';
        this.controlUpdate.emit({ saved: true });
      })
      .catch(() => {
        this.status = 'error';
        this.controlUpdate.emit({ saved: false });
      });
  }


  // TODO - Do we need this handler?
  registerOnTouched() { }
}
