

  <ng-container *ngFor="let item of menuList" >
    <cub-left-menu-button [menuItem]="item"></cub-left-menu-button>
  </ng-container>

<!--
<button type="button" class="btn btn-secondary" placement="right"
        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on right">
  ngbPopover example
</button>
-->
