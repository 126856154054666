import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-fetch-data',
    templateUrl: './fetch-data.component.html',
    standalone: true,
    imports: [NgIf, NgFor]
})
export class FetchDataComponent {
  public props: Property[];
  public testData = '';

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    // http.get<Property[]>(baseUrl + 'api/properties').subscribe(result => {
    //   this.props = result;
    // }, error => console.error(error));
    http.get<string>(baseUrl + 'api/properties/test').subscribe(result => {
      this.testData = result;
    }, error => console.error(error));
  }
}

export class Property {
  propertyDesc: string;
}
