
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';
import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';

import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { AlertService } from '../../core/alert.service';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'cub-textarea-auto-save',
    templateUrl: './textarea-auto-save.component.html',
    styleUrls: ['./textarea-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => TextareaAutoSaveComponent), multi: true }
    ],
    standalone: true,
    imports: [MatFormFieldModule, NgIf, MatInputModule, FormsModule]
})
export class TextareaAutoSaveComponent implements OnChanges, ControlValueAccessor {

  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() itemKey: number;
  @Input() dataType: string;
  @Input() dataLength: number;
  @Input() label: string;
  @Input() rows: number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fieldValue') _fieldValue!: string;
  @ViewChild('input') input;

  private delay = 400;
  public keyUp = new Subject<string>();
  private origValue = this._fieldValue;
  cssClass = '';
  status = 'error';
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    private elementRef: ElementRef,
    private saveService: SaveService,
    private alertService: AlertService
  ) {
    // this.origValue = this._fieldValue;
    const eventStream = observableFromEvent(elementRef.nativeElement, 'keyup').pipe(
      map(() => this._fieldValue))
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged());

    eventStream.subscribe(input => {
      // this.save(input);  //uncomment if we want to use keydown delayed saving
    });
  }

  get fieldValue() {
    return this._fieldValue;
  }

  set fieldValue(val) {
    this._fieldValue = val;
    if (this.origValue === '') {
      this.origValue = val;
    }
    this.propagateChange(val);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._fieldValue !== undefined) {
      this.origValue = changes._fieldValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
      this.fieldValue = value;
    } else {
      this.fieldValue = '';
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  onFocus() {
    this.status = 'normal';
  }

  onBlur() {
    this.save(this._fieldValue);
  }

  onModelChange(evt: any) {
    this._fieldValue = evt.replace(/,/g, '');  // remove commas
  }

  save(value: string): void {
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.label, this.itemKey, this.origValue, value, value, this.dataType, this.dataLength);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        this.status = 'success';
        this.origValue = value;
      })
      .catch(() => { this.status = 'error'; });
  }

  private handleError2(error: any) {
    this.cssClass = 'error';
  }

  // TODO - Do we need this callback?
  registerOnTouched() { }
}
