<form [formGroup]="propertyAddressForm"
      novalidate>
  <div class="inv-new-header">
    <h3>Edit Address</h3>
  </div>
  <div class="inv-new-body">
    <div class="inv-grid">
      <mat-card appearance="outlined" style="grid-column: 1 / 2;">
        <mat-card-content>
          <div class="content-detail">
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 4;">
				      <mat-label>Street Address</mat-label>
              <input matInput
                     formControlName="address1"
                     (blur)="findLocation()">
              <mat-error *ngIf="propertyAddressForm.controls['address1'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 3;">
				      <mat-label>City</mat-label>
              <input matInput
                     required
                     formControlName="city"
                     (blur)="findLocation()">
              <mat-error *ngIf="propertyAddressForm.controls['city'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
				      <mat-label>State</mat-label>
              <mat-select
                          formControlName="stateKey"
                          (selectionChange)="findLocation()">
                <mat-option *ngFor="let state of states"
                            [value]="parse(state.id)">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="propertyAddressForm.controls['stateKey'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 3;">
				      <mat-label>Zip</mat-label>
              <input matInput
                     required
                     formControlName="zipCode"
                     (blur)="findLocation()">
              <mat-error *ngIf="propertyAddressForm.controls['zipCode'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 3;">
				      <mat-label>County</mat-label>
              <input matInput
                     required
                     formControlName="county"
                     (blur)="findLocation()">
              <mat-error *ngIf="propertyAddressForm.controls['county'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 3;">
				      <mat-label>Country</mat-label>
              <input matInput
                     required
                     formControlName="country"
                     (blur)="findLocation()">
              <mat-error *ngIf="propertyAddressForm.controls['country'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1 / 2;">
				      <mat-label>Latitude</mat-label>
              <input matInput
                     readonly
                     formControlName="latitude">
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 2 / 2;">
				      <mat-label>Longitude</mat-label>
              <input matInput
                     readonly
                     formControlName="longitude">
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined" style="grid-column: 2 / 2;">
        <mat-card-title>
          Map
          <span class="title-warning"
                style="width: 150px;">
            Upon entering the address, please confirm the location.
          </span>
        </mat-card-title>
        <mat-card-content>
          <cub-map [latitude]="propertyAddressForm.controls['latitude'].value"
                   [longitude]="propertyAddressForm.controls['longitude'].value"
                   [zoom]="zoomLevel"
                   [mapStyle]="mapStyle"
                   [mapHeight]="330"
                   [street]="propertyAddressForm.controls['address1'].value"
                   [city]="propertyAddressForm.controls['city'].value"
                   [state]="stateIdToName(propertyAddressForm.controls['stateKey'].value)"
                   [country]="propertyAddressForm.controls['country'].value"></cub-map>
          <br />
        </mat-card-content>
      </mat-card>
      <div style="grid-column: 1 / 3; margin-top: 10px;">
        <button mat-button
                color="primary"
                style="float: left;"
                [disabled]="isSaving"
                (click)="cancel()">Cancel</button>
        <button mat-raised-button
                color="primary"
                type="submit"
                class="button-standard"
                [disabled]="propertyAddressForm.pristine || propertyAddressForm.invalid || isSaving"
                (click)="submit()"
                style="float: right;">Save</button>
        <span *ngIf="isSaving" style="float: right; margin-right: 20px; margin-top: 7px;">
          <fa-icon [icon]="faSpinner" [spin]="true" style="margin-right: 5px;"></fa-icon> Saving...
        </span>
      </div>
    </div>
  </div>
</form>
