
import { ListOptions } from '.';

export class ListView {
  id: number;
  cubsUserKey: number;
  system: string;
  name: string;
  defaultView: boolean;
  selected: boolean;
  listOptions: ListOptions;

  constructor() {}
}
