export class TrialBalance {
  book: number;
  propertyId: string;
  priorPeriodDesc: string;
  currentPeriodDesc: string;
  glAccountNum: string;
  priorAmt: number;
  currentAmt: number;
  currentActivity: number;

  constructor() {}
}
