import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InvestmentDashboardComponent } from './investment-dashboard/investment-dashboard.component';
import { InvestmentHomeComponent } from './investment-home/investment-home.component';
import { InvestmentDetailComponent } from './investment-detail/investment-detail.component';
import { InvestmentNewComponent } from './investment-new/investment-new.component';

// import { CrisisDetailResolver }   from './crisis-detail-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: InvestmentHomeComponent,
    children: [
      {
        path: '',
        component: InvestmentDashboardComponent
      },
      {
        path: 'home',
        component: InvestmentDashboardComponent
      },
      {
        path: 'dashboard',
        component: InvestmentDashboardComponent
      },
      {
        path: 'new',
        component: InvestmentNewComponent
      },
      {
        path: ':id',
        component: InvestmentDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class InvestmentRoutingModule { }
