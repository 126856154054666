
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';
import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { DecimalPipe, NgIf, NgClass } from '@angular/common';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { AlertService } from '../../core/alert.service';
import { MatFormFieldModule } from '@angular/material/form-field';




@Component({
    selector: 'cub-input-auto-save',
    templateUrl: './input-auto-save.component.html',
    styleUrls: ['./input-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputAutoSaveComponent), multi: true }
    ],
    standalone: true,
    imports: [MatFormFieldModule, NgIf, MatInputModule, FormsModule, NgClass]
})
export class InputAutoSaveComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {

  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() itemKey: number;
  @Input() dataType: string;
  @Input() dataLength: number;
  @Input() label: string;
  @Input() readOnly!: string;
  @Input() numFormat = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fieldValue') _fieldValue: string;
  @ViewChild('input') input;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();

  private origValue = '';
  private controlInitialized = false;
  private userUpdated = false;
  private itemKeyReset = true;

  private delay = 400;
  public keyUp = new Subject<string>();
  cssClass = '';
  currentStyles = { color: this.readOnly ? 'red' : 'black' };
  status = 'error';
  isDisabled = false;
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    private elementRef: ElementRef,
    private saveService: SaveService,
    private dp: DecimalPipe,
    private alertService: AlertService
  ) {
    const eventStream = observableFromEvent(elementRef.nativeElement, 'keyup').pipe(
      map(() => this._fieldValue))
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged()
      );

    eventStream.subscribe(input => {
      // this.save(input);  //uncomment if we want to use keydown delayed saving
    });
  }

  ngOnInit() {
    if (this.numFormat === '') {
      switch (this.dataType) {
        case 'money':
        case 'int':
        case 'double':
        case 'percent':
          this.numFormat = '0.0';
          break;
      }
    }
  }

  ngAfterViewInit() {
    this.controlInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemKey !== undefined && this.controlInitialized) {
      this.origValue = null; // changes._fieldValue.currentValue;
      this.itemKeyReset = true;
    }
  }

  get fieldValue() {
    return this._fieldValue;
  }

  set fieldValue(val) {
    this._fieldValue = val;

    // not sure wtf is going on here but in some cases userUpdated was not being updated
    // is this if statement a correct assumption?  - Rick
    if (val !== this.origValue) {
      this.userUpdated = true;
    }

    if (this.controlInitialized && !this.itemKeyReset) {
      this.userUpdated = true;
    }

    if (this.origValue === null) {
      this.origValue = val;
      this.itemKeyReset = false;
    }
    this.propagateChange(val);
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value !== undefined) {
      if (this.numFormat.length > 0) {
        if (value !== null) {
          if (this.dataType === 'percent') {
            this.fieldValue = this.dp.transform((value * 100).toString().replace(/,/g, ''), this.numFormat);
          } else {
            this.fieldValue = this.dp.transform(value.toString().replace(/,/g, ''), this.numFormat);
          }
          this.fieldValue = this._fieldValue;
        } else {
          this.fieldValue = '';
        }
      } else {
        this.fieldValue = value;
      }
    } else {
      this.fieldValue = '';
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  onPaste(e: any) {
    let content = e.clipboardData.getData('text/plain');
    this.origValue = this.fieldValue;  // not sure why this is necessary but it works

    // this is necessary after pasting for some reason
    setTimeout(() => {
      this.userUpdated = true;
    }, 10);
  }

  onBlur() {
    if (this.userUpdated) {
      if (this.numFormat.length > 0) {
        this.fieldValue = this.dp.transform(this._fieldValue.replace(/,/g, ''), this.numFormat);
      } else {
        this.fieldValue = this._fieldValue;
      }
      let valueToSave = this._fieldValue;
      if (valueToSave === null) {
        valueToSave = '';
      }
      if (this.origValue.toString() !== valueToSave) {
        if (this.dataType === 'percent') {
          valueToSave = (+valueToSave / 100).toString();
        }
        this.save(valueToSave);
      }
    }
  }

  onModelChange(evt: any) {
    this._fieldValue = evt;
    this.fieldValue = this._fieldValue;
  }

  save(value: string): void {
    let changePacket: ChangePacket;
    let valueToLog = value;
    if (this.dataType === 'percent') {
      valueToLog = (+value * 100).toString();
    }
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.label, this.itemKey, this.origValue, value, valueToLog, this.dataType, this.dataLength);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        this.status = 'success';
        this.origValue = value;
        this.emitUpdate();
        this.userUpdated = false;
      })
      .catch(() => {
        this.status = 'error';
        this.userUpdated = false;
      });
  }

  private handleError2(error: any) {
    this.cssClass = 'error';
  }

  emitUpdate() {
    this.controlUpdate.emit({ saved: true });
  }

  // TODO - DO we need this handler?
  registerOnTouched() { }
}
