
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { trigger, transition, query, style, animate, group, state } from '@angular/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';

import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { Contact, EmailAddress, PhoneNumber, ContactSearchParameters } from '../../contact/contact';
import { Address } from 'src/app/shared/address';
import { ContactService } from './contact.service';
import { ContactEditDialogComponent } from '../contact-edit-dialog/contact-edit-dialog.component';
import { ContactPickerOverlayRef } from './contact-picker-overlay-ref';
import { CONTACT_PICKER_DIALOG_DATA } from './contact-picker-overlay.tokens';
import { environment } from '../../../environments/environment';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgFor } from '@angular/common';

type PaneType = 'search' | 'details';

@Component({
    selector: 'cub-contact-picker-overlay',
    templateUrl: './contact-picker-overlay.component.html',
    styleUrls: ['./contact-picker-overlay.component.scss'],
    animations: [
        trigger('slide', [
            state('search', style({ transform: 'translateX(0)' })),
            state('details', style({ transform: 'translateX(-50%)' })),
            transition('* => *', animate(300))
        ])
    ],
    standalone: true,
    imports: [NgIf, MatRadioModule, FormsModule, NgFor, MatButtonModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule]
})
export class ContactPickerOverlayComponent implements OnInit {

  @ViewChild('txtSearch', { static: true }) txtSearch;
  activePane: PaneType = 'search';
  contacts: Contact[] = [];
  selectedContact: Contact;
  detailedContact: Contact;  // we need a separate object for this because it's technically different from selectedContact once the detail is laoded from api
  originalContact: Contact;
  newContact: Contact;
  searchTerm$ = new Subject<string>();
  isLoading = false;
  showDetailsOnly = false;
  showNew = false;
  mainAddress: Address;
  mainEmailAddress: EmailAddress;
  mainPhoneNumber: PhoneNumber;
  salutations: Lookup[] = [];

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private contactService: ContactService,
    private lookupService: LookupService,
    public dialogRef: ContactPickerOverlayRef,
    @Inject(CONTACT_PICKER_DIALOG_DATA) public params: any
  ) {
    this.searchContacts(this.searchTerm$)
      .subscribe(results => {
        this.contacts = results;
        this.isLoading = false;
      });
  }

  ngOnInit() {
    this.selectedContact = new Contact();
    this.detailedContact = new Contact();
    this.newContact = new Contact();
    this.newContact.firstName = '';
    this.newContact.lastName = '';
    this.newContact.isPerson = true;
    this.salutations = this.lookupService.getTableValues('salutations');
    this.txtSearch.nativeElement.focus();
    this.originalContact = this.params.contact;
    this.showDetailsOnly = this.params.showDetailsOnly;
    if (this.params.showDetailsOnly) {
      this.activePane = 'details';
      this.selectedContact = this.params.contact;
      this.detailedContact = this.params.contact;
      this.loadContactDetails();
    } else {
      this.activePane = 'search';
    }
  }

  close() {
    this.dialogRef.save(this.detailedContact);
  }

  cancel() {
    this.dialogRef.cancel(this.originalContact);
  }

  clearField() {
    this.detailedContact = new Contact();
    this.detailedContact.pcKey = -1;
    this.dialogRef.save(this.detailedContact);
  }

  togglePanel(pane: PaneType) {
    this.activePane = pane;
    if (pane === 'search') {
      this.showNew = false;
    }
  }

  showNewPanel() {
    this.showNew = true;
    this.togglePanel('details');
  }

  loadContactDetails() {
    if (this.detailedContact.addresses.length > 0) {
      this.mainAddress = this.detailedContact.addresses[0];
    }
    if (this.detailedContact.phoneNumbers.length > 0) {
      this.mainPhoneNumber = this.detailedContact.phoneNumbers[0];
    }
    if (this.detailedContact.emailAddresses.length > 0) {
      this.mainEmailAddress = this.detailedContact.emailAddresses[0];
    }
  }

  getAddressLink(): string {
    return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(this.mainAddress.address1 + ',' + this.mainAddress.city + ',' + this.mainAddress.stateID + ',' + this.mainAddress.zipCode);
  }

  searchOnKeyup(val: string) {
    if (val.length > 2) {
      this.isLoading = true;
      this.searchTerm$.next(val);
    }
  }

  searchContacts(terms: Observable<string>): Observable<Contact[]> {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.contactService.searchContacts(new ContactSearchParameters(term))));
  }

  private formatUrl(url: string): string {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = newUrl.replace('http://cub/', 'https://cub.centerpoint.com/');
    }
    return newUrl;
  }

  selectionChange() {
    this.dialogRef.select(this.selectedContact);
    this.contactService.getContactDetail(this.selectedContact.pcKey).subscribe(c => {
      this.detailedContact = c;
      this.loadContactDetails();
    });
  }

  saveNewContact() {
    this.contactService.saveContact(this.newContact).subscribe(c => {
      this.selectedContact = c;
      this.dialogRef.save(this.selectedContact);
    });
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null ? '' : c2.toString());
  }

  selectContact() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {
      contact: this.detailedContact
    };
    const dialogRef = this.dialog.open(ContactEditDialogComponent, dialogConfig);
  }
}
