import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { TeamService } from './../team.service';
import { Bracket, BracketObject } from './../bracket/bracket';
import { Team, Game } from './../bracket/bracket';
import { BracketService } from './../bracket.service';
import { MenuService } from 'src/app/core/menu.service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'cub-bracket-print',
    templateUrl: './bracket-print.component.html',
    styleUrls: ['./bracket-print.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class BracketPrintComponent implements OnInit {

  teams: Team[] = [];
  brackets: Bracket[] = [];
  bracket: Bracket;
  masterBracket: Bracket;
  bracketId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    private titleService: Title,
    private bracketService: BracketService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.menuService.toggleMinMaxLeftMenu(true);
    this.menuService.toggleTopMenu(false);

    this.route.params.subscribe(params => {
      console.log('got params', params);
      this.bracketId = +params['id'];
      this.bracketService.bracketList$.subscribe(bs => {
        this.brackets = bs;  //
        this.getBracket();
      });
    });
    this.getTeams();
  }

  getBracket() {
    const brackets = this.brackets.filter(br => br.id === this.bracketId);
    if (brackets.length > 0) {
      this.bracket = brackets[0];
      // this.titleService.setTitle('Bracket: ' + this.bracket.name);
      console.log('bracket', this.bracket, this.bracket.regions[0].name);
    }
    const masterBrackets = this.brackets.filter(br => br.year === this.bracket.year && br.master);
    if (masterBrackets.length > 0) {
      this.masterBracket = masterBrackets[0];
      // this.points = Bracket.getPoints(this.bracket, this.masterBracket);
      console.log('master bracket', this.masterBracket);
    }
  }

  getTeams() {
    return this.teamService.getTeams().subscribe(t => {
      this.teams = t;
    });
  }

  getTeamName(rgn: number, teamId: number): string {
    let team = this.bracket.regions[rgn].teams.filter(t => t.id === teamId)[0];
    if (team !== undefined) {
      return team.name;
    } else {
      return '';
    }
  }

  getTeamSeed(rgn: number, teamId: number): string {
    return this.bracket.regions[rgn].teams.filter(t => t.id === teamId)[0].seed.toString();
  }

  getChampLogo(): string {
    let logo = '';

    if (this.bracket.regions[4].rounds[2].games[0]) {
      const teamId = this.bracket.regions[4].rounds[2].games[0].team1Id;
      const team = this.bracket.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = 'assets/img/logos/500/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }

    return logo;
  }

  getLeftChampLogo(): string {
    let logo = '';

    if (this.bracket.regions[4].rounds[1].games[0]) {
      const teamId = this.bracket.regions[4].rounds[1].games[0].team1Id;
      const team = this.bracket.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = 'assets/img/logos/500/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }

    return logo;
  }

  getRightChampLogo(): string {
    let logo = '';

    if (this.bracket.regions[4].rounds[1].games[0]) {
      const teamId = this.bracket.regions[4].rounds[1].games[0].team2Id;
      const team = this.bracket.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = 'assets/img/logos/500/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }

    return logo;
  }

  isPickWrong(rgn: number, round: number, gameId: number, team: string): boolean {
    let wrongPicks = false
    if (this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team] === undefined) {
      wrongPicks = false
    } else {
      wrongPicks = this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team] !== this.bracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team];
    }
    return wrongPicks;
  }
}
