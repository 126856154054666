import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrialBalance } from '../trialBalance';
import { GldataService } from '../gldata.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { faFolderOpen, faWandSparkles, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { GLTransferResult } from '../glTransfer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-gldata-upload',
    templateUrl: './gldata-upload.component.html',
    styleUrls: ['./gldata-upload.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatInputModule, NgIf, NgFor, FontAwesomeModule]
})
export class GldataUploadComponent implements OnInit {

  @Input() book: number;
  @Input() month: number;
  @Input() year: number;
  @Input() fileType: string;
  public tb: TrialBalance = new TrialBalance();
  fgGlData: UntypedFormGroup;
  filesToUpload: FileList;
  priorFile: File;
  currentFile: File;
  chromeExtInstalled = false;
  uploading = false;
  uploaded = false;

  disabled = true;
  uploadResults: GLTransferResult;
  uploadSuccess = true;
  uploadIcon = 'fa fa-upload';
  prelimIcon = 'fa fa-file-excel-o fa-lg';
  transferResults = '';
  transferSuccess = true;
  transferIcon = 'fa fa-share';
  headerText = 'Upload & Process File';
  results = 'Successfully uploaded!';
  prelimUrl = '';
  prelimFile = '';

  faFolderOpen = faFolderOpen;
  faWandSparkles = faWandSparkles;
  faSpinner = faSpinner;

  priorFileBook = 0;
  priorFileDate: Date;
  currentFileBook = 0;
  currentFileDate: Date;

  constructor(
    private fb: UntypedFormBuilder,
    private renderer2: Renderer2,
    private glService: GldataService
  ) { }

  ngOnInit() {
    this.priorFile = null;
    this.currentFile = null;

    this.headerText = 'Upload ' + this.fileType + ' Book and export file to Egnyte';

    this.prelimFile = this.setPrelimFileName();
    this.createFormControls();
    this.configureLinksForExtension();
  }

  configureLinksForExtension() {
    let global = this.renderer2.listen('window', 'message', (evt) => {
      if (evt.data.type && (evt.data.type === 'CUBExtIsInstalled')) {
        this.chromeExtInstalled = true;
      }
    });

    setTimeout(function () {
      window.postMessage({ type: 'testCUBExtInstalled', text: '' }, '*');
    }, 500);
    // this is where the page is trying to determine if the CUB extension is installed.  the extension is listening for this message.
    if (!document.location.href.startsWith('https')) {
    }
  }

  createFormControls() {
    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    this.fgGlData = this.fb.group({
      priorInput: new UntypedFormControl(''),
      priorHidden: new UntypedFormControl(''),
      currentInput: new UntypedFormControl(''),
      currentHidden: new UntypedFormControl(''),
    });
  }

  handleFileInput(fileList: FileList, filePeriod: string) {
    if (fileList.length > 0) {
      if (filePeriod === 'prior') {
        this.priorFile = fileList.item(0);
        this.readfile(this.priorFile, 'prior');
      } else {
        this.currentFile = fileList.item(0);
        this.readfile(this.currentFile, 'current');
      }
    } else {
      this.clearFileInfo(filePeriod);
    }
  }

  readfile(file: File, filePeriod: string) {
    let reader = new FileReader();
    reader.onload = () => {
      let values = reader.result.toString().split(',');
      this.setFileInfo(filePeriod, +values[1], values[6]);
    };
    reader.readAsText(file);
  }

  enableUpload() {
    if (this.priorFile !== null && this.currentFile !== null) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  setFileInfo(filePeriod: string, book: number, periodDate: string) {
    if (filePeriod === 'prior') {
      this.fgGlData.controls.priorInput.setValue(this.priorFile.name);
      this.priorFileBook = book;
      this.priorFileDate = new Date(periodDate);
    } else {
      this.fgGlData.controls.currentInput.setValue(this.currentFile.name);
      this.currentFileBook = book;
      this.currentFileDate = new Date(periodDate);
    }

    this.enableUpload();
  }

  clearFileInfo(filePeriod: string) {
    if (filePeriod === 'prior') {
      this.priorFile = null;
      this.fgGlData.controls.priorInput.setValue('');
      this.priorFileBook = 0;
      this.priorFileDate = new Date();
    } else {
      this.currentFile = null;
      this.fgGlData.controls.currentInput.setValue('');
      this.currentFileBook = 0;
      this.currentFileDate = new Date();
    }

    this.enableUpload();
  }

  createJournalEntry() {
    this.uploadResults = new GLTransferResult();
    this.uploadIcon = 'fa fa-spinner fa-spin';
    this.uploading = true;
    this.glService.uploadFiles(this.fileType, this.book, this.month, this.year, this.priorFile, this.currentFile)
      .subscribe((result: GLTransferResult) => {
        console.log('uploadFiles', result);
        this.uploading = false;
        this.uploaded = true;
        if (result.success) {
          this.uploadIcon = 'fa fa-check';
          this.uploadSuccess = true;
          this.prelimUrl = environment.glPrelimFileUrl + this.setPrelimFileName();
        } else {
          this.uploadIcon = 'fa fa-upload';
          this.uploadSuccess = false;
          this.uploadResults = result;
          this.prelimUrl = '';
        }
      });
  }

  setPrelimFileName() {
    let fileName = '_prelim.xlsx';

    // if adding a new case here make sure you add it in GLTransfer.cs: SetPrelimFileName() on the server
    switch (this.book) {
      case 1: {
        fileName = 'accrual_prelim.xlsx';
        break;
      }
    }
    return fileName;
  }

}
