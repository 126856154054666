<div class="cub-sys-home prop-home-dash table-view" [style.height]="_mainHeight">
  <div style="margin: 15px 20px 25px 20px;">
    <button mat-raised-button
            style="float: right;"
            (click)="drawer.toggle()">
      <fa-icon [icon]="faColumns2"
               style="margin-right: 5px;"></fa-icon> Columns
    </button>
    <button mat-raised-button
            (click)="download()"
            style="float: right; margin-right: 25px;">
      <fa-icon [icon]="faDownload"
               style="margin-right: 5px;"></fa-icon> Download
    </button>
    <mat-form-field style="float: right; margin-right: 25px; margin-top: -5px; width: 200px;">
      <mat-label>Filter</mat-label>
      <input matInput
             (keyup)="applyFilter($event.target.value)"
             >
    </mat-form-field>
    <!--
    <div class="warning">
      This screen is a work in progress. Proceed w/ caution.
    </div>
    -->
    <h3 style="display: inline-block;">{{ title }}</h3>
    <span *ngIf="_listItems"
          style="margin-left: 50px;">({{ _listItems ? _listItems.length : '' }} items)</span>
  </div>
  <!-- Column list -->
  <div class="main-table-container"
       style="height: 34px; overflow: hidden;"
       [scrollLeft]="mainTableScrollLeft">
    <!-- below the 17 is for extra padding for the scrollbar in the main list  -->
    <div [style.width.px]="getColWidthTotal() + 17"
         style="padding-right: 17px;"
         class="main-table-header">
      <div [style.width.px]="30"
           [class.cell-sticky]="true"
           [class.right-align]="false"
           [style.zIndex]="100"
           [style.position]="'sticky'"
           [style.left.px]="0">
        &nbsp;
      </div>
      <ng-container *ngFor="let col of displayedColumnHeaders; let i = index">
        <div [style.width.px]="col.minWidth"
             [class.cell-sticky]="col.fixedInTable"
             [class.cell-sticky-last]="isLastFixedCol(col)"
             [class.right-align]="isNumericColumn(col)"
             [style.zIndex]="100 - i"
             [style.position]="col.fixedInTable ? 'sticky' : 'relative'"
             [style.left.px]="getLeftForFixedColumn(col, i)"
             [style.cursor]="col.sortable ? 'pointer' : null"
             [title]="col.tooltip"
             (click)="sortByColumn(col)">
          <fa-icon *ngIf="sortColumn.objectPropertyName === col.objectPropertyName && sortDirection === 'asc'"
                   [icon]="faSortUp"
                   class="sort-icon"></fa-icon>
          <fa-icon *ngIf="sortColumn.objectPropertyName === col.objectPropertyName && sortDirection === 'desc'"
                   [icon]="faSortDown"
                   class="sort-icon"></fa-icon>
          {{ col.shortName }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="main-table-container"
       style="overflow: hidden;">
    <mat-drawer-container class="example-container"
                          [hasBackdrop]="true">
      <mat-drawer #drawer
                  style="overflow: hidden;"
                  [position]="'end'"
                  [mode]="'over'">
        <div style="width: 300px;">
          <div style="font-size: 12px; color: #666; margin-left: 20px; margin-top: 15px;">Views:</div>
          <fa-icon [icon]="faBinoculars"
                   style="margin-right: 15px; margin-left: 20px; color: #666; "></fa-icon>
          <mat-select placeholder="Views"
                      [(value)]="_selectedViewKey"
                      (selectionChange)="viewChange($event)"
                      style="padding: 5px; width: 75%;">
            <mat-option *ngFor="let v of _views"
                        [value]="v.key">
              {{ v.name }}
            </mat-option>
          </mat-select>
          <mat-tree [dataSource]="dataSource"
                    [treeControl]="treeControl"
                    class="column-tree"
                    style="border-top: solid 1px #ddd; margin-top: 10px; padding-top: 10px;">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node"
                           matTreeNodePadding>
              <li class="mat-tree-node">
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button
                        disabled></button>
                <mat-checkbox [checked]="node.columnData.selected"
                              [disabled]="node.columnData.fixedInTable"
                              (change)="columnChanged(node)">{{node.columnData.name}}</mat-checkbox>
              </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                  matTreeNodePadding>
              <li>
                <div class="mat-tree-node">
                  <button mat-icon-button
                          matTreeNodeToggle
                          [attr.aria-label]="'toggle ' + node.columnData.name">
                    <fa-icon [icon]="faChevronRight"
                             *ngIf="!treeControl.isExpanded(node)"
                             style="margin-right: 5px;"></fa-icon>
                    <fa-icon [icon]="faChevronDown"
                             *ngIf="treeControl.isExpanded(node)"
                             style="margin-right: 5px;"></fa-icon>
                  </button>
                  <mat-checkbox [checked]="node.columnData.selected"
                                (change)="parentColumnChanged(node)">{{node.columnData.name}}</mat-checkbox>
                </div>
                <ul [class.column-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </li>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
      </mat-drawer>
      <!-- table data -->
      <mat-drawer-content *ngIf="_listItems">
        <cdk-virtual-scroll-viewport itemSize="35"
                                      minBufferPx="1000"
                                      maxBufferPx="2000"
                                     (scroll)="onScroll($event)"
                                     class="main-table-scroller">
          <div *cdkVirtualFor="let listItem of _listItems"
               [style.width.px]="getColWidthTotal()"
               class="main-table-row">
            <div [style.width.px]="30"
                 [class.cell-sticky]="true"
                 [class.right-align]="false"
                 [style.zIndex]="100"
                 [style.position]="'sticky'"
                 [style.left.px]="0">
              <button mat-icon-button
                      [disableRipple]="true"
                      style="margin-top: -10px; margin-left: -10px;"
                      [matMenuTriggerFor]="actionMenu">
                <fa-icon [icon]="faEllipsisV"
                         class="fa-lg"></fa-icon>
              </button>
              <mat-menu #actionMenu="matMenu"
                        [xPosition]="'after'">
                <button *ngFor="let action of actions"
                        (click)="actionClick(action, listItem)"
                        mat-menu-item>{{ action.title }}</button>
              </mat-menu>
            </div>
            <ng-container *ngFor="let col of displayedColumnHeaders; let i = index">
              <div [style.width.px]="col.minWidth"
                   [style.position]="col.fixedInTable ? 'sticky' : 'relative'"
                   [style.zIndex]="100 - i"
                   [style.left.px]="getLeftForFixedColumn(col, i)"
                   [class.cell-sticky]="col.fixedInTable"
                   [class.cell-sticky-last]="isLastFixedCol(col)"
                   [class.right-align]="isNumericColumn(col)"
                   [class.clickable]="isClickable(col, listItem)"
                   [title]="getPrefix(col) + getFormattedValue(listItem[col.objectPropertyName], col, listItem) + getSuffix(col)"
                   (click)="columnClick(col, listItem)">
                <span class="prefix"
                      *ngIf="listItem[col.objectPropertyName] !== null">{{ getPrefix(col) }}</span>
                <span>{{ getFormattedValue(listItem[col.objectPropertyName], col, listItem) }}</span>
                <span class="suffix"
                      *ngIf="listItem[col.objectPropertyName] !== null">{{ getSuffix(col) }}</span>
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
  <div class="main-table-container"
       style="height: 34px; overflow: hidden;"
       [scrollLeft]="mainTableScrollLeft">
    <div [style.width.px]="getColWidthTotal() + 17"
         class="main-table-header">
      <div [style.width.px]="30"
           [class.cell-sticky]="true"
           [class.right-align]="false"
           [style.zIndex]="100"
           [style.position]="'sticky'"
           [style.left.px]="0">
        &nbsp;
      </div>
      <ng-container *ngFor="let col of displayedColumnHeaders; let i = index">
        <div [style.width.px]="col.minWidth"
             [class.cell-sticky]="col.fixedInTable"
             [class.cell-sticky-last]="isLastFixedCol(col)"
             [class.right-align]="isNumericColumn(col)"
             [style.zIndex]="100 - i"
             [style.position]="col.fixedInTable ? 'sticky' : 'relative'"
             [style.left.px]="getLeftForFixedColumn(col, i)">
          {{ getFooterValue(col) }}
        </div>
      </ng-container>
      <div style="width: 17px;">&nbsp;</div>
    </div>
  </div>

</div>
