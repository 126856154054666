
import { catchError } from 'rxjs/operators';

import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Broker } from './broker';
import { BrokerCommission } from './brokerCommission';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../core/alert.service';
import { UserService } from '../../core/user.service';


@Injectable()
export class BrokerCommissionService {
  private commissionsUrl = environment.cubCloudUrl + 'brokerCommissions';

  private tempList: BrokerCommission[] = [];  // just to show subscribe feature
  private commissionList = new ReplaySubject<BrokerCommission[]>(1);
  commissions$ = this.commissionList.asObservable();   // $ is connvention to indicate observable
  private firmList = new ReplaySubject<string[]>(1);
  firms$ = this.firmList.asObservable();   // $ is connvention to indicate observable

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private userService: UserService
  ) {
  }

  newBrokerCommission(mode: string, tableKey: number): BrokerCommission {
    let bc = new BrokerCommission();
    this.setNewCommissionTableKey(bc, mode, tableKey);
    return bc;
  }

  setNewCommissionTableKey(newCommission: BrokerCommission, mode: string, tableKey: number) {
    // sets the appropriate key to the input tableKey based on the mode
    switch (mode) {
      case 'property': {
        newCommission.propertyKey = tableKey;
        break;
      }
      case 'lease': {
        newCommission.leaseKey = tableKey;
        break;
      }
      case 'dispoProspect': {
        newCommission.dispoProspectKey = tableKey;
        break;
      }
      case 'dispoItem': {
        newCommission.dispoItemKey = tableKey;
        break;
      }
      case 'dispoPackage': {
        newCommission.dispoPackageKey = tableKey;
        break;
      }
      default: {
        // this should never happen so we want to cause a FOREIGN KEY constraint failure
        break;
      }
    }
  }

  getCommissions(mode: string, id: number): Observable<BrokerCommission[]> {
    const url = `${this.commissionsUrl}/${mode}/${id}`;
    let list = this.http.get<BrokerCommission[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  deleteCommission(id: number): Promise<number> {
    const url = `${this.commissionsUrl}/delete/${id}`;
    return this.http
      .delete(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .then(() => id)
      .catch((err) => this.handleError2(err, this.alertService, 'Sorry, there was a problem saving this broker commission. Please contact MIS.'));
  }

  getBrokerageFirms(): Observable<string[]> {
    const url = `${this.commissionsUrl}/firms`;
    return this.http.get<string[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveBrokerCommission(brokerCommission: BrokerCommission) {
    const url = `${this.commissionsUrl}/save`;
    return this.http
      .post<BrokerCommission>(url, JSON.stringify(brokerCommission), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(
        catchError((err) => this.handleError(err, this.alertService, 'Sorry, there was a problem saving this broker commission. Please contact MIS.')));
  }

  getBrokersForFirm(firm: string): Observable<Broker[]> {
    const url = `${this.commissionsUrl}/brokers/firm`;
    return this.http
      .post<Broker[]>(url, JSON.stringify(firm), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveBroker(broker: Broker) {
    const url = `${this.commissionsUrl}/brokers/save`;
    return this.http
      .post<Broker>(url, JSON.stringify(broker), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(
        catchError((err) => this.handleError(err, this.alertService, 'Sorry, there was a problem saving this broker. Please contact MIS.')));
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + alertMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

  private handleError2(error: any, alertService: AlertService, alertMsg: string): Promise<any> {
    let message = error._body;
    alertService.error(alertMsg);
    return Promise.reject(message || error);
  }

}
