import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';
import { LoggerService } from './logger.service';
import { User } from "../user/user";
import { UserService } from 'src/app/core/user.service';
import { FileInfo } from 'src/app/core/fileInfo';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private fileUrl = environment.cubCloudUrl + 'file/';

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private loggerService: LoggerService,
    private userService: UserService) {
  }

  exists(path: string): Observable<boolean> {
    return this.http.get<boolean>(this.fileUrl + 'exists/' + path, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  move(origPath: string, destPath: string): Observable<boolean> {

    let body: string = '{' +
      '"sourcePath": "' + origPath + '",' +
      '"destPath": "' + destPath + '",' +
      '"inheritFromNewParent": "true"' +
      '}';

    return this.http.post<boolean>(this.fileUrl + 'move', body, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  copy(origPath: string, destPath: string): Observable<boolean> {

    let body: string = '{' +
      '"sourcePath": "' + origPath + '",' +
      '"destPath": "' + destPath + '",' +
      '"inheritFromNewParent": "true"' +
      '}';

    return this.http.post<boolean>(this.fileUrl + 'directory/copy', body, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  upload(path: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('path', path);

    let uploadHeaders = this.userService.getUrlUserHeaders('multipart/form-data');
    uploadHeaders = uploadHeaders.delete('content-type');
    uploadHeaders = uploadHeaders.append('Accept', '*/*');
    console.log('http headers', uploadHeaders);
    return this.http.post<any>(this.fileUrl + 'upload', formData, { headers: uploadHeaders, withCredentials: false });
  }

  getFiles(path: string): Observable<FileInfo> {
    return this.http.get<FileInfo>(this.fileUrl + 'files/' + path, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }


  fixFilename(filename: string): string {
    let newName = filename.split('#').join('_');
    newName = newName.split(':').join('-');
    newName = newName.split('>').join('-');
    newName = newName.split('<').join('-');
    newName = newName.split('|').join('-');
    newName = newName.split('{').join('-');
    newName = newName.split('}').join('-');
    newName = newName.split('~').join('-');
    newName = newName.split('^').join('-');
    newName = newName.split(';').join('-');
    newName = newName.split('\\').join('-');
    newName = newName.split('&').join('-');
    newName = newName.split('?').join('-');
    newName = newName.split(' ').join('_');

    return newName;
  }

}
