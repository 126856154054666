import { LeaseBarneyApproved } from './lease-barney-approved';
import { Component, OnInit, Inject } from '@angular/core';
import { NumWithCommasPipe } from 'src/app/shared/num-with-commas.pipe';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LeaseBarneyFull } from './../../lease-barney/lease-barney-full';
import { LeaseBarneyBrief } from './../../lease-barney/lease-barney-brief';
import { LeaseBarneyService } from './../../lease-barney/lease-barney.service';
import { NumWithCommasPipe as NumWithCommasPipe_1 } from '../num-with-commas.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, DatePipe } from '@angular/common';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';


@Component({
    selector: 'cub-lease-barney-lookup-dialog',
    templateUrl: './lease-barney-lookup-dialog.component.html',
    styleUrls: ['./lease-barney-lookup-dialog.component.scss'],
    standalone: true,
    imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgClass, MatButtonModule, DatePipe, NumWithCommasPipe_1]
})
export class LeaseBarneyLookupDialogComponent implements OnInit {
  approvedLeaseBarneys: LeaseBarneyApproved[] = [];
  leaseBarney: LeaseBarneyFull;
  selectedLeaseBarneyKey: number;
  selectedLeaseBarneyName: string;
  myScrollTop: number;
  title: string;

  constructor(
    public readonly dialogRef: MatDialogRef<LeaseBarneyLookupDialogComponent>,
    private router: Router,
    private leaseBarneyService: LeaseBarneyService,
    private np: NumWithCommasPipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.myScrollTop = 0;
    this.selectedLeaseBarneyKey = 0;
    this.title = 'Lease Barney Picker';
    this.buildLeaseBarneyList();
  }

  buildLeaseBarneyList() {
    this.leaseBarneyService.getApprovedLeaseBarneys().then(result => {
      this.approvedLeaseBarneys = result;
    });
  }

  onSelect(l: LeaseBarneyApproved) {
    this.selectedLeaseBarneyKey = l.leaseBarneyKey;
    this.selectedLeaseBarneyName = l.tenantName;
  }

  clickSave() {
    let lb: LeaseBarneyFull;
    this.leaseBarneyService.getLeaseBarney(this.selectedLeaseBarneyKey.toString()).then(result => {
      lb = result;
      this.dialogRef.close({ saved: true, leaseBarney: lb });
    });
  }

  clickClose() {
    let lb: LeaseBarneyFull;
    this.dialogRef.close({ saved: false, leaseBarney: lb });
  }

  viewDetails() {
    if (this.selectedLeaseBarneyKey !== 0) {
      let url = this.router.createUrlTree(['./leaseBarneys/', this.selectedLeaseBarneyKey]);
      window.open(url.toString(), '_blank');
    }
  }

}
