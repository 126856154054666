
import { PhoneNumber } from './../../contact/contact';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddressEditDialogComponent } from './../address-edit-dialog/address-edit-dialog.component';
import { ContactService } from './../contact-picker/contact.service';
import { Contact, ContactType, EmailAddress, ContactSearchParameters } from '../../contact/contact';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { Constants } from '../../core/constants';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoggerService } from '../../core/logger.service';
import { Address } from 'src/app/shared/address';

import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faCopyright } from '@fortawesome/pro-light-svg-icons/faCopyright';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { ContactEditPhoneComponent } from '../contact-edit-phone/contact-edit-phone.component';
import { ContactEditEmailComponent } from '../contact-edit-email/contact-edit-email.component';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'cub-contact-edit-dialog',
    templateUrl: './contact-edit-dialog.component.html',
    styleUrls: ['./contact-edit-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, FontAwesomeModule, MatTabsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, MatAutocompleteModule, MatButtonModule, ContactEditEmailComponent, ContactEditPhoneComponent, AsyncPipe]
})
export class ContactEditDialogComponent implements OnInit {

  contactForm: UntypedFormGroup;
  contactKey: number;
  contact: Contact = new Contact();
  companies: Contact[] = [];
  filteredCompanies: Observable<Contact[]>;
  salutations: Lookup[] = [];
  pcTypes: Lookup[] = [];
  autoCompleteControl = new UntypedFormControl();
  searchTerm$ = new Subject<string>();
  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faUser = faUser;
  faCopyright = faCopyright;
  latestCompanyVal = '';
  tabIndex = 0;

  constructor(
    private dialogRef: MatDialogRef<ContactEditDialogComponent>,
    private loggerService: LoggerService,
    private lookupService: LookupService,
    private dialog: MatDialog,
    private contactService: ContactService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data) {
    this.contact = data.contact;
    this.contactKey = this.contact.pcKey;
    this.createForm();
  }

  ngOnInit() {
    this.loadLookups();
    this.loggerService.logPageVisit('contact edit', '');
    this.autoCompleteControl.valueChanges.subscribe(v => {
      if (typeof v === 'string') {
        this.filteredCompanies = this.searchContacts(v);
      } else {
        this.latestCompanyVal = v;
      }
    });

    if (this.contactKey !== 0) {
      this.contactService.getContactDetail(this.contactKey).subscribe(c => {
        this.contact = c;
        if (this.contact.salutationKey === 0) {
          this.contact.salutationKey = null;
        }
        this.createForm();
      });
    }
  }

  searchContacts(term: string): Observable<Contact[]> {
    let params = new ContactSearchParameters();
    params.text = term;
    params.includePeople = false;
    return this.contactService.searchContacts(params);
  }

  displayFn(c?: Contact): string | undefined {
    return c ? c.companyName : undefined;
  }

  loadLookups() {
    this.salutations = this.lookupService.getTableValues('salutations');
    this.pcTypes = this.lookupService.getTableValues('pcTypes');
  }

  createForm() {
    this.contactForm = this.fb.group({
      firstName: new UntypedFormControl(this.contact.firstName, this.contact.isPerson ? [Validators.required] : []),
      middleName: new UntypedFormControl(this.contact.middleName),
      lastName: new UntypedFormControl(this.contact.lastName, this.contact.isPerson ? [Validators.required] : []),
      jobTitle: new UntypedFormControl(this.contact.jobTitle),
      company: this.autoCompleteControl,
      companyName: new UntypedFormControl(this.contact.companyName, this.contact.isPerson ? [] : [Validators.required]),
      pcTypes: new UntypedFormControl(this.contact.contactTypes),
      salutation: new UntypedFormControl(this.contact.salutationKey, this.contact.isPerson ? [Validators.required] : []),
      notes: new UntypedFormControl(this.contact.notes)
    });
    if (this.contactKey !== 0) {
      this.contactService.getContactDetail(this.contact.companyKey).subscribe(c => {
        this.contactForm.controls.company.setValue(c);
      });
    }
  }

  showLocation(c: Contact) {
    return (c.addresses.length === 0 ? '' : '| ' + c.addresses[0].city + ', ' + c.addresses[0].stateID);
  }

  formToObject() {
    this.contact.contactTypes = this.contactForm.controls.pcTypes.value.map(v => (typeof v === 'string' ? new ContactType(parseInt(v, 10), '') : v));
    if (this.contact.isPerson) {
      this.contact.firstName = this.contactForm.controls.firstName.value;
      this.contact.middleName = this.contactForm.controls.middleName.value;
      this.contact.lastName = this.contactForm.controls.lastName.value;
      this.contact.jobTitle = this.contactForm.controls.jobTitle.value;
      if (this.contactForm.controls.company.value !== null) {
        this.contact.companyKey = this.contactForm.controls.company.value.pcKey;
        this.contact.companyName = this.contactForm.controls.company.value.companyName;
      }
      this.contact.salutationKey = this.contactForm.controls.salutation.value;
      this.contact.notes = this.contactForm.controls.notes.value;
    } else {
      this.contact.companyName = this.contactForm.controls.companyName.value;
    }
  }

  onSubmit() {
    this.formToObject();
    this.checkCompanySelection();
    if (this.contactForm.valid) {
      this.contactService.saveContact(this.contact).subscribe(c => {
        if (c.pcKey !== 0) {
          this.dialogRef.close(c);
        } else {
          alert('saving the contact failed');
        }
      });
    } else {
      this.tabIndex = 0;
    }
  }

  checkCompanySelection() {
    if (this.latestCompanyVal.length > 0 && this.contactForm.controls.company.value.key === undefined) {
      this.contactForm.controls.company.setErrors({ valid: false });
    }
  }

  /* email stuff */
  updateEmailAddress(email: EmailAddress) {
    let tempEmail = this.contact.emailAddresses.filter(e => e.emailKey === email.emailKey);
    if (tempEmail.length > 0) {
      tempEmail[0].email = email.email;
      tempEmail[0].typeKey = email.typeKey;
    }
    this.contactForm.markAsDirty();
  }

  removeEmailAddress(email: EmailAddress) {
    let i = 0;
    let indexToRemove = 0;
    this.contact.emailAddresses.forEach(e => {
      if (e.emailKey === email.emailKey) {
        indexToRemove = i;
      }
      i++;
    });
    this.contact.emailAddresses.splice(indexToRemove, 1);
    this.contactForm.markAsDirty();
  }

  addEmail() {
    let tempEmail = new EmailAddress();
    tempEmail.editMode = true;
    tempEmail.emailKey = 0;
    // if there are any other "new" emails, give this a unique key that still indicates it's new (negative number)
    if (this.contact.emailAddresses.filter(e => e.emailKey <= 0).length > 0) {
      tempEmail.emailKey = this.contact.emailAddresses.filter(e => e.emailKey <= 0).map(e => e.emailKey).sort()[0] - 1;
    }
    this.contact.emailAddresses.push(tempEmail);
  }

  /* phone stuff */
  updatePhone(phone: PhoneNumber) {
    let tempPhone = this.contact.phoneNumbers.filter(e => e.phoneKey === phone.phoneKey);
    if (tempPhone.length > 0) {
      tempPhone[0].number = phone.number;
      tempPhone[0].typeKey = phone.typeKey;
    }
    this.contactForm.markAsDirty();
  }

  removePhone(phone: PhoneNumber) {
    let i = 0;
    let indexToRemove = 0;
    this.contact.phoneNumbers.forEach(e => {
      if (e.phoneKey === phone.phoneKey) {
        indexToRemove = i;
      }
      i++;
    });
    this.contact.phoneNumbers.splice(indexToRemove, 1);
    this.contactForm.markAsDirty();
  }

  addPhone() {
    let tempPhone = new PhoneNumber();
    tempPhone.editMode = true;
    tempPhone.phoneKey = 0;
    // if there are any other "new" phone, give this a unique key that still indicates it's new (negative number)
    if (this.contact.phoneNumbers.filter(e => e.phoneKey <= 0).length > 0) {
      tempPhone.phoneKey = this.contact.phoneNumbers.filter(e => e.phoneKey <= 0).map(e => e.phoneKey).sort()[0] - 1;
    }
    this.contact.phoneNumbers.push(tempPhone);
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null || c2 === undefined ? '' : c2.toString());
  }

  comparePCTypes(c1: number, c2: ContactType): boolean {
    return c1.toString() === (c2 === null || c2 === undefined ? '' : c2.typeKey.toString());
  }

  getAddressCount(): string {
    return (this.contact.addresses.length > 0 ? '(' + this.contact.addresses.length.toString() + ')' : '');
  }
  getPhoneCount(): string {
    return (this.contact.phoneNumbers.length > 0 ? '(' + this.contact.phoneNumbers.length.toString() + ')' : '');
  }
  getEmailCount(): string {
    return (this.contact.emailAddresses.length > 0 ? '(' + this.contact.emailAddresses.length.toString() + ')' : '');
  }
  getTitleName(): string {
    let title = '';
    if (this.contact.pcKey <= 0) {
      if (this.contact.isPerson) {
        title = 'New Person';
      } else {
        title = 'New Company';
      }
    } else {
      title = (this.contact.isPerson ? this.contact.firstName + ' ' + this.contact.lastName : this.contact.companyName);
    }
    return title;
  }

  cancel() {
    this.dialogRef.close(this.contact);
  }

  addAddress() {
    let tempAddress = new Address();
    tempAddress.addressKey = 0;
    if (this.contact.isPerson) {
      tempAddress.typeKey = 5;
    } else {
      tempAddress.typeKey = 2;
    }
    // if there are any other "new" phone, give this a unique key that still indicates it's new (negative number)
    if (this.contact.addresses.filter(e => e.addressKey <= 0).length > 0) {
      tempAddress.addressKey = this.contact.addresses.filter(e => e.addressKey <= 0).map(e => e.addressKey).sort()[0] - 1;
    }
    this.contact.addresses.push(tempAddress);
    this.editAddress(this.contact.addresses[this.contact.addresses.length - 1]);
  }

  editAddress(address: Address) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '900px';
    dialogConfig.data = {
      address
    };
    const dialogRef = this.dialog.open(AddressEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(d => {
      if (d.saved) {
        console.log('address return', d);
        this.contact.addresses.filter(a => a.addressKey === address.addressKey)[0] = Object.assign([], d.address);
        this.contactForm.markAsDirty();
      }
    });
  }

  removeAddress(address: Address) {
    let i = 0;
    let indexToRemove = 0;
    this.contact.addresses.forEach(a => {
      if (a.addressKey === address.addressKey) {
        indexToRemove = i;
      }
      i++;
    });
    this.contact.addresses.splice(indexToRemove, 1);
    this.contactForm.markAsDirty();
  }

}
