
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Lookup } from './lookup';
import { LookupTable } from './lookup-table';
import { User } from '../user/user';
import { UserService } from '../core/user.service';

@Injectable()
export class LookupService {

  private lookupUrl = environment.cubCloudUrl + 'lookuptable';

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getLookupTables(): Observable<LookupTable[]> {
    const url = `${this.lookupUrl}`;
    let list = this.http.get<LookupTable[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getTableValues(tableName: string): Lookup[] {
    let tables: LookupTable[] = JSON.parse(localStorage.getItem('lookupTables'));
    let lookupValues: Lookup[] = [];
    tables.forEach(table => {
      if (table.name === tableName) {
        let values: Lookup[] = table.values;
        values.forEach(value => {
          lookupValues.push(new Lookup(value.id, value.name, value.parentId));
        });
      }
    });
    return lookupValues;
  }
}
