import { TopMenuComponent } from './../core/top-menu/top-menu.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { LoggerService } from './../core/logger.service';
import { AlertService } from './../core/alert.service';
import { environment } from './../../environments/environment';
import { TenantCompany } from './tenant-company';
import { TenantCompanyLease } from './tenant-company-lease';
import { TenantCompanySubsidiary } from './tenant-company-subsidiary';
import { UserService } from '../core/user.service';
import { ReturnMessage } from '../shared/returnMessage';
import { ArAgingDetail, PropertyService } from '../property';

@Injectable()
export class TenantCompanyService {
  private tenantCompanyUrl = environment.cubCloudUrl + 'tenantcompanies';
  private tenantCompanyList = new ReplaySubject<TenantCompany[]>(1);
  private tempTenantCompanyList: TenantCompany[] = [];
  tenantCompanies$ = this.tenantCompanyList.asObservable();
  private tenantRollupList = new ReplaySubject<TenantCompany[]>(1);
  private tempTenantRollupList: TenantCompany[] = [];
  tenantRollup$ = this.tenantRollupList.asObservable();
  private orphanLeaseList = new ReplaySubject<TenantCompanyLease[]>(1);
  private tempOrphanLeaseList: TenantCompanyLease[] = [];
  orphanLeases$ = this.orphanLeaseList.asObservable();

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private userService: UserService,
    private propertyService: PropertyService,
    private loggerService: LoggerService) {
    this.getTenantCompanies();
    this.getTenantRollup(true, true);
  }

  getTenantCompanies() {
    const url = `${this.tenantCompanyUrl}`;
    this.http.get<TenantCompany[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(tcs => {
      this.tenantCompanyList.next(tcs);
      this.tempTenantCompanyList = tcs;
    });
  }

  getRefreshedCompanyList(includeCnt: boolean, includeJv: boolean) {
    const url = `${this.tenantCompanyUrl}/refreshList`;
    this.http.post<TenantCompany[]>(url, {includeCnt, includeJv}, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(tenantCompanies => {
      this.tenantCompanyList.next(tenantCompanies);
      this.tempTenantCompanyList = tenantCompanies;
    });
  }


  getTenantRollup(includeCnt: boolean, includeJv: boolean) {
    const url = `${this.tenantCompanyUrl}/rolluplist`;
    return this.http.post<TenantCompany[]>(url, {includeCnt, includeJv}, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(trl => {
      this.tenantRollupList.next(trl);
      this.tempTenantRollupList = trl;
    });
  }

  getOrphanLeases() {
    const url = `${this.tenantCompanyUrl}/orphanleases`;
    return this.http.get<TenantCompanyLease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(ol => {
      this.orphanLeaseList.next(ol);
      this.tempOrphanLeaseList = ol;
    });
  }

  getTenantCompany(id: string): Observable<TenantCompany> {
    const url = `${this.tenantCompanyUrl}/${id}`;
    return this.http.get<TenantCompany>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTenantCompanyLeases(id: string): Observable<TenantCompanyLease[]> {
    const url = `${this.tenantCompanyUrl}/${id}/companyleases`;
    return this.http.get<TenantCompanyLease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTenantCompanySubsidiaries(companyKey: number, rollupTypeKey: number): Observable<TenantCompanySubsidiary[]> {
    const url = `${this.tenantCompanyUrl}/subsidiaries`;
    return this.http.post<TenantCompanySubsidiary[]>(url, { companyKey: companyKey.toString(), rollupTypeKey: rollupTypeKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  updateLeaseLink(leaseKey: number, oldCompanyKey: number, newCompanyKey: number) {
    const url = `${this.tenantCompanyUrl}/leaselinkupdate`;
    return this.http.post<ReturnMessage>(url, { leaseKey: leaseKey.toString(), oldCompanyKey: oldCompanyKey.toString(), newCompanyKey: newCompanyKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
    .toPromise();
  }

  deleteLeaseLink(leaseKey: number, companyKey: number) {
    const url = `${this.tenantCompanyUrl}/leaselinkdelete`;
    return this.http.post<ReturnMessage>(url, { leaseKey: leaseKey.toString(), companyKey: companyKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
    .toPromise();
  }

  reloadLeaseCache() {
    const url = `${this.tenantCompanyUrl}/reloadleasecache`;
    return this.http.post<ReturnMessage>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
    .toPromise();
  }

  updateParentCompanyLink(companyKey: number, oldParentCompanyKey: number, newParentCompanyKey: number, rollupTypeKey: number): Observable<boolean> {
    const url = `${this.tenantCompanyUrl}/parentcompanylinkupdate`;
    return this.http.post<boolean>(url, { companyKey: companyKey.toString(), oldParentCompanyKey: oldParentCompanyKey.toString(), newParentCompanyKey: newParentCompanyKey.toString(), rollupTypeKey: rollupTypeKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  deleteParentCompanyLink(companyKey: number, parentCompanyKey: number, rollupTypeKey: number): Observable<boolean> {
    const url = `${this.tenantCompanyUrl}/parentcompanylinkdelete`;
    return this.http.post<boolean>(url, { companyKey: companyKey.toString(), parentCompanyKey: parentCompanyKey.toString(), rollupTypeKey: rollupTypeKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  deleteCompany(companyKey: number): Observable<boolean> {
    const url = `${this.tenantCompanyUrl}/deletecompany`;
    return this.http.post<boolean>(url, { companyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }


  createTenantCompany(companyName: string): Observable<number> {
    const url = `${this.tenantCompanyUrl}/createtenantcompany`;
    return this.http.post<number>(url, { companyName: companyName.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + alertMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

  private handleError2(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



}
