import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ListService } from '../../core/list.service';
import { ListOptions } from '../list-control/listOptions';
import { ListItem } from '../list-item/listItem';
import { ListGroupComponent } from '../list-group/list-group.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';


@Component({
    selector: 'cub-list-result',
    templateUrl: './list-result.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, ListItemComponent, ListGroupComponent]
})
export class ListResultComponent implements OnInit, OnChanges {

  @ViewChild('kg') kg: ElementRef;
  @Input() items: ListItem[];
  @Input() groups: ListItem[];
  @Input() searchText: string;
  @Input() isLoading: boolean;
  @Input() itemMode: string;
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  @Output() onScrollTop: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenu: EventEmitter<any> = new EventEmitter();
  selectedGroupName: string;
  groupingBy: string;
  listOptions: ListOptions;
  selectedId: string;
  listItemHeight = 54;
  myScrollTop = 0;
  system: string;
  listHeight: number;

  constructor(
    private listService: ListService
  ) {
    this.items = [];
    this.groups = [];
    this.selectedGroupName = '';
    this.groupingBy = '';
    this.selectedId = '';
  }

  ngOnInit() {
    this.listHeight = 500;
    this.listService.listSettings$.subscribe(listSettings => {
      this.listOptions = listSettings;
      if (this.listOptions.groupBy.length > 0) {
        this.groupingBy = this.listOptions.groupBy.filter(g => g.selected)[0].name;
      }
      this.selectedGroupName = this.listOptions.selectedGroup;
      this.system = this.listOptions.name;
    });
    // make items 2 col by default
    if (this.itemMode === undefined) {
      this.itemMode = 'twoCol';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // 2/8/19 - for some reason this code caused the first item selected to remain selected until selected again.
    // not sure why right now, but I'm turning it off for now.  Will investigate further when time allows.
    if (changes['items']) {
      if (changes['items'].currentValue.length > 0 && this.selectedId.length > 0) {
        this.highlightSelectedItem();
      }
    }
  }

  highlightSelectedItem(): void {
    if (this.items) {
      this.items.forEach(i => {
        i.active = i.itemId.toString() === this.selectedId.toString();
      });
    } else {
      this.items.forEach(i => {
        i.active = false;
      });
    }
  }

  backToList() {
    this.selectedGroupName = '';
    this.listOptions.selectedGroup = this.selectedGroupName;
    this.listService.listUpdated(this.listOptions);
  }

  groupSelected(): boolean {
    return this.selectedGroupName !== '' && this.selectedGroupName !== undefined && this.selectedGroupName !== null;
  }

  showGroups(): boolean {
    // show list of groups if we are grouping by something and a specific group isn't selected
    return (this.groupingBy !== 'Nothing' && this.groupingBy !== '') && (this.selectedGroupName === null || this.selectedGroupName === '' || this.selectedGroupName === undefined);
  }

  showList(): boolean {
    return !this.showGroups();
  }

  selectItem(item: ListItem) {
    item.active = true;
    this.selectedId = item.itemId;
    this.items.forEach(i => {
      i.active = i.itemId.toString() === this.selectedId.toString();
    });
    this.onSelection.emit(item);
  }

  contextMenu(event: MouseEvent) {
    this.onContextMenu.emit(event);
  }
}
