
<h2 style="margin-bottom: 20px;">{{ data.title }}</h2>

<div id="helpDiv" *ngIf="data.type === 'help'">
  <i class="fa fa-close fa-lg" style="float: right; margin-top: -55px;" (click)="cancel()"></i>
  What is a View?  A View is a personalized list of objects in CUB that you can save and always have easy access to.
  Within each view, you can save filter, grouping, and sorting settings along with any text you searched for.
  <br><br>
  For instance, you can have one view that shows all of the property in the portfolio, and another view that shows just the properties you care about.  Then you can quickly toggle between the two!  Amazing!
  <br><br>
  <h5 style="margin-bottom: 5px; margin-top: 15px;"><i class="fa fa-check"></i> View Selection</h5>
  We've already created a view for you called "My View".  When you create others, you can easily toggle between them by selecting them at the top of the view drop down.  The one with the check box
  next to it is the one that's currently selected.
  <h5 style="margin-bottom: 5px; margin-top: 15px;"><i class="fa fa-plus"></i> Create a New View</h5>
  To create a new view, first make any changes to list that you want to save (like filtering or sorting), then select the view drop down and select "Save as New View".  You will then be prompted
  to enter a name for the view.
  <h5 style="margin-bottom: 5px; margin-top: 15px;"><i class="fa fa-save"></i> Save Current View</h5>
  If you ever want to make changes to the view, simply make the changes to the list then select "Save to Current View".
  <h5 style="margin-bottom: 5px; margin-top: 15px;"><i class="fa fa-bookmark"></i> The Default View</h5>
  The default view (the one with the bookmark icon next to it), is the one that will automatically load when the screen loads.  To set this, just select the view want as the default, then select "Set as Default View".
  <h5 style="margin-bottom: 5px; margin-top: 15px;"><i class="fa fa-trash-o"></i> Rename/Delete View</h5>
  You can easily rename or delete the current view by selecting those options as well.  You must always have 1 view, though.  CUB won't let you delete your only view.
</div>

<div id="renameDiv" *ngIf="data.type === 'rename'">
  <form (ngSubmit)="renameView()" #renameForm="ngForm">
    <mat-form-field style="width: 100%; margin-bottom: 20px;">
      <mat-label>View Name</mat-label>
      <input matInput id="renameTxt" name="renameTxt" #renameTxt="ngModel" [(ngModel)]="viewName"  required minlength="4" />
      <mat-hint>Must be at least 4 characters long</mat-hint>
    </mat-form-field>
    <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
    <button type="submit" [disabled]="!renameForm.form.valid" mat-raised-button color="primary" style="float: right;">Rename</button>
  </form>
</div>

<div id="saveAsDiv" *ngIf="data.type === 'saveAs'">
  <form (ngSubmit)="saveNewView()" #saveAsForm="ngForm">
    <mat-form-field style="width: 100%; margin-bottom: 20px;">
      <mat-label>View Name</mat-label>
      <input matInput id="saveTxt" name="saveTxt" #saveTxt [(ngModel)]="viewName"  required minlength="4" />
      <mat-hint>Must be at least 4 characters long</mat-hint>
    </mat-form-field>
    <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
    <button type="submit" [disabled]="!saveAsForm.form.valid" mat-raised-button color="primary" style="float: right;">Save</button>
  </form>
</div>

<div id="deleteDiv" *ngIf="data.type === 'delete'">
  <div *ngIf="!onlyView" style="width: 100%; margin-bottom: 20px;">Are you sure you want to delete <span style="font-weight: bold;">'{{ viewName }}'</span>?</div>
  <div *ngIf="onlyView" style="width: 100%; margin-bottom: 20px;">I'm sorry, but you can't delete your only view - you must always have at least one</div>
  <button *ngIf="onlyView" mat-raised-button color="primary" (click)="cancel()">OK</button>
  <button *ngIf="!onlyView" mat-raised-button (click)="cancel()">Cancel</button>
  <button *ngIf="!onlyView" mat-raised-button color="primary" (click)="deleteView()" style="float: right;">Delete</button>
</div>
