export class Building {
  stateID: string;
  classKey: number;
  propertyKey: number;
  buildingKey: number;
  buildingID: string;
  buildingDesc: string;
  propertyDesc: string;
  levels: number;
  grossSpace: number;
  leasableSpace: number;
  warehouseSpace: number;
  officeSpace: number;
  retailSpace: number;
  otherSpace: number;
  excludableSpace: number;
  clearHt: string;
  carSpaces: number;
  builtDt: Date;
  roofType: string;
  exteriorConstruction: string;
  addressKey: number;
  address1: string;
  city: string;
  stateKey: number;
  zipCode: string;
  county: string;
  country: string;
  latitude: number;
  longitude: number;
  creatorUID: string;
  creatorDt: string;
  lastUID: string;
  lastDt: string;
  unitCount: number;
  lastEnvironmentalDt: Date;
  lastRehabDt: Date;
  trailerSpaces: number;
  contractor: string;
  architect: string;
  environmentalNotes: string;
  occupancy: number;
  occupiedSpace: number;
  occupiedUnits: number;
  yardiSBLDGID: string;
  yardiHMY: number;
  isDepartment: string;
  buildingNotes: string;
  leedCategoryKey: number;
  leedStatusKey: number;
  leedCertifiedDt: Date;
  leedPoints: number;
  enrolledEnergyStarPortfolioMgmt: boolean;
  energyStarCertifiedDt: Date;

  environmentalPhase1?: boolean;
  alta?: boolean;
  pcaDt?: Date;
  structuralDrawingsFound?: boolean;
  structuralReviewPerformed?: boolean;
  exteriorLightingType?: string;
  landscapeIrrigationControllerType?: string;
  landscapedTurfAreaSf?: number;

  // client side fields
  unitsExpanded: boolean;

  constructor() {
    this.unitsExpanded = true;
  }
}

