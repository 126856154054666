import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

import * as moment from 'moment';
import { Lookup } from 'src/app/core/lookup';

import { LookupService } from '../../core/lookup.service';
import { User } from '../../user/user';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-cub-user-edit-dialog',
    templateUrl: './cub-user-edit-dialog.component.html',
    styleUrls: ['./cub-user-edit-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatCheckboxModule, MatButtonModule]
})
export class CubUserEditDialogComponent implements OnInit {

  propertiesList: Lookup[] = [];
  departments: Lookup[] = [];

  cubUserForm: UntypedFormGroup;
  cubUser: User;

  constructor(
    private readonly dialogRef: MatDialogRef<CubUserEditDialogComponent>,
    private readonly lookupService: LookupService,
    private readonly fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.cubUser = data.cubUser;
  }

  ngOnInit(): void {
    this.loadLookups();
    this.createForm();
  }

  private loadLookups() {
    this.propertiesList = this.lookupService.getTableValues('yardiPropLists');
    this.departments = this.lookupService.getTableValues('bonusDepartments');
  }

  private createForm() {
    this.cubUserForm = this.fb.group({
      firstName: new UntypedFormControl(this.cubUser.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.cubUser.lastName, [Validators.required]),
      accountName: new UntypedFormControl(this.cubUser.accountName, [Validators.required]),
      department: new UntypedFormControl(this.cubUser.department, [Validators.required]),
      pTOHoursGiven: new UntypedFormControl(this.cubUser.pTOHoursGiven, [Validators.required]),
      hireDt: new UntypedFormControl(this.cubUser.hireDt, [Validators.required]),
      birthday: new UntypedFormControl(this.cubUser.birthday, [Validators.required]),
      birthdayIsPublic: new UntypedFormControl(this.cubUser.birthdayIsPublic ?? true),
      isPropertyMgr: new UntypedFormControl(this.cubUser.isPropertyMgr ?? false),
      useNewPortal: new UntypedFormControl(this.cubUser.useNewPortal ?? false),
      yardiPropertyListHmy: new UntypedFormControl(this.cubUser.yardiPropertyListHmy),
    });

    combineLatest([
      this.cubUserForm.controls.firstName.valueChanges.pipe(startWith('')),
      this.cubUserForm.controls.lastName.valueChanges.pipe(startWith('')),
    ]
    ).subscribe(([firstName, lastName]) => {
      if (!this.cubUserForm.controls.accountName.dirty) {
        // auto compute account name only if the field has not already been edited
        const initial = firstName ? firstName.charAt(0) : "";
        const computedAcctName = `${initial}${lastName}`.toLowerCase();
        this.cubUserForm.controls.accountName.setValue(computedAcctName);
      }
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const submission = {
      ...this.cubUser,
      ...this.cubUserForm.value,
      accountName: `CENTERPOINT-PRO\\${this.cubUserForm.value.accountName}`,
      hireDt: moment(this.cubUserForm.value.hiredDt).format('MM/DD/YYYY'),
      birthday: moment(this.cubUserForm.value.birthday).format('MM/DD/YYYY'),
    };

    console.log('onSubmit', submission);
    if (submission['yardiPropertyListHmy'] === null) {
      submission['yardiPropertyListHmy'] = 0;
    } else {
      submission['yardiPropertyListHmy'] = parseInt(submission['yardiPropertyListHmy']);  //  this needs to be an int
    }
    console.log('onSubmit2', submission);

    this.dialogRef.close({
      payload: submission,
      status: 'saved',
    });
  }

  onCancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close({ status: 'cancelled' });
  }

}
