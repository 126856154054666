<div id="invDashboard"
     class="dashboard"
     style="padding-top: 0;">
  <div class="app-toolbar">
    <button mat-stroked-button
            style="margin-right: 15px;"
            (click)="openHelp()">
      <fa-icon [icon]="faQuestionSquare"
               class="fas fa-lg"
               style="margin-right: 5px;"></fa-icon> Help
    </button>
    <button mat-stroked-button
            [matMenuTriggerFor]="otherMenu"
            style="margin-right: 15px;">
      <fa-icon [icon]="faFileChartPie"
               class="fa-lg"
               style="margin-right: 5px;"></fa-icon> Reports <fa-icon [icon]="faCaretDown"
               class="fa-sm"
               style="margin-right: 5px; color: #999; margin-left: 2px;"></fa-icon>
    </button>
    <mat-menu #otherMenu="matMenu"
              [yPosition]="'below'"
              [xPosition]="'after'"
              [overlapTrigger]="false">
      <button mat-menu-item
              (click)="runReport('AcqPipeline-Revenue')">Revenue Pipeline Report</button>
      <button mat-menu-item
              (click)="runReport('AcqPipeline-ITC')">ITC Pipeline Report</button>
      <button mat-menu-item
              (click)="runReport('AcqPipeline-CalEast')">CalEast Pipeline Report</button>
      <button mat-menu-item
              (click)="runReport('AcqPipeline-Region')">Region Pipeline Report</button>
    </mat-menu>
    <button mat-raised-button
            color="primary"
            (click)="newDeal()">
      <fa-icon [icon]="faPlus"
               class="fa-lg"
               style="margin-right: 5px;"></fa-icon> New Deal
    </button>
  </div>
  <mat-tab-group #mainTabs
                 name="mainTabs"
                 [mat-stretch-tabs]="false"
                 (selectedTabChange)="tabChange($event)"
                 selectedIndex="0">
    <mat-tab label="Dashboard">
      <div class="investment-dashboard-container">
        <cub-investment-pipeline-summary></cub-investment-pipeline-summary>
        <mat-card appearance="outlined" style="padding: 10px;">
          <h5><i class="fa fa-calendar"></i> &nbsp;Upcoming Dates</h5>
          <cub-investment-date-summary></cub-investment-date-summary>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="Grid"
             [disabled]="false">
      <cub-table [listItems]="filteredInvestmentsForGrid"
                 [views]="tableViews"
                 [selectedViewKey]="selTableViewKey"
                 [actions]="actions"
                 [title]="tableTitle"
                 [gridHeightPercent]="98"
                 class="inv-grid-view"
                 (actionSelected)="onTableActionSelection($event)"></cub-table>
    </mat-tab>
    <mat-tab label="Map"
             [disabled]="false">
      <cub-map-multiple [markers]="mapMarkers"
                        [mapHeight]="mapHeight"
                        [forceUpdate]="mapForceUpdate"
                        (addressChange)="openAddressForm($event)"
                        [zoom]="mapZoom">
      </cub-map-multiple>
    </mat-tab>
  </mat-tab-group>
</div>
