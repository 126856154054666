import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Team, Bracket } from './bracket/bracket';

@Injectable()
export class TeamService {

  user: any;
  private rootUrl = environment.cubCloudUrl + 'marchmadness/';
  private httpOptions = {};

  constructor(
    private http: HttpClient
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cub-User': 'kgerman',
        'Cub-Auth-Code': 'f98b441d-4963-4812-b8e0-0e47d4dc0d1c'
      })
    };
  }

  getTeams() {
    return this.http.get<Team[]>(this.rootUrl + 'getTeams', this.httpOptions);
  }
}
