import { HttpClient, HttpHeaders } from "@angular/common/http";
import { formatDate } from '@angular/common';
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { ReplaySubject } from "rxjs";
import { Report } from "../report";
import { ListOptions, ListView } from "../shared/list-control";
import { Security } from "../user/security";
import { User } from "../user/user";
import { UserMenu } from "../user/user-menu";

@Injectable()
export class UserService {
  private rootUrlMe = environment.cubCloudUrl + "users/me"; // URL to web api
  private rootUrl = environment.cubCloudUrl + "users";
  private graphUrl = environment.cubCloudUrl + "graph";

  private userMenu = new ReplaySubject<UserMenu>(1);
  userMenu$ = this.userMenu.asObservable(); // $ is onnvention to indicate observable
  private reports = new ReplaySubject<Report[]>(1);
  reports$ = this.reports.asObservable(); // $ is onnvention to indicate observable
  private userData = new ReplaySubject<User>(1);
  userData$ = this.userData.asObservable();
  private securityData = new ReplaySubject<Security>(1);
  security$ = this.securityData.asObservable();
  private secDataLocal: Security;

  constructor(private http: HttpClient) {
    // i needed to copy this here when we moved to the cloud or else the property auth gaurd didn't work on direct loads.  no idea why, but it works and shouldn't harm anything
    if (localStorage.getItem("secMenuTime") !== null) {
      let sec = JSON.parse(localStorage.getItem("secObj")) as Security;
      this.secDataLocal = sec;
      this.securityData.next(sec);
    }
  }

  downloadImage(username: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.get('https://cubfilestorage.blob.core.windows.net/userphotos/' + username + '.jpg', httpOptions);
  }

  getMyDetails() {
    return this.http.get<any>(`https://graph.microsoft.com/v1.0/me?$select=lastPasswordChangeDateTime`);  //?$filter=ResourceVisualization/containerType='microsoft_graph_drive_item'
  }

  getPowerBIGroups() {
    return this.http.get<any>(`https://api.powerbi.com/v1.0/myorg/groups`);
  }

  getPowerBIReportsInGroup(groupId: string) {
    return this.http.get<any>(`https://api.powerbi.com/v1.0/myorg/groups/` + groupId + `/reports`);
  }

  getLocalGraphToken(): string {
    return localStorage.getItem('graphToken');
  }

  getUserEvents() {
    const untilDt = moment().add(7, 'days').toDate();
    return this.http.get<any>("https://graph.microsoft.com/v1.0/me/events?$top=3&$orderby=start/dateTime&$filter=end/dateTime ge '" + new Date().toISOString() + "' and start/dateTime le '" + untilDt.toISOString() + "'");
  }

  convertUtcToLocal(utcDate: string): string {
    const localDateObj = moment(utcDate).format('MMM D, h:mm a') + ' UTC';
    return moment(new Date(localDateObj).toString()).format('MMM D, h:mm a');
  }

  convertLocalToUtc(localDate: string): string {
    const localDateObj = new Date(localDate);
    const localOffset = localDateObj.getTimezoneOffset() * 60 * 1000;
    const utcDateObj = new Date(localDateObj.getTime() + localOffset);
    const utcDate = utcDateObj.toISOString();
    console.log('utcDate: ' + utcDate);
    return utcDate;
  }

  getUrlUserHeaders(contentType: string) {
    var httpHeaders = new HttpHeaders();
    if (localStorage.getItem("user") !== null) {
      let localUser = JSON.parse(localStorage.getItem("user")) as User;
      if (contentType != '') {
        httpHeaders = httpHeaders.append('Content-Type', contentType);
      }
      httpHeaders = httpHeaders.append('Cub-User', localUser.accountName?.replace('CENTERPOINT-PRO\\', ''));
      httpHeaders = httpHeaders.append('Cub-Auth-Code', localUser.azureAuthCode);
    }
    return httpHeaders;
  }

  getRecentFiles() {
    var httpHeaders = new HttpHeaders();
    httpHeaders = this.getUrlUserHeaders('application/json');
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('graphToken'));
    return this.http.get<any>("https://graph.microsoft.com/v1.0/me/drive/recent?$top=10", {
      headers: httpHeaders
    });
  }

  getRecentOfficeItems() {
    var httpHeaders = new HttpHeaders();
    httpHeaders = this.getUrlUserHeaders('application/json');
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('graphToken'));
    return this.http.get<any>(`https://graph.microsoft.com/v1.0/me/insights/used?$orderby=LastUsed/LastAccessedDateTime desc`, {  //?$filter=ResourceVisualization/containerType='microsoft_graph_drive_item'
      headers: httpHeaders
    });
  }

  getDrives() {
    var httpHeaders = new HttpHeaders();
    httpHeaders = this.getUrlUserHeaders('application/json');
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('graphToken'));
    return this.http.get<any>("https://graph.microsoft.com/v1.0/me/drives", {
      headers: httpHeaders
    });
  }

  getUserSchedule(token: string, emailAddress: string, dt: string) {
    var httpHeaders = new HttpHeaders();
    httpHeaders = this.getUrlUserHeaders('application/json');
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + token);

    var jsonToPost = {
      "schedules": [emailAddress],
      "startTime": {
        "dateTime": dt + "T07:00:00",
        "timeZone": "Central Standard Time"
      },
      "endTime": {
        "dateTime": dt + "T17:00:00",
        "timeZone": "Central Standard Time"
      },
      "availabilityViewInterval": 30
    }
    return this.http.post<string>('https://graph.microsoft.com/v1.0/users/b0f45e78-7f4d-45ad-9d5c-f7abbf0aade7/calendar/getSchedule', jsonToPost, {
      headers: httpHeaders
    });
  }

  getGraphToken() {
    console.log(this.graphUrl + '/token');
    return this.http.get(this.graphUrl + '/token', {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false,
      responseType: 'text'
    });
  }

  getUserList() {
    return this.http.get<User[]>(this.rootUrl, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  getAllUsers() {
    let url = `${this.rootUrl}/all`;
    return this.http.get<User[]>(url, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  addUser(u: User) {
    let url = `${this.rootUrl}/new`;
    return this.http.post<User>(url, u, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  removeUser(accountName: string) {
    let url = `${this.rootUrl}/remove/${accountName}`;
    return this.http.post<boolean>(url, '', {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  getOtherUser(username: string) {
    let url = `${this.rootUrl}` + "/" + username;
    return this.http.get<User>(url, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  refreshAzureAuthCode(username: string) {
    //let url = `${this.rootUrl}` + "/refreshAzureAuthCode/" + username.replace('CENTERPOINT-PRO\\', '');
    //this.http.get<string>(this.formatUrl(url), { withCredentials: true }).subscribe(data => {
    //  let localUser = JSON.parse(localStorage.getItem("user")) as User;
    //  localUser.azureAuthCode = data;
    //  localStorage.setItem("user", JSON.stringify(localUser));
    //  this.userData.next(localUser);
    //});
  }

  updateUser(user: User) {
    this.userData.next(user);
  }

  getUserDetails() {
    if (localStorage.getItem("user") !== null) {
      let localUser = JSON.parse(localStorage.getItem("user")) as User;
      this.userData.next(localUser);
      //if (environment.production && typeof window.clarity !== "undefined")
      //  window.clarity("identify", localUser.accountName);
      //setInterval(() => {  // lets refresh the azure code every 60 minutes
      //  this.refreshAzureAuthCode(localUser.accountName);
      //}, 1000 * 60 * 60);
    }
    this.refreshUserFromServer();
  }

  refreshUserFromServer() {
    this.http
      .get<User>(this.rootUrlMe, {
        headers: this.getUrlUserHeaders('application/json'),
        withCredentials: false
      })
      .subscribe(data => {
        localStorage.setItem("user", JSON.stringify(data));
        this.userData.next(data as User);
        //if (data.azureAuthCode === '' || data.azureAuthCode === undefined || data.azureAuthCode === null) {
        //  console.log('getting new azure auth code', data);
        //  this.refreshAzureAuthCode(data.accountName);
        //}
      });
  }

  refreshUserFromServerWithObservable() {
    return this.http
      .get<User>(this.rootUrlMe, {
        headers: this.getUrlUserHeaders('application/json'),
        withCredentials: false
      });
  }

  getMenu(force?: boolean) {
    let loadMenuNow = false;
    // load from local storage if there
    if (localStorage.getItem("userMenuTime") !== null) {
      this.userMenu.next(
        JSON.parse(localStorage.getItem("userMenu")) as UserMenu
      );
    }
    // now check to see if we need to update the menu
    if (
      force ||
      loadMenuNow ||
      localStorage.getItem("userMenuTime") == null ||
      moment([]).isAfter(
        moment(localStorage.getItem("userMenuTime")).add(1, "d")
      )
    ) {
      let url = `${this.rootUrlMe}` + "/menu";
      this.http
        .get<UserMenu>(url, {
          headers: this.getUrlUserHeaders('application/json'),
          withCredentials: false
        })
        .subscribe(menu => {
          localStorage.setItem("userMenu", JSON.stringify(menu));
          localStorage.setItem("userMenuTime", moment([]).toISOString());
          this.userMenu.next(menu as UserMenu);
        });
    }
  }

  getRecents() {
    let url = `${this.rootUrlMe}` + "/recents";
    this.http
      .get<UserMenu>(url, {
        headers: this.getUrlUserHeaders('application/json'),
        withCredentials: false
      })
      .subscribe(recents => {
        let userMenu = JSON.parse(localStorage.getItem("userMenu")) as UserMenu;
        if (userMenu !== null) {
          userMenu.subMenu[4] = recents;
          localStorage.setItem("userMenu", JSON.stringify(userMenu));
          this.userMenu.next(userMenu as UserMenu);
        }
      });
  }

  getReports() {
    let url = `${this.rootUrlMe}` + "/reports";
    this.http
      .get<Report[]>(url, {
        headers: this.getUrlUserHeaders('application/json'),
        withCredentials: false
      })
      .subscribe(reports => {
        this.reports.next(reports as Report[]);
      });
  }

  reloadSecurity() {
    this.loadSecurity(true);
  }

  loadSecurity(force?: boolean) {
    let loadSecNow = false;
    if (localStorage.getItem("secMenuTime") !== null) {
      // load from local storage if there
      let sec = JSON.parse(localStorage.getItem("secObj")) as Security;
      this.secDataLocal = sec;
      this.securityData.next(sec);
    }
    if (
      force ||
      loadSecNow ||
      localStorage.getItem("secMenuTime") == null ||
      moment([]).isAfter(
        moment(localStorage.getItem("secMenuTime")).add(1, "d")
      )
    ) {
      let url = `${this.rootUrlMe}` + "/security";
      this.http
        .get<Security>(url, {
          headers: this.getUrlUserHeaders('application/json'),
          withCredentials: false
        })
        .subscribe(sec => {
          localStorage.setItem("secObj", JSON.stringify(sec));
          localStorage.setItem("secMenuTime", moment([]).toISOString());
          this.secDataLocal = sec;
          this.securityData.next(sec as Security);
        });
    }
  }

  hasPermissionKey(functionKey: number, permissionKey: number): boolean {
    let hasPerm = false;
    this.secDataLocal?.permList?.forEach(perm => {
      if (perm.functionKey === functionKey && perm.permKey === permissionKey) {
        hasPerm = true;
        return;
      }
    });
    return hasPerm;
  }

  public canViewProperty(propertyId: string): boolean {
    let canView = true;
    let user = JSON.parse(localStorage.getItem("user")) as User;
    if (user.yardiPropertyListHmy > 0 && user.yardiPropertyIdList.length > 0) {
      canView = user.yardiPropertyIdList.indexOf(propertyId) > -1;
    }
    return canView;
  }

  logReportUse(report: Report) {
    let url = `${this.rootUrlMe}` + "/reports/logUse";
    return this.http.post<boolean>(url, report, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  getListOptionsForSystem(system: string) {
    let url = `${this.rootUrlMe}` + "/getListOptions/" + system;
    return this.http.get<ListOptions>(url, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  saveListOptionsForSystem(options: ListOptions) {
    let url = `${this.rootUrlMe}` + "/saveListOptions/";
    localStorage.setItem("listOptions" + options.name, JSON.stringify(options));
    localStorage.setItem("listOptionsTime", moment([]).toISOString());
    return this.http.post<boolean>(url, options, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  saveView(view: ListView) {
    let url = `${this.rootUrlMe}` + "/saveView";
    return this.http.post<ListView>(url, view, {
      headers: this.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }
}
