<form [formGroup]="fg"
      novalidate
      autocomplete="off">
  <div class="propPickerWrapper">
    <div id="1-2"
         style="height: 40px; text-align: center;border-bottom: 1px solid #ddd;">
      <span style="font-size: 20px; font-weight: bold;">Building Picker</span>
    </div>
    <div>
      &nbsp;
    </div>
    <div class="propFilterContent">
      <div>
        <mat-form-field>
				  <mat-label>Property Status Filter</mat-label>
          <mat-select formControlName="classSearchMode"
                      size="1"
                      >
            <mat-option *ngFor="let lval of ClassModeVals"
                        id="lval.id"
                        [value]="lval.id">
              {{ lval.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="propPickerContent">
      <mat-form-field>
				<mat-label>search for building</mat-label>
        <input formControlName="searchText"
               matInput
               >
        <mat-icon matSuffix><i class="fa fa-search"></i></mat-icon>
      </mat-form-field>
    </div>
    <span>Select a Building</span><br />
    <cdk-virtual-scroll-viewport class="propList"
                                 style="height: 250px; width: 100%; border: solid 1px #a9a9a9;"
                                 itemSize=12
                                 [scrollTop]="myScrollTop">
      <div *cdkVirtualFor="let b of filteredBuildings"
           id="b.buildingID"
           [ngClass]="{'selectedClass': b.buildingKey === selectedBuildingKey, 'deselectedClass': b.buildingKey !== selectedBuildingKey }"
           (click)="onBuildingSelect(b.buildingKey)"
           style="cursor: pointer;">
        <span>{{ b.buildingID }} - {{ b.propertyDesc }},
          {{ b.city }} {{ b.state }}</span>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
  <div style="margin: 20px 0px 4px 0px;">
    <button mat-flat-button
            (click)="clickClose()">
      Close</button>
    <button mat-raised-button
            color="primary"
            align="end"
            (click)="clickSave()"
            style="float: right;">
      <ng-container *ngIf="!isSearchMode">
        Select
      </ng-container>
      <ng-container *ngIf="isSearchMode">
        Search
      </ng-container>
    </button>
  </div>
</form>
