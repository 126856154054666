<div class="standing-header"
     style="margin: 15px 25px">
  <h3 style="display: inline-block; margin-top: 5px">
    <fa-icon [icon]="faBasketballHoop"
             style="margin-right: 5px"></fa-icon> March Madness Standings
  </h3>
  <button *ngIf="!canViewOthers"
          mat-flat-button
          color="primary"
          style="float: right; margin-left: 20px"
          (click)="newBracket()">
    <fa-icon [icon]="faPlus"
             style="margin-right: 5px"></fa-icon> New </button>
  <button mat-stroked-button
          [matMenuTriggerFor]="menu"
          style="float: right; margin-right: 0">
    <fa-icon [icon]="faStream"
             style="margin-right: 5px"></fa-icon>My Brackets <fa-icon [icon]="faAngleDown"
             class="fa-sm"
             class="menu-arrow"></fa-icon>
  </button>
  <mat-menu #menu="matMenu"
            xPosition="before">
    <button mat-menu-item
            *ngFor="let bracket of myBrackets"
            (click)="goToBracket(bracket)">
      <fa-icon [icon]="faStream"
               style="margin-right: 5px"></fa-icon> {{ bracket.name }} ({{ bracket.id }}) </button>
  </mat-menu>
  <button mat-stroked-button
          [matMenuTriggerFor]="yearMenu"
          style="float: right; margin-right: 20px">
    <span style="color: #aaa">Year: &nbsp;</span> {{ selYear }} <fa-icon [icon]="faAngleDown"
             class="fa-sm menu-arrow"
             style="margin-left: 1px"></fa-icon>
  </button>
  <mat-menu #yearMenu="matMenu"
            xPosition="before"
            style="float: right; margin-right: 0">
    <button mat-menu-item
            *ngFor="let year of years"
            (click)="yearChanged(year)"> {{ year }} </button>
  </mat-menu>
  <button *ngIf="isGod"
          mat-stroked-button
          style="float: right; margin-right: 25px"
          (click)="goToMaster()"> Master </button>
  <button *ngIf="isGod"
          mat-stroked-button
          style="float: right; margin-right: 25px"
          (click)="newTournament()"> New Tournament </button>
</div>
<div class="madness-main"
     style="
    margin: 25px;
    margin-top: 15px;
    overflow-y: scroll;
  ">
  <form style="border-bottom: solid 1px #ddd; padding-bottom: 5px"></form>
  <div class="standings standings-header">
    <div>#</div>
    <div>Name</div>
    <div>R1</div>
    <div>S16</div>
    <div>E8</div>
    <div>F4</div>
    <div>Semis</div>
    <div>Champ</div>
    <div>Total</div>
    <!-- <div title="Possible Points">Poss</div> -->
    <div>Final Four</div>
  </div>
  <div *ngFor="let b of brackets; let i = index"
       (click)="goToBracket(b)"
       class="standings"
       [class.mine]="b.userName === this.user.accountName"
       [class.not-finished]="notFinished(b)">
    <div [attr.title]="notFinished(b) ? 'Not Finished' : null"> {{ i + 1 }} </div>
    <div> {{ b.name }} <fa-icon *ngIf="notFinished(b)"
               [icon]="faExclamationSquare"
               class="fa-lg"
               style="margin-left: 8px"
               title="Not Finished"></fa-icon>
      <fa-icon *ngIf="!b.paid"
               [icon]="faDollarSign"
               style="margin-left: 8px"
               class="fa-lg"
               title="Not Paid"></fa-icon>
    </div>
    <div>{{ getTotalForRound(b, 1) }}</div>
    <div>{{ getTotalForRound(b, 2) }}</div>
    <div>{{ getTotalForRound(b, 3) }}</div>
    <div>{{ getTotalForRound(b, 4) }}</div>
    <div>{{ getTotalForRound(b, 5) }}</div>
    <div>{{ getTotalForRound(b, 6) }}</div>
    <div>{{ b.points }}</div>
    <!-- <div>{{ getPotentialPoints(b) }}</div> -->
    <div class="final-four">
      <img *ngIf="getChampLogo(b) !== ''"
           [src]="getChampLogo(b)"
           title="Champion" />
      <fa-icon *ngIf="getChampLogo(b) === ''"
               class="fa-lg"
               title="Not Selected"
               [icon]="faExclamationSquare"></fa-icon>
      <img *ngIf="getRunnerUpLogo(b) !== ''"
           [src]="getRunnerUpLogo(b)"
           title="Runner Up" />
      <fa-icon *ngIf="getRunnerUpLogo(b) === ''"
               class="fa-lg"
               title="Not Selected"
               [icon]="faExclamationSquare"></fa-icon>
      <img *ngIf="getFinalFourLoser(b, 0) !== ''"
           [src]="getFinalFourLoser(b, 0)"
           title="Final 4 loser" />
      <fa-icon *ngIf="getFinalFourLoser(b, 0) === ''"
               class="fa-lg"
               title="Not Selected"
               [icon]="faExclamationSquare"></fa-icon>
      <img *ngIf="getFinalFourLoser(b, 1) !== ''"
           [src]="getFinalFourLoser(b, 1)"
           title="Final 4 loser" />
      <fa-icon *ngIf="getFinalFourLoser(b, 1) === ''"
               class="fa-lg"
               title="Not Selected"
               [icon]="faExclamationSquare"></fa-icon>
    </div>
  </div>
</div>