import { CorporateEntityPropertySalesTax } from './corp-entity-property-sales-tax';
import { CorporateEntityPropertyBusinessLicense } from './corp-entity-prop-business-license';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { LoggerService } from './../core/logger.service';
import { AlertService } from './../core/alert.service';
import { environment } from './../../environments/environment';
import { CorporateEntityFull } from './corp-entity-full';
import { CorpEntityBrief } from './corp-entity-brief';
import { CorporateEntityOwner } from './corp-entity-owner';
import { CorporateEntityAction } from './corp-entity-action';
import { CorporateEntityOwnedProperty } from './corp-entity-owned-prop';
import { CorporateEntityOwnedEntity } from './corp-entity-owned-entity';
import { CorporateEntityJurisdiction } from './corp-entity-jurisdiction';
import { CorporateEntityProperty } from './corp-entity-property';
import { UserService } from '../core/user.service';
import { ReturnMessage } from '../shared/returnMessage';

@Injectable()
export class CorpEntityService {

  private corpEntityUrl = environment.cubCloudUrl + 'corpEntities';
  private passCredentials = false;
  private corpEntityList = new ReplaySubject<CorpEntityBrief[]>(1);
  private tempCorpEntityList: CorpEntityBrief[] = [];
  corpEntities$ = this.corpEntityList.asObservable();
  private mySearchTerm = '';

  constructor(private http: HttpClient, private alertService: AlertService, private loggerService: LoggerService, private userService: UserService) {
    this.passCredentials = false;
    this.getCorpEntities();
  }

  getCorpEntities() {
    const url = `${this.corpEntityUrl}`;
    this.http.get<CorpEntityBrief[]>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials }).subscribe(corpEntities => {
      this.corpEntityList.next(corpEntities);
      this.tempCorpEntityList = corpEntities;
    });
  }

  getRefreshedCorpEntities() {
    const url = `${this.corpEntityUrl}/getrefreshedlist`;
    this.http.get<CorpEntityBrief[]>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials }).subscribe(corpEntities => {
      this.corpEntityList.next(corpEntities);
      this.tempCorpEntityList = corpEntities;
    });
  }

  getCorpEntity(id: string): Observable<CorporateEntityFull> {
    const url = `${this.corpEntityUrl}/${id}`;
    return this.http.get<CorporateEntityFull>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  validateCorpEntity(id: string): Observable<string> {
    const url = `${this.corpEntityUrl}/${id}/validate`;
    return this.http.get<string>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  createCorpEntityFolder(id: string) {
    const url = `${this.corpEntityUrl}/createfolder/${id}`;
    return this.http.get<ReturnMessage>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
    .toPromise();
  }

  setSearchTerm(term: string) {
    this.mySearchTerm = term;
  }

  getSearchTerm() {
    return this.mySearchTerm;
  }

  // Owners
  getOwners(id: string) {
    const url = `${this.corpEntityUrl}/${id}/owners`;
    return this.http.get<CorporateEntityOwner[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  deleteOwner(id: string) {
    return this.http.get<string>(this.formatUrl(`${this.corpEntityUrl}/deleteowner/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  createOwner(id: string) {
    return this.http.get<string>(this.formatUrl(`${this.corpEntityUrl}/createowner/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  // Corporate Actions
  getActions(id: string) {
    const url = `${this.corpEntityUrl}/${id}/actions`;
    return this.http.get<CorporateEntityAction[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  deleteAction(id: string) {
    return this.http.get<string>(this.formatUrl(`${this.corpEntityUrl}/deleteaction/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  createAction(id: string) {
    return this.http.get<string>(this.formatUrl(`${this.corpEntityUrl}/createaction/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  createCorpEntity(entityName: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/createcorpentity`);
    return this.http
      .post<number>(url, { entityName }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  // Owned properties
  getOwnedProperties(id: string) {
    const url = `${this.corpEntityUrl}/${id}/ownedProps`;
    return this.http.get<CorporateEntityOwnedProperty[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  getOwnedEntities(id: string) {
    const url = `${this.corpEntityUrl}/${id}/ownedentities`;
    return this.http.get<CorporateEntityOwnedEntity[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  deleteOwnedProperty(id: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/deleteownedprop`);
    return this.http
      .post<number>(url, { pKey: id }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  insertOwnedProperty(corpEntityKey: string, propertyKey: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/insertownedprop`);
    return this.http
      .post<number>(url, { corpEntityKey, propertyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  // jurisdictions
  getJurisdictions(id: string) {
    const url = `${this.corpEntityUrl}/${id}/jurisdictions`;
    return this.http.get<CorporateEntityJurisdiction[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  validatePropertySelection(id: string) {
    const url = `${this.corpEntityUrl}/${id}/validatepropselection`;
    return this.http.get<ReturnMessage>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  deleteJurisdiction(id: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/deletejurisdiction`);
    return this.http
      .post<number>(url, { pKey: id }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  insertJurisdiction(corpEntityKey: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/insertjurisdiction`);
    return this.http
      .post<number>(url, { corpEntityKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  // for property picker
  getProperties() {
    const url = `${this.corpEntityUrl}/properties`;
    return this.http.get<CorporateEntityProperty[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  getPropertyBusinessLicenses(id: string) {
    const url = `${this.corpEntityUrl}/getpropertybusinesslicenses/${id}`;
    return this.http.get<CorporateEntityPropertyBusinessLicense[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  insertPropertyBusinessLicense(propertyKey: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/insertpropertybusinesslicense`);
    return this.http
      .post<number>(url, { propertyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  DeleteBusinessLicense(pKey: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/deletebusinesslicense`);
    return this.http
      .post<number>(url, { pKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  getPropertySalesTaxInfo(id: string) {
    const url = `${this.corpEntityUrl}/getpropertysalestaxinfo/${id}`;
    return this.http.get<CorporateEntityPropertySalesTax>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  insertPropertySalesTaxInfo(propertyKey: string) {
    const url = this.formatUrl(`${this.corpEntityUrl}/insertpropertysalestaxinfo`);
    return this.http
      .post<number>(url, { propertyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials })
      .toPromise()
      .catch(this.handleError2);
  }

  getUnlinkedProperties() {
    const url = `${this.corpEntityUrl}/unlinkedproperties`;
    return this.http.get<CorporateEntityProperty[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: this.passCredentials });
  }

  /***************************************
  non-controller calls.
  ***************************************/
  updateDeleteFlagInList(id: number, isDeleted: boolean) {
    let myList: CorpEntityBrief[] = [];
    myList = this.tempCorpEntityList;
    for (let ce of this.tempCorpEntityList) {
      if (ce.pKey === id) {
        ce.isDeleted = isDeleted;
      }
    }
    this.corpEntityList.next(myList);
  }


  private formatUrl(url: string): string {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = newUrl.replace('http://cub/', 'https://cub.centerpoint.com/');
    }
    return newUrl;
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + alertMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

  private handleError2(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
