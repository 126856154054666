
import * as moment from 'moment';

export class Log {
  type: LogType;
  source: string;
  message: string;
  stackTrace: string;
  user: string;
  logDate: string;
  functionKey: number;
  sessionId: string;
  os: string;
  osVersion: string;
  browser: string;
  browserVersion: string;
  device: string;
  userAgent: string;
  ipAddress: string;

  constructor() {
    this.logDate = moment().format();
  }
}

export enum LogType {
  BatchNote = 1,
  BatchError = 2,
  BatchWarning = 3,
  ReportActivity = 4,
  UserTracking = 5,
  ErrorPage = 6,
  Error = 7,
  Activity = 8,
  PortalTracking = 9
}
