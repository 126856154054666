import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListFilter, ListView } from '../list-control';
import { ListOptions } from '../list-control/listOptions';
import { ListService } from '../../core/list.service';

import { faFilter, faCaretDown, faPlus, faCheck, faTimes, faTrashAlt, faBuilding, faFileSignature, faSave } from '@fortawesome/pro-light-svg-icons';
import { UserService } from 'src/app/core/user.service';
import { ListFilterPipe } from './list-control-filter.pipe';
import { ListFilterSelectedPipe } from './list-control-filter-selected.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'cub-list-control-filter',
    templateUrl: './list-control-filter.component.html',
    styleUrls: ['./list-control-filter.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatButtonModule, MatMenuModule, FontAwesomeModule, ListFilterSelectedPipe, ListFilterPipe]
})
export class ListControlFilterComponent {
  @Input() listOptions: ListOptions;
  @Input() system?: string;
  @Input() canEditFilters: boolean;
  @Input() listViews: ListView[];
  @Output() filterUpdated: EventEmitter<any> = new EventEmitter();
  filterItems: ListFilter[];
  selectedFilters: ListFilter[];
  selectedCount = 0;
  dirty = false;

  faFilter = faFilter;
  faCaretDown = faCaretDown;
  faPlus = faPlus;
  faCheck = faCheck;
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;
  faBuilding = faBuilding;
  faFileSignature = faFileSignature;
  faSave = faSave;

  constructor(
    private listService: ListService,
    private userService: UserService) {
    this.system = '';
  }

  buttonClick(filter: ListFilter, parentFilter: ListFilter, evt: any) {
    if (parentFilter === null || parentFilter.selectType === 'multiple') {
      filter.selected = !filter.selected; // select or deselect
      if (parentFilter != null && parentFilter.filterItems != null) {
        if (filter.allBehavior) {
          // if this is an "all", then (de)select all others
          parentFilter.filterItems.forEach(item => {
            if (item.name !== filter.name) {
              item.selected = !filter.selected;
            }
          });
        } else {
          // if not an "all", we need to see if we deselected the last selected item.  if so, select all, otherwise make sure "all" is off.
          let anyLeftSelected = false;
          parentFilter.filterItems.forEach(item => {
            if (!item.allBehavior) {
              anyLeftSelected = anyLeftSelected || item.selected;
            }
          });
          if (!anyLeftSelected) {
            if (parentFilter.filterItems.find(f => f.allBehavior)) {
              parentFilter.filterItems.find(f => f.allBehavior).selected = true;
            }
          } else {
            if (parentFilter.filterItems.find(f => f.allBehavior)) {
              parentFilter.filterItems.find(f => f.allBehavior).selected = false;
            }
          }
        }
      }
    } else if (parentFilter.selectType === 'radio') {
      parentFilter.filterItems.map(f => (f.selected = false));
      filter.selected = true;
    }
    if (parentFilter !== null && parentFilter.selectType === 'multiple') {
      evt.stopPropagation();
    }
    this.dirty = true;
    this.emitUpdate();
  }

  removeFilter(filter: ListFilter) {
    filter.selected = false;
    this.dirty = true;
    this.emitUpdate();
  }

  emitUpdate() {
    this.listService.listUpdated(this.listOptions);
  }

  getSelectedCount(filters: ListFilter[]): number {
    let myCount = 0;
    filters.forEach(item => {
      if (item.selected) {
        myCount++;
      }
    });
    return myCount;
  }

  removeAllFilters() {
    this.filterItems.forEach(filter => {
      filter.selected = false;
    });
    this.dirty = true;
    this.listService.listUpdated(this.listOptions);
    // this.emitUpdate();
  }

  allSelected(items: ListFilter[]): boolean {
    let selected = items.filter(i => i.selected);
    return selected.length === 1 && selected[0].name === 'All';
  }

  filtersOn(): boolean {
    return this.listOptions.filters.filter(f => f.selected && !f.alwaysOn).length > 0;
  }

  alwaysOnCount(): number {
    return this.listOptions.filters.filter(f => f.alwaysOn).length;
  }

  getButtonTitle(filter: ListFilter): string {
    let title = filter.title + '\n';
    let showing = filter.filterItems
      .filter(i => i.selected)
      .map(i => i.name)
      .join(',');
    title += 'showing: ' + showing + '\n';
    if (showing !== 'All') {
      title +=
        'not showing: ' +
        filter.filterItems
          .filter(i => !i.selected && !i.allBehavior)
          .map(i => i.name)
          .join(',') +
        '\n';
    }
    return title;
  }

  saveView() {
    let view = this.listViews.filter(v => v.selected)[0];

    view.listOptions = this.listOptions;
    view.listOptions.searchText = '';

    this.userService.saveView(view).subscribe(v => {
      console.log('saved view', v);
      this.listViews.filter(v => v.selected)[0] = v;
      this.dirty = false;
      if (v.id <= 0) {
        alert('error saving list settings');
      } else {
        // todo
      }
    });
  }
}
