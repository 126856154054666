import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../user/user';

@Pipe({
    name: 'filterBySystem',
    pure: false,
    standalone: true
})
export class ListGroupSortPipe implements PipeTransform {

  tmp = [];
  transform(items: Array<any>, system: string): any {

    let user: User = JSON.parse(localStorage.getItem('user')) as User;
    this.tmp.length = 0;
    let arr = [];
    arr = items;
    if (system === 'property' || system === 'lease') {
      arr = arr.filter(i => i.system === system || i.system === 'all' || i.system === null);
    }
    this.tmp.push(...arr);  // spread syntax https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator
    return this.tmp;
  }

}

// this is a "stateful" pipe
// https://stackoverflow.com/questions/34456430/ngfor-doesnt-update-data-with-pipe-in-angular2
