<div class="anim-container" [@slide]="activePane" style="transform: translateX(-45px);">
  <div id="searchPanel" #searchPanel>
    <div #search class="searchBox">
      <div class="container-1">
          <span class="icon"><i class="fa fa-search"></i></span>
          <input #txtSearch id="txtSearch" type="search" placeholder="Search..." (keyup)="searchOnKeyup($event.target.value)" value="" />
      </div>
    </div>
    <div #list class="radio-list">
      <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw" style="margin-left: 130px; margin-top: 20px"></i>
      <mat-radio-group *ngIf="!isLoading" class="example-radio-group" [(ngModel)]="selectedContact" (change)="selectionChange()">
        <mat-radio-button class="example-radio-button" *ngFor="let contact of contacts" [value]="contact">
            {{ contact.firstName }} {{ contact.lastName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div id="newDiv">
      <button mat-button (click)="showNewPanel();"><i class="fa fa-plus"></i> &nbsp;New Contact</button>
      <button mat-button (click)="clearField();" style="float: right; margin-right: 5px;"><i class="fa fa-remove"></i> &nbsp;Clear Field </button>
    </div>
    <div #action class="control">
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <span *ngIf="selectedContact.pcKey == 0" >&nbsp;</span>
      <button mat-button *ngIf="selectedContact.pcKey != 0" (click)="togglePanel('details')" title="Show Contact Details">Details</button>
      <button mat-raised-button color="primary" (click)="close()">Save</button>
    </div>
  </div>
  <div id="detailPanel" #detailPanel >
    <div *ngIf="!showDetailsOnly" id="detailBack" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: solid 1px #eee;" (click)="togglePanel('search')">
      <i class="fa fa-chevron-left"></i>
      <span *ngIf="!showNew">Back</span>
      <span *ngIf="showNew">New Contact</span>
    </div>
    <div id="detailInfo" *ngIf="!showNew">
      <h3>{{ detailedContact.name }}</h3>
      <div style="margin-bottom: 0;">{{ detailedContact.companyName }}</div>
      <div>{{ detailedContact.jobTitle }}</div>
      <div>
        <i class="fa fa-phone"></i>
        <a *ngIf="mainPhoneNumber !== undefined" href="tel:{{ mainPhoneNumber.number }}">{{ mainPhoneNumber.number }}</a>
      </div>
      <div>
        <i class="fa fa-envelope-o"></i>
        <a *ngIf="mainEmailAddress !== undefined" href="mailto:{{ mainEmailAddress.email }}" target="_newEmail">{{ mainEmailAddress.email }}</a>
      </div>
      <div>
        <div>
          <i class="fa fa-map-signs"></i>
          <a *ngIf="mainAddress !== undefined" href="{{ getAddressLink() }}" target="_newEmail">{{ mainAddress.street }}</a>
        </div>
        <div style="text-indent: 25px;">
            <a *ngIf="mainAddress !== undefined" href="{{ getAddressLink() }}" target="_newEmail">{{ mainAddress.city }}, {{ mainAddress.stateID }} {{ mainAddress.zip }}</a>
        </div>
      </div>
    </div>
    <div id="newInfo" *ngIf="showNew">
      <mat-form-field>
				<mat-label>Salutation</mat-label>
        <mat-select [compareWith]="selectCompare"  [(ngModel)]="newContact.salutationKey">
          <mat-option *ngFor="let salutation of salutations" [value]="salutation.id">
            {{ salutation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
				<mat-label>First Name</mat-label>
        <input matInput  [(ngModel)]="newContact.firstName" >
      </mat-form-field>
      <mat-form-field class="example-full-width">
				<mat-label>Last Name</mat-label>
        <input matInput  [(ngModel)]="newContact.lastName" >
      </mat-form-field>
    </div>
    <div id="detailControl">
      <button mat-raised-button *ngIf="showDetailsOnly" (click)="cancel()">Close</button>
      <button mat-raised-button color="primary" style="float: right;" *ngIf="!showNew" (click)="selectContact()">More Details</button>
      <button mat-raised-button color="primary" style="float: right;" *ngIf="showNew" (click)="saveNewContact()">Create</button>
    </div>
  </div>
</div>
