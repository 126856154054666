import { Component, Input } from '@angular/core';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'cub-widget-indicator',
    templateUrl: './widget-indicator.component.html',
    styleUrls: ['./widget-indicator.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTooltipModule, NgIf, DecimalPipe, CurrencyPipe]
})
export class WidgetIndicatorComponent {

  @Input() header: string;
  @Input() valueToShow: string;
  @Input() valueToShow2: string;
  @Input() valueType = 'string';
  @Input() numberFormat = '';
  @Input() numberFormat2 = '';
  @Input() unit: string;
  @Input() unit2: string;
  @Input() valueToGraph = 1;
  @Input() min: number;
  @Input() max: number;
  graphWidth = 40;
  barWidth = 4;

  calculateLeft(): number {
    let val = ((this.valueToGraph - this.min) / (this.max - this.min) * this.graphWidth) - (this.barWidth / 2);
    if (val <= 0) {
      val = (this.barWidth / 2) * -1 + 1;
    } else if (val >= (this.graphWidth - this.barWidth)) {
      val = this.graphWidth - (this.barWidth / 2) - 3;
    }
    return val;
  }
}
