
export class PropertyBudgetEntry {
  acctHmy: number;
  acctCode: string;
  acctDesc: string;
  acctType: string;
  totalIntoHmy: number;
  underline: number;
  negate: number;
  gLBudget: number;
  gLActual: number;
  budgetGL1: number;
  budgetGL2: number;
  budgetGL3: number;
  budgetGL4: number;
  budgetGL5: number;
  budgetGL6: number;
  budgetGL7: number;
  budgetGL8: number;
  budgetGL9: number;
  budgetGL10: number;
  budgetGL11: number;
  budgetGL12: number;
  remove: boolean;
  bold: boolean; // this is local only
  groupTotal: boolean;

  constructor() {
  }
}
