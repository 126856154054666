
import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ChangePacket } from './changePacket';
import { AlertService } from '../core/alert.service';
import { UserService } from '../core/user.service';

@Injectable()
export class SaveService {
  private cubCloudUrl = environment.cubCloudUrl + 'save';

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private userService: UserService
  ) { }

  saveChange(packet: ChangePacket): Promise<ChangePacket> {
    const url = `${this.cubCloudUrl}`;
    return this.http
      .post<ChangePacket>(url, JSON.stringify(packet), {
        headers: this.userService.getUrlUserHeaders('application/json'),
        withCredentials: false
      })
      .toPromise()
      .then(response => packet)
      .catch(this.handleError2);

    // .catch((err) => this.handleError(err, this.alertService));
  }

  getChangeLog(tableName: string, itemKey: number): Observable<ChangePacket[]> {
    const url = `${this.cubCloudUrl}/changelog/${tableName}/${itemKey}`;
    return this.http.get<ChangePacket[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  private handleError2(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
