<div [class.twoCol]="!showDashboardIcon"
     [class.threeCol]="showDashboardIcon">
  <cub-list-control-view [title]="title"
                         [system]="system"
                         [listViews]="listViews"
                         [canEditFilters]="canEditFilters"
                         [dashboardUrl]="dashboardUrl"></cub-list-control-view>
  <cub-list-control-filter *ngIf="listOptions.showFilter"
                           [listOptions]="listOptions"
                           [listViews]="listViews"
                           [canEditFilters]="canEditFilters"
                           [system]="system"></cub-list-control-filter>
  <fa-icon *ngIf="showDashboardIcon"
           title="Return to Dashboard"
            class="dashIcon"
           (click)="goToDashboard()"
           [icon]="faTachometerAlt"></fa-icon>
</div>
