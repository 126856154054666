import { ListOptions } from './../../shared/list-control/listOptions';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Constants } from '../../core/constants';
import { ListService } from '../../core/list.service';
import { Investment } from '../investment';
import { InvestmentListPipe } from '../investment-home/investment-list.pipe';
import { InvestmentService } from '../investment.service';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faIndustryAlt } from '@fortawesome/pro-regular-svg-icons/faIndustryAlt';
import { faTruckContainer } from '@fortawesome/pro-light-svg-icons/faTruckContainer';
import { faContainerStorage } from '@fortawesome/pro-light-svg-icons/faContainerStorage';
import { NumWithCommasPipe } from '../../shared/num-with-commas.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, CurrencyPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';



@Component({
    selector: 'cub-investment-pipeline-summary',
    templateUrl: './investment-pipeline-summary.component.html',
    styleUrls: ['./investment-pipeline-summary.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, NgIf, MatTableModule, FontAwesomeModule, MatTooltipModule, CurrencyPipe, NumWithCommasPipe]
})
export class InvestmentPipelineSummaryComponent implements OnInit, AfterViewInit {

  allInvestments: Investment[] = [];
  invClosed: Investment[] = [];
  invUnderContract: Investment[] = [];
  invLOI: Investment[] = [];
  invUnderReview: Investment[] = [];
  displayedColumns = ['inv_name', 'inv_tac', 'inv_sf', 'inv_closing'];
  closedDataSource = new MatTableDataSource();
  underContractDataSource = new MatTableDataSource();
  loiDataSource = new MatTableDataSource();
  underReviewDataSource = new MatTableDataSource();
  closedFilterSettings: ListOptions;
  filterSettings: ListOptions;
  closingTotalTAC = 0;
  closingTotalSF = 0;
  underContractTotalTAC = 0;
  underContractTotalSF = 0;
  loiTotalTAC = 0;
  loiTotalSF = 0;
  underReviewTotalTAC = 0;
  underReviewTotalSF = 0;
  closedPanelState = false;
  underContractPanelState = false;
  loiPanelState = false;
  underReviewPanelState = false;
  faTruckContainer = faTruckContainer;
  faBuilding = faBuilding;
  faContainerStorage = faContainerStorage;
  faIndustryAlt = faIndustryAlt;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private investmentService: InvestmentService,
    private listService: ListService
  ) { }

  ngOnInit() {
    this.listService.listSettings$.subscribe(listSettings => {
      console.log('inv list subscribe: ', listSettings);
      if (listSettings.name === 'Investment') {
        this.filterSettings = JSON.parse(JSON.stringify(listSettings)) as ListOptions;
        this.closedFilterSettings = JSON.parse(JSON.stringify(listSettings)) as ListOptions;
        this.closedFilterSettings.filters[1].filterItems[0].selected = false;
        this.closedFilterSettings.filters[1].filterItems[1].selected = false;
        this.closedFilterSettings.filters[1].filterItems[3].selected = true;
        this.loadData();
      }
    });
    this.getInvestments();
  }

  ngAfterViewInit() {
    this.investmentService.getInvestments();
  }

  getInvestments() {
    this.investmentService.investments$.subscribe(result => {
      console.log('getInvestments: ', result, this.closedFilterSettings);
      this.allInvestments = result;
      this.invClosed = new InvestmentListPipe().transform(this.allInvestments, this.closedFilterSettings);
      this.loadData();
    });
  }

  loadData() {
    this.loadClosed();
    this.loadUnderContract();
    this.loadLOI();
    this.loadUnderReview();
  }

  loadClosed() {
    this.invClosed = new InvestmentListPipe().transform(this.allInvestments, this.filterSettings);
    this.invClosed = this.invClosed.filter((inv, index, arr) => !inv.archived && (inv.statusKey === Constants.AcqStatusClosed));
    this.invClosed.sort((a, b) => {
      let aBefore = moment(a.closingDt).isBefore(b.closingDt);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.closingTotalTAC = this.invClosed.reduce((prevVal, elem) => prevVal + elem.tac, 0);
    this.closingTotalSF = this.invClosed.reduce((prevVal, elem) => prevVal + elem.totalSF, 0);
    this.closedDataSource.data = this.invClosed;
  }

  loadUnderContract() {
    this.invUnderContract = new InvestmentListPipe().transform(this.allInvestments, this.filterSettings);
    this.invUnderContract = this.invUnderContract.filter((inv, index, arr) => inv.statusKey === Constants.AcqStatusUnderContract);
    this.invUnderContract.sort((a, b) => {
      let aBefore = moment(a.closingDt).isBefore(b.closingDt);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.underContractTotalTAC = this.invUnderContract.reduce((prevVal, elem) => prevVal + elem.tac, 0);
    this.underContractTotalSF = this.invUnderContract.reduce((prevVal, elem) => prevVal + elem.totalSF, 0);
    this.underContractDataSource.data = this.invUnderContract;
  }

  loadLOI() {
    this.invLOI = new InvestmentListPipe().transform(this.allInvestments, this.filterSettings);
    this.invLOI = this.invLOI.filter((inv, index, arr) => inv.statusKey === Constants.AcqStatusSignedLOI);
    this.invLOI.sort((a, b) => {
      let aBefore = moment(a.closingDt).isBefore(b.closingDt);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.loiTotalTAC = this.invLOI.reduce((prevVal, elem) => prevVal + elem.tac, 0);
    this.loiTotalSF = this.invLOI.reduce((prevVal, elem) => prevVal + elem.totalSF, 0);
    this.loiDataSource.data = this.invLOI;
  }

  loadUnderReview() {
    this.invUnderReview = new InvestmentListPipe().transform(this.allInvestments, this.filterSettings);
    this.invUnderReview = this.invUnderReview.filter((inv, index, arr) => inv.statusKey === Constants.AcqStatusUnderReview);
    this.invUnderReview.sort((a, b) => {
      let aBefore = moment(a.closingDt).isBefore(b.closingDt);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.underReviewTotalTAC = this.invUnderReview.reduce((prevVal, elem) => prevVal + elem.tac, 0);
    this.underReviewTotalSF = this.invUnderReview.reduce((prevVal, elem) => prevVal + elem.totalSF, 0);
    this.underReviewDataSource.data = this.invUnderReview;
  }

  onPipeline(inv: Investment): boolean {
    return inv.onReportCalEast || inv.onReportITC || inv.onReportRevenue;
  }

  goToDetail(key: number): void {
    this.router.navigate([key], { relativeTo: this.route });
  }

  formatClosingDt(dt: Date): string {
    let ret = '';
    if (moment(dt).isValid()) {
      ret = moment(dt).format('MM/DD/YY');
    }
    return ret;
  }

  getDealIcon(dealTypeKey: number): any {
    switch (dealTypeKey) {
      case 1:
        return faIndustryAlt;
        break;
      case 2:
        return faContainerStorage;
        break;
      case 3:
        return faTruckContainer;
        break;
      default:
        return faIndustryAlt;
        break;
    }
  }

  getDealTooltip(dealTypeKey: number): any {
    switch (dealTypeKey) {
      case 1:
        return 'Property';
        break;
      case 2:
        return 'Container/Trailer Yard';
        break;
      case 3:
        return 'Truck Terminal';
        break;
      default:
        return 'Unknown Type';
        break;
    }
  }
}
