<div class="inv-date-summary">

  <div id="noDates" *ngIf="this.todayDataSource.data.length == 0">
    <div style="font-style: italic; color: #666;">There are no upcoming dates</div>
  </div>
  <div id="datesForToday" *ngIf="this.todayDataSource.data.length > 0">
    <h4>Today!</h4>
    <mat-table #todayTable [dataSource]="todayDataSource" class="example-table" matSort>

      <ng-container matColumnDef="inv_dt">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="dayDate">
            <div>{{ formatDate(row.dateVal) }}</div>
            <div class="dateName">{{ formatDateAsDay(row.dateVal) }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inv_name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" title="{{ row.prospectName }}">
                    {{ row.prospectName }}
                </span>
              </span>
              <div class="dateName">{{ row.dateName }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.prospectKey)"></mat-row>
    </mat-table>
  </div>

  <div id="datesForWeek" *ngIf="this.weekDataSource.data.length > 0">
    <h4>Next 7 Days</h4>
    <mat-table #weekTable [dataSource]="weekDataSource" class="example-table" matSort>

      <ng-container matColumnDef="inv_dt">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="dayDate">
            <div>{{ formatDate(row.dateVal) }}</div>
            <div class="dateName">{{ formatDateAsDay(row.dateVal) }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inv_name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" title="{{ row.prospectName }}">
                    {{ row.prospectName }}
                </span>
              </span>
              <div class="dateName">{{ row.dateName }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.prospectKey)"></mat-row>
    </mat-table>
  </div>

  <div id="datesForMonth" *ngIf="this.monthDataSource.data.length > 0">
    <h4>Next 30 Days</h4>
    <mat-table #monthTable [dataSource]="monthDataSource" class="example-table" matSort>

      <ng-container matColumnDef="inv_dt">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="dayDate">
            <div>{{ formatDate(row.dateVal) }}</div>
            <div class="dateName">{{ formatDateAsDay(row.dateVal) }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inv_name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" title="{{ row.prospectName }}">
                  {{ row.prospectName }}
              </span>
            </span>
            <div class="dateName">{{ row.dateName }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <div id="datesForMonth" *ngIf="this.threeMonthDataSource.data.length > 0">
    <h4>Next 90 Days</h4>
    <mat-table #threeMonthTable [dataSource]="threeMonthDataSource" class="example-table" matSort>

      <ng-container matColumnDef="inv_dt">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="dayDate">
            <div>{{ formatDate(row.dateVal) }}</div>
            <div class="dateName">{{ formatDateAsDay(row.dateVal) }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inv_name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" title="{{ row.prospectName }}">
                  {{ row.prospectName }}
              </span>
            </span>
            <div class="dateName">{{ row.dateName }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

</div>

