<div class="bracket-print" *ngIf="bracket !== undefined">
  <div class="round round1 left-side">
    <div class="team" *ngFor="let team of bracket.regions[0].teams">
      <span class="seed">{{ team.seed }}</span>
      <span class="name">{{ team.name }}</span>
    </div>
    <div class="team" *ngFor="let team of bracket.regions[3].teams">
      <span class="seed">{{ team.seed }}</span>
      <span class="name">{{ team.name }}</span>
    </div>
  </div>
  <div class="round round2 left-side">
    <div *ngFor="let game of bracket.regions[0].rounds[1].games">
      <div class="team" [class.wrong]="isPickWrong(0, 1, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(0, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(0, 1, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(0, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[3].rounds[1].games">
      <div class="team" [class.wrong]="isPickWrong(3, 1, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(3, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(3, 1, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(3, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round sweet16 left-side">
    <div *ngFor="let game of bracket.regions[0].rounds[2].games">
      <div class="team" [class.wrong]="isPickWrong(0, 2, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(0, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(0, 2, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(0, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[3].rounds[2].games">
      <div class="team" [class.wrong]="isPickWrong(3, 2, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(3, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(3, 2, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(3, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round elite8 left-side">
    <div *ngFor="let game of bracket.regions[0].rounds[3].games">
      <div class="team" [class.wrong]="isPickWrong(0, 3, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(0, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(0, 3, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(0, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[3].rounds[3].games">
      <div class="team" [class.wrong]="isPickWrong(3, 3, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(3, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(3, 3, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(3, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round final4 left-side">
    <div>
      <div
        class="team"
        [class.wrong]="
          isPickWrong(4, 0, bracket.regions[4].rounds[0].games[1].id, 'team1Id')
        "
      >
        <span class="name">{{
          getTeamName(0, bracket.regions[4].rounds[0].games[1].team1Id)
        }}</span>
      </div>
      <div
        class="team"
        [class.wrong]="
          isPickWrong(4, 0, bracket.regions[4].rounds[0].games[1].id, 'team2Id')
        "
      >
        <span class="name">{{
          getTeamName(3, bracket.regions[4].rounds[0].games[1].team2Id)
        }}</span>
      </div>
    </div>
  </div>
  <div class="round"></div>
  <div class="round final4 right-side">
    <div>
      <div
        class="team"
        [class.wrong]="
          isPickWrong(4, 0, bracket.regions[4].rounds[0].games[0].id, 'team1Id')
        "
      >
        <span class="name">{{
          getTeamName(1, bracket.regions[4].rounds[0].games[0].team1Id)
        }}</span>
      </div>
      <div
        class="team"
        [class.wrong]="
          isPickWrong(4, 0, bracket.regions[4].rounds[0].games[0].id, 'team2Id')
        "
      >
        <span class="name">{{
          getTeamName(2, bracket.regions[4].rounds[0].games[0].team2Id)
        }}</span>
      </div>
    </div>
  </div>
  <div class="round elite8 right-side">
    <div *ngFor="let game of bracket.regions[1].rounds[3].games">
      <div class="team" [class.wrong]="isPickWrong(1, 3, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(1, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(1, 3, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(1, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[2].rounds[3].games">
      <div class="team" [class.wrong]="isPickWrong(2, 3, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(2, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(2, 3, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(2, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round sweet16 right-side">
    <div *ngFor="let game of bracket.regions[1].rounds[2].games">
      <div class="team" [class.wrong]="isPickWrong(1, 2, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(1, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(1, 2, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(1, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[2].rounds[2].games">
      <div class="team" [class.wrong]="isPickWrong(2, 2, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(2, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(2, 2, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(2, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round round2 right-side">
    <div *ngFor="let game of bracket.regions[1].rounds[1].games">
      <div class="team" [class.wrong]="isPickWrong(1, 1, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(1, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(1, 1, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(1, game.team2Id) }}</span>
      </div>
    </div>
    <div *ngFor="let game of bracket.regions[2].rounds[1].games">
      <div class="team" [class.wrong]="isPickWrong(2, 1, game.id, 'team1Id')">
        <span class="name">{{ getTeamName(2, game.team1Id) }}</span>
      </div>
      <div class="team" [class.wrong]="isPickWrong(2, 1, game.id, 'team2Id')">
        <span class="name">{{ getTeamName(2, game.team2Id) }}</span>
      </div>
    </div>
  </div>
  <div class="round round1 right-side">
    <div class="team" *ngFor="let team of bracket.regions[1].teams">
      <span class="seed">{{ team.seed }}</span>
      <span class="name">{{ team.name }}</span>
    </div>
    <div class="team" *ngFor="let team of bracket.regions[2].teams">
      <span class="seed">{{ team.seed }}</span>
      <span class="name">{{ team.name }}</span>
    </div>
  </div>
  <div class="title">
    {{ bracket.name }}
  </div>
  <div class="leftChamp">
    <img [src]="getLeftChampLogo()" style="width: 1in" />
  </div>
  <div class="rightChamp">
    <img [src]="getRightChampLogo()" style="width: 1in" />
  </div>
  <div class="championship">
    <img [src]="getChampLogo()" style="width: 2in" />
  </div>
</div>

<!--
<div>
  <pre>
      {{bracket | json}}
    </pre>
</div>
-->
