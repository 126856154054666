<div class="goal-popup">
  <div class="header">
    <h5>{{ goal.popoverTitle }}</h5>
    <hr />
    <div>{{ goal.popoverDesc }}</div>
    <div *ngIf="goal.popoverTitle === 'ESG Goal'">
      <a href="https://cntcloud.sharepoint.com/sites/Not-a-Newsletter/SitePages/ESG-PERFORMANCE-MEASURES---FY21.aspx"
         target="_blank">To see more details visit this link.</a>
    </div>
    <hr />
  </div>
  <div class="left">
    <div>
      <span>{{ goal.maximumFormatted }}</span>
      <span>maximum</span>
    </div>
    <div style="margin-top: 78px;">
      <span>{{ goal.targetFormatted }}</span>
      <span>target</span>
    </div>
    <div style="margin-top: 88px;">
      <span>{{ goal.thresholdFormatted }}</span>
      <span>threshold</span>
    </div>
  </div>
  <div class="right">
    <div class="custom"
         [class.above-target-bg]="goal.hasInverseImpact ? goal.total < goal.target : goal.total >= goal.target"
         [class.below-target-bg]="goal.hasInverseImpact ? goal.total >= goal.target : goal.total < goal.target"
         [style.marginTop.px]="getTotalMarginTop()">
      <div>
        <span [class.above-target]="goal.hasInverseImpact ? goal.total < goal.target : goal.total >= goal.target"
              [class.below-target]="goal.hasInverseImpact ? goal.total >= goal.target : goal.total < goal.target">{{ goal.totalFormatted }}</span>
        <span>total</span>
      </div>
      <div>
        <span [class.above-target-light]="goal.hasInverseImpact ? goal.total < goal.target : goal.total >= goal.target"
              [class.below-target-light]="goal.hasInverseImpact ? goal.total >= goal.target : goal.total < goal.target">{{ goal.inSightFormatted }}</span>
        <span>in sight</span>
      </div>
      <div>
        <span [class.above-target]="goal.hasInverseImpact ? goal.total < goal.target : goal.total >= goal.target"
              [class.below-target]="goal.hasInverseImpact ? goal.total >= goal.target : goal.total < goal.target">{{ goal.inPlaceFormatted }}</span>
        <span>in place</span>
      </div>
    </div>
    <div [style.marginTop.px]="getWeightPctMarginTop()">
      <span>{{ (goal.weight * 100) | number:'1.0-0' }}%</span>
      <span>weight</span>
      <span class="tip">(% of your bonus this goal affects)</span>
    </div>
  </div>
  <div class="chart">
    <canvas #chartCanvas
            width="275"
            height="400"></canvas>
  </div>
</div>
