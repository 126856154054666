import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Format from 'd3-format';

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
    selector: 'cub-graph-gauge',
    templateUrl: './graph-gauge.component.html',
    styleUrls: ['./graph-gauge.component.scss'],
    standalone: true
})
export class GraphGaugeComponent implements OnChanges {

  @Input() header: string;
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() graphMax: number;
  @Input() graphMin: number;
  @Input() graphValue: number;
  @Input() graphTarget: number;
  private margin: Margin;

  private svg: any;

  constructor(private container: ElementRef) { }

  private initMargins() {
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initMargins();
    this.drawChart();
  }

  private drawChart() {
    this.svg = d3.select(this.container.nativeElement).select("svg")
      .attr("width", this.graphWidth + this.margin.left + this.margin.right)
      .attr("height", this.graphHeight + this.margin.top + this.margin.bottom)
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    this.svg.selectAll("*").remove(); // removes all all elements for when it repaints on input parameter changes

    if (this.graphValue > this.graphMax) {
      this.graphMax = this.graphValue * 1.05;
    }

    let arcColor = "#2A992A";
    let actuald = this.graphValue / this.graphMax;
    let padRad = 0.025;
    let chartInset = 10;
    let totalPercent = 0.725;  // Orientation of gauge:
    let radius = Math.min(this.graphWidth, this.graphHeight) / 1.35;
    let barWidth = 20 * this.graphWidth / 150;
    let paddingTop = 25;

    //Utility methods
    let percToDeg = function(perc) {
      return perc * 360;
    };
    let percToRad = function(perc) {
      return degToRad(percToDeg(perc));
    };
    let degToRad = function(deg) {
      return deg * Math.PI / 180;
    };

    // Add layer for the panel
    let chart = this.svg.append('g').attr('transform', "translate(" + ((this.graphWidth + this.margin.left) / 2.2) + ", " + ((this.graphHeight + this.margin.top) / 2 + paddingTop) + ")");
    chart.append('path').attr('class', "arc chart-first").style("fill", "#EDEDED");
    chart.append('path').attr('class', "arc chart-second").style("fill", arcColor);
    chart.append('path').attr('class', "arc chart-third").style("fill", "#554B48");

    let arcEmpty = d3Shape.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth);
    let arcValue = d3Shape.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth);
    let arcTarget = d3Shape.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth);

    let next_start = totalPercent;
    let arcStartRad = percToRad(next_start);
    let arcEndRad = arcStartRad + percToRad(0.55);
    next_start += (this.graphTarget / this.graphMax) / 2;
    arcEmpty.startAngle(arcStartRad).endAngle(arcEndRad);

    let perc = 0.02;
    arcStartRad = percToRad(next_start);
    arcEndRad = arcStartRad + percToRad(perc / 2);
    next_start += (perc) / 2;
    arcTarget.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

    perc = actuald;
    next_start = 0.72;
    arcStartRad = percToRad(next_start);
    arcEndRad = arcStartRad + percToRad(perc / 2);
    arcValue.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

    chart.select(".chart-first").attr('d', arcEmpty);
    chart.select(".chart-second").attr('d', arcValue);
    chart.select(".chart-third").attr('d', arcTarget);

    this.annotGen('value-metricToday', d3Format.format(".2s")(this.graphMin).replace('G', 'B'), this.graphWidth * 0.20, this.graphHeight * 0.75 + paddingTop, 12, '#888', 'end');  // left
    this.annotGen('value-metricTarget', d3Format.format(".2s")(this.graphTarget).replace('G', 'B'), this.graphWidth * 0.54, this.graphHeight * 0.05 + 2, 10, '#888', 'start');  // top label
    this.annotGen('value-metricTarget', d3Format.format(".2s")(this.graphMax).replace('G', 'B'), this.graphWidth * 0.74, this.graphHeight * 0.75 + paddingTop, 12, '#888', 'start'); // right
    this.annotGen('value-metricActual', d3Format.format(".2s")(this.graphValue).replace('G', 'B'), this.graphWidth * 0.48, this.graphHeight * 0.55 + paddingTop, 22, '#333', 'middle'); // value
  }

  private annotGen(id, data, xPos, yPos, fontSize, fill, anchor) {
    this.svg.append("text")
      .text(function() {
        return data + '%';
      })
      .attr('id', id)
      .attr('transform', "translate(" + xPos + ", " + yPos + ")")
      .attr("font-size", fontSize)
      .attr('text-anchor', anchor)
      .style("fill", fill);
  }
}
