<form [formGroup]="emailForm" novalidate>
  <div *ngIf="emailAddress.editMode" class="editMode">
    <mat-form-field>
      <mat-label>Email Type</mat-label>
      <mat-select [compareWith]="selectCompare"  formControlName="emailType">
        <mat-option *ngFor="let type of emailTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput  formControlName="emailAddress">
      <mat-error *ngIf="emailForm.controls['emailAddress'].invalid">You must enter a valid email address</mat-error>
    </mat-form-field>
    <button mat-button (click)="cancelItem()" >
      Cancel
    </button>
    <button mat-raised-button (click)="saveItem()" color="primary" >
      <fa-icon [icon]="faSave" class="fa-lg"></fa-icon> &nbsp;Save
    </button>
  </div>
  <div *ngIf="!emailAddress.editMode" class="viewMode">
    <fa-icon [icon]="faEdit" class="fa-lg" (click)="editItem()"></fa-icon>
    <span class="type" (click)="editItem()">{{ getEmailTypeDesc() }}</span>
    <span class="email" (click)="editItem()">{{ emailAddress.email }}</span>

    <button mat-icon-button *ngIf="!confirmDelete" (click)="confirmDeletion()">
      <fa-icon [icon]="faTrashAlt" class="fa-lg"></fa-icon>
    </button>
    <button mat-raised-button *ngIf="confirmDelete" (click)="deleteItem()" >
      <fa-icon [icon]="faTrashAlt" class="fa-lg"></fa-icon> &nbsp;Confirm
    </button>
    <button mat-button *ngIf="confirmDelete" (click)="cancelDelete()">
      Cancel
    </button>
  </div>
</form>
