<h6 mat-dialog-title>{{ data.title }}</h6>
<mat-dialog-content>
  <p>
    {{ data.message }}
  </p>
</mat-dialog-content>
<div style="margin-top: 20px; width: 100%">
  <button mat-button mat-dialog-close (click)="cancel()">
    {{ data.cancelText }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    style="float: right"
    (click)="ok()"
  >
    {{ data.submitText }}
  </button>
</div>
