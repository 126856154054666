export const environment = {
  redirectUrl: 'https://cubtest.centerpoint.com/',
  clientId: '2db9fed7-07e2-46e3-8ce5-f20dd2322097',
  tenantId: '082c43a2-7c4d-46f4-b07b-97018da7bffc',
  tenantName: 'cntcloud',

  production: false,
  qa: true,
  localDev: false,
  remote: false,

  // urls
  mainUrl: 'https://cubtest.centerpoint.com/',
  cubCloudUrl: 'https://cubtest.centerpoint.com/api/',
  apiBaseURL: './server/api/',
  publicWebsite: 'https://centerpoint.com/properties/',
  egnyteUrl: 'https://cntclouddev.egnyte.com/navigate/path/shared/',
  versionCheckURL: 'https://cubtest.centerpoint.com/version.json',
  reportUrl: 'https://reports.centerpoint.com/ReportServer?/',
  reportDetailURL: 'https://reports.centerpoint.com/ReportServer/Pages/ReportViewer.aspx?/',
  propertyBlobStorageUrl: 'https://cubfilestorage.blob.core.windows.net/propertyfiles/',
  userBlobStorageUrl: 'https://cubfilestorage.blob.core.windows.net/userphotos/',
  glPrelimFileUrl: 'https://webqa.centerpoint.com/uploadedFiles/GLDataTransferFiles/PreliminaryRun/',
  cxCubCloudUrl: 'https://cubtest.centerpoint.com/api/',
  reportFunctionUrl: 'https://cubReportFunctions.azurewebsites.net/api/',

  // paths
  allShareDirPath: '\\\\egnytedrivedev\\allshare\\',
  propertyFileDirPath: '\\\\egnytedrivedev\\allshare\\Property Files\\',
  vendorFileDirPath: '\\\\egnytedrivedev\\allshare\\Vendor Forms\\',
  glPrelimPath: '\\\\egnytedrivedev\\allshare\\Accounting\\GI Historical Data Transfer\\Preliminary Archive',
  glExportPath: '\\\\egnytedrivedev\\allshare\\Extranet\\CenterPoint CalEast File Sharing\\Shared Documents',
  invDirPath: 'shared/acquisitions/deals/'
};
