import { Address } from 'src/app/shared/address';

export class MapInfo {
  title: string;
  content: string;
  link: string;
  linkText: string;
  itemKey: number;

  constructor(title: string, content: string, link: string, linkText: string, itemKey: number) {
    this.title = title;
    this.content = content;
    this.link = link;
    this.linkText = linkText;
    this.itemKey = itemKey;
  }
}
