<mat-checkbox *ngIf="!useSlideToggle"
              [(ngModel)]="selectedValue"
              [(indeterminate)]="indeterminate"
              (change)="selectChange($event)"
              color="primary"
              style="width: 100%;"
              [disabled]="disabled">
  {{ label }}
</mat-checkbox>

<mat-slide-toggle *ngIf="useSlideToggle"
                  [(ngModel)]="selectedValue"
                  (change)="selectChange($event)"
                  color="primary"
                  style="width: 100%;"
                  [disabled]="disabled">
  {{ label }}
</mat-slide-toggle>
