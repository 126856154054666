import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupService } from '../../core/lookup.service';
import { LeaseBarneyService } from '../lease-barney.service';
import { NumWithCommasPipe } from '../../shared/num-with-commas.pipe';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'cub-lease-barney-dialog',
    templateUrl: './lease-barney-dialog.component.html',
    styleUrls: ['./lease-barney-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, DecimalPipe, NumWithCommasPipe]
})
export class LeaseBarneyDialogComponent {
  constructor(
    private leaseBarneyService: LeaseBarneyService,
    public snackBar: MatSnackBar,
    private lookupService: LookupService,
    public dialogRef: MatDialogRef<LeaseBarneyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  close() {
    this.dialogRef.close();
  }

}
