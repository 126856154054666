<form class="brokerForm" [formGroup]="fgCommission" autocomplete="off">
  <div>
    <h4>Broker Commission</h4>
    <div class="commissionGridWrapper">

      <div class="commissionBrokerType">
        <mat-form-field >
				  <mat-label>Broker type</mat-label>
          <mat-select formControlName="brokerType" >
            <mat-option *ngFor="let type of brokerTypes" [value]="type.id">{{ type.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!fgCommission.controls.brokerType.valid">
              Broker type is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="commissionPayType">
        <mat-form-field >
				  <mat-label>Payment type</mat-label>
          <mat-select formControlName="payType" >
            <mat-option *ngFor="let type of payTypes" [value]="type.id">{{ type.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!fgCommission.controls.payType.valid">
              Payment type is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="commissionPaidDt">
          <mat-form-field>
				  <mat-label>Approval date</mat-label>
          <input matInput formControlName="paidDt" [matDatepicker]="pickerPaidDt" >
          <mat-error *ngIf="!fgCommission.controls.paidDt.valid">
              A valid date value is required
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="pickerPaidDt"></mat-datepicker-toggle>
          <mat-datepicker #pickerPaidDt></mat-datepicker>
          </mat-form-field>
      </div>

      <div class="commissionPaidAmt">
        <mat-form-field >
				  <mat-label>Paid amount</mat-label>
          <input formControlName="paidAmt" matInput >
          <mat-error *ngIf="!fgCommission.controls.paidDt.valid">
            A valid dollar amount is required
          </mat-error>
      </mat-form-field>
      </div>

      <div class="commissionFirm" >
          <mat-form-field>
				  <mat-label>Brokerage Firm</mat-label>
          <input matInput [formControl]="brokerageFirmCtrl" (blur)="blurBrokerageFirm()" formControlName="brokerageFirm" [matAutocomplete]="auto"  aria-label="Brokerage Firm" spellcheck="false">
          <mat-error *ngIf="!fgCommission.controls.brokerageFirm.valid">
            Brokerage Firm is required
          </mat-error>
          <mat-autocomplete #auto="matAutocomplete" >
            <mat-option *ngFor="let firm of filteredFirms | async" [value]=firm (onSelectionChange)="onSelectFirm(firm)" >{{ firm }}</mat-option>
          </mat-autocomplete>
          </mat-form-field>
      </div>

      <div class="commissionBrokers">
        <mat-card appearance="outlined" class="commissionBrokersCard">
          <mat-card-header>
            <mat-card-title>Select Broker(s)
              <button mat-icon-button [disabled]="!fgCommission.controls.brokerageFirm.valid" (click)="addBroker()">
                <i class="fa fa-plus fa-lg" aria-hidden="true" title="Add broker(s)" style="margin: 0px;"></i>
              </button>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div *ngFor="let broker of brokersByFirm" formArrayName="brokers" class="brokerListWrapper">
              <mat-checkbox [color]="'primary'" [checked]="broker.isChecked" class="brokerListCheckbox" (change)="toggleBroker(broker, $event)">{{ broker.firstName }} {{ broker.lastName }} </mat-checkbox>
                <button mat-icon-button class="brokerListButton" (click)="editBroker(broker)">
                  <i class="fa fa-pencil" aria-hidden="true" title="Edit broker"></i>
                </button>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-error *ngIf="!fgCommission.controls.brokers.valid && (brokerageFirmCtrl.touched || brokerageFirmCtrl.dirty)">
          <small>At lease one broker is required</small>
        </mat-error>
      </div>

      <div class="commissionActions">
          <button mat-raised-button (click)="clickClose()">Close</button>
          <button mat-raised-button color="primary" align="end" [disabled]="!fgCommission.valid" (click)="clickSave()">Save</button>
      </div>
    </div>
  </div>
</form>
