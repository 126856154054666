<div class="bar-graph-details">
  <h3>{{ title }}</h3>
  <div #barChart class="bar-chart">
    <div *ngFor="let bar of data; let i = index" class="bar"
        [style.width.px]="((chartWidth - 20) / data.length) - 12"
        (mouseenter)="showBarDetails(bar, i)"
        (mouseleave)="showOriginal()"
        (click)="goToUrl(bar)">
      <div [style.height.px]="getBarSpaceAbove(bar)"></div>
      <div [style.height.px]="getBarHeight(bar)" [style.backgroundColor]="getHighlightColor(i)"></div>
      <div>{{ bar.label }}</div>
    </div>
  </div>
  <table>
    <colgroup>
      <col style="width: 50%;" />
      <col style="width: 50%;" />
    </colgroup>
    <tbody>
      <tr>
        <td>
          <div [style.color]="highlightColor">{{ dataToShow }}</div>
          <div>{{ label }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
