import { Component, Input, OnInit } from '@angular/core';
import { ListService } from '../../core/list.service';
import { ListGroupOption, ListSortOption } from '../list-control';
import { ListOptions } from '../list-control/listOptions';

import { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faSortAlphaUp } from '@fortawesome/pro-light-svg-icons/faSortAlphaUp';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons/faSortAlphaDown';
import { faObjectGroup } from '@fortawesome/pro-light-svg-icons/faObjectGroup';
import { faObjectUngroup } from '@fortawesome/pro-light-svg-icons/faObjectUngroup';
import { faArrowFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowFromBottom';
import { faArrowFromTop } from '@fortawesome/pro-light-svg-icons/faArrowFromTop';
import { ListGroupSortPipe } from './list-group-sort.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'cub-list-control-group-sort',
    templateUrl: './list-control-group-sort.component.html',
    styleUrls: ['./list-control-group-sort.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatMenuModule, FontAwesomeModule, NgFor, ListGroupSortPipe]
})
export class ListControlGroupSortComponent implements OnInit {

  @Input() listOptions: ListOptions;
  @Input() system: string;
  selectedGroup: ListGroupOption;
  sortingAsc = true;
  groupChanged = false;

  faCaretDown = faCaretDown;
  faCheck = faCheck;
  faSortAlphaUp = faSortAlphaUp;
  faSortAlphaDown = faSortAlphaDown;
  faObjectGroup = faObjectGroup;
  faObjectUngroup = faObjectUngroup;
  faArrowFromBottom = faArrowFromBottom;
  faArrowFromTop = faArrowFromTop;

  constructor(
    private listService: ListService
  ) {
    this.selectedGroup = null;
  }

  ngOnInit() {
    this.getSelectedGroup();
    this.listService.listSettings$.subscribe(listSettings => {
      this.listOptions = listSettings;
      this.getSelectedGroup();
    });
  }

  getSelectedGroup(): void {
    if (this.listOptions.groupBy.length > 0) {
      this.selectedGroup = this.listOptions.groupBy.filter(g => g.selected)[0];
    } else {
      this.selectedGroup = null;
    }
  }

  onSortChange(sortField: ListSortOption) {
    if (sortField.selected) {
      sortField.asc = !sortField.asc;
    } else {
      sortField.asc = true;
      sortField.selected = true;
    }
    this.sortingAsc = sortField.asc;
    this.groupChanged = false;

    // deselect all others
    this.listOptions.sortBy.forEach(sortBy => {
      if (sortBy !== sortField) {
        sortBy.selected = false;
        return;
      }
    });

    this.updateListService(false);
  }

  onGroupChange(groupField: ListGroupOption) {
    groupField.selected = true;
    this.groupChanged = true;
    this.selectedGroup = groupField;

    this.listOptions.groupBy.forEach(groupBy => {
      if (groupBy !== groupField) {
        groupBy.selected = false;
        return;
      }
    });

    this.listOptions = JSON.parse(JSON.stringify(this.listOptions)) as ListOptions; // copy object to force update in list-control-view component
    this.updateListService(true);
  }

  getSelectedSortName(): string {
    return this.listOptions.sortBy.filter(s => s.selected)[0].name;
  }

  getSelectedGroupName(): string {
    return this.listOptions.groupBy.filter(g => g.selected)[0].name;
  }

  updateListService(causesDataRefresh: boolean) {
    this.listService.listUpdated(this.listOptions);
  }

  getGroupIcon(): any {
    return (this.selectedGroup.name !== 'Nothing' ? faObjectGroup : faObjectUngroup);
  }

  getSortIcon(): any {
    return (this.sortingAsc ? faSortAlphaUp : faSortAlphaDown);
  }

  getSortDirectionIcon(sortField: any): any {
    return (sortField.asc ? faArrowFromBottom : faArrowFromTop);
  }
}
