import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { AlertService } from '../../core/alert.service';
import { Property } from '../../property/property';
import { PropertyService } from '../../property/property.service';
import { Unit } from '../../property/unit';
import { NumWithCommasPipe } from '../num-with-commas.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-lease-barney-unit-picker',
    templateUrl: './lease-barney-unit-picker.component.html',
    styleUrls: ['./lease-barney-unit-picker.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgClass, NgFor, NgIf, MatButtonModule, NumWithCommasPipe]
})
export class LeaseBarneyUnitPickerComponent implements OnInit {
  fg: UntypedFormGroup;
  allProps: Property[] = [];
  filteredProps: Property[];
  propUnits: Unit[] = [];
  filteredUnits: Unit[] = [];
  selectedPropertyId: string;  // store the value of currently selected property key here, then save to db when exiting form.
  selectedPropertyKey: number;
  selectedUnitKeys: number[];  // store the currently selected units here, and if they change use this to save back to db.
  selectedUnitKey: number;
  propUnitKeys: number[] = [];
  searchTerm$ = new Subject<string>();
  searchText: string;
  tenantName: string;
  property: Property;
  // selPropList: any;
  selUnitList: any;
  myScrollTop: number;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<string>,
    private propertyService: PropertyService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.selectedPropertyId = this.data.currentPropId;
    this.selectedPropertyKey = this.data.currentPropKey;
    this.searchText = this.data.currentPropId;
    this.selectedUnitKeys = this.data.currentUnits;
    this.tenantName = this.data.tenantName;
    this.selectedUnitKey = this.data.currentUnits;
    this.myScrollTop = 0;
    // Evenutally this picker must be able to handle multiple units.  The stored proc to save will
    // need to be able to accept a table-valued set of unit keys.?
    // this.selectedUnitKey = this.data.currentUnits[0];

    this.propertyService.properties$.subscribe(result => {
      this.allProps = result;
      let arr = this.allProps;
      if (this.searchText !== null && this.searchText.length > 0) {
        arr = arr.filter(p => (p.classKey !== 1008 && p.classKey !== 3007) &&
          p.city.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          p.propertyDesc.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          p.propertyId.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          (p.areisID !== undefined && p.areisID !== null && p.areisID.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1));
      }
      this.filteredProps = arr;
    });

    if (this.selectedPropertyId !== '') {
      this.propertyService.getUnits(this.selectedPropertyId).subscribe(d => {
        this.propUnits = d;
        this.setCurrentUnitInCtrl();
      });
    }
    this.createForm();
    this.onSearchTermChange();
  }

  onSearchTermChange(): void {
    this.fg.get('searchText').valueChanges.subscribe(val => {
      this.searchText = val;
      let arr = this.allProps;
      if (this.searchText !== null && this.searchText.length > 0) {
        arr = arr.filter(p => p.city.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          p.propertyDesc.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          p.propertyId.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
          (p.areisID !== undefined && p.areisID !== null && p.areisID.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1));
      }
      this.filteredProps = arr;
      this.selectedUnitKey = 0;
      this.propUnits = [];
    });

  }

  createForm() {
    this.fg = this.fb.group({
      searchText: new UntypedFormControl(this.searchText),
      selProperty: new UntypedFormControl(this.selectedPropertyKey),
      tenantName: new UntypedFormControl(this.tenantName),
      selUnitList: new UntypedFormControl(this.selUnitList)
    });
  }

  onPropertySelect(value: string) {
    this.propertyService.getProperty(value).subscribe(p => {
      this.property = p;
      this.selectedPropertyKey = p.propertyKey;
      this.selectedPropertyId = p.propertyId;
      this.propertyService.getUnits(this.selectedPropertyId).subscribe(d => {
        this.propUnits = d;
        this.selectedUnitKey = 0;
        this.setCurrentUnitInCtrl();
      });
    });
  }

  setCurrentUnitInCtrl() {
    this.fg.patchValue({
      selUnitList: this.selectedUnitKey
    });
  }

  onUnitSelect(value: number) {
    this.selectedUnitKey = value;
  }

  cancel() {

  }

  clickClose() {
    let unitId = '';
    this.dialogRef.close({ saved: false, tenantName: this.tenantName, propertyKey: this.selectedPropertyKey.toString(), unitKey: this.selectedUnitKey.toString(), unitId });
  }

  onSubmit() {

  }

  clickSave() {
    let tenantValue = this.fg.controls.tenantName.value;
    if (this.selectedUnitKey === 0) {
      alert('Sorry, but you must select a property and a unit before we can create the lease barney. Click `Close` to exit.');
    } else {
      this.filteredUnits = this.propUnits.filter(u => u.unitKey === this.selectedUnitKey);
      let unitId = this.filteredUnits[0].unitID;
      this.dialogRef.close({ saved: true, tenantName: tenantValue, propertyKey: this.selectedPropertyKey.toString(), unitKey: this.selectedUnitKey.toString(), unitId });
    }
  }
}
