
import { PortalGauge } from './portalData';

export class GoalChart {

  private ctx: any;
  private width = 500;
  private height = 200;
  private canvasMargin = 20;
  public popUpLinkWidth = 0;
  public drawingInset = 0;
  private portalGauges: PortalGauge[] = [];

  constructor(ctx: any, width: number, height: number, gauges: PortalGauge[]) {
    this.ctx = ctx;
    this.width = width;
    this.height = height;
    this.portalGauges = gauges;
  }

  draw(): any {

    let maxGraphY = this.canvasMargin;
    let maxLineY = this.canvasMargin * 2;
    let targetLineY = (this.height / 2) - 10;
    let baseLineY = this.height - 50;
    let goalTitleY = this.height - (this.canvasMargin * 1.25);
    let goalSubTitleY = this.height - (this.canvasMargin * 0.6);

    // max line
    this.ctx.beginPath();
    this.ctx.moveTo(this.canvasMargin, maxLineY);
    this.ctx.lineTo(this.width - this.canvasMargin, maxLineY);
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#ccc';
    this.ctx.stroke();

    // target line
    this.ctx.beginPath();
    this.ctx.moveTo(this.canvasMargin, targetLineY);
    this.ctx.lineTo(this.width - this.canvasMargin, targetLineY);
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#ccc';
    this.ctx.stroke();

    // baseline
    this.ctx.beginPath();
    this.ctx.moveTo(this.canvasMargin, baseLineY);
    this.ctx.lineTo(this.width - this.canvasMargin, baseLineY);
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#aaa';
    this.ctx.stroke();

    this.drawingInset = this.width / this.canvasMargin;
    let widthToGraph = this.width - (this.drawingInset * 2.0);
    let barWidth = widthToGraph / this.portalGauges.length / 2.0;
    let barSpacing = barWidth;
    let goalTitleMaxWidth = barWidth + barSpacing - (barSpacing * .1);
    this.popUpLinkWidth = barWidth + barSpacing;

    // goal target
    this.ctx.fillStyle = '#777';
    this.ctx.font = '10px Roboto';
    this.ctx.fillText('target', this.canvasMargin, targetLineY + 10);

    // goal max
    this.ctx.fillStyle = '#777';
    this.ctx.font = '10px Roboto';
    this.ctx.fillText('max', this.canvasMargin, maxLineY + 10);

    for (let i = 0; i < this.portalGauges.length; i++) {
      const goal = this.portalGauges[i];

      // draw stacked bars
      this.ctx.fillStyle = '#6E84B9';
      if (goal.total < goal.target && !goal.hasInverseImpact) {
        this.ctx.fillStyle = '#e55555';
      }
      // get top of the stacked bars
      let goalPixelRatio = (baseLineY - maxLineY) / (goal.maximum - goal.threshold);
      let stackedHeight = (goal.total - goal.threshold) * goalPixelRatio;
      let stackedTop = baseLineY - stackedHeight;
      let inPlaceHeight = stackedHeight * (goal.inPlace / goal.total);
      let inSightHeight = stackedHeight * (goal.inSight / goal.total);
      // console.log('goal debug', goal.description, goalPixelRatio, stackedTop, stackedHeight, inPlaceHeight, inSightHeight);

      let ratioAboveTarget = (goal.total - goal.target) / (goal.maximum - goal.target);
      if (goal.hasInverseImpact) {
        ratioAboveTarget = (goal.target - goal.total) / (goal.target - goal.maximum);
      }
      let inSightRatio = Math.abs(goal.inSight) / goal.target;
      let inSightGraphValueY = targetLineY - (ratioAboveTarget * (targetLineY - maxGraphY));
      let barLeft = this.canvasMargin + this.drawingInset + (barSpacing * i * 2);
      if (i > 0) {
        barLeft += (barSpacing / (this.portalGauges.length - 1));
      } else {
        barLeft += (barSpacing / 3);
      }
      let inSightYHeight = (baseLineY - inSightGraphValueY) * inSightRatio;

      // new stuff here
      inSightYHeight = (baseLineY - inSightGraphValueY) * (goal.inSight / goal.total);
      inPlaceHeight = (baseLineY - inSightGraphValueY) * (goal.inPlace / goal.total);

      // in sight bar
      // console.log('in sight', goal.description, inSightGraphValueY, inSightYHeight);
      if (inSightYHeight < 0) {
        inSightGraphValueY = inSightGraphValueY + inSightYHeight;
        this.ctx.fillRect(barLeft, inSightGraphValueY, barWidth, Math.abs(inSightYHeight));
        this.ctx.strokeStyle = '#21376C';
        this.ctx.fillStyle = '#ffffff';
        this.ctx.fillRect(barLeft + 2, inSightGraphValueY + 2, barWidth - 4, Math.abs(inSightYHeight) - 4);
      } else {
        this.ctx.fillRect(barLeft, inSightGraphValueY, barWidth, Math.abs(inSightYHeight));
      }

      // in place bar
      this.ctx.fillStyle = '#21376C';
      if (goal.total < goal.target && !goal.hasInverseImpact) {
        this.ctx.fillStyle = '#b22222';
      }
      // console.log('in place', goal.description, inSightGraphValueY + inSightYHeight, inPlaceHeight);

      // sometimes the in sight can be negative (for percentages), so this tries to handle that
      let inPlaceTop = inSightGraphValueY + inSightYHeight;
      if (inSightYHeight < 0) {
        inPlaceTop = inSightGraphValueY - inSightYHeight;
        inPlaceHeight = inPlaceHeight + inSightYHeight;
      }
      this.ctx.fillRect(barLeft, inPlaceTop, barWidth, inPlaceHeight);


      // goal title
      let titleFontSize = 13;
      let centerBar = barLeft + (barWidth / 2);
      this.ctx.fillStyle = '#444';
      this.ctx.font = 'bold ' + titleFontSize.toString() + 'px Roboto';
      while (this.ctx.measureText(goal.description).width > goalTitleMaxWidth) {
        titleFontSize--;
        this.ctx.font = 'bold ' + titleFontSize.toString() + 'px Roboto';
      }
      let titleTextWidth = this.ctx.measureText(goal.description).width;
      let titleTextLeft = centerBar - (titleTextWidth / 2);
      this.ctx.fillText(goal.description, titleTextLeft, goalTitleY);

      // goal subtitle
      this.ctx.fillStyle = '#777';
      let subTitleFontSize = 11;
      this.ctx.font = subTitleFontSize.toString() + 'px Roboto';
      while (this.ctx.measureText(goal.gaugeMeasure).width > goalTitleMaxWidth) {
        subTitleFontSize--;
        this.ctx.font = subTitleFontSize.toString() + 'px Roboto';
      }
      let subTitleTextWidth = this.ctx.measureText(goal.gaugeMeasure).width;
      let subTitleTextLeft = centerBar - (subTitleTextWidth / 2);
      this.ctx.fillText(goal.gaugeMeasure, subTitleTextLeft, goalSubTitleY);

      // goal value label
      this.ctx.fillStyle = '#21376C';
      let valueLabelFontSize = 13;
      this.ctx.font = 'bold ' + valueLabelFontSize.toString() + 'px Roboto';
      while (this.ctx.measureText(goal.totalFormatted).width > goalTitleMaxWidth) {
        valueLabelFontSize--;
        this.ctx.font = 'bold ' + valueLabelFontSize.toString() + 'px Roboto';
      }
      let valueLabelTextWidth = this.ctx.measureText(goal.totalFormatted).width;
      let valueLabelTextLeft = centerBar - (valueLabelTextWidth / 2);
      let valueLabelY = inSightGraphValueY - 8;
      if (inSightGraphValueY - 8 < this.canvasMargin) {  // do we need to move the text within the bar to show it?  (bar went to max y)
        valueLabelY = this.canvasMargin + 5;
        this.ctx.fillStyle = '#fff';
      }
      this.ctx.fillText(goal.totalFormatted, valueLabelTextLeft, valueLabelY);

      // goal weight label
      this.ctx.fillStyle = '#ddd';
      this.ctx.font = '11px Roboto';
      let weightText = parseInt((goal.weight * 100).toString(), 10).toString() + '%';
      let weightWidth = this.ctx.measureText(weightText).width;
      let weightLeft = centerBar - (weightWidth / 2);
      this.ctx.fillText(weightText, weightLeft, baseLineY - 8);

    }

    return this.ctx;
  }
}
