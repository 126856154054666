
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { Lookup } from '../select/lookup';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


export function createSelectValidator() {
  return (s: UntypedFormControl) => {
    let err = {
      notSelected: s.value
    };

    return (s.value === '' || +s.value === 0) ? err : null;
  };
}

/* there may be a way to combine this with the regular select control - but no time now - tit works - kg 9/27/17 */

@Component({
    selector: 'cub-select-multiple-auto-save',
    templateUrl: './select-multiple-auto-save.component.html',
    styleUrls: ['./select-multiple-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectMultipleAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectMultipleAutoSaveComponent), multi: true }
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class SelectMultipleAutoSaveComponent implements OnInit, OnChanges {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedValue') _selectedValue: string[];
  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() label: string;
  @Input() itemKey: number;
  @Input() dataType: string;
  @Input() values: Lookup[];
  private origValue = [];
  private origStringValue = [];
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    private saveService: SaveService
  ) {
    this.dataType = 'int';  // default to int - can be set by calling component to string
  }

  ngOnInit() {
    this.validateFn = createSelectValidator();
  }

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val;
    this.propagateChange(val.toString());

    if (this.origValue.length === 0) {
      this.origValue = val;
      this.origStringValue = this.values.filter(v => this.origValue.find(f => f === v.id)).map(v => v.name);
    }
    this.propagateChange(val.toString());
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.validateFn = createSelectValidator();
    if (changes._fieldValue !== undefined) {
      this.origValue = changes._fieldValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
      this.selectedValue = value; // ? value[0] : [];
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // TODO - Do we need this callback?
  registerOnTouched() { }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  selectChange(evt: any) {
    this.save(this._selectedValue);
  }

  save(values: string[]): void {
    let newStringValues = this.values.filter(v => values.find(f => f === v.id)).map(v => v.name);
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.label, this.itemKey, this.origStringValue.join(), values.join(), newStringValues.join(), this.dataType, 32);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        this.origValue = values;
      })
      .catch(() => {
        // TODO - Better error handling
      });
  }

  compareFunction(c1: any, c2: any): boolean {
    if (typeof c2 === 'number') {
      return c1 === c2.toString();
    } else if (typeof c2 === 'string') {
      return c1 === c2;
    }
    return false;
  }

}
