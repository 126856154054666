
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SystemData } from '../dashboard/portalData';
import { Observable, ReplaySubject } from 'rxjs';
import { QuarterlyMetric } from '../dashboard/quarterlyMetric';
import { CalendarEvent } from './calendarEvent';
import { DataColumn } from './dataColumn';
import { DataColumnView } from './dataColumnView';
import { User } from '../user/user';
import { UserService } from '../core/user.service';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  private mainUrl = environment.cubCloudUrl + 'systemData';
  private dwUrl = environment.cubCloudUrl + 'datawarehouse';
  private systemData = new ReplaySubject<SystemData>(1);
  systemData$ = this.systemData.asObservable();
  private quarterlySystemData = new ReplaySubject<QuarterlyMetric[]>(1);
  quarterlySystemData$ = this.quarterlySystemData.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    // commented out because of timing errors. These are called from prepareCUB function in app.component.ts, from ngOnInit
    // this.getSystemData();
    // this.getQuarterlyMetrics();
  }

  getSystemData() {
    const url = `${this.mainUrl}`;
    if (localStorage.getItem('systemData') !== null) {
      this.systemData.next(JSON.parse(localStorage.getItem('systemData')) as SystemData);
    }
    this.http.get<SystemData>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(d => {
      localStorage.setItem('systemData', JSON.stringify(d));
      this.systemData.next(d as SystemData);
    });
  }

  getCurrentQuarter(): Observable<string> {
    const url = `${this.dwUrl}/currentquarter`;
    return this.http.get<string>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getQuarterlyDataFromDW(qtr: string): Observable<QuarterlyMetric> {
    let qtrFixed = qtr.replace(/\//g, '-');
    const url = `${this.dwUrl}/quarterlymetrics/${qtrFixed}`;
    return this.http.get<QuarterlyMetric>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getCalendarEvents(): Observable<CalendarEvent[]> {
    const url = `${this.mainUrl}/calendarEvents`;
    return this.http.get<CalendarEvent[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getQuarterlyMetrics() {
    const url = `${this.mainUrl}/metrics`;
    if (localStorage.getItem('quarterlySystemData') !== null) {
      this.quarterlySystemData.next(JSON.parse(localStorage.getItem('quarterlySystemData')) as QuarterlyMetric[]);
    }
    this.http.get<QuarterlyMetric[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(d => {
      localStorage.setItem('quarterlySystemData', JSON.stringify(d));
      this.quarterlySystemData.next(d as QuarterlyMetric[]);
    });
    let list = this.http.get<QuarterlyMetric[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getFieldsForScreen(screenKey: number) {
    const url = `${environment.cubCloudUrl}dictionary/${screenKey}`;
    return this.http.get<DataColumn[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getViewsForScreen(screenKey: number) {
    const url = `${environment.cubCloudUrl}dictionary/screens/${screenKey}/views`;
    return this.http.get<DataColumnView[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
