<div class='panel panel-primary'
     style="width: 100%;">
  <google-map #googleMap
    width="100%"
    [height]="mapHeight"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    (zoomChanged)="zoomChanged()"
    (centerChanged)="centerChanged()"
    (mapClick)="moveMap($event)"
  >
    <map-marker [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                (mapDragend)="markerDragEnd2($event)">
    </map-marker>
  </google-map>
</div>
