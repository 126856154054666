<h1 id="tableLabel">Properties</h1>
<div style="height: 30px; border: solid 1px #ddd; padding: 5px;">
  {{ testData }}
</div>
<table class='table table-striped'
       aria-labelledby="tableLabel"
       *ngIf="props">
  <thead>
    <tr>
      <th>Properties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let prop of props">
      <td>{{ prop.propertyDesc }}</td>
    </tr>
  </tbody>
</table>
