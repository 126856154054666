import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import 'chartjs-plugin-annotation';

@Component({
    selector: 'cub-graph-pie',
    templateUrl: './graph-pie.component.html',
    styleUrls: ['./graph-pie.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgChartsModule]
})
export class GraphPieComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective, { static: true }) baseChart;
  @Input() chartLabels: string[];
  @Input() chartColors: string[];
  @Input() chartData: number[];
  @Input() chartSize: number;
  @Input() chartTitle!: string;
  @Input() showLegend = false;

  public barChartType = 'pie';
  labelClass = 'label';
  valueClass = 'value';
  public data: any;
  private chartDataSets: any;

  public chartOptions = {
    title: {
      display: (this.chartTitle !== ''),
      text: ''
    },
    layout: {
      padding: 10
    },
    maintainAspectRatio: false

    /*
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
          drawTicks: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        position: 'bottom',
        gridLines: {
          display: false,
          drawTicks: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }]
    },

    elements: {
      point: {
        pointStyle: 'rectRounded',
        radius: 0,
        hitRadius: 10,
        borderWidth: 0,
        borderColor: '#1BA048',
        backgroundColor: '#1BA048'
      },
      line: {
        tension: 0.4,
        borderColor: '#006D15',
        backgroundColor: '#006D15'
      }
    },

    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],

    animation: {
      duration: 0, // general animation time
    },

    lineOnHover: {
      enabled: true,
      lineColor: '#006D15',
      lineWidth: 1
    },
    hover: {
      mode: 'nearest',
      intersect: true // duration of animations when hovering an item
    },

    defaultColor: '#006D15',
    // responsiveAnimationDuration: 0, // animation duration after a resize


    responsive: true,

    annotation: {
      // Defines when the annotations are drawn.
      // This allows positioning of the annotation relative to the other
      // elements of the graph.
      //
      // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
      // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
      drawTime: 'afterDatasetsDraw', // (default)

      // Mouse events to enable on each annotation.
      // Should be an array of one or more browser-supported mouse events
      // See https://developer.mozilla.org/en-US/docs/Web/Events
      events: ['click'],

      // Double-click speed in ms used to distinguish single-clicks from
      // double-clicks whenever you need to capture both. When listening for
      // both click and dblclick, click events will be delayed by this
      // amount.
      dblClickSpeed: 350, // ms (default)

      // Array of annotation configuration objects
      // See below for detailed descriptions of the annotation options
      annotations: [{
        drawTime: 'afterDraw', // overrides annotation.drawTime if set
        id: 'a-line-1', // optional
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 255,
        borderColor: 'transparent',
        borderWidth: 0,

        label: {
          backgroundColor: '#006D15',
          fontFamily: 'sans-serif',
          fontSize: 12,
          fontColor: '#fff',
          xPadding: 6,
          yPadding: 6,
          cornerRadius: 6,
          position: 'right',
          xAdjust: 0,
          yAdjust: 0,
          enabled: true,
          content: 'Properties'
        },
        // Fires when the user clicks this annotation on the chart
        // (be sure to enable the event in the events array below).
        onClick: function(e) {
          // `this` is bound to the annotation element
        }
      },
      {
        drawTime: 'afterDraw', // overrides annotation.drawTime if set
        id: 'a-line-2', // optional
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 285,
        borderColor: 'transparent',
        borderWidth: 0,

        label: {
          backgroundColor: '#006D15',
          fontFamily: 'sans-serif',
          fontSize: 30,
          fontColor: '#fff',
          xPadding: 6,
          yPadding: 0,
          cornerRadius: 0,
          position: 'right',
          xAdjust: 0,
          yAdjust: 0,
          enabled: true,
          content: '350'
        },
        // Fires when the user clicks this annotation on the chart
        // (be sure to enable the event in the events array below).
        onClick: function(e) {
          // `this` is bound to the annotation element
        }
      }
      ]
    }
    */
  };

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.updateChart();
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.baseChart.chart !== undefined) {
      setTimeout(() => {
        this.updateChart();
      }, 500);
    }
  }

  updateChart() {
    this.baseChart.chart.config.data.labels = this.chartLabels;
    this.baseChart.chart.config.data.datasets.data = this.chartData;
    this.baseChart.chart.config.data.datasets[0].backgroundColor = this.chartColors;
    this.baseChart.chart.options.title.display = (this.chartTitle !== '');
    this.baseChart.chart.options.title.text = this.chartTitle;
    this.baseChart.chart.update();
  }

  // TODO - Do we need this handler?
  chartClicked(e: any) {
    // console.log(this.baseChart.chart.config);
  }
}
