
export class ListGroupOption {
  name: string;
  fieldName: string;
  system: string;
  selected: boolean;

  constructor() {
  }
}
