export class Address {
  addressKey: number;
  stateKey: number;
  typeKey: number;
  addressDesc: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  stateID: string;
  latitude: number;
  longitude: number;
  county: string;
  country: string;
  zipCode: string;
  type: string;

  constructor() {
    this.addressDesc = '';
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.city = '';
    this.zipCode = '';
    this.county = '';
    this.country = '';
    this.type = '';
  }
}
