import { Component, OnInit, Inject, Input, OnChanges, SimpleChange  } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray, UntypedFormBuilder, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../core/alert.service';
import { LoggerService } from '../../core/logger.service';

import { CertificateUploadService } from './certificate-upload.service';

import * as moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-certificate-upload',
    templateUrl: './certificate-upload.component.html',
    styleUrls: ['./certificate-upload.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, NgIf, MatButtonModule]
})
export class CertificateUploadComponent implements OnInit {
  // file: File;
  filesToUpload: FileList;

  fgUpload: UntypedFormGroup;
  descLabel = 'Lease Id';
  fileLabel = 'Select a file';
  pageHeader = 'Tenant Certificate Upload';
  accept = '';
  multiple = false;


  constructor(
    public dialogRef: MatDialogRef<CertificateUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, description: string, key: number, multiple: boolean, accept: string },
    private fb: UntypedFormBuilder,
    private uploadService: CertificateUploadService,
    private alertService: AlertService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('600px', '425px');
    this.loggerService.logPageVisit('certificate upload', this.data.mode);
    this.createFormControls();
  }

  handleFileInput(fileList: FileList) {
    if (fileList.length > 0) {
      this.filesToUpload = fileList;
      this.fgUpload.controls.pickerInput.setValue(fileList.item(0).name);
      this.fgUpload.controls.pickerValidator.setValue(fileList.item(0).name);
      this.fileLabel = 'Selected file';
    } else {
      this.filesToUpload = null;
      this.fgUpload.controls.pickerInput.setValue('');
      this.fgUpload.controls.pickerValidator.setValue('');
      this.fileLabel = 'Select a file';
    }
  }

  clickClose() {
    this.dialogRef.close();
  }

  clickUpload() {
    this.uploadService.uploadFiles(this.data.mode, this.data.description, this.data.key, this.filesToUpload, this.fgUpload.controls.date.value, this.fgUpload.controls.comments.value)
    .subscribe((result: boolean) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  createFormControls() {
    let isDisabled = true;
    this.accept = this.data.accept;
    this.multiple = this.data.multiple;
    this.pageHeader = this.data.mode + ' Certificate Upload';

    if (this.data.mode === 'Vendor') {
      this.descLabel = 'Vendor';
      isDisabled = false;
    }

    let today = moment(); // moment().format('MM/DD/YYYY')

    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    this.fgUpload = this.fb.group({
      date: new UntypedFormControl(new Date(), [Validators.required]),
      description: new UntypedFormControl({value: this.data.description, disabled: true}),
      comments: new UntypedFormControl({value: '', disabled: isDisabled}),
      pickerInput: new UntypedFormControl(''),
      pickerValidator: new UntypedFormControl('', [Validators.required]), // dummy control to hold validator for disabled pickerInput field
      pickerHidden: new UntypedFormControl(''),
    });
  }

}
