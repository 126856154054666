import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ListService } from '../../core/list.service';
import { ListTab } from '../../shared/list-control/listTab';
import { NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'cub-list-control-tabs',
    templateUrl: './list-control-tabs.component.html',
    styleUrls: ['./list-control-tabs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatTabsModule, NgFor]
})
export class ListControlTabsComponent implements OnInit {

  @Input() tabs: ListTab[];
  @Output() tabSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private listService: ListService
    ) { }

  ngOnInit() {
    this.listService.tabChanged(this.tabs[0]);
  }

  tabChanged(evt: any) {
    let tab = this.tabs.filter(t => t.title === evt.tab.textLabel)[0];
    this.tabSelected.emit(tab);
    this.listService.tabChanged(tab);
  }

}
