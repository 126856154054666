import { Broker } from './broker';

import * as moment from 'moment';

export class BrokerCommission {
  transactionKey: number;
  brokerageFirm: string;
  paidDt: Date;
  paidAmt: number;
  relationshipTypeKey: number;
  relationshipTypeDesc: string;
  payTypeKey: number;
  payTypeDesc: string;
  dispoPackageKey: number; // this is for the OLD dispo pipeline
  dispoItemKey: number; // this is for the OLD dispo pipeline
  dispoProspectKey: number; // this is for the NEW dispo pipeline
  propertyKey: number;
  leaseKey: number;
  notes: string;

  brokers: Broker[] = [];

  constructor() {
    this.transactionKey = 0;
    this.brokerageFirm = '';
    this.paidDt = null;
    this.paidAmt = 0;
    this.relationshipTypeKey = 0;
    this.relationshipTypeDesc = '';
    this.payTypeKey = 0;
    this.payTypeDesc = '';
    this.dispoPackageKey = null;
    this.dispoItemKey = null;
    this.dispoProspectKey = null;
    this.propertyKey = null;
    this.leaseKey = null;
    this.notes = '';
  }

}
