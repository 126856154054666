import { Component, OnInit, Renderer2, HostBinding, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoggerService } from '../../../core/logger.service';
import { MessageDialogComponent } from '../../../shared/message-dialog/message-dialog.component';
import { faClose } from '@fortawesome/pro-light-svg-icons';

import { AlertService } from '../../../core/alert.service';
import { GldataService } from '../gldata.service';
import { GlTransfer } from '../glTransfer';
import { TransferedData } from '../glTransfer';
import { GLCodeMaintenance } from '../glTransfer';

import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { GldataUploadComponent } from '../gldata-upload/gldata-upload.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export interface GLMonth {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'cub-gldata-transfer-data',
    templateUrl: './gldata-transfer-data.component.html',
    styleUrls: ['./gldata-transfer-data.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FontAwesomeModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, NgIf, GldataUploadComponent]
})
export class GldataTransferDataComponent implements OnInit {

  @HostBinding('@.disabled') disabled = true;
  month = 0;
  year = 0;
  selectedTab = 0;
  hasPropError = false;
  hasGLError = false;
  chromeExtInstalled = false;
  fgGlTransfer: UntypedFormGroup;
  glTransfer: GlTransfer = new GlTransfer();
  faClose = faClose;

  @ViewChild('sortProp', { static: true }) sortProp: MatSort;
  @ViewChild('sortGL', { static: true }) sortGL: MatSort;

  dsProperty: MatTableDataSource<TransferedData>;
  propColumns: string[] = ['sendGLData', 'propertyId', 'propertyIdCalpers', 'areisId', 'bucketName', 'warnProperty'];

  dsGlCodes: MatTableDataSource<GLCodeMaintenance>;
  glColumns: string[] = ['glAcctNum', 'glAcctDesc', 'calPersGLAcctNum', 'calPersGLAcctDesc', 'isDepreciation', 'plugAcctNum', 'warnGL'];

  glMonths: GLMonth[] = [
    { value: '1', viewValue: 'January' },
    { value: '2', viewValue: 'February' },
    { value: '3', viewValue: 'March' },
    { value: '4', viewValue: 'April' },
    { value: '5', viewValue: 'May' },
    { value: '6', viewValue: 'June' },
    { value: '7', viewValue: 'July' },
    { value: '8', viewValue: 'August' },
    { value: '9', viewValue: 'September' },
    { value: '10', viewValue: 'October' },
    { value: '11', viewValue: 'November' },
    { value: '12', viewValue: 'December' }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private renderer2: Renderer2,
    private glService: GldataService,
    public dialog: MatDialog,
    private titleService: Title,
    private alertService: AlertService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('CUB - GL Data Export');
    this.loggerService.logPageVisit('gldata-transfer', '');

    // moment months are zero based so no need to subtract 1 for previous month
    this.month = moment().month();
    this.year = moment().year();

    this.createFormControls(); // create the form controls
    this.configureLinksForExtension();  // configure folder links
  }

  configureLinksForExtension() {
    let global = this.renderer2.listen('window', 'message', (evt) => {
      if (evt.data.type && (evt.data.type === 'CUBExtIsInstalled')) {
        this.chromeExtInstalled = true;
      }
    });
  }

  close() {
    this.dialog.closeAll();
  }

  createFormControls() {
    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    this.fgGlTransfer = this.fb.group({
      glMonth: new UntypedFormControl(this.month.toString(), [Validators.required]),
      glYear: new UntypedFormControl(this.year, [Validators.required, Validators.min(2010), Validators.max(this.year), Validators.pattern(/^[0-9]{4}$/)]),
    });
  }

  initializeDatasources() {
    this.dsProperty = new MatTableDataSource(this.glTransfer.glSentList);
    this.dsProperty.sort = this.sortProp;

    this.dsGlCodes = new MatTableDataSource(this.glTransfer.glCodeList);
    this.dsGlCodes.sort = this.sortGL;
  }

  openPrelimFolder() {
    let url = environment.glPrelimPath;
    this.loggerService.logPageVisit('open folder - gl data', url);
    if (this.chromeExtInstalled) {
      window.postMessage({ type: 'OpenFileExplorer', text: url }, '*');
    } else {
      console.log('No Chrome Extension!');
    }
  }

  openExportFolder() {
    let url = environment.glExportPath;
    this.loggerService.logPageVisit('open folder - gl data', url);
    if (this.chromeExtInstalled) {
      window.postMessage({ type: 'OpenFileExplorer', text: url }, '*');
    } else {
      console.log('No Chrome Extension!');
    }
  }

  openDialog(dialogTitle: string, dialogMsg: string) {
    let dlg = this.dialog.open(MessageDialogComponent, {
      width: '450px',
      data: { title: dialogTitle, content: dialogMsg }
    });
  }

  setMonth() {
    this.month = parseInt(this.fgGlTransfer.controls.glMonth.value, 10);
  }

  setYear() {
    this.year = parseInt(this.fgGlTransfer.controls.glYear.value, 10);
  }
}
