<div
  class="game"
  [class.round2]="round === 2"
  [class.round3]="round === 3"
  [class.round4]="round === 4"
  [class.atTop]="atTop"
>
  <div
    class="team"
    (click)="teamSelected(1)"
    [class.selected]="selectedTeamIndex === 1"
    [class.disabled]="!canEdit"
    [class.wrongPick]="isPickWrong(0)"
  >
    <div class="team-logo">
      <img *ngIf="getTopSeed() !== ''" [src]="getLogo(team1)" />
    </div>
    <div class="team-name">
      <span class="seed">{{ getTopSeed() }}</span> {{ getTopSeedName() }}
    </div>
  </div>
  <div
    class="team"
    (click)="teamSelected(2)"
    [class.selected]="selectedTeamIndex === 2"
    [class.disabled]="!canEdit"
    [class.wrongPick]="isPickWrong(1)"
  >
    <div class="team-logo">
      <img *ngIf="getBottomSeed() !== ''" [src]="getLogo(team2)" />
    </div>
    <div class="team-name">
      <span class="seed">{{ getBottomSeed() }}</span> {{ getBottomSeedName() }}
    </div>
  </div>
</div>
