import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: true
})

export class HighlightPipe implements PipeTransform {

  transform(origText: string, search: string): string {
    let finalText = origText;

    if (search && origText) {
      search.split(';').forEach(searchTerm => {
        let fieldSpecific = searchTerm.split(':');
        let textToFind = fieldSpecific[0];
        if (fieldSpecific.length > 1) {
          textToFind = fieldSpecific[1];
        }

        if (textToFind && origText) {
          let pattern = textToFind.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
          pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
          }).join('|');
          const regex = new RegExp(pattern, 'gi');

          finalText = finalText.replace(regex, (match) => `<span class="highlight">${match}</span>`);
        }
      });
    }

    return finalText;
  }
}
