export class ListItem {
  itemId: string;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  active: boolean;
  scrollIndex: number;

  constructor() {}
  // constructor(id: string, title1: string, title2: string, title3: string, title4: string, active: boolean) {
  //   this.itemId = id;
  //   this.title1 = title1;
  //   this.title2 = title2;
  //   this.title3 = title3;
  //   this.title4 = title4;
  //   this.active = active;
  // }
}
