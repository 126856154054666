<div class="folderPicker">
  <h4>Please pick a folder:</h4>
  <div style="border: solid 1px #ddd; height: 550px; overflow: auto; padding: 5px;">
    <mat-tree [dataSource]="dataSource"
              [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node"
                     (click)="clickNode(node)"
                     matTreeNodePadding>
        <button mat-icon-button
                disabled></button>
        <fa-icon *ngIf="!node.isFile" [icon]="faFolderClosed"></fa-icon>
        <fa-icon *ngIf="node.isFile" [icon]="faFileAlt"></fa-icon>
        <span class="folder-name"
              [class.selected]="node.path === currentPath">{{node.item}}</span>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                     [class.selected]="node.path === currentPath"
                     (click)="clickNode(node)"
                     matTreeNodePadding>
        <button mat-icon-button
                type="button"
                *ngIf="!node.isFile && node.expanded"
                [attr.aria-label]="'Toggle ' + node.item"
                matTreeNodeToggle>
          <fa-icon [icon]="faChevronDown"
                   style="color: #999;"></fa-icon>
        </button>
        <button mat-icon-button
                type="button"
                *ngIf="!node.isFile && !node.expanded"
                [attr.aria-label]="'Toggle ' + node.item"
                matTreeNodeToggle>
          <fa-icon [icon]="faChevronRight"
                   style="color: #999;"></fa-icon>
        </button>
        <button mat-icon-button
                [attr.aria-label]="'Toggle ' + node.item">
          <fa-icon [icon]="faSpinner"
                   [spin]="true"
                   *ngIf="node.isLoading"></fa-icon>
          <fa-icon [icon]="faFolderOpen"
                   [class.selected]="node.path === currentPath"
                   *ngIf="!node.isLoading"></fa-icon>
        </button>
        <span class="folder-name"
              [class.selected]="node.path === currentPath">{{node.item}}</span>
      </mat-tree-node>
    </mat-tree>
  </div>
  <div style="margin-top: 20px;">
    <button mat-button
            type="button"
            (click)="cancel()">Cancel</button>
    <button mat-raised-button
            color="primary"
            type="button"
            style="float: right; width: 80px;"
            (click)="done()">Done</button>
  </div>
</div>
