<form class="badDataForm" [formGroup]="fgBadData" autocomplete="off">
  <div>
    <h4>Report Bad Data - {{ statusTitle }}</h4>
    <div class="badDataGridWrapper">
      <div style="grid-column: 1 / span 4;" *ngIf="ticket.propertyKey !== null || ticket.leaseKey !== null">
        <h6>{{ ticket.location }}</h6>
      </div>
      <div style="grid-column: 1 / span 4;" *ngIf="ticket.propertyKey === null && ticket.leaseKey === null">
        <mat-form-field style="width: 100%">
				  <mat-label>Location</mat-label>
          <input formControlName="location" matInput  >
          <mat-error *ngIf="!fgBadData.controls.description.valid">
            Location of bad data is required
          </mat-error>
        </mat-form-field>
      </div>
      <div style="grid-column: 1 / span 4;">
        <mat-form-field style="width: 100%">
				  <mat-label>{{ badDataTitle }}</mat-label>
          <textarea formControlName="description" matInput rows="5" ></textarea>
          <mat-hint align="start" *ngIf="ticket.ticketKey > 0" ><strong>{{ createdBy }}</strong> </mat-hint>
          <mat-error *ngIf="!fgBadData.controls.description.valid">
            Description of bad data is required
          </mat-error>
        </mat-form-field>
      </div>
      <div style="grid-column: 1 / span 4;">
        <mat-form-field style="width: 100%">
				  <mat-label>Assigned To</mat-label>
          <input type="text" formControlName="assignees" matInput  />
         </mat-form-field>
      </div>
      <div style="grid-column: 1 / span 4;">
        <mat-form-field style="width: 100%">
				  <mat-label>{{ resolutionTitle }}</mat-label>
          <textarea formControlName="resolution" matInput rows="5"  (blur)="blurResolution()" (keyup)="blurResolution()"></textarea>
          <mat-hint align="start" *ngIf="ticket.ticketKey > 0 && ticket.statusKey === 2" ><strong>{{ resolvedBy }}</strong> </mat-hint>
        </mat-form-field>
      </div>
      <div style="grid-column: 1 / span 4;">
      </div>
    </div>
    <div style="margin-bottom: 8px;">
      <button mat-flat-button (click)="clickClose()" style="margin-right: 3px;">Close</button>
      <button mat-raised-button color="primary" align="end" [disabled]="!fgBadData.valid" *ngIf="ticket.ticketKey === 0" (click)="clickReportBadData()" style="float: right; margin-right: 10px;">Report Bad Data</button>
      <button mat-raised-button color="primary" align="end" [disabled]="!hasResolution" *ngIf="ticket.ticketKey > 0 && ticket.statusKey === 1" (click)="clickResolve()" style="float: right; margin-right: 10px;">Resolve Issue</button>
    </div>
  </div>
</form>
