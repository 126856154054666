<div class="inv-detail">
  <div class="inv-header">
    <div class="inv-header-title">
      <h3>{{ inv.name }} <img id="imgArchived" alt="archived" src="./assets/img/archived.png" *ngIf="inv.archived" /></h3>
      <div class="inv-header-tools">
        <button mat-stroked-button (click)="archive()">
          <fa-icon *ngIf="!inv.archived" [icon]="faBox" class="fa-lg" matTooltip="Archive Prospect"></fa-icon><span *ngIf="!inv.archived" style="margin-left: 12px;">Archive</span>
          <fa-icon *ngIf="inv.archived" [icon]="faBoxOpen" class="fa-lg" matTooltip="Bring Back Prospect"></fa-icon><span *ngIf="inv.archived" style="margin-left: 12px;">Bring Back</span>
        </button>
        <button mat-stroked-button (click)="renameClick()" matTooltip="Rename Deal" style="margin-left: 20px;">
          <fa-icon [icon]="faICursor" class="fa-lg"></fa-icon><span style="margin-left: 12px;">Rename Deal</span>
        </button>
        <button mat-stroked-button (click)="openFolder()" matTooltip="Open Folder" style="margin-left: 20px;">
          <fa-icon [icon]="faFolderOpen" class="fa-lg"></fa-icon><span style="margin-left: 12px;">Open Folder</span>
        </button>
      </div>
    </div>
    <div class="inv-header-details">
      <div>
        <span>Location:</span> {{ inv.location }}
      </div>
      <div>
        <span>Region(s):</span> {{ regions }}
      </div>
      <div>
        <span>Status:</span> {{ inv.archived ? 'Archived' : inv.statusDesc }} {{ inv.closed ? '(Closed)' : '' }}
      </div>
      <div>
        <span>Space:</span> {{ inv.totalSF | numWithCommas }}
      </div>
      <div>
        <span>TAC (/sf):</span> ${{ displayTAC(inv.tac) | numWithCommas }} / {{ (inv.tacPerSF !== undefined && inv.tacPerSF !== null && inv.tacPerSF.toString() === '' ? '' : inv.tacPerSF | currency:'USD':'symbol':'1.2-2' | numWithCommas) }}
        <!-- This nonsense is necessary because I kept getting a pipe error for empty strings.
                    It seems as if the auto save inputs containing the values below are changing them to empty strings
                    which is causing the errors.  If I check for the empty string (and undefined) before using the pipe,
                    everything works. Not pretty, but it works.  KG out -->
      </div>
      <div>
        <span>Closing Dt:</span> {{ dateToString(inv.closingDt) }}
      </div>
      <div>
        <span>Broker/Firm:</span> {{ inv.broker }} / {{ inv.firm }}
      </div>
    </div>
    <div class="inv-header-summary inv-header-summary-pipeline">
      <div class="sect-header">Report Manager</div>
      <div>
        <cub-checkbox-auto-save name="chkCalpers" [label]="'On CalPers Report'" [(ngModel)]="inv.onReportCalEast" (controlUpdate)="calpersReportToggle()" [tableName]="systemTable" [fieldName]="'onCalEastReport'" [itemKey]="inv.key" [useSlideToggle]="true">
        </cub-checkbox-auto-save>
      </div>
      <div>
        <cub-checkbox-auto-save name="chkCalpers" [label]="'Fell Off Last Report'" [(ngModel)]="inv.fellOffLastReport" [tableName]="systemTable" [fieldName]="'fellOffLastReport'" [itemKey]="inv.key" [useSlideToggle]="true">
        </cub-checkbox-auto-save>
      </div>
      <div>
        <cub-checkbox-auto-save name="chkRevenue" [label]="'On Revenue Report'" [(ngModel)]="inv.onReportRevenue" [tableName]="systemTable" [fieldName]="'onRevenueReport'" [itemKey]="inv.key" [useSlideToggle]="true">
        </cub-checkbox-auto-save>
      </div>
      <div>&nbsp;</div>
      <div>
        <cub-checkbox-auto-save name="chkITC" [label]="'On ITC Report'" [(ngModel)]="inv.onReportITC" [tableName]="systemTable" [fieldName]="'onITCReport'" [itemKey]="inv.key" [useSlideToggle]="true">
        </cub-checkbox-auto-save>
      </div>
      <div>&nbsp;</div>
      <div>
        <cub-checkbox-auto-save name="chkRegion" [label]="'On Region Report'" [(ngModel)]="inv.onReportRegion" [tableName]="systemTable" [fieldName]="'onRegionReport'" [itemKey]="inv.key" [useSlideToggle]="true">
        </cub-checkbox-auto-save>
      </div>
      <!--
      <div>
        <cub-checkbox-auto-save name="chkTimeline"
          [label]="'On Timeline'"
          [(ngModel)]="inv.onTimeline"
          [tableName]="systemTable"
          [fieldName]="'onTimeline'"
          [itemKey]="inv.key"
          [useSlideToggle]="true" >
        </cub-checkbox-auto-save>
      </div>
      -->
    </div>
  </div>
  <mat-tab-group #tabs [selectedIndex]="0" color="accent" [mat-stretch-tabs]="false" >
    <mat-tab label="Pipeline">
      <div class="general-content">
        <div id="colLeft" class="general-content-col">
          <mat-expansion-panel id="pnlGeneral" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> General </mat-panel-title>
              <mat-panel-description> {{ inv.name }} </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-content">
              <div>
                <cub-input-auto-save [label]="'Total Square Feet'" [(ngModel)]="inv.totalSF" [tableName]="systemTable" [fieldName]="'totalSF'" [itemKey]="inv.key" [dataType]="'int'" [dataLength]="500" [numFormat]="'1.0-0'" (controlUpdate)="calculateCoverageRatio();">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Occupancy Pct'" [(ngModel)]="inv.occupancyPct" [tableName]="systemTable" [fieldName]="'occupancy'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-select-auto-save name="selPipelineGroup" [label]="'Pipeline Group'" [(ngModel)]="inv.pipelineGroupKey" [values]="pipelineGroups" [tableName]="systemTable" [fieldName]="'pipelineGroupKey'" [itemKey]="inv.key">
                </cub-select-auto-save>
              </div>
              <div>
                <cub-select-auto-save name="selStatus" [label]="'Status'" [(ngModel)]="inv.statusKey" [values]="acqStatus" [tableName]="systemTable" [fieldName]="'statusKey'" [itemKey]="inv.key">
                </cub-select-auto-save>
              </div>
              <div>
                <cub-select-auto-save name="selStrategy" [label]="'Strategy'" [(ngModel)]="inv.invStrategyKey" [values]="invStrategy" [tableName]="systemTable" [fieldName]="'invStrategyKey'" [itemKey]="inv.key">
                </cub-select-auto-save>
              </div>
              <div>
                <cub-select-auto-save name="selEsgMatrix" [label]="'CalPERS ESG Matrix'" [(ngModel)]="inv.esgMatrixId" [values]="esgMatrix" [tableName]="systemTable" [fieldName]="'esgMatrixId'" [itemKey]="inv.key">
                </cub-select-auto-save>
              </div>
              <div>
                <cub-select-auto-save name="selBidProcess" [label]="'Bid Process Status'" [(ngModel)]="inv.bidProcessKey" [values]="acqBidStatus" [tableName]="systemTable" [fieldName]="'bidProcessKey'" [itemKey]="inv.key">
                </cub-select-auto-save>
              </div>
              <div>
                <cub-checkbox-auto-save name="chkFortress" [label]="'Fortress Asset'" [(ngModel)]="inv.isFortress" [tableName]="systemTable" [fieldName]="'isFortress'" [itemKey]="inv.key">
                </cub-checkbox-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Year Built'" [(ngModel)]="inv.yearBuilt" [tableName]="systemTable" [fieldName]="'yearBuilt'" [itemKey]="inv.key" [dataType]="'string'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Doors'" [(ngModel)]="inv.doors" [tableName]="systemTable" [fieldName]="'doors'" [itemKey]="inv.key" [dataType]="'int'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Land SF'" [(ngModel)]="inv.landSF" [tableName]="systemTable" [fieldName]="'landSF'" [itemKey]="inv.key" [dataType]="'int'" [dataLength]="500" [numFormat]="'1.0-0'" (controlUpdate)="calculateAcres();">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [readOnly]="true" [label]="'Acres'" [(ngModel)]="inv.acres" [tableName]="systemTable" [fieldName]="'acres'" [itemKey]="inv.key" [dataType]="'double'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Lease Term in Place'" [(ngModel)]="inv.leaseTermInPlace" [tableName]="systemTable" [fieldName]="'leaseTermInPlace'" [itemKey]="inv.key" [dataType]="'double'" [dataLength]="500" [numFormat]="'1.1-1'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [readOnly]="true" [label]="'Coverage Ratio'" [ngModel]="inv.coverageRatio" [tableName]="systemTable" [fieldName]="'coverageRatio'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <cub-select-multiple-auto-save name="selDealMakers" [label]="'CNT Contacts'" [(ngModel)]="inv.cntContacts" [values]="dealMakers" [tableName]="systemTable" [fieldName]="'XREF;RELATIONSHIP;1008;ACQ_PROSPECT_KEY;PC_KEY'" [itemKey]="inv.key">
                </cub-select-multiple-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <cub-input-auto-save [label]="'Broker'" [(ngModel)]="inv.broker" [tableName]="systemTable" [fieldName]="'broker'" [itemKey]="inv.key" [dataType]="'string'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <cub-input-auto-save [label]="'Firm'" [(ngModel)]="inv.firm" [tableName]="systemTable" [fieldName]="'firm'" [itemKey]="inv.key" [dataType]="'string'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <cub-input-auto-save [label]="'Seller'" [(ngModel)]="inv.seller" [tableName]="systemTable" [fieldName]="'seller'" [itemKey]="inv.key" [dataType]="'string'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <cub-input-auto-save [label]="'Tenant'" [(ngModel)]="inv.tenant" [tableName]="systemTable" [fieldName]="'tenant'" [itemKey]="inv.key" [dataType]="'string'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <span style="color: #888; font-size: 11px;">Tenant Details</span>
                <cub-textarea-auto-save [label]="''" [(ngModel)]="inv.tenantDetails" [tableName]="systemTable" [fieldName]="'tenantDetails'" [itemKey]="inv.key" [rows]="4" [dataType]="'string'" [dataLength]="500">
                </cub-textarea-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <span style="color: #888; font-size: 11px;">Comments</span>
                <cub-textarea-auto-save [label]="''" [(ngModel)]="inv.comments" [tableName]="systemTable" [fieldName]="'comments'" [itemKey]="inv.key" [rows]="4" [dataType]="'string'" [dataLength]="500">
                </cub-textarea-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <span style="color: #888; font-size: 11px;">Project Description</span>
                <cub-textarea-auto-save [label]="''" [(ngModel)]="inv.projectDesc" [tableName]="systemTable" [fieldName]="'projectDesc'" [itemKey]="inv.key" [rows]="4" [dataType]="'string'" [dataLength]="500">
                </cub-textarea-auto-save>
              </div>
              <div style="grid-column-start: 1; grid-column-end: 3;">
                <span style="color: #888; font-size: 11px;">Internal Regional Notes</span>
                <cub-textarea-auto-save [label]="''" [(ngModel)]="inv.internalRegionalNotes" [tableName]="systemTable" [fieldName]="'internalRegionalNotes'" [itemKey]="inv.key" [rows]="4" [dataType]="'string'" [dataLength]="500">
                </cub-textarea-auto-save>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
        <div class="general-content-col">
          <mat-expansion-panel id="pnelFinancial" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Financials </mat-panel-title>
              <mat-panel-description> ${{ inv.tac | numWithCommas }} </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-content">
              <div>
                <cub-input-auto-save [label]="'Price'" [(ngModel)]="inv.purchasePrice" [tableName]="systemTable" [fieldName]="'purchasePrice'" [itemKey]="inv.key" [dataType]="'money'" (controlUpdate)="makeCalculations();" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'IRR Unlevered'" [(ngModel)]="inv.irrUnlevered" [tableName]="systemTable" [fieldName]="'irrUnlevered'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Total All Costs'" [(ngModel)]="inv.tac" [tableName]="systemTable" [fieldName]="'tac'" [itemKey]="inv.key" [dataType]="'money'" (controlUpdate)="makeCalculations();" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'IRR Levered'" [(ngModel)]="inv.irrLevered" [tableName]="systemTable" [fieldName]="'irrLevered'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Initial NOI'" [(ngModel)]="inv.initialYield" [tableName]="systemTable" [fieldName]="'initialYield'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500" [numFormat]="'1.0-0'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Initial Cap Rate'" [(ngModel)]="inv.capRate" [tableName]="systemTable" [fieldName]="'capRate'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Stable NOI'" [(ngModel)]="inv.stableYield" [tableName]="systemTable" [fieldName]="'stableYield'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500" [numFormat]="'1.0-0'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Stable Cap Rate'" [(ngModel)]="inv.stableCapRate" [tableName]="systemTable" [fieldName]="'stableCapRate'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Commission Amount'" [(ngModel)]="inv.commissionAmt" [tableName]="systemTable" [fieldName]="'commissionAmt'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500" [numFormat]="'1.0-0'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Straight Line'" [(ngModel)]="inv.straightLine" [tableName]="systemTable" [fieldName]="'straightLine'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel id="pnlCalpers" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Calpers Pipeline </mat-panel-title>
              <mat-panel-description> {{ inv.onReportCalEast ? 'O' : 'Not o' }}n Pipeline </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-content">
              <div>
                <cub-input-auto-save [readOnly]="true" [label]="'Calpers Share'" [(ngModel)]="inv.calpersShare" [tableName]="systemTable" [fieldName]="'calpersShare'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [readOnly]="true" [label]="'Ownership Pct'" [(ngModel)]="inv.ownershipPct" [tableName]="systemTable" [fieldName]="'ownershipPct'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Debt Assumed'" [(ngModel)]="inv.debtAssumed" [tableName]="systemTable" [fieldName]="'debtAssumed'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Loan to Value'" [(ngModel)]="inv.ltv" [tableName]="systemTable" [fieldName]="'ltv'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Debt New'" [(ngModel)]="inv.debtNew" [tableName]="systemTable" [fieldName]="'debtNew'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Interest Rate'" [(ngModel)]="inv.interestRate" [tableName]="systemTable" [fieldName]="'interestRate'" [itemKey]="inv.key" [dataType]="'percent'" [dataLength]="500" [numFormat]="'1.2-2'">
                </cub-input-auto-save>
              </div>
              <div>
                <cub-input-auto-save [label]="'Debt Total'" [(ngModel)]="inv.debtTotal" [tableName]="systemTable" [fieldName]="'debtTotal'" [itemKey]="inv.key" [dataType]="'money'" [dataLength]="500">
                </cub-input-auto-save>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel id="pnlTimeline" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Timeline </mat-panel-title>
              <mat-panel-description> {{ dateToString(inv.dealStartDt) }} - {{ dateToString(inv.closingDt) }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-content">
              <div>
                <cub-date-auto-save [label]="'ITC Date'" [(ngModel)]="inv.itcDt" [tableName]="systemTable" [fieldName]="'itcDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'GI Preview Date'" [(ngModel)]="inv.giPreviewDt" [tableName]="systemTable" [fieldName]="'giPreviewDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'EC Date'" [(ngModel)]="inv.icDt" [tableName]="systemTable" [fieldName]="'icDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'IC Date'" [(ngModel)]="inv.aaDt" [tableName]="systemTable" [fieldName]="'aaDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'Prelimary ESG Date'" [(ngModel)]="inv.prelimEsgDt" [tableName]="systemTable" [fieldName]="'prelimEsgDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'DD Inspection Date'" [(ngModel)]="inv.ddInspectionDt" [tableName]="systemTable" [fieldName]="'ddInspectionDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'Final ESG Date'" [(ngModel)]="inv.finalEsgDt" [tableName]="systemTable" [fieldName]="'finalEsgDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'DD Start Date'" [(ngModel)]="inv.ddStartDt" [tableName]="systemTable" [fieldName]="'ddStartDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'Board Date'" [(ngModel)]="inv.boardDt" [tableName]="systemTable" [fieldName]="'boardDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'DD End Date'" [(ngModel)]="inv.ddEndDt" [tableName]="systemTable" [fieldName]="'ddEndDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'Date Added To Pipeline'" [(ngModel)]="inv.addedToPipelineDt" [tableName]="systemTable" [fieldName]="'addedToPipelineDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-date-auto-save [label]="'Closing Date'" (controlUpdate)="closingDtSaved()" [(ngModel)]="inv.closingDt" [tableName]="systemTable" [fieldName]="'closingDt'" [itemKey]="inv.key" [dataLength]="500">
                </cub-date-auto-save>
              </div>
              <div>
                <cub-checkbox-auto-save name="chkUnderwritingFinal" [label]="'Underwriting Finalized'" [(ngModel)]="inv.isUnderwritingFinal" [tableName]="systemTable" [fieldName]="'isUnderwritingFinal'" [itemKey]="inv.key">
                </cub-checkbox-auto-save>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Properties">
      <div style="padding: 20px">
        <cub-investment-prop-list [investment]="inv"></cub-investment-prop-list>
      </div>
    </mat-tab>
    <mat-tab label="Change Log">
      <cub-change-log [tableName]="systemTable" [itemKey]="inv.key"></cub-change-log>
    </mat-tab>
  </mat-tab-group>
</div>
