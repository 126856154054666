export class ArAgingDetail {
  propertyId: string;
  leaseId: string;
  propertyName: string;
  tenantName: string;
  status: string;
  transactionNumber: string;
  chargeCode: string;
  chargeCodeDesc: string;
  detailDate: string;
  month: string;
  currentOwed: number;
  p0_30: number;
  p31_60: number;
  p61_90: number;
  pOver90: number;
  prePaid: number;
  total: number;

  // not on server
  cubTenantName: string;

  constructor() { }
}
