
import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { MapService } from './map.service';
import { MapInfoWindow, MapMarker, GoogleMap, GoogleMapsModule } from '@angular/google-maps'

@Component({
    selector: 'cub-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    standalone: true,
    imports: [GoogleMapsModule]
})
export class MapComponent implements OnChanges {

  @ViewChild("googleMap",{static: false}) public googleMap: GoogleMap;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() mapStyle;
  @Input() mapHeight: number;
  @Input() street: string;
  @Input() city: string;
  @Input() state: string;
  @Input() country: string;
  @Input() markerTitle: string;
  @Input() enabled: boolean;
  @Output() mapViewChange: EventEmitter<any> = new EventEmitter();
  @Output() foundLatLong: EventEmitter<any> = new EventEmitter();
  private movedLat: number;
  private movedLong: number;
  private movedZoom: number;
  center: google.maps.LatLngLiteral = { lat: 41.86, lng: -88.06 };
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    mapId: '6ce877400196df95'
  } as google.maps.MapOptions  // this "as google.maps.MapOptions" is required because for some reason the mapId attribute isn't exposed in the object so it creates a build error

  mapLoaded = false;

  marker : any;

  constructor(
    private mapService: MapService
  ) {
    this.latitude = 41.8656209;
    this.longitude = -88.06023281;
    this.zoom = 18;
    this.movedZoom = 12;
    this.mapHeight = 200;
    this.mapStyle = 'roadmap';
    this.street = '';
    this.city = '';
    this.state = '';
    this.country = 'US';
    this.enabled = true;
    this.center = { lat: this.latitude, lng: this.longitude };
    this.setMarker();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadMap();
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  clickedMarker() {
    // TODO - Do we need this handler?
    // console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd2($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.center = { lat: this.latitude, lng: this.longitude };
    console.log('drag end', this.latitude, this.longitude, $event);
  }

  markerDragEnd(m: Marker, $event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.foundLatLong.emit({ latitude: this.latitude, longitude: this.longitude });
    console.log('drag end', $event.coords.lat, m, $event);
  }

  centerChanged() {
    let zoom = this.googleMap.getZoom();
    let lat = this.googleMap.getCenter().lat();
    let lng = this.googleMap.getCenter().lng();
    this.movedLat = lat;
    this.movedLong = lng;
    this.mapViewChange.emit({ zoomLevel: zoom, latitude: lat, longitude: lng });
  }

  zoomChanged() {
    let zoom = this.googleMap.getZoom();
    let lat = this.googleMap.getCenter().lat();
    let lng = this.googleMap.getCenter().lng();
    this.mapViewChange.emit({ zoomLevel: zoom, latitude: lat, longitude: lng });
  }

  loadMap() {
    this.center = { lat: this.latitude, lng: this.longitude };
    if (this.latitude !== undefined && this.longitude !== undefined) {  // on initial load, don't get lat/long if it already has one  //  removes this so we don't get lat long if we have it already && this.street?.length === 0
      this.movedLat = this.latitude;
      this.movedLong = this.longitude;
      this.movedZoom = this.zoom;
      this.mapLoaded = true;
      this.setMarker();
    } else {
      this.mapService.findLocation(this.street, this.city, this.state, this.country).subscribe(data => {
        if (data.resourceSets.length > 0) {
          if (data.resourceSets[0].resources.length > 0) {
            this.latitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
            this.longitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
            this.center = { lat: this.latitude, lng: this.longitude };
            this.zoom = 12;
            this.mapLoaded = true;
            this.setMarker();
            this.foundLatLong.emit({ latitude: this.latitude, longitude: this.longitude });
          }
        }
      });
    }
  }

  setMarker() {
    this.marker = {
      position: {
        lat: this.latitude,
        lng: this.longitude
      },
      label: {
        text: ' '
      },
      title: this.markerTitle,
      options: {
        animation: google.maps.Animation.DROP,
        draggable: true,
      }
    }
  }
}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  title?: string;
  draggable: boolean;
}

