import { DecimalPipe, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { GoalChart } from './../goalChart';
import { PortalGauge } from './../portalData';


@Component({
    selector: 'cub-widget-goal-popup',
    templateUrl: './widget-goal-popup.component.html',
    styleUrls: ['./widget-goal-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, DecimalPipe]
})
export class WidgetGoalPopupComponent implements OnInit {

  @ViewChild('chartCanvas', { static: true }) chartCanvas: ElementRef;
  goalChart: GoalChart;
  roughTargetTop = 160;
  faInfoCircle = faInfoCircle;

  constructor(
    public dialogRef: MatDialogRef<WidgetGoalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public goal: PortalGauge,
    private dp: DecimalPipe) { }

  ngOnInit() {
    let ctx: CanvasRenderingContext2D = this.chartCanvas.nativeElement.getContext('2d');
    let goals: PortalGauge[] = [];
    goals.push(this.goal);
    this.goalChart = new GoalChart(ctx, 300, 400, goals);
    this.goalChart.draw();
  }

  getTotalMarginTop(): number {
    let top = (this.goal.total - this.goal.target) / (this.goal.maximum - this.goal.target);
    if (this.goal.hasInverseImpact) {
      top = (this.goal.target - this.goal.total) / (this.goal.target - this.goal.maximum);
    }
    top = this.roughTargetTop - (top * this.roughTargetTop) - 30; // 30 rough height of div
    if (top < 0) {
      top = 0;
    }
    return top;
  }

  getWeightPctMarginTop(): number {
    let totalTop = this.getTotalMarginTop();
    return 400 - totalTop - 25 - 180;  // 25 ~ height to total div, 100 ~ padding at bottom of chart
  }

}
