
  <div class="badge mat-elevation-z8">
    <div class="top">
      <div class="badge-header">
        <img src="../../../assets/img/cnt-shield-blue.png" style="width: 40px;">
        <div class="hole"></div>
        <span [title]="user.department">
          <fa-icon [icon]="faBalanceScaleRight" *ngIf="user.department === 'Legal'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faAbacus" *ngIf="user.department === 'Accounting'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faDesktopAlt" *ngIf="user.department === 'MIS'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faForklift" *ngIf="user.department === 'Construction'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faBullseyeArrow" *ngIf="user.department === 'Marketing'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faBuilding" *ngIf="user.department === 'Operations' || user.department === 'Assett Management'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faPaperclip" *ngIf="user.department === 'Office Management'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faMoneyBillAlt" *ngIf="user.department === 'Finance'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faHandsUsd" *ngIf="user.department === 'Investments'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faSearchDollar" *ngIf="user.department === 'Risk Management'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faHandsHelping" *ngIf="user.department === 'Human Resources'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faUnicorn" *ngIf="user.department === 'Special Projects'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faShovel" *ngIf="user.department === 'Development'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faUserCrown" *ngIf="user.department === 'Officer'" class="fa-2x"></fa-icon>
          <fa-icon [icon]="faBriefcase" *ngIf="user.department === 'Corporate'" class="fa-2x"></fa-icon>
        </span>
      </div>
      <div class="badge-person">
        <img [src]="getUrl()" />
        <div>
          <div class="first-name">{{ user.firstName }}</div>
          <div class="last-name">{{ user.lastName }}</div>
          <hr class="divider" />
          <div class="title">{{ user.jobTitle }}</div>
        </div>
      </div>
    </div>
    <div [class]="getClass()">
      <div>
        <div>Team</div>
        <div>{{ user.department }}</div>
      </div>
      <div>
        <div>Location</div>
        <div>{{ user.officeLocation }}</div>
      </div>
      <div>
        <div>Phone</div>
        <div>{{ getPhone(user.workPhone) }}</div>
      </div>
      <div>
        <div>Email</div>
        <div>{{ user.workEmail }}</div>
      </div>
      <div>
        <div>Birthday</div>
        <div>{{ formatBirthday(user.birthday) }}</div>
      </div>
      <div>
        <div>Hired</div>
        <div>{{ formatDt(user.hireDt) }}</div>
      </div>
    </div>
    <div class="footer">
      <fa-icon *ngIf="serviceTimeYears >= 5" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 10" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 15" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 20" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 25" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 30" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <fa-icon *ngIf="serviceTimeYears >= 35" [icon]="faTrophyAlt" class="fa-2x"></fa-icon>
      <span *ngIf="showTrophy()" >&nbsp;&nbsp; {{ serviceTimeYears }}+ years of service!</span>
      &nbsp;&nbsp;
    </div>
  </div>
