import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alert, AlertType } from '../alert';
import { AlertService } from '../alert.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-version-alert',
    templateUrl: './version-alert.component.html',
    styleUrls: ['./version-alert.component.scss'],
    standalone: true,
    imports: [MatButtonModule]
})
export class VersionAlertComponent implements OnInit {

  message: string;
  countdown = 60;

  constructor(
    public dialogRef: MatDialogRef<VersionAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data;
  }

  ngOnInit() {
    setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.reload();
      }
    }, 1000);
  }

  cancel(): void {
    let ret = confirm(`Are you sure you don't want the latest version of CUB? You may have some issues if you choose not to upgrade. Click OK to stick with what you have.  Click Cancel to refresh the screen.`);
    if (ret) {
      this.reload();
    } else {
      this.dialogRef.close();
    }
  }

  reload(): void {
    location.reload();
  }
}
