import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed  } from 'powerbi-client';
import { PowerBIReportEmbedComponent, PowerBIEmbedModule } from 'powerbi-client-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-power-bi-report',
    templateUrl: './powerbi-report.component.html',
    styleUrls: ['./powerbi-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, PowerBIEmbedModule]
})
export class PowerbiReportComponent implements OnInit, OnChanges {

  @Input() reportId: string;
  @Input() groupId: string;
  @ViewChild(PowerBIReportEmbedComponent) reportObj: PowerBIReportEmbedComponent;

  isEmbedded = false;
  embedUrl = '';
  accessToken = '';
  powerBiConfig: IReportEmbedConfiguration = {
    type: 'report',
    id: '',
    groupId: '',
    permissions: models.Permissions.Read,
    embedUrl: 'https://app.powerbi.com/reportEmbed',
    accessToken: localStorage.getItem('powerbiToken'),
    tokenType: models.TokenType.Aad
  };
  pages: Page[];

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.powerBiConfig = {
        type: 'report',
        id: this.reportId,
        groupId: this.groupId,
        permissions: models.Permissions.Read,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        accessToken: localStorage.getItem('powerbiToken'),
        tokenType: models.TokenType.Aad,
        //settings: {
        //    layoutType: models.LayoutType.Custom,
        //    customLayout: {
        //        pageSize: {
        //            type: models.PageSizeType.Custom
        //        },
        //        displayOption: models.DisplayOption.ActualSize,
        //        pagesLayout: {
        //            "ReportSection0afe73f8d5d666763069" : {
        //                defaultLayout: {
        //                    displayState: {
        //                        mode: models.VisualContainerDisplayMode.Visible
        //                    }
        //                },
        //                visualsLayout: {
        //                    "VisualContainer1": {
        //                        x: 1,
        //                        y: 1,
        //                        z: 1,
        //                        width: 400,
        //                        height: 300,
        //                        displayState: {
        //                            mode: models.VisualContainerDisplayMode.Visible
        //                        }
        //                    },
        //                    "VisualContainer2": {
        //                        displayState: {
        //                            mode: models.VisualContainerDisplayMode.Visible
        //                        }
        //                    },
        //                }
        //            }
        //        }
        //    }
        //}
      };
      this.isEmbedded = true;
      // console.log('PowerbiReportComponent INIT', this.isEmbedded, this.powerBiConfig);
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportId) {
      this.powerBiConfig.id = changes.reportId.currentValue;
    }
    if (changes.groupId) {
      this.powerBiConfig.groupId = changes.groupId.currentValue;
    }
  }

  onReportLoaded(event) {
    console.log('onReportLoaded', event);
    const report = this.reportObj.getReport();
    console.log('report control: report', report);
    report.getPages().then((pages) => {
      console.log('report control: page', pages);
      pages[1].visibility = 0;
      pages[1].setActive();
      pages[0].getVisualByName('50579f2da0aec49d1edf').then((visual) => {
        visual.setVisualDisplayState(models.VisualContainerDisplayMode.Visible);
        visual.resizeVisual(1200, 60);
        visual.moveVisual(0, 0);
      });
      pages[1].getVisualByName('9edbe3a94c5eb44e8c03').then((visual) => {
        visual.moveVisual(0, 1);
        visual.resizeVisual(1200, 60);
      });
      pages[1].getVisuals().then((visuals) => {
        console.log('report control: visuals', visuals);
        for (let i = 0; i < visuals.length; i++) {
          if (i != 2 && i != 3) {
            visuals[i].setVisualDisplayState(models.VisualContainerDisplayMode.Hidden);
          }
        }
      });
    });
    report.getFilters().then((filters) => {
      console.log('report control: filters', filters);
    });

    this.filterByJob();
  }

  filterByJob() {
    const report = this.reportObj.getReport();

    let myFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: 1,
        operator: 'In',
        requireSingleSelection: false,
        target: {table: 'dw_construction_job_details', column: 'Job Code'},
        values: ['d117007-1']
      },
    ];

    report.updateFilters(models.FiltersOperations.Replace, myFilter).then((filters) => {
      report.refresh();
      console.log('filters 2', filters);
    });
  }
}
