
import {catchError} from 'rxjs/operators';

import { throwError as observableThrowError ,  Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../core/alert.service';
import { UserService } from 'src/app/core/user.service';


@Injectable()
export class CertificateUploadService {
  private uploadUrl = environment.cubCloudUrl + 'certificates';

constructor(
  private http: HttpClient,
  private alertService: AlertService,
    private userService: UserService
) {
}

uploadFiles(mode: string, description: string, key: number, fileList: FileList, documentDt: Date, comments: string) {
  const url = `${this.uploadUrl}/upload`;

  let formData: FormData = new FormData();
  let file: File = fileList[0];

  formData.append('mode', mode);
  formData.append('description', description);
  formData.append('key', key.toString());
  formData.append('documentDt', JSON.stringify(documentDt));
  formData.append('comments', JSON.stringify(comments));
  formData.append('uploadFile', file, file.name);

  // I was having an issue when I was setting the headers and the iterwebs told me to leave it blank.
  // Voila the file was available on the server within the HttpContext.Current.Request and the data is still
  // coming over as JSON if you inspect that shit.

  return this.http
    .post(url, formData, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(
    catchError((err) => this.handleError(err, this.alertService, 'Sorry, there was a problem uploading this certificate. Please contact MIS.')));
}

private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
  let errMsg: string;
  let publicMsg = 'Something bad happened; please contact MIS or try again later.';
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errMsg = error.error.message;
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    errMsg = error.error;
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  alertService.error('An error occurred: ' + alertMsg);
  // return an observable with a user-facing error message
  return observableThrowError(publicMsg);
}

}
