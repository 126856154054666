
  <cub-alert></cub-alert>
  <cub-side-sub-menu></cub-side-sub-menu>
  <cub-search-result-popup></cub-search-result-popup>

  <div class="fullscreen">
    <cub-top-menu class="top"
        [loggedIn]="authService.loggedIn"
        (logoutClicked)="logout()"
        *ngIf="showTopMenu"
        style="position: fixed; width: 100%; top: 0; z-index: 999; height: 66px;">
    </cub-top-menu>
    <mat-sidenav-container class="middle"
                           [style.marginTop.px]="getMarginTop()">
      <mat-sidenav [mode]="sideNavMode"
                   [(opened)]="sideNavOpened">
        <cub-left-menu></cub-left-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
