import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ListService } from '../../core/list.service';
import { MenuService } from '../../core/menu.service';
import { UserService } from '../../core/user.service';
import { User } from '../../user/user';
import { ListOptions, ListView } from '../list-control';
import { ListControlDialogComponent } from '../list-control-dialog/list-control-dialog.component';
import { LoggerService } from '../../core/logger.service';

import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons/faQuestionSquare';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faICursor } from '@fortawesome/pro-light-svg-icons/faICursor';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faTachometerAlt } from '@fortawesome/pro-light-svg-icons/faTachometerAlt';
import { Constants } from 'src/app/core/constants';
import { NgFor, NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'cub-list-control-view',
    templateUrl: './list-control-view.component.html',
    styleUrls: ['./list-control-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('showingListing', [
            state('true', style({
                transform: 'rotate(0)'
            })),
            state('false', style({
                transform: 'rotate(90deg)'
            })),
            transition('true => false', animate('500ms ease-in')),
            transition('false => true', animate('500ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [FontAwesomeModule, MatButtonModule, MatMenuModule, NgFor, NgIf]
})
export class ListControlViewComponent implements OnInit {
  @Input() listViews: ListView[]; // ignoring the input of this now - could just be a local variable
  @Input() title: string;
  @Input() system: string;
  @Input() canEditFilters: boolean;
  @Input() dashboardUrl?: string;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();
  searchTerm: string;
  listOptions: ListOptions;
  showSystemList = true;
  userLocal: User;
  dashboardSelected = true;

  faQuestionSquare = faQuestionSquare;
  faQuestionCircle = faQuestionCircle;
  faCheck = faCheck;
  faTrashAlt = faTrashAlt;
  faICursor = faICursor;
  faPlus = faPlus;
  faBookmark = faBookmark;
  faSave = faSave;
  faCaretDown = faCaretDown;
  faBars = faBars;
  faTachometerAlt = faTachometerAlt;
  faFilter = faFilter;

  constructor(private router: Router, private loggerService: LoggerService, private listService: ListService, private userService: UserService, public dialog: MatDialog, private menuService: MenuService) {
    this.listViews = [];
    this.userLocal = new User();
    this.listOptions = new ListOptions();
  }

  ngOnInit() {
    if (this.listViews.length > 0) {
      this.listOptions = this.listViews.filter(v => v.defaultView)[0].listOptions;
    }

    // update views when user gets updated from it's service
    this.userService.userData$.subscribe(u => {
      this.userLocal = u;
      let currViewId = this.listViews.filter(v => v.selected)[0].id;
      this.listViews = u.listViews.filter(v => v.system === this.system);
      let currView = this.listViews.filter(v => v.id === currViewId);
      if (currView.length > 0) {
        this.selectView(currView[0]);
        this.listOptions = currView[0].listOptions;
      }
    });

    this.listService.selectedId$.subscribe(id => {
      if (id.length > 0) {
        this.dashboardSelected = false;
      } else {
        this.dashboardSelected = true;
      }
    });
    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showSystemList = result;
    });
  }

  selectView(view: ListView) {
    // make selection
    this.listViews.forEach(v => {
      v.selected = false;
    });
    view.selected = true;
    view.listOptions.viewName = view.name;

    this.listService.listUpdated(view.listOptions);
    this.loggerService.logPageVisit('view selected', 'cubs user key: ' + view.cubsUserKey.toString());
  }

  saveView() {
    let view = this.listViews.filter(v => v.selected)[0];

    view.listOptions = this.listOptions;
    view.listOptions.searchText = this.searchTerm;

    this.userService.saveView(view).subscribe(v => {
      if (v.id <= 0) {
        alert('error saving list settings');
      } else {
        // todo
      }
    });
  }

  setViewAsDefault() {
    let view = this.listViews.filter(v => v.selected)[0];
    view.defaultView = true;

    this.userService.saveView(view).subscribe(v => {
      if (v.id > 0) {
        this.listViews.forEach(v2 => {
          v2.defaultView = false;
        });
        view.defaultView = true;
        this.updateUserViews();
      } else {
        alert('error saving list settings');
      }
    });
  }

  getCurrentViewName(): string {
    let viewName = 'No view selected';
    let views = this.listViews.filter(v => v.selected);
    if (views.length > 0) {
      viewName = views[0].name;
    }
    return viewName;
  }

  showHelp() {
    let dialogRef = this.dialog.open(ListControlDialogComponent, {
      width: '700px',
      data: { title: 'View Help', type: 'help' }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  showRename() {
    let dialogRef = this.dialog.open(ListControlDialogComponent, {
      width: '500px',
      data: { title: 'Rename View', type: 'rename', view: this.listViews.filter(v => v.selected)[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.conclusion === 'renamed') {
        let selView = this.listViews.filter(v => v.selected)[0];
        selView.name = result.newName;
        this.userService.saveView(selView).subscribe(v => {
          if (v.id > 0) {
            this.updateUserViews();
          } else {
            alert('error renaming view');
          }
        });
      }
    });
  }

  showSaveAs() {
    let dialogRef = this.dialog.open(ListControlDialogComponent, {
      width: '500px',
      data: { title: 'Save this View As', type: 'saveAs', view: this.listViews.filter(v => v.selected)[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.conclusion === 'saved') {
        let selView = this.listViews.filter(v => v.selected)[0];
        let newView = JSON.parse(JSON.stringify(selView)) as ListView; // copy selected view
        newView.defaultView = false; // not default by default - get it?
        newView.id = 0; // forces save routine to insert vs update
        newView.listOptions = this.listOptions; // get current options
        newView.listOptions.searchText = this.searchTerm; // get current search text
        newView.name = result.newName; // set the new name
        this.userService
          .saveView(newView) // now save to db
          .subscribe(v => {
            if (v.id > 0) {
              // if saved worked, select new view and add it to the list!
              newView.selected = true;
              newView.id = v.id;
              selView.selected = false;
              this.listViews.push(newView);
              this.updateUserViews();
            } else {
              alert('error saving new View');
            }
          });
      }
    });
  }

  deleteView() {
    let dialogRef = this.dialog.open(ListControlDialogComponent, {
      width: '500px',
      data: { title: 'Delete View', type: 'delete', views: this.listViews }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.conclusion === 'deleteSelected') {
        let selView = this.listViews.filter(v => v.selected)[0];
        this.listService.deleteView(selView.id).subscribe(b => {
          if (b) {
            this.listViews = this.listViews.filter(v => !v.selected);
            this.selectView(this.listViews[0]);
            if (selView.defaultView) {
              // if deleted view was a default view, make first view in list the new default and save that to db
              let newDefaultView = this.listViews.filter(v => v.selected)[0];
              newDefaultView.defaultView = true;
              this.userService.saveView(newDefaultView).subscribe(ret => {
                this.updateUserViews();
              });
            } else {
              this.updateUserViews();
            }
          } else {
            alert('error deleting view');
          }
        });
      }
    });
  }

  updateUserViews() {
    // update the user object with new views
    this.userLocal.listViews.sort((a, b) => (a.system < b.system ? 1 : a.system > b.system ? -1 : 0));
    this.userLocal.listViews.splice(this.userLocal.listViews.findIndex(v => v.system === this.system), this.userLocal.listViews.filter(v => v.system === this.system).length, ...this.listViews);
    this.userService.updateUser(this.userLocal);
  }

  toggleSearch() {
    this.listOptions.showSearch = !this.listOptions.showSearch;
  }

  toggleFilter() {
    this.listOptions.showFilter = !this.listOptions.showFilter;
  }

  toggleSystemList(): void {
    this.menuService.toggleSystemMenu(!this.showSystemList);
  }

  goToDashboard() {
    this.dashboardSelected = true;
    this.listService.setAsSelected('');
    if (this.dashboardUrl) {
      this.router.navigate([this.dashboardUrl]);
    }
  }
}
