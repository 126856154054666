<div class="cub-table-card">
  <table class="brokerCommissionTable">
    <thead>
      <th style="text-align:left">Type</th>
      <th style="text-align:left">Brokerage Firm</th>
      <th style="text-align:left">Approval Date</th>
      <th style="text-align:right">Commission</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngIf="brokerCommissions?.length == 0" style="cursor: default">
        <td colspan=5 align="left">There are no broker commissions for this {{ commissionDesc }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngFor="let commission of brokerCommissions" style="cursor: pointer">
        <td align="left" (click)="editCommission(commission)">{{ commission.relationshipTypeDesc }}</td>
        <td align="left" (click)="editCommission(commission)">{{ commission.brokerageFirm }}</td>
        <td align="left" (click)="editCommission(commission)">{{ commission.paidDt | date: 'MM/dd/yyyy' }}</td>
        <td align="right" (click)="editCommission(commission)">{{ commission.paidAmt | currency:'USD':'symbol':'1.2-2' }}</td>
        <td align="right"><i class="fa fa-trash" aria-hidden="true" title="delete commission" (click)="deleteCommission(commission)" style="margin: 0px;"></i></td>
      </tr>
    </tbody>
  </table>
</div>
