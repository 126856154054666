
// this is the "server version"
export class BracketObject {
  id: number;
  bracket: string;  // not used

  constructor() {
  }
}

export class Region {
  name: string;
  teams: Team[];
  rounds: Round[];

  constructor() {
    this.teams = [];
    this.rounds = [];
  }
}

export class Round {
  name: string;
  games: Game[];

  constructor(n: string) {
    this.name = n;
    this.games = [];
  }
}

export class Game {
  id: number;
  team1Id: number;
  team2Id: number;

  constructor(i: number, t1ID?: number, t2Id?: number) {
    this.id = i;
    if (t1ID) {
      this.team1Id = t1ID;
    }
    if (t2Id) {
      this.team2Id = t2Id;
    }
  }
}

export class Team {
  id: number;
  espnId: number;
  name: string;
  seed: number;

  constructor() {
    this.name = '';
    this.seed = 0;
  }
}

export class Bracket {
  id: number;
  userName: string;
  year: number;
  name: string;
  master: boolean;
  regions: Region[];
  bracket: string;  // not used
  points: number;
  paid: boolean;

  constructor() {
    this.regions = [];
    this.regions.push(new Region());
    this.regions.push(new Region());
    this.regions.push(new Region());
    this.regions.push(new Region());
    this.regions.push(new Region()); // Final Four
    this.regions[4].name = 'Final Four';
    this.regions[4].rounds.push(new Round('Final Four'));
    this.regions[4].rounds.push(new Round('Championship'));
    this.regions[4].rounds.push(new Round('Champion')); // necessary?
  }

  static getPoints(b: Bracket, master: Bracket): number {
    let points = 0;
    master.regions.forEach((rgn, i) => {
      rgn.rounds.forEach((round, j) => {
        round.games.forEach((game, g) => {
          if (i < 4 && j > 0 || i === 4) { // if not final four and not first round (no winners in first round) or is final four raound
            if (b.regions[i].rounds[j].games[g].team1Id !== undefined) {
              if (b.regions[i].rounds[j].games[g].team1Id === master.regions[i].rounds[j].games[g].team1Id) {
                points += Bracket.getRoundMultiple(i, j);
              }
            }
            if (b.regions[i].rounds[j].games[g].team2Id !== undefined) {
              if (b.regions[i].rounds[j].games[g].team2Id === master.regions[i].rounds[j].games[g].team2Id) {
                points += Bracket.getRoundMultiple(i, j);
              }
            }
          }
        });
      });
    });
    return points;
  }

  static getPotentialPoints(b: Bracket, master: Bracket): number {
    let points = 0;
    master.regions.forEach((rgn, i) => {
      rgn.rounds.forEach((round, j) => {
        round.games.forEach((game, g) => {
          if (((j > 0 && i < 4) || (i === 4)) && (game.team1Id === undefined || game.team2Id === undefined)) {  // only potential points after first round and games with no winner yet
            let rdPoints = this.getPotentialPointsForGame(b, master, i, j, g);
            points += rdPoints;
            console.log('total points: ' + points, 'game points: ' + rdPoints, 'rgn: ' + i, 'rnd: ' + j, 'game: ' + g);
          }
        });
      });
    });
    return points;
  }

  static getPotentialPointsForGame(b: Bracket, master: Bracket, rgn: number, rnd: number, g: number): number {
    let points = 0;
    if (rgn < 4) {
      if (rnd === 1) {
        points += 2;
      } else if (rnd === 2) {
        if (g === 0) {
          if (!this.isPickWrong(b, master, rgn, 1, 9, 'team1Id') || !this.isPickWrong(b, master, rgn, 1, 9, 'team2Id')) {
            points += 2;
          }
          if (!this.isPickWrong(b, master, rgn, 1, 10, 'team1Id') || !this.isPickWrong(b, master, rgn, 1, 10, 'team2Id')) {
            points += 2;
          }
        }
        if (g === 1) {
          if (!this.isPickWrong(b, master, rgn, 1, 11, 'team1Id') || !this.isPickWrong(b, master, rgn, 1, 11, 'team2Id')) {
            points += 2;
          }
          if (!this.isPickWrong(b, master, rgn, 1, 12, 'team1Id') || !this.isPickWrong(b, master, rgn, 1, 12, 'team2Id')) {
            points += 2;
          }
        }
      } else if (rnd === 3) {
        if (g === 0) {
          if (!this.isPickWrong(b, master, rgn, 2, 13, 'team1Id') || !this.isPickWrong(b, master, rgn, 2, 13, 'team2Id')) {
            points += 4;
          }
          if (!this.isPickWrong(b, master, rgn, 2, 14, 'team1Id') || !this.isPickWrong(b, master, rgn, 2, 14, 'team2Id')) {
            points += 4;
          }
        }
      }
    } else if (rgn === 4) { // final four
      if (rnd === 0) {
        if (g === 0) {
          if (!this.isPickWrong(b, master, 0, 3, 15, 'team1Id') || !this.isPickWrong(b, master, 0, 3, 15, 'team2Id')) {
            points += 8;
          }
          if (!this.isPickWrong(b, master, 3, 3, 15, 'team1Id') || !this.isPickWrong(b, master, 3, 3, 15, 'team2Id')) {
            points += 8;
          }
        }
        if (g === 1) {
          if (!this.isPickWrong(b, master, 1, 3, 15, 'team1Id') || !this.isPickWrong(b, master, 1, 3, 15, 'team2Id')) {
            points += 8;
          }
          if (!this.isPickWrong(b, master, 2, 3, 15, 'team1Id') || !this.isPickWrong(b, master, 2, 3, 15, 'team2Id')) {
            points += 8;
          }
        }
      } else if (rnd === 1) {
        if (g === 0) {
          if (!this.isPickWrong(b, master, rgn, 0, 61, 'team1Id') || !this.isPickWrong(b, master, rgn, 0, 61, 'team2Id')) {
            points += 12;
          }
          if (!this.isPickWrong(b, master, rgn, 0, 62, 'team1Id') || !this.isPickWrong(b, master, rgn, 0, 62, 'team2Id')) {
            points += 12;
          }
        }
      } else if (rnd === 2) {
        if (g === 0) {
          if (!this.isPickWrong(b, master, rgn, 1, 63, 'team1Id') || !this.isPickWrong(b, master, rgn, 1, 63, 'team2Id')) {
            points += 16;
          }
        }
      }
    }
    return points;
  }

  static isPickWrong(b: Bracket, master: Bracket, rgn: number, round: number, gameId: number, team: string): boolean {
    let wrongPicks = false;
    if (master.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team] === undefined) {
      wrongPicks = false;
    } else {
      wrongPicks = master.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team] !== b.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0][team];
    }
    return wrongPicks;
  }

  static getPointsForRound(b: Bracket, master: Bracket, r: number): number {
    let points = 0;
    master.regions.forEach((rgn, i) => {
      rgn.rounds.forEach((round, j) => {
        if (j === r && r < 4) {
          round.games.forEach((game, g) => {
            if (i < 4 && j > 0) { // if not final four and not first round (no winners in first round)
              if (b.regions[i].rounds[j].games[g].team1Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team1Id === master.regions[i].rounds[j].games[g].team1Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
              if (b.regions[i].rounds[j].games[g].team2Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team2Id === master.regions[i].rounds[j].games[g].team2Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
            }
          });
        } else if (r === 4) {
          round.games.forEach((game, g) => {
            if (i === 4 && j === 0) { // if not final four and not first round (no winners in first round)
              if (b.regions[i].rounds[j].games[g].team1Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team1Id === master.regions[i].rounds[j].games[g].team1Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
              if (b.regions[i].rounds[j].games[g].team2Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team2Id === master.regions[i].rounds[j].games[g].team2Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
            }
          });
        } else if (r === 5) {
          round.games.forEach((game, g) => {
            if (i === 4 && j === 1) { // if not final four and not first round (no winners in first round)
              if (b.regions[i].rounds[j].games[g].team1Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team1Id === master.regions[i].rounds[j].games[g].team1Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
              if (b.regions[i].rounds[j].games[g].team2Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team2Id === master.regions[i].rounds[j].games[g].team2Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
            }
          });
        } else if (r === 6) {
          round.games.forEach((game, g) => {
            if (i === 4 && j === 2) { // if not final four and not first round (no winners in first round)
              if (b.regions[i].rounds[j].games[g].team1Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team1Id === master.regions[i].rounds[j].games[g].team1Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
              if (b.regions[i].rounds[j].games[g].team2Id !== undefined) {
                if (b.regions[i].rounds[j].games[g].team2Id === master.regions[i].rounds[j].games[g].team2Id) {
                  points += Bracket.getRoundMultiple(i, j);
                }
              }
            }
          });
        }
      });
    });
    return points;
  }

  static getRoundMultiple(regionIndex: number, roundIndex: number): number {
    let multiple = 1;

    if (regionIndex < 4) {
      switch (roundIndex) {
        case 1:
          multiple = 1;
          break;
        case 2:
          multiple = 2;
          break;
        case 3:
          multiple = 4;
          break;
      }
    } else {
      switch (roundIndex) {
        case 0:
          multiple = 8;
          break;
        case 1:
          multiple = 12;
          break;
        case 2:
          multiple = 16;
          break;
      }
    }
    return multiple;
  }
}
