import { Component, OnInit, Input } from '@angular/core';
import { ListView, ListOptions } from '../list-control';
import { ListService } from '../../core/list.service';
import { MenuService } from '../../core/menu.service';

import { faTachometerAlt } from '@fortawesome/pro-light-svg-icons/faTachometerAlt';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/user.service';
import { Constants } from 'src/app/core/constants';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListControlFilterComponent } from '../list-control-filter/list-control-filter.component';
import { NgIf } from '@angular/common';
import { ListControlViewComponent } from '../list-control-view/list-control-view.component';

@Component({
    selector: 'cub-list-control-top',
    templateUrl: './list-control-top.component.html',
    styleUrls: ['./list-control-top.component.scss'],
    standalone: true,
    imports: [ListControlViewComponent, NgIf, ListControlFilterComponent, FontAwesomeModule]
})
export class ListControlTopComponent implements OnInit {

  @Input() title: string;
  @Input() system: string;
  @Input() dashboardUrl?: string;
  @Input() listViews: ListView[];
  @Input() listOptions?: ListOptions;
  @Input() showDashboardIcon?: boolean;
  showListing = true;
  canEditFilters = true;

  faTachometerAlt = faTachometerAlt;
  faFileExcel = faFileExcel;
  faTable = faTable;

  constructor(
    private listService: ListService,
    private menuService: MenuService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.listViews.length > 0) {
      this.listOptions = this.listViews.filter(v => v.defaultView)[0].listOptions;
    }

    this.listService.listSettings$.subscribe(listSettings => {
      this.listOptions = listSettings;
    });
    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showListing = result;
    });
    this.updateListService();
    this.canEditFilters = !this.userService.hasPermissionKey(Constants.PermCantEditFilters, Constants.PermLevel_NO_ACCESS);
  }

  updateListService() {
    if (this.listViews.length > 0) {
      this.listService.listUpdated(this.listOptions);
      // this.listService.listUpdated(this.listViews.filter(v => v.selected)[0].listOptions);
    }
  }

  goToDashboard() {
    if (this.dashboardUrl) {
      this.router.navigate([this.dashboardUrl]);
    }
  }

}
