<div class="bad-data">
  <div style="display: grid; grid-template-columns: 4fr 2fr 1fr;">
    <div>
      <h5 style="display: inline-block;">
        <fa-icon [icon]="faBullhorn"
                 style="margin-right: 5px;"></fa-icon>
        Bad Data Tickets
      </h5>
      <span style="font-size: 14px; color: #999; margin-left: 20px;">({{ filteredTickets.length }} tickets)</span>
    </div>
    <mat-form-field style="width: 80%; margin-top: -15px;">
      <mat-label>Search</mat-label>
      <input matInput
             (keyup)="applyFilter($event.target.value)"
             >
    </mat-form-field>
    <mat-checkbox color="primary"
                  style="margin-top: 5px;"
                  [(ngModel)]="showResolved"
                  (change)="toggleResolved($event)">Show Resolved</mat-checkbox>
  </div>
  <div id="badDataContainer">
    <table id="tableTicketsLg"
           mat-table
           [dataSource]="dataSource"
           matSort>
      <ng-container matColumnDef="ticketKey">
        <th mat-header-cell
            style="display: none;"
            *matHeaderCellDef
            mat-sort-header>Id </th>
        <td mat-cell
            style="display: none;"
            *matCellDef="let row"> {{row.ticketKey}} </td>
      </ng-container>
      <ng-container matColumnDef="systemDesc">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> System </th>
        <td mat-cell
            *matCellDef="let row"> {{row.systemDesc}} </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Location </th>
        <td mat-cell
            *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <span title="{{ row.description }}">{{ row.location }}</span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Description </th>
        <td mat-cell
            *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <span title="{{ row.description }}">{{ row.description }}</span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedList">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Assigned To </th>
        <td mat-cell
            *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <span title="{{ row.description }}">{{ row.assignedList }}</span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="creatorName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Created By </th>
        <td mat-cell
            *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <span title="{{ row.description }}">{{ row.creatorName }}</span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="creatorDt">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Reported Dt</th>
        <td mat-cell
            *matCellDef="let row"> {{row.creatorDt | date:'shortDate'}} </td>
      </ng-container>
      <ng-container matColumnDef="statusDesc">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Status </th>
        <td mat-cell
            *matCellDef="let row"> {{row.statusDesc}} </td>
      </ng-container>

      <tr mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"
          (click)="editTicket(row.ticketKey)"></tr>
    </table>
  </div>
  <div style="margin: 20px;">
    <button mat-raised-button
            color="primary"
            class="button-standard"
            style="float: right;"
            (click)="close()">Done</button>
  </div>
</div>
