
export class ListSortOption {
  name: string;
  fieldName: string;
  system: string;
  selected: boolean;
  asc: boolean;

  constructor() {
  }
}
