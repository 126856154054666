import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarchMadnessHomeComponent } from './march-madness-home/march-madness-home.component';
import { MarchMadnessRoutingModule } from './march-madness-routing.module';
import { BracketComponent } from './bracket/bracket.component';
import { TournamentNewComponent } from './tournament-new/tournament-new.component';
import { TeamPickerComponent } from './team-picker/team-picker.component';
import { MatchupComponent } from './matchup/matchup.component';
import { NopeekingComponent } from './nopeeking/nopeeking.component';
import { BracketPrintComponent } from './bracket-print/bracket-print.component';
import { BracketService } from './bracket.service';
import { TeamService } from './team.service';
import { MarchMadnessListingComponent } from './march-madness-listing/march-madness-listing.component';

@NgModule({
    imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MarchMadnessRoutingModule,
    MarchMadnessHomeComponent,
    BracketComponent,
    TournamentNewComponent,
    TeamPickerComponent,
    MatchupComponent,
    NopeekingComponent,
    BracketPrintComponent,
    MarchMadnessListingComponent
],
    providers: [
        BracketService,
        TeamService
    ]
})
export class MarchMadnessModule { }
