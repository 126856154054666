import { Location, NgFor, NgIf } from '@angular/common';
import { UserService } from '../../core/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { User } from '../user';
import { LoggerService } from '../../core/logger.service';
import { UserListFilterPipe } from './user-list-filter.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { faBalanceScaleRight } from '@fortawesome/pro-light-svg-icons/faBalanceScaleRight';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faTrophyAlt } from '@fortawesome/pro-light-svg-icons/faTrophyAlt';
import { faIdBadge } from '@fortawesome/pro-light-svg-icons/faIdBadge';
import { faListAlt } from '@fortawesome/pro-light-svg-icons/faListAlt';
import { faAbacus } from '@fortawesome/pro-light-svg-icons/faAbacus';
import { faDesktopAlt } from '@fortawesome/pro-light-svg-icons/faDesktopAlt';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faMoneyBillAlt } from '@fortawesome/pro-light-svg-icons/faMoneyBillAlt';
import { faUserCrown } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faHandsUsd } from '@fortawesome/pro-light-svg-icons/faHandsUsd';
import { faHandsHelping } from '@fortawesome/pro-light-svg-icons/faHandsHelping';
import { faUnicorn } from '@fortawesome/pro-light-svg-icons/faUnicorn';
import { faShovel } from '@fortawesome/pro-light-svg-icons/faShovel';
import { faSearchDollar } from '@fortawesome/pro-light-svg-icons/faSearchDollar';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';

import * as moment from 'moment';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CubUserEditDialogComponent } from '../cub-user-edit-dialog/cub-user-edit-dialog.component';
import { UserBadgeComponent } from '../user-badge/user-badge.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'cub-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    standalone: true,
    imports: [MatCheckboxModule, FormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, FontAwesomeModule, NgFor, MatInputModule, MatButtonToggleModule, NgIf, UserBadgeComponent, MatTableModule, MatSortModule, UserListFilterPipe]
})
export class UserListComponent implements OnInit {

  faBalanceScaleRight = faBalanceScaleRight;
  faCircle = faCircle;
  faTrophyAlt = faTrophyAlt;
  faListAlt = faListAlt;
  faIdBadge = faIdBadge;
  faLayerGroup = faLayerGroup;
  faFilter = faFilter;
  faBriefcase = faBriefcase;

  employeesOnly = true;
  listMode = 'badge';
  selDepartment = 'All';
  selOffice = 'All';
  searchText = '';
  tblDataSource;
  tblColumns: string[] = ['image', 'firstName', 'lastName', 'title', 'department', 'officeLocation', 'hireDt'];
  @ViewChild(MatSort) sort: MatSort;
  users: User[];
  usersForTable: User[];
  departments: string[];
  offices: string[];
  routeId = '';


  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loggerService: LoggerService,
    private titleService: Title,
    private userService: UserService
  ) {
    this.users = [];
    this.departments = [];
    this.offices = [];
  }

  ngOnInit() {
    this.loggerService.logPageVisit('employee listing', '');
    this.titleService.setTitle('CUB - Employees');
    this.getUsers();
    this.determineRouteStatus();
  }

  getUsers() {
    this.userService.getAllUsers().subscribe(users => {
      this.users = users.filter(u => u.active);
      this.usersForTable = users.filter(u => u.active);
      this.users.forEach(user => {
        if (user.department === 'CDO' ||
          user.department === 'CIO' ||
          user.department === 'CFO' ||
          user.department === 'CEO' ||
          user.department === 'COO') {
          user.department = 'Officer';
        }
      });
      this.tblDataSource = new MatTableDataSource(this.usersForTable);
      this.tblDataSource.sort = this.sort;
      this.departments = [...Array.from(new Set(this.users.map(u => u.department))).filter(d => d.length > 0)].sort();
      this.offices = [...Array.from(new Set(this.users.map(u => u.officeLocation)))].sort();
      this.offices = this.offices.filter(o => o.trim().length > 0);
    });
  }

  employeeOnly(evt: MatCheckboxChange) {

  }


  getUrl(user: User): string {
    return environment.userBlobStorageUrl + user.accountName?.replace('CENTERPOINT-PRO\\', '') + '.jpg';
  }

  formatDt(dt): string {
    return moment(dt).format('MM.DD.YYYY');
  }

  getDeptIcon(dept: string): any {
    let icon: any;

    switch (dept) {
      case 'Legal':
        icon = faBalanceScaleRight;
        break;
      case 'Accounting':
        icon = faAbacus;
        break;
      case 'MIS':
        icon = faDesktopAlt;
        break;
      case 'Construction':
        icon = faForklift;
        break;
      case 'Marketing':
        icon = faBullseyeArrow;
        break;
      case 'Operations':
      case 'Asset Management':
        icon = faBuilding;
        break;
      case 'Office Management':
        icon = faPaperclip;
        break;
      case 'Finance':
        icon = faMoneyBillAlt;
        break;
      case 'Investments':
        icon = faHandsUsd;
        break;
      case 'Risk Management':
        icon = faSearchDollar;
        break;
      case 'Human Resources':
        icon = faHandsHelping;
        break;
      case 'Special Projects':
        icon = faUnicorn;
        break;
      case 'Development':
        icon = faShovel;
        break;
      case 'Officer':
        icon = faUserCrown;
        break;
      case 'Corporate':
        icon = faBriefcase;
        break;
      default:
        break;
    }
    return icon;
  }

  refreshTable() {
    this.usersForTable = new UserListFilterPipe().transform(this.users, this.selDepartment, this.selOffice, this.searchText, this.employeesOnly);
    this.tblDataSource = new MatTableDataSource(this.usersForTable);
    this.tblDataSource.sort = this.sort;
  }

  selectUser(user: User) {
    this.router.navigate([user.accountName.replace('CENTERPOINT-PRO\\', '')], { relativeTo: this.route });
  }

  determineRouteStatus() {
    if (this.router.url.endsWith("new")) {
      this.addCubUser();
    }
  }


  addCubUser() {
    const cubUser = new User();
    cubUser.pTOHoursGiven = 0;  // default to 0
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.position = { top: '200px' };
    dialogConfig.data = {
      cubUser
    };
    this.dialog.open(CubUserEditDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          if (result.status === "saved") {
            this.userService.addUser(result.payload)
              .subscribe(() => {
                this.router.navigate(['users']);
              });
          }
          else if (result.status === "cancelled") {
            this.router.navigate(['users']);
          }
        }
      });
  }
}
