<div id="linksPage" style="margin: 20px; display: flex; flex-wrap: wrap">
  <mat-card appearance="outlined" *ngFor="let linkGroup of linkList">
    <mat-card-header>
      <mat-card-title>
        <h4>
          <i class="fa fa-lg {{ linkGroup.icon }}"></i>
          {{ linkGroup.name }}
        </h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let link of linkGroup.subMenu">
        <button
          mat-button
          *ngIf="link.external"
          (click)="goToExternalLink(link)"
        >
          {{ link.name }}
        </button>
        <button
          mat-button
          *ngIf="!link.external"
          (click)="goToInternalLink(link)"
        >
          {{ link.name }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
