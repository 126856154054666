import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact, ContactType, ContactSearchParameters } from '../../contact/contact';
import { Address } from 'src/app/shared/address';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';

import { faTrashAlt, faEdit, faSave, faMapMarker } from '@fortawesome/pro-light-svg-icons';
import { MapService } from '../map/map.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MapComponent } from '../map/map.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-address-edit-dialog',
    templateUrl: './address-edit-dialog.component.html',
    styleUrls: ['./address-edit-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, FontAwesomeModule, MapComponent, MatCheckboxModule]
})
export class AddressEditDialogComponent implements OnInit {

  address: Address;
  origAddress: Address;
  addressForm: UntypedFormGroup;
  states: Lookup[] = [];
  addressTypes: Lookup[] = [];

  faTrashAlt = faTrashAlt;
  faEdit = faEdit;
  faSave = faSave;
  faMapMarker = faMapMarker;

  constructor(
    private lookupService: LookupService,
    private fb: UntypedFormBuilder,
    private mapService: MapService,
    private dialogRef: MatDialogRef<AddressEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.address = data.address;
      console.log(this.address);
    }

  ngOnInit() {
    this.loadLookups();
    this.createForm();
    this.origAddress = JSON.parse(JSON.stringify(this.address));
  }

  loadLookups() {
    this.states = this.lookupService.getTableValues('states');
    this.addressTypes = this.lookupService.getTableValues('addressTypes');
  }

  createForm() {
    this.addressForm = this.fb.group({
      street: new UntypedFormControl(this.address.address1, [Validators.required]),
      city: new UntypedFormControl(this.address.city, [Validators.required]),
      state: new UntypedFormControl(this.address.stateKey, [Validators.required]),
      zip: new UntypedFormControl(this.address.zipCode, [Validators.required]),
      addressType: new UntypedFormControl(this.address.typeKey, [Validators.required]),
      county: new UntypedFormControl(this.address.county),
      country: new UntypedFormControl(this.address.country),
      latitude: new UntypedFormControl({ value: this.address.latitude, disabled: true }),
      longitude: new UntypedFormControl({ value: this.address.longitude, disabled: true }),
      updateLatLong: new UntypedFormControl(false)
    });
  }

  formToObject() {
    this.address.address1 = this.addressForm.controls.street.value;
    this.address.city = this.addressForm.controls.city.value;
    this.address.stateKey = this.addressForm.controls.state.value;
    this.address.stateID = this.states.filter(s => s.id.toString() === this.addressForm.controls.state.value.toString())[0].name;
    this.address.typeKey = this.addressForm.controls.addressType.value;
    this.address.zipCode = this.addressForm.controls.zip.value;
    this.address.county = this.addressForm.controls.county.value;
    this.address.country = this.addressForm.controls.country.value;
    this.address.latitude = this.addressForm.controls.latitude.value;
    this.address.longitude = this.addressForm.controls.longitude.value;
  }

  cancelItem() {
    this.address = JSON.parse(JSON.stringify(this.origAddress));
    this.dialogRef.close({ saved: false, address: this.address });
  }

  saveItem() {
    if (this.addressForm.valid) {
      this.formToObject();
      this.dialogRef.close({ saved: true, address: this.address });
    }
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null || c2 === undefined ? '' : c2.toString());
  }

  foundLatLong(data: any) {
    this.address.latitude = data.latitude;
    this.address.longitude = data.longitude;
    this.addressForm.controls.latitude.setValue(this.address.latitude);
    this.addressForm.controls.longitude.setValue(this.address.longitude);
  }

  findAddress() {
      this.mapService.findLocation(
        this.addressForm.controls.street.value,
        this.addressForm.controls.city.value,
        this.states.filter(s => s.id.toString() === this.addressForm.controls.state.value.toString())[0].name,
        this.addressForm.controls.country.value)
        .subscribe(data => {
        if (data.resourceSets.length > 0) {
          if (data.resourceSets[0].resources.length > 0) {
            this.address.latitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
            this.address.longitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
            this.addressForm.controls.latitude.setValue(this.address.latitude);
            this.addressForm.controls.longitude.setValue(this.address.longitude);
          }
        }
      });
  }

  mapViewChanged(data: any) {
    if (this.addressForm.controls.updateLatLong.value) {
      this.addressForm.controls.latitude.setValue(data.latitude);
      this.addressForm.controls.longitude.setValue(data.longitude);
    }
  }

  reloadMap() {

  }

}
