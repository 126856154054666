import { Address } from 'src/app/shared/address';
import { PropertyService } from '../../property/property.service';
import { MapService } from '../map/map.service';
import { LookupService } from '../../core/lookup.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Property } from '../../property/property';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Lookup } from '../../core/lookup';
import {
  faSpinner
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MapComponent } from '../map/map.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'cub-address-edit-property-dialog',
    templateUrl: './address-edit-property-dialog.component.html',
    styleUrls: ['./address-edit-property-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MapComponent, MatButtonModule, FontAwesomeModule]
})
export class AddressEditPropertyDialogComponent implements OnInit {
  propertyAddressForm = this.fb.group({
    address1: ['', [Validators.required]],
    city: ['', [Validators.required]],
    stateKey: ['', [Validators.required]],
    zipCode: ['', [Validators.required]],
    county: ['', [Validators.required]],
    country: ['', [Validators.required]],
    latitude: [''],
    longitude: [''],
  });

  states: Lookup[] = [];
  zoomLevel = 18;
  mapZoomLevel = 18;
  mapStyle = 'roadmap';
  isSaving = false;
  faSpinner = faSpinner;

  constructor(
    public readonly dialogRef: MatDialogRef<AddressEditPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      property: Property,
    },
    private readonly fb: UntypedFormBuilder,
    private propertyService: PropertyService,
    private lookupService: LookupService,
    private mapService: MapService,
  ) { }


  ngOnInit() {
    this.propertyAddressForm.patchValue(this.data.property);
    this.loadLookups();
  }

  submit() {
    if (this.propertyAddressForm.dirty && this.propertyAddressForm.valid) {
      const {
        addressKey,
        address1,
        address2,
        address3,
        city,
        stateKey,
        country,
        latitude,
        longitude,
        county,
        zipCode,
        typeKey,
        addressDesc,
        type
      } = this.propertyAddressForm.value;

      const key = this.data.property.addressKey;

      const address: Address = {
        addressKey,
        stateKey,
        address1,
        address2,
        address3,
        city,
        stateID: this.stateIdToName(stateKey),
        latitude,
        longitude,
        county,
        country,
        zipCode,
        typeKey,
        addressDesc,
        type
      };
      address.addressKey = key;
      this.isSaving = true;
      this.propertyAddressForm.disable();
      this.propertyService.savePropertyAddress(address).subscribe(() => {
        this.isSaving = false;
        this.propertyAddressForm.enable();
        this.dialogRef.close({ status: 'saved' });
      });
    } else {
      this.dialogRef.close({ status: 'cancelled' });
    }
  }

  cancel() {
    this.dialogRef.close({ status: 'cancelled' });
  }

  loadLookups() {
    this.states = this.lookupService.getTableValues('states');
  }

  stateIdToName(id: any): string {
    return this.states.filter(state => state.id === id.toString())[0].name;
  }

  parse(id: string) {
    return parseInt(id, 10);
  }

  findLocation() {
    const {
      address1,
      city,
      stateKey,
      country,
    } = this.propertyAddressForm.value;
    this.mapService.findLocation(address1, city, stateKey, country).subscribe(data => {
      const coordinates = data.resourceSets[0].resources[0].geocodePoints[0].coordinates;
      this.propertyAddressForm.controls.latitude.patchValue(coordinates[0]);
      this.propertyAddressForm.controls.longitude.patchValue(coordinates[1]);
      this.mapZoomLevel = 18;
      this.zoomLevel = 18;
    });
  }
}
