<div style="display: inline-flex; position: relative; width:100%">
  <mat-form-field style="position: relative;" #contactField>
    <i *ngIf="loadingUser" class="userDetailIcon fa fa-circle-o-notch fa-spin fa-fw" matPrefix></i>
    <i *ngIf="!loadingUser" class="userDetailIcon fa"  [class.fa-male]="myContact.salutationKey === 1"
                                        [class.fa-female]="myContact.salutationKey === 2 || myContact.salutationKey === 3"
                                        [class.fa-user]="myContact.salutationKey === 0 || myContact.salutationKey === undefined || myContact.salutationKey === null"
                                        matPrefix (click)="showDetails($event)"></i>
    <mat-label>{{ placeholder }}</mat-label>
    <input #txtInput matInput name="contactPickerInp"  readonly (click)="showPicker($event)" >
    <i class="fa fa-caret-down" matSuffix (click)="showPicker($event)"></i>
  </mat-form-field>
</div>
