<form [formGroup]="fgGlTransfer" novalidate autocomplete="off">
  <div class="gldata-transfer-detail">
    <div class="gldata-transfer-header-title">
      <fa-icon [icon]="faClose" class="fa-lg" (click)="close()"></fa-icon>
      <h3>GL Data Export</h3>
    </div>
    <div class="gldata-transfer-header">
      <mat-form-field>
				<mat-label>GL Month</mat-label>
        <mat-select
          formControlName="glMonth"

          (selectionChange)="setMonth()"
        >
          <mat-option *ngFor="let month of glMonths" [value]="month.value">{{
            month.viewValue
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="margin-left: 20px">
				<mat-label>GL Year</mat-label>
        <input
          formControlName="glYear"
          matInput

          (change)="setYear()"
        />
        <mat-error *ngIf="!fgGlTransfer.controls.glYear.valid">
          A valid year between 2010 and {{ year }} is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="gldata-upload">
      <div>
        <cub-gldata-upload
          [book]="1"
          [month]="month"
          [year]="year"
          [fileType]="'Accrual'"
        ></cub-gldata-upload>
      </div>
    </div>
  </div>
</form>
