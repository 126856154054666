import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListView } from '.';
import { ListService } from '../../core/list.service';
import { MenuService } from '../../core/menu.service';
import { ListOptions } from './listOptions';
import { ListControlGroupSortComponent } from '../list-control-group-sort/list-control-group-sort.component';
import { ListControlSearchComponent } from '../list-control-search/list-control-search.component';
import { ListControlTabsComponent } from '../list-control-tabs/list-control-tabs.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'cub-list-control',
    templateUrl: './list-control.component.html',
    styleUrls: ['./list-control.component.scss'],
    standalone: true,
    imports: [NgIf, ListControlTabsComponent, ListControlSearchComponent, ListControlGroupSortComponent]
})
export class ListControlComponent implements OnInit {

  @Input() title: string;
  @Input() system: string;
  @Input() dashboardUrl?: string;
  @Input() listViews: ListView[];
  @Input() listOptions?: ListOptions;
  @Input() tabs?: string[];
  @Output() controlUpdate?: EventEmitter<any> = new EventEmitter();
  @Output() tabSelected?: EventEmitter<any> = new EventEmitter();
  showListing = true;

  constructor(
    private listService: ListService,
    private menuService: MenuService,
    public dialog: MatDialog) {
      this.listOptions = new ListOptions();
  }

  ngOnInit() {
    if (this.listViews.length > 0) {
      this.listOptions = this.listViews.filter(v => v.defaultView)[0].listOptions;
    }

    this.listService.listSettings$.subscribe(listSettings => {
      this.listOptions = listSettings;
    });
    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showListing = result;
    });
    this.updateListService();
  }

  onSearch(evt) {
    this.listOptions.searchText = evt;
    this.updateListService();
  }

  updateListService() {
    if (this.listViews.length > 0) {
      this.listService.listUpdated(this.listOptions);
      // this.listService.listUpdated(this.listViews.filter(v => v.selected)[0].listOptions);
    }
  }

  tabChanged(tab: any) {
    this.tabSelected.emit(tab);
    this.system = tab.system;
  }
}
