import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ListService } from '../../core/list.service';

import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'cub-list-control-search',
    templateUrl: './list-control-search.component.html',
    styleUrls: ['./list-control-search.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, FormsModule, NgIf]
})
export class ListControlSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('listSearchBox', { static: true }) searchBoxEl: ElementRef;
  searchTerm: string;
  isSearching = false;
  @Output() searchTermUpdated: EventEmitter<any> = new EventEmitter();
  private searchTerms = new Subject<string>();

  faSearch = faSearch;
  faTimes = faTimes;

  constructor(
    private listService: ListService
  ) {
    this.searchTerms.pipe(
      debounceTime(500))
      .subscribe((val) => this.emitSearch(val));
  }

  ngOnInit() {
    this.listService.listSettings$.subscribe(listSettings => {
      if (!this.isSearching) {
        this.searchTerm = listSettings.searchText;
      }
    });
  }

  ngAfterViewInit() {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        this.searchBoxEl.nativeElement.focus();
      }
    });
  }

  emitSearch(searchText: string) {
    this.isSearching = true;
    this.searchTermUpdated.emit(searchText);
    setTimeout(() => {
      this.isSearching = false;
    }, 5000);
  }

  search(term: string) {
    this.searchTerms.next(term);
  }
}
