
import { Location, NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Investment, InvestmentProperty } from '..';
import { Constants } from '../../core/constants';
import { LookupService } from '../../core/lookup.service';
import { MapService } from '../../shared/map/map.service';
import { Lookup } from '../../shared/select/lookup';
import { SpinnerDialogComponent } from '../../shared/spinner-dialog/spinner-dialog.component';
import { integerValidator } from '../../shared/validators/integer.validator';
import { zoomValidator } from '../../shared/validators/zoom.validator';
import { InvestmentNewDialogComponent } from '../investment-new-dialog/investment-new-dialog.component';
import { InvestmentService } from '../investment.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MapComponent } from '../../shared/map/map.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


function requiredForPortValidator(input: UntypedFormControl) {
  let isValid = true;

  if (input.value) {
    if (input.parent) {
      if (input.parent.controls) {
        isValid = input.value.length > 0 || input.parent.controls['portfolio'].value !== true;
      }
    }
  } else {
    if (input.parent) {
      if (input.parent.controls) {
        if (input.parent.controls['portfolio'].value === true) {
          isValid = false;
        }
      }
    }
  }
  return isValid ? null : { IsRequiredForPort: true };
}

@Component({
    selector: 'cub-investment-new',
    templateUrl: './investment-new.component.html',
    styleUrls: ['./investment-new.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatSlideToggleModule, NgIf, MatFormFieldModule, MatInputModule, MatCardModule, MatSelectModule, NgFor, MatOptionModule, MapComponent, MatProgressBarModule, MatButtonModule]
})
export class InvestmentNewComponent implements OnInit {

  newInvForm: UntypedFormGroup;
  inv: Investment;
  invProp: InvestmentProperty;
  isPortfolio = false;
  zoomLevel = 5;
  mapZoomLevel = 5;
  mapStyle = 'roadmap';
  mapLatitude = 0;
  mapLongitude = 0;
  zoomProgress = 0;

  states: Lookup[] = [];
  strategeries: Lookup[] = [];
  markets: Lookup[] = [];
  msas: Lookup[] = [];
  regions: Lookup[] = [];
  structureTypes: Lookup[] = [];
  calpersMarkets: Lookup[] = [];

  constructor(
    private lookupService: LookupService,
    private investmentService: InvestmentService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private mapService: MapService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.inv = new Investment();
    this.inv.onReportRevenue = false;
    this.inv.onReportITC = false;
    this.inv.onReportCalEast = false;
    this.inv.statusKey = Constants.Lkp_Inv_Status_UnderReview;
    this.inv.pipelineGroupKey = Constants.Lkp_Inv_PipelineGroup_Property;
    this.invProp = new InvestmentProperty();
    this.invProp.address.stateKey = Constants.StateIL;
    this.invProp.address.latitude = 41.854051;
    this.invProp.address.longitude = -87.9231052;
    this.inv.properties.push(this.invProp);
    this.zoomProgress = parseInt((parseFloat(this.mapZoomLevel.toString()) / 18.0 * 100.0).toString(), 10);
    this.loadLookups();
  }

  createForm() {
    this.newInvForm = this.fb.group({
      city: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl('', [integerValidator]),
      name: new UntypedFormControl('', [Validators.required]),
      street: new UntypedFormControl('', [requiredForPortValidator]),
      state: new UntypedFormControl(Constants.StateIL, [Validators.required]),
      zipCode: new UntypedFormControl('', [Validators.required]),
      market: new UntypedFormControl('', [Validators.required]),
      msa: new UntypedFormControl('', [Validators.required]),
      region: new UntypedFormControl('', [Validators.required]),
      portfolio: new UntypedFormControl(this.isPortfolio),
      zoom: new UntypedFormControl(this.zoomProgress, [Validators.required, zoomValidator]),
      type: new UntypedFormControl('', [Validators.required]),
      calpersMarket: new UntypedFormControl('', [Validators.required])
    });
  }

  formToObject() {
    // oh yeah we old school
    this.inv.name = this.newInvForm.controls.name.value;
    this.invProp.desc = this.newInvForm.controls.name.value;
    this.invProp.address.address1 = this.newInvForm.controls.street.value;
    this.invProp.address.city = this.newInvForm.controls.city.value;
    this.invProp.address.stateKey = this.newInvForm.controls.state.value;
    this.invProp.address.stateID = this.states.filter(s => parseInt(s.id, 10) === parseInt(this.newInvForm.controls.state.value, 10))[0].name;
    this.invProp.address.zipCode = this.newInvForm.controls.zipCode.value;
    this.invProp.marketKey = this.newInvForm.controls.market.value;
    this.invProp.msaKey = this.newInvForm.controls.msa.value;
    this.invProp.regionKey = this.newInvForm.controls.region.value;
    this.invProp.structureTypeKey = this.newInvForm.controls.type.value;
    this.invProp.calpersMarketKey = this.newInvForm.controls.calpersMarket.value;
    this.inv.purchasePrice = parseInt(this.newInvForm.controls.price.value.replace(/,/g, ''), 10);
    if (this.newInvForm.controls.price.value.length > 0) {
      this.inv.calpersShare = this.inv.purchasePrice * this.inv.ownershipPct;
    }
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(InvestmentNewDialogComponent, {
      width: '250px',
      data: { isPortfolio: this.isPortfolio }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isPortfolio = result;
    });
  }

  loadLookups() {
    this.regions = this.lookupService.getTableValues('regions');
    this.markets = this.lookupService.getTableValues('markets');
    this.states = this.lookupService.getTableValues('states');
    this.structureTypes = this.lookupService.getTableValues('structureTypes');
    this.calpersMarkets = this.lookupService.getTableValues('calpersMarket');
    this.msas = this.lookupService.getTableValues('msa');
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null || c2 === undefined ? '' : c2.toString());
  }

  portToggle() {
    if (!this.isPortfolio) {
      this.newInvForm.controls.street.setValue(this.inv.name);
    }
  }

  onSubmit() {
    this.formToObject();
    if (this.newInvForm.valid) {

      let dialogRef = this.dialog.open(SpinnerDialogComponent, {
        disableClose: true,
        width: '450px',
        data: { mode: 'bar', title: 'Creating the Deal', content: 'This may take a few seconds please be patient.' }
      });

      this.investmentService.saveInvestment(this.inv).subscribe(newInv => {
        dialogRef.close();
        this.router.navigate(['/investments/' + newInv.key]);
      });
    }
  }

  validateSave(): boolean {
    let valid = true;

    if (this.newInvForm.controls.city.invalid) {
      valid = false;
    }
    if (this.zoomProgress < 100) {
      valid = false;
    }

    return valid;
  }

  findLocation() {
    let street = (this.isPortfolio ? this.invProp.address.address1 : this.inv.name);
    this.mapService.findLocation(street, this.invProp.address.city, 'IL', 'US').subscribe(data => {
      if (data.resourceSets.length > 0) {
        if (data.resourceSets[0].resources.length > 0) {
          this.invProp.address.latitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
          this.invProp.address.longitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
          this.mapZoomLevel = 18;
          this.zoomLevel = 18;
          this.updateZoomProgress();
          this.mapStyle = 'satellite';
        }
      }
    });
  }

  cancel() {
    this.router.navigate(['/investments'], { relativeTo: this.route });
    // this.location.back();
  }

  onMapChange(data: any) {
    this.mapLatitude = data.latitude;
    this.mapLongitude = data.longitude;
    this.mapZoomLevel = data.zoomLevel;
    this.updateZoomProgress();
  }

  updateZoomProgress() {
    this.zoomProgress = parseInt((parseFloat(this.mapZoomLevel.toString()) / 18.0 * 100.0).toString(), 10);
    this.newInvForm.controls.zoom.setValue(this.zoomProgress);
  }

  locateOnMap() {
    this.formToObject();
    if (!this.isPortfolio) {
      if (this.invProp.desc.length > 0 && this.invProp.address.city.length > 0) {
        this.findLocation();
      }
    } else {
      if (this.invProp.address.address1.length > 0 && this.invProp.address.city.length > 0) {
        this.findLocation();
      }
    }
  }

  getErrorMessage() {
    return this.newInvForm.controls.city.hasError('required') ? 'You must enter a value' :
      this.newInvForm.controls.city.hasError('email') ? 'Not a valid email' :
        '';
  }
}
