import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewEncapsulation } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
    selector: 'cub-graph-donut',
    templateUrl: './graph-donut.component.html',
    styleUrls: ['./graph-donut.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class GraphDonutComponent implements OnChanges {

  @Input() header: string;
  @Input() dataArray: any;
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  private width: number;
  private height: number;

  private margin: Margin;

  private svg: any;
  private radius: number;
  private arc: any;
  private pie: any;
  private color: any;
  private g: any;

  constructor(private container: ElementRef) {
  }

  private initMargins() {
    this.margin = { top: 40, right: 40, bottom: 40, left: 40 };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initMargins();
    this.initSvg();
    this.drawChart();
  }

  private initSvg() {
    d3.select('#donut > *').remove();
    this.svg = d3.select(this.container.nativeElement).select('svg');

    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;
    this.radius = Math.min(this.width, this.height) / 2;

    this.color = d3Scale.scaleOrdinal()
      .range(['#2196F3', '#F44336', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00']);

    this.arc = d3Shape.arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius - 40);

    this.pie = d3Shape.pie()
      .sort(null)
      .value((d: any) => d.dataPoint);

    this.g = this.svg.append('g').attr('transform', 'translate(' + this.graphWidth / 2 + ',' + (this.graphHeight / 2 - (this.margin.top / 2)) + ')');
  }

  private drawChart() {
    let keys = this.dataArray.map(d => d.label);

    let g2 = this.g.selectAll('.arc')
      .data(this.pie(this.dataArray))
      .enter().append('g')
      .attr('class', 'arc');

    g2.append('path')
      .attr('d', this.arc)
      .style('fill', d => this.color(d.data.label));

    let legend = this.g.append('g')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 10)
      .attr('text-anchor', 'start')
      .selectAll('g')
      .data(keys.slice())
      .enter().append('g')
      .attr('transform', (d, i) => 'translate(' + (i * (this.graphWidth / 2) - (this.graphWidth / 2)) + ',' + ((this.graphHeight / 2) - (this.margin.bottom / 2)) + ')');

    legend.append('rect')
      .attr('x', this.margin.right)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', this.color);

    legend.append('text')
      .attr('x', this.margin.right + 30)
      .attr('y', 9.5)
      .attr('dy', '0.35em')
      .attr('font-size', 15)
      .text(d => d);
  }

}
