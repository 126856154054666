<mat-card appearance="outlined" class="widget-indicator">
  <div class="widget-main">
    <!-- row 1 - icon & header -->
    <div>
      <fa-icon *ngIf="headerIcon==='fa-warehouse'" [icon]="faWarehouse" class="fa widget-icon"></fa-icon>
      <fa-icon *ngIf="headerIcon==='fa-sack-dollar'" [icon]="faSackDollar" class="fa" style="vertical-align: middle; color: #666;"></fa-icon>
      <fa-icon *ngIf="headerIcon==='fa-chart-line'" [icon]="faChartLine" class="fa" style="vertical-align: middle; color: #666;"></fa-icon>
      <fa-icon *ngIf="headerIcon==='fa-percent'" [icon]="faPercent" class="fa" style="vertical-align: middle; color: #666;"></fa-icon>
      <fa-icon *ngIf="headerIcon==='fa-money-bill-alt'" [icon]="faMoneyBillAlt" class="fa" style="vertical-align: middle; color: #666;"></fa-icon>
    </div>
    <div class="widget-metric-label">
      <span style="position: relative;">{{ header }}</span>
    </div>
    <!-- row 2 - proposed metric -->
    <div class="widget-proposed-metric">
      <span *ngIf="valueType === 'percent'">{{ budgetValue | percent:'1.2-2' }}</span>
      <span *ngIf="valueType === 'number'">{{ budgetValue | number:numberFormat }}</span>
      <span *ngIf="valueType === 'currency'">{{ budgetValue | currency:'USD':'symbol':numberFormat }}</span>
    </div>

    <!-- row 4, market metric -->
    <div class="value-label"><span>Market</span></div>
    <div>
      <span *ngIf="valueType === 'percent'">{{ marketValue | percent:'1.2-2' }}</span>
      <span *ngIf="valueType === 'number'">{{ marketValue | number:numberFormat }}</span>
      <span *ngIf="valueType === 'currency'">{{ marketValue | currency:'USD':'symbol':numberFormat }}</span>
    </div>
    <div *ngIf="marketVariance<0" class="value-delta negative-value">
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ marketVariance | number:numberFormat }} bps</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ marketVariance | number:numberFormat }}</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ marketVariance | currency:'USD':'symbol':numberFormat }}</span>
    </div>
    <div *ngIf="marketVariance>0" class="value-delta positive-value">
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ marketVariance | number:numberFormat }} bps</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ marketVariance | number:numberFormat }}</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ marketVariance | currency:'USD':'symbol':numberFormat }}</span>
    </div>
    <div *ngIf="marketVariance===0" class="value-delta value-budget">
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ marketVariance | number:numberFormat }} bps</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ marketVariance | number:numberFormat }}</span>
      <span title="{{ marketVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ marketVariance | currency:'USD':'symbol':numberFormat }}</span>
    </div>
    <!-- Show Prior Value when present -->
    <ng-container *ngIf="priorValue!==-999">
      <div class="value-label"><span>Prior</span></div>
      <div>
        <span *ngIf="valueType === 'percent'">{{ priorValue | percent:'1.2-2' }}</span>
        <span *ngIf="valueType === 'number'">{{ priorValue | number:numberFormat }}</span>
        <span *ngIf="valueType === 'currency'">{{ priorValue | currency:'USD':'symbol':numberFormat }}</span>
      </div>
      <div *ngIf="priorVariance<0" class="value-delta negative-value">
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ priorVariance | number:numberFormat }} bps</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ priorVariance | number:numberFormat }}</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ priorVariance | currency:'USD':'symbol':numberFormat }}</span>
      </div>
      <div *ngIf="priorVariance>0" class="value-delta positive-value">
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ priorVariance | number:numberFormat }} bps</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ priorVariance | number:numberFormat }}</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ priorVariance | currency:'USD':'symbol':numberFormat }}</span>
      </div>
      <div *ngIf="priorVariance===0" class="value-delta value-budget">
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'percent'">{{ priorVariance | number:numberFormat }} bps</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'number'">{{ priorVariance | number:numberFormat }}</span>
        <span title="{{ priorVariance | number:'1.0-4' }}" *ngIf="valueType === 'currency'">{{ priorVariance | currency:'USD':'symbol':numberFormat }}</span>
      </div>
    </ng-container>

  </div>
</mat-card>
