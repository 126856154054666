<form [formGroup]="fg" novalidate autocomplete="off">
  <div class="tenantCompanyParentPickerWrapper">
    <div id="1-2" style="height: 40px; text-align: center; border-bottom: 1px solid #ddd;">
      <span style="font-size: 20px; font-weight: bold;">{{ title }}</span>
    </div>
    <br>
    <div>
      <ng-container *ngIf="targetLease.tenantName !== ''">
        Tenant to Match: {{ targetLease.tenantName }}
      </ng-container>
    </div>
    <br>
    <div class="tenantCompanyParentPickerContent">
      <div id="divSearch">
        <input formControlName="searchText" style="width: 95%;" matInput placeholder="Search for Parent" />
        <mat-icon matSuffix><i class="fa fa-search"></i></mat-icon>
      </div>
      <div (click)="createTenantCompany()" style="text-align: center;">
        <mat-icon matSuffix><i class="fa fa-plus"></i></mat-icon>
      </div>
    </div>
    <div class="ParentResultsHeader">
      <div>Company Name</div>
      <div>Leased SF</div>
      <div>Leases</div>
      <div>Credit %</div>
    </div>
    <cdk-virtual-scroll-viewport class="tenantCompanyParentList" style="height: 400px; width: 100%; border: solid 1px #a9a9a9;" itemSize="12" [scrollTop]="myScrollTop">
      <div *cdkVirtualFor="let p of filteredCompanies" id="p.key" [ngClass]="{
          selectedClass: p.key === selectedCompanyKey,
          deselectedClass: p.key !== selectedCompanyKey,
          ParentResults: 1 === 1
        }" (click)="onParentSelect(p)" style="cursor: pointer;">
        <div>
          <span title="{{ p.companyName }}">{{ p.companyName }}</span>
        </div>
        <div>
          <span title="{{ p.leasedSF | numWithCommas }}">{{
            p.leasedSF | numWithCommas
          }}</span>
        </div>
        <div>
          <span title="{{ p.leaseCount }}">{{ p.leaseCount }}</span>
        </div>
        <div>
          <span title="{{ p.cntCreditScore }}"> {{ p.cntCreditScore }} </span>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <br>
    <button mat-raised-button color="accent" style="float:left;" (click)="viewDetails()">
      View Company Details
    </button>
  </div>

  <div class="bottomButtons">
    <div>
      <button mat-raised-button style="float: left;" (click)="clickClose()">
        Close
      </button>
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="clickSave()" style="float: right;">
        Select
      </button>
    </div>
  </div>
</form>
