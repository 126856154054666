import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cub-nopeeking',
    templateUrl: './nopeeking.component.html',
    styleUrls: ['./nopeeking.component.scss'],
    standalone: true
})
export class NopeekingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
