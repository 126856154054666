import { Component } from '@angular/core';
import { MenuService } from '../../core/menu.service';

@Component({
    selector: 'cub-stand-alone-test',
    templateUrl: './stand-alone-test.component.html',
    styleUrls: ['./stand-alone-test.component.scss'],
    standalone: true
})
export class StandAloneTestComponent {

  constructor(
    private menuService: MenuService
  ) {
    this.menuService.toggleTopMenu(false);
    this.menuService.toggleLeftMenu(false);
  }

}
