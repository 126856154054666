import { ListOptions } from './listOptions';

export class ListUpdate {
  // reloadData: boolean;
  listOptions: ListOptions;
  selectedGroup: string;

  // todo just temp
  options: any;
  searchTerm: string;
  groupChanged: boolean;

  constructor(options: ListOptions,
              // reload: boolean,
              groupChanged: boolean) {
    this.listOptions = options;
    // this.reloadData = reload;
    this.groupChanged = groupChanged;
    this.selectedGroup = '';
  }
}
