import { Pipe, PipeTransform } from '@angular/core';
import { DepartmentalGoal } from '../user';

@Pipe({
    name: 'goalSort',
    standalone: true
})
export class GoalPipePipe implements PipeTransform {

  transform(value: DepartmentalGoal[], args?: any): DepartmentalGoal[] {
    return value.sort((a, b) => {
      if ( a.weight < b.weight ){
        return 1;
      }
      if ( a.weight > b.weight ){
        return -1;
      }
      return 0;
    });
  }

}
