import { Pipe, PipeTransform } from '@angular/core';
import { ListGroupOption, ListOptions } from '../../shared/list-control';
import { Investment } from '../investment';
import { InvestmentListPipe } from './investment-list.pipe';
import * as moment from 'moment';

@Pipe({
    name: 'investmentGroup',
    standalone: true
})
export class InvestmentGroupPipe implements PipeTransform {

  tmp = [];
  transform(investments: Investment[], listOptions: ListOptions): any {
    this.tmp.length = 0;
    if (investments != null) {
      let arr = [];
      investments = new InvestmentListPipe().transform(investments, listOptions);
      arr = investments.slice(0);
      let myGroupField: ListGroupOption = listOptions.groupBy.filter(s => s.selected)[0];

      if (myGroupField.name !== '') {
        let currentFiscalYr = (moment().month() <= 5 ? moment().year() : moment().year() + 1).toString();
        if (myGroupField.fieldName === 'closingDt') {
          arr = [...Array.from(new Set(arr.map(property => (property[myGroupField.fieldName] !== '' ? (moment(property[myGroupField.fieldName], 'MM/DD/YYYY').month() <= 5 ?
                                                                                                                  moment(property[myGroupField.fieldName], 'MM/DD/YYYY').year() :
                                                                                                                  moment(property[myGroupField.fieldName], 'MM/DD/YYYY').year() + 1).toString() : currentFiscalYr.toString()))))];  // create a set of group names from the array
        } else {
          arr = [...Array.from(new Set(arr.map(property => property[myGroupField.fieldName])))];  // create a set of group names from the array
        }
        // add current fiscal year?
        if (myGroupField.fieldName === 'closingDt' && arr.filter(y => y === currentFiscalYr).length === 0) {
          arr.push(currentFiscalYr.toString());
        }
        arr.sort((one, two) => (one > two ? -1 : 1));
        arr = arr.map(function(groupName) {  // now take that set and get counts for each group
          let rObj = {};
          rObj['name'] = groupName;
          if (myGroupField.fieldName === 'closingDt') {
            rObj['count'] = investments.filter(l => (moment(l[myGroupField.fieldName], 'MM/DD/YYYY').month() <= 5 ?
                                                            moment(l[myGroupField.fieldName], 'MM/DD/YYYY').year() :
                                                            moment(l[myGroupField.fieldName], 'MM/DD/YYYY').year() + 1).toString() === groupName
                                                        || (groupName === currentFiscalYr && l[myGroupField.fieldName] === '')).length;
          } else {
            rObj['count'] = investments.filter(l => l[myGroupField.fieldName] === groupName).length;
          }
          return rObj;
        });
      }

      this.tmp.push(...arr);  // spread syntax https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator
    }
    return this.tmp;
  }

}
