import { Pipe, PipeTransform } from '@angular/core';
import { SystemData, CalendarObject } from '../portalData';

@Pipe({
    name: 'birthday',
    standalone: true
})
export class BirthdayPipe implements PipeTransform {

  transform(calObjs: CalendarObject[], showBirthdays: boolean, showAnniversaries: boolean, showInvDeals: boolean): any {
    if (showBirthdays) {
      return calObjs.filter(c => c.calendarKey === 4);
    } else if (showAnniversaries) {
      return calObjs.filter(c => c.calendarKey === 8);
    } else if (showInvDeals) {
      return calObjs.filter(c => c.calendarKey === 9);
    } else  {
      return calObjs.filter(c => c.calendarKey != 4 && c.calendarKey != 8 && c.calendarKey != 9);
    }
  }

}
