

import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { WidgetLineGraphColoredComponent } from './widget-line-graph-colored/widget-line-graph-colored.component';
import { WidgetLineGraphWithDetailsComponent } from './widget-line-graph-with-details/widget-line-graph-with-details.component';
import { WidgetBarGraphWithDetailsComponent } from './widget-bar-graph-with-details/widget-bar-graph-with-details.component';
import { NgChartsModule } from 'ng2-charts';
import { WidgetGoalsComponent } from './widget-goals/widget-goals.component';
import { WidgetGoalPopupComponent } from './widget-goal-popup/widget-goal-popup.component';
import { DashboardCalendarComponent } from './dashboard-calendar/dashboard-calendar.component';
import { BirthdayPipe } from './dashboard-calendar/birthday.pipe';
import { InvestmentModule } from './../investment/investment.module';
import { QtrSystemDataPipe } from './qtrSystemData.pipe';
import { EventTooltipDirective } from './event-tooltip.directive';
import { DashboardEventTooltipComponent } from './dashboard-event-tooltip/dashboard-event-tooltip.component';
import { MarchMadnessModule } from '../misc/march-madness/march-madness.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgChartsModule,
        InvestmentModule,
        MarchMadnessModule,
        DashboardComponent,
        WidgetLineGraphColoredComponent,
        WidgetGoalsComponent,
        WidgetGoalPopupComponent,
        DashboardCalendarComponent,
        WidgetLineGraphWithDetailsComponent,
        WidgetBarGraphWithDetailsComponent,
        BirthdayPipe,
        QtrSystemDataPipe,
        EventTooltipDirective,
        DashboardEventTooltipComponent
    ],
    providers: [
        CurrencyPipe,
        DecimalPipe,
        PercentPipe,
        BirthdayPipe
    ],
    exports: [
        DashboardComponent
    ],
})
export class DashboardModule { }
