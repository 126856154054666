
import { of as observableOf, Observable, ReplaySubject, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SearchResult, FileSearchResult } from './searchResult';
import { UserService } from './user.service';



@Injectable()
export class SearchService {

  private url = environment.cubCloudUrl + 'search';
  private fileUrl = environment.cubCloudUrl + 'file/search';
  private isSearching = new ReplaySubject<boolean>(1);
  isSearching$ = this.isSearching.asObservable();
  private showSearchPopup = new ReplaySubject<boolean>(1);
  showSearchPopup$ = this.showSearchPopup.asObservable();
  private searchResults = new ReplaySubject<SearchResult[]>(1);
  searchResults$ = this.searchResults.asObservable();
  private fileSearchResults = new ReplaySubject<FileSearchResult[]>(1);
  fileSearchResults$ = this.fileSearchResults.asObservable();
  lastTerm: string;
  public lastSystem: string;

  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {
    this.showSearchPopup.next(false);
    this.isSearching.next(false);
  }

  setSystem(system: string) {
    this.lastSystem = system;
  }

  searchBySystem(system: string, folder: string) {
    let terms: Observable<string>;
    this.lastSystem = system;
    terms = observableOf(this.lastTerm);
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(this.lastTerm, system, folder)));
  }

  search(terms: Observable<string>, system: string) {
    this.lastSystem = system;
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(term, system, '')));
  }

  searchByTerm(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(term, this.lastSystem, '')));
  }

  searchEntries(term: string, system: string, folder: string): Observable<any> {
    this.showSearchPopup.next(true);
    this.isSearching.next(true);
    this.lastTerm = term;
    let url = `${this.url}/${term}/${system}`;
    let fileUrl = `${this.fileUrl}/${term}`;

    if (term.length === 0) {
      this.closeSearch();
    }
    console.log('searching', term, system, folder);
    if (system === 'File' && term.length > 2) {
      return this.http
        .get<any>(fileUrl, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    } else {
      if (term.length > 0) {
        return this.http
          .post<any>(
            url,
            JSON.stringify({ folder: '/Shared' + (folder.length === 0 ? '' : '/' + folder) }),
            { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
      } else {
        return EMPTY;
      }
    }
  }

  mapResult(result) {
    if (this.lastSystem === 'File') {
      this.fileSearchResults.next(result);
    } else {
      this.searchResults.next(result);
    }
    this.isSearching.next(false);
    return result;
  }

  closeSearch() {
    this.showSearchPopup.next(false);
  }

  openSearch() {
    this.showSearchPopup.next(true);
  }

}
