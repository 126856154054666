
export class ListTab {
  system: string;
  title: string;

  constructor(system: string, title: string) {
    this.system = system;
    this.title = title;
  }
}
