import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../core/alert.service';
import { GlTransfer, GLTransferResult } from './glTransfer';
import { TransferedData } from './glTransfer';
import { GLCodeMaintenance } from './glTransfer';

@Injectable()
export class GldataService {

  private gldataUrl = environment.cubCloudUrl + 'gldata';

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) { }

  getGLTransfer(): Observable<GlTransfer> {
    let url = `${this.gldataUrl}`;

    return this.http.get<GlTransfer>(this.formatUrl(url), { withCredentials: true });
  }

  uploadFiles(fileType: string, book: number, month: number, year: number, priorFile: File, currentFile: File) {
    const url = `${this.gldataUrl}/upload`;

    let formData: FormData = new FormData();
    formData.append('fileType', fileType);
    formData.append('book', book.toString());
    formData.append('month', month.toString());
    formData.append('year', year.toString());
    formData.append('priorFile', priorFile, priorFile.name);
    formData.append('currentFile', currentFile, currentFile.name);

    // I was having an issue when I was setting the headers and the iterwebs told me to leave it blank.
    // Voila the file was available on the server within the HttpContext.Current.Request and the data is still
    // coming over as JSON if you inspect that shit.

    return this.http
      .post<GLTransferResult>(this.formatUrl(url), formData, { headers: new HttpHeaders(), withCredentials: true })
      .pipe(
        catchError((err) => this.handleError(err, this.alertService, 'Sorry, there was a problem uploading the ' + fileType + ' files. Please contact MIS.'))
      );
  }

  transferFiles(fileType: string, book: number, month: number, year: number) {
    const url = `${this.gldataUrl}/transfer/${book}/${month}/${year}`;

    return this.http
      .post<string>(this.formatUrl(url), JSON.stringify(fileType), { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true })
      .pipe(
        catchError((err) => this.handleError(err, this.alertService, 'Sorry, there was a problem creating/exporting the export file. Please contact MIS.'))
      );
  }

  savePropertyMapping(mapping: TransferedData) {
    const url = `${this.gldataUrl}/update/property`;
    return this.http
      .post<string>(this.formatUrl(url), JSON.stringify(mapping), this.getOptions())
      .pipe(
        catchError((err) => this.handleError(err, this.alertService, ''))
      );
  }

  saveGLMapping(mapping: GLCodeMaintenance) {
    const url = `${this.gldataUrl}/update/glcode`;
    return this.http
      .post<string>(this.formatUrl(url), JSON.stringify(mapping), this.getOptions())
      .pipe(
        catchError((err) => this.handleError(err, this.alertService, ''))
      );
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + alertMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

  private formatUrl(url: string): string {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = newUrl.replace('http://cub/', 'https://cub.centerpoint.com/');
    }
    return newUrl;
  }

  private getOptions() {
    return {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: true
    };
  }
}
