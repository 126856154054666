import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'listFilterSelected',
    pure: false,
    standalone: true
})
export class ListFilterSelectedPipe implements PipeTransform {
  tmp = [];
  transform(items: Array<any>, selected: string): any {
    this.tmp.length = 0;
    if (selected === 'selected') {
      let arr = items
        .filter(item => item.selected)
        .sort((a, b) => {
          if (a.alwaysOn > b.alwaysOn) {
            return -1;
          } else if (a.alwaysOn < b.alwaysOn) {
            return 1;
          } else {
            return 0;
          }
        });
      this.tmp.push(...arr);
    } else if (selected === 'notSelected') {
      let arr = items.filter(item => !item.selected && !item.allBehavior).sort((a, b) => {
        if (a.system > b.system) {
          return -1;
        } else if (a.system < b.system) {
          return 1;
        } else {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      this.tmp.push(...arr);
    } else if (selected === 'either') {
      let arr = items.filter(item => item.selected);
      if (arr.length === 1 && arr[0].name === 'All') {
        this.tmp.push(...arr);
      } else {
        arr = items
          .filter(item => item.name !== 'All')
          .sort((a, b) => {
            if (a.selected > b.selected) {
              return -1;
            } else if (a.selected < b.selected) {
              return 1;
            } else {
              return 0;
            }
          });
        this.tmp.push(...arr);
      }
    }
    return this.tmp;
  }
}

// this is a "stateful" pipe
// https://stackoverflow.com/questions/34456430/ngfor-doesnt-update-data-with-pipe-in-angular2
