import { Component, Input, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerCommission } from './brokerCommission';
import { BrokerCommissionService } from './broker-commission.service';
import { BrokerCommissionDialogComponent } from '../broker-commission-dialog/broker-commission-dialog.component';
import { Disposition } from 'src/app/disposition/disposition';
import { NgIf, NgFor, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'cub-broker-commission',
    templateUrl: './broker-commission.component.html',
    styleUrls: ['./broker-commission.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, CurrencyPipe, DatePipe]
})
export class BrokerCommissionComponent implements OnInit, OnChanges {

  myTableKey: number;
  @Input()
  set tableKey(value: number) {
    console.log('new tableKey', value);
    this.myTableKey = value;
    this.getBrokerCommissions();
  }
  @Input() mode: string;
  brokerCommissions: BrokerCommission[];
  enabled = false;
  commissionDesc = 'lease';

  constructor(
    private dialog: MatDialog,
    private brokerService: BrokerCommissionService,
    private route: ActivatedRoute
  ) {
    this.brokerCommissions = [];
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['tableKey'] === 0 || params['tableKey'] === undefined) {
        this.enabled = false;
      } else {
        this.enabled = true;
      }

      this.setCommissionDescription();
    });
  }

  // this is necessary to track changes to the input variable from the calling component
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (let propName in changes) {
      if (propName === 'tableKey') {
        this.getBrokerCommissions();
      }
    }
  }

  getBrokerCommissions() {
    if (this.myTableKey !== undefined && this.myTableKey > 0) {
      this.enabled = true;
      this.brokerService.getCommissions(this.mode, this.myTableKey).subscribe(result => {
        this.brokerCommissions = result;
      });
    }
  }

  addCommission() {
    if (this.enabled) {
      // only add a new one if the control is enabled
      let newCommission = new BrokerCommission();
      this.setNewCommissionTableKey(newCommission);

      // open a dialog with the following attributes
      let addDialog = this.dialog.open(BrokerCommissionDialogComponent, {
        disableClose: true,
        data: { brokerCommission: newCommission }
      });

      addDialog.afterClosed().subscribe(result => {
        newCommission = result;
        if (newCommission.transactionKey > 0) {
          this.updateCommissionInList(result);  // just add it to the list
        }
      });
    }
  }

  editCommission(brokerCommission: BrokerCommission) {

    // open a dialog with the following attributes
    let editDialog = this.dialog.open(BrokerCommissionDialogComponent, {
      disableClose: true,
      data: { brokerCommission }
    });

    editDialog.afterClosed().subscribe(result => {
      brokerCommission = result;
      this.updateCommissionInList(brokerCommission);
    });
  }

  deleteCommission(brokerCommission: BrokerCommission) {
    // add a confirmation dialog here
    if (confirm('Are you sure you want to remove this commission?')) {
      this.brokerService.deleteCommission(brokerCommission.transactionKey)
        .then(() => {
          // remove it from the list
          let itemIndex = this.brokerCommissions.findIndex(t => t.transactionKey === brokerCommission.transactionKey);
          this.brokerCommissions.splice(itemIndex, 1);
        });
    }
  }

  updateCommissionInList(brokerCommission: BrokerCommission) {
    let itemIndex = this.brokerCommissions.findIndex(t => t.transactionKey === brokerCommission.transactionKey);

    // find the broker commission by key and replace it with the passed 'brokerCommission' or add it to the array if it doesn't exist
    if (itemIndex > -1) {
      this.brokerCommissions[itemIndex] = brokerCommission;
    } else {
      this.brokerCommissions.push(brokerCommission);  // add it to the array of commissions
    }
  }

  setNewCommissionTableKey(newCommission: BrokerCommission) {
    // sets the appropriate key to the input tableKey based on the mode
    switch (this.mode) {
      case 'property': {
        newCommission.propertyKey = this.myTableKey;
        break;
      }
      case 'lease': {
        newCommission.leaseKey = this.myTableKey;
        break;
      }
      case 'dispoProspect': {
        newCommission.dispoProspectKey = this.myTableKey;
        break;
      }
      case 'dispoItem': {
        newCommission.dispoItemKey = this.myTableKey;
        break;
      }
      case 'dispoPackage': {
        newCommission.dispoPackageKey = this.myTableKey;
        break;
      }
      default: {
        // this should never happen so we want to cause a FOREIGN KEY constraint failure
        break;
      }
    }
  }

  setCommissionDescription() {
    // sets the appropriate key to the input tableKey based on the mode
    switch (this.mode) {
      case 'property': {
        this.commissionDesc = 'property';
        break;
      }
      case 'lease': {
        this.commissionDesc = 'lease';
        break;
      }
      case 'dispoProspect': {
        this.commissionDesc = 'disposition';
        break;
      }
      case 'dispoItem': {
        this.commissionDesc = 'disposition item';
        break;
      }
      case 'dispoPackage': {
        this.commissionDesc = 'disposition package';
        break;
      }
      default: {
        // this should never happen so we want to cause a FOREIGN KEY constraint failure
        break;
      }
    }
  }

}
