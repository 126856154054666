<div id="filter"
     class="list-filter">
  <div class="mainRow"
       style="margin-top: 3px;">
    <!-- The list of selected filters -->
    <div
         style="margin-left: 15px;"
         class="filter-list">
      <span class="note"
            *ngIf="getSelectedCount(listOptions.filters) === 0"
            style="float: left; margin-right: 10px; margin-left: 5px; margin-top: 6px;"> No Filters Applied </span>
      <div *ngFor="let filter of listOptions.filters | listFilterSelected: 'selected' | canSee: system"
           [class.notAlwaysOn]="!filter.alwaysOn && alwaysOnCount() > 0">
        <button mat-button
                [matMenuTriggerFor]="submenu"
                [disabled]="!canEditFilters"
                class="filterButton"
                [title]="getButtonTitle(filter)">
          <span style="font-weight: 300; color: #ddd; font-size: 13px;">{{ filter.shortName }}<span
                  *ngIf="getSelectedCount(filter.filterItems) > 0">: </span></span>
          <span style="color: #fff;"
                *ngFor="let subFilter of filter.filterItems | listFilterSelected: 'selected'; let isLast = last">{{
            subFilter.shortName }}{{ isLast ? '' : ', ' }}</span>
          <span *ngIf="!allSelected(filter.filterItems)"
                style="margin-left: 5px;">
            <span *ngFor="let subFilter of filter.filterItems | listFilterSelected: 'notSelected'; let isLast = last"
                  class="not-selected">{{ subFilter.shortName }}{{ isLast ? '' : ', ' }}</span>
          </span>
          <fa-icon [icon]="faCaretDown"
                   class="caret"></fa-icon>
        </button>
        <mat-menu #submenu="matMenu"
                  [yPosition]="'below'"
                  [xPosition]="'after'"
                  [overlapTrigger]="false">
          <button *ngFor="let subFilter of filter.filterItems"
                  mat-menu-item
                  (click)="buttonClick(subFilter, filter, $event)"
                  title="{{ subFilter.title }}">
            <fa-icon [icon]="faCheck"
                     *ngIf="subFilter.selected"
                     style="font-size: 15px;"></fa-icon>
            <span class="spacer"
                  *ngIf="!subFilter.selected">&nbsp;</span>
            <span>{{ subFilter.name }}</span>
          </button>
          <button *ngIf="filter.filterItems.length > 0 && !filter.alwaysOn"
                  class="separator"
                  mat-menu-item></button>
          <button mat-menu-item
                  (click)="removeFilter(filter)"
                  *ngIf="!filter.alwaysOn">
            <fa-icon [icon]="faTrashAlt"
                     style="font-size: 15px;"></fa-icon> Remove
          </button>
        </mat-menu>
      </div>
      <!-- add button to add filters that aren't selected yet -->
      <div [class.notAlwaysOn]="filtersOn()">
        <button mat-button
                [matMenuTriggerFor]="newMenu"
                [disabled]="!canEditFilters"
                class="filterButton"
                style="min-width: 38px; color: #fff;"
                title="Add Filters"
                *ngIf="getSelectedCount(listOptions.filters) !== listOptions.filters.length">
          <fa-icon [icon]="faPlus"
                   style="font-size: 16px;"></fa-icon>
        </button>
        <mat-menu #newMenu="matMenu"
                  [yPosition]="'below'"
                  [xPosition]="'after'"
                  [overlapTrigger]="false">
          <button *ngFor="let filter of listOptions.filters | listFilterSelected: 'notSelected' | canSee: system"
                  mat-menu-item
                  (click)="buttonClick(filter, null, $event)"
                  title="{{ filter.title }}">
            <fa-icon [icon]="faBuilding"
                     *ngIf="filter.system === 'property'"
                     style="font-size: 15px;"></fa-icon>
            <fa-icon [icon]="faFileSignature"
                     *ngIf="filter.system === 'lease'"
                     style="font-size: 15px;"></fa-icon>
            <span>{{ filter.name }}</span>
          </button>
        </mat-menu>
      </div>
      <button mat-button
              [disabled]="!canEditFilters"
              class="filterButtonMinor"
              style="min-width: 38px;"
              title="Save these filter settings so they're selected every time you open this screen"
              (click)="saveView()"
              *ngIf="dirty">
        <fa-icon [icon]="faSave"
                 style="font-size: 16px;"></fa-icon>
      </button>
    </div>
  </div>
</div>
