<h6 mat-dialog-title>{{ data.title }}</h6>
<mat-dialog-content>
  <div [innerHTML]="data.content"></div>
  <!--   <p>
    {{ data.content }}
  </p>
 -->
</mat-dialog-content>
<div style="margin-top: 20px; width: 100%">
  <button mat-raised-button color="primary" *ngIf="!data.modal" [mat-dialog-close]="true" cdkFocusInitial style="float: right" (click)="ok()">
    OK
  </button>
</div>
