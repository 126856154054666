
import { UntypedFormControl } from '@angular/forms';

export function zoomValidator(input: UntypedFormControl) {
  let isValid = true;

  if (input.value) {
    if (input.value < 100) {
      isValid = false;
    }
  }
  return isValid ? null : { HasGoodZoom: true };
}
