<form [formGroup]="propForm"
      (ngSubmit)="onSubmit()"
      novalidate>
  <mat-card appearance="outlined" class="cub-table-card"
            style="margin-bottom: 80px;">
    <mat-card-header>
      <mat-card-title>
        <span title="{{ property.key }}">Edit Property: {{ property.desc }}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="grid-edit">
        <div class="grid-edit-fields">
          <mat-form-field style="grid-column: 1/3">
				    <mat-label>Street</mat-label>
            <input matInput

                   formControlName="street"
                   [readonly]="investment.closed"
                   (blur)="formToObject();locateOnMap();">
            <mat-error *ngIf="propForm.controls['street'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>City</mat-label>
            <input matInput

                   formControlName="city"
                   [readonly]="investment.closed"
                   (blur)="formToObject();locateOnMap();">
            <mat-error *ngIf="propForm.controls['city'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>State</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="state"
                        (selectionChange)="updateStateName($event);formToObject();">
              <mat-option *ngFor="let state of states"
                          [value]="state.id"> {{ state.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['state'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Zip Code</mat-label>
            <input matInput

                   formControlName="zipCode"
                   [readonly]="investment.closed"
                   (blur)="formToObject();locateOnMap();">
            <mat-error *ngIf="propForm.controls['zipCode'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Region</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="region"
                        (selectionChange)="updateRegionName($event);formToObject();">
              <mat-option *ngFor="let regions of regions"
                          [value]="regions.id"> {{ regions.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['region'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>CNT Market</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="market"
                        (selectionChange)="updateMarketName($event);formToObject();">
              <mat-option *ngFor="let market of markets"
                          [value]="market.id"> {{ market.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['market'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>MSA</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="msa">
              <mat-option *ngFor="let msa of msas"
                          [value]="msa.id"> {{ msa.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['msa'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>CalPERS Market</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="calpersMarket">
              <mat-option *ngFor="let type of calpersMarkets"
                          [value]="type.id"> {{ type.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['calpersMarket'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Green Street Rating</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="greenSt"
                        (selectionChange)="updateGreenStName($event);formToObject();">
              <mat-option *ngFor="let green of greenSt"
                          [value]="green.id"> {{ green.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['greenSt'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Property Type</mat-label>
            <mat-select [compareWith]="selectCompare"

                        formControlName="type">
              <mat-option *ngFor="let type of structureTypes"
                          [value]="type.id"> {{ type.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="propForm.controls['type'].invalid">You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Square Feet</mat-label>
            <input matInput

                   formControlName="sf"
                   [readonly]="investment.closed"
                   (blur)="onSfBlur();">
            <mat-error *ngIf="propForm.controls['sf'].invalid && propForm.controls['sf'].errors.IsInteger">You must
              enter a number</mat-error>
            <mat-error *ngIf="propForm.controls['sf'].invalid && !propForm.controls['sf'].errors.IsInteger">You must
              enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Purchase Price</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput

                   formControlName="purchasePrice"
                   [readonly]="investment.closed"
                   (blur)="onPurchasePriceBlur();">
            <mat-error
                       *ngIf="propForm.controls['purchasePrice'].invalid && propForm.controls['purchasePrice'].errors.IsInteger">
              You must enter a number</mat-error>
            <mat-error
                       *ngIf="propForm.controls['purchasePrice'].invalid && !propForm.controls['purchasePrice'].errors.IsInteger">
              You must enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>TAC</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput

                   formControlName="tac"
                   [readonly]="investment.closed"
                   (blur)="onTacBlur();">
            <mat-error *ngIf="propForm.controls['tac'].invalid && propForm.controls['tac'].errors.IsInteger">You must
              enter a number</mat-error>
            <mat-error *ngIf="propForm.controls['tac'].invalid && !propForm.controls['tac'].errors.IsInteger">You must
              enter a value</mat-error>
          </mat-form-field>
          <mat-form-field>
				    <mat-label>Closing Date</mat-label>
            <input matInput
                   [matDatepicker]="picker"
                   formControlName="closingDt"
                   [readonly]="investment.closed"
                   >
            <mat-datepicker-toggle *ngIf="!investment.closed"
                                   matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="propForm.controls['closingDt'].invalid">You must enter a number</mat-error>
          </mat-form-field>
        </div>
        <div>
          <cub-map [latitude]="property.address.latitude"
                   [longitude]="property.address.longitude"
                   [zoom]="zoomLevel"
                   [mapStyle]="mapStyle"
                   [mapHeight]="400"
                   (mapViewChange)="onMapChange($event)"
                   style="width: 95%;"></cub-map>
          <br />
          <span class="zoom-note">Please zoom in enough to locate the exact spot</span>
          <mat-progress-bar mode="determinate"
                            value="{{zoomProgress}}"
                            style="margin-bottom: 10px; margin-top: 10px;"></mat-progress-bar>
          <input matInput
                 formControlName="zoom"
                 style="display: none;"
                 required>
          <mat-error *ngIf="propForm.controls['zoom'].invalid">You will need to zoom in a little more</mat-error>
          <span class="zoom-note"
                *ngIf="zoomProgress >= 100">You did it!</span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions style="margin-bottom: 16px; height: 45px;">
      <button mat-raised-button
              (click)="cancel();"
              style="float: left; ">Cancel</button>
      <button mat-raised-button
              color="primary"
              type="submit"
              [disabled]="propForm.pristine || investment.closed"
              style="float: right; margin-left: 40px;"><i class="fa fa-save"></i> Save</button>
      <button mat-button
              *ngIf="property.key > 0 && investment.properties.length > 1 && !investment.closed"
              type="button"
              color="warn"
              (click)="delete();"
              style="float: left; margin-left: 40px;"><i class="fa fa-trash"></i> Delete</button>
      <button mat-button
              *ngIf="property.key > 0"
              type="button"
              (click)="goToProp();"
              style="float: right;"><i class="fa fa-building"></i> Go to Property Screen</button>
      <button mat-button
              *ngIf="property.key > 0"
              type="button"
              (click)="findLocation();"
              style="float: right;"><i class="fa fa-map"></i> Map Address</button>
    </mat-card-actions>
  </mat-card>
</form>
