
import { Component, Input, ElementRef, ViewChild, ViewEncapsulation, DoCheck, OnChanges } from '@angular/core';
import { DecimalPipe, CurrencyPipe, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { WidgetGoalPopupComponent } from '../widget-goal-popup/widget-goal-popup.component';
import { PortalGauge } from './../portalData';
import { GoalChart } from './../goalChart';

@Component({
    selector: 'cub-widget-goals',
    templateUrl: './widget-goals.component.html',
    styleUrls: ['./widget-goals.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor]
})
export class WidgetGoalsComponent implements DoCheck, OnChanges {

  @ViewChild('chartCanvas', { static: true }) chartCanvas: ElementRef;
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() chartData: PortalGauge[] = [];
  goalChart: GoalChart;
  canvasWidth = 500;
  canvasHeight = 200;
  popUpLinkWidth = 0;
  drawingInset = 0;
  drawCounter = 0;

  constructor(
    public dialog: MatDialog,
    private dp: DecimalPipe,
    private cp: CurrencyPipe
    ) { }

  drawOnCanvas() {
    let ctx: CanvasRenderingContext2D = this.chartCanvas.nativeElement.getContext('2d');
    ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.goalChart = new GoalChart(ctx, this.canvasWidth, this.canvasHeight, this.chartData);
    this.goalChart.draw();
    this.popUpLinkWidth = this.goalChart.popUpLinkWidth;
    this.drawingInset = this.goalChart.drawingInset;
  }

  ngDoCheck() {
    if (this.canvasWidth !== this.container.nativeElement.offsetWidth) {
      this.canvasWidth = this.container.nativeElement.offsetWidth;
      this.clearAndRedraw();
      this.drawCounter++;
    }
  }

  ngOnChanges() {
    this.drawOnCanvas();
  }

  clearAndRedraw() {
    setTimeout(() => {
      this.drawOnCanvas();
    }, 1); // why does this work?  I have no idea
  }

  getLetMarginForPopupLink(index: number): number {
    return this.popUpLinkWidth * index + this.drawingInset;
  }

  openPopup(index: number) {
    let dialogRef = this.dialog.open(WidgetGoalPopupComponent, {
      disableClose: false,
      width: '750px',
      data: this.chartData[index]
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
