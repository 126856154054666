import { Injectable, Inject, Injector, ElementRef, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, OriginConnectionPosition, OverlayConnectionPosition, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ContactPickerOverlayComponent } from './contact-picker-overlay.component';
import { ContactPickerOverlayRef } from './contact-picker-overlay-ref';
import { Contact } from '../../contact/contact';
import { CONTACT_PICKER_DIALOG_DATA } from './contact-picker-overlay.tokens';

export interface ContactPickerParam {
  employeesOnly: boolean;
  showDetailsOnly: boolean;
  contact: Contact;
}

interface ContactPickerDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  params?: ContactPickerParam;
}

const DEFAULT_CONFIG: ContactPickerDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  params: null
};

@Injectable()
export class ContactPickerService {

  overlayRef: OverlayRef;

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) { }

  open(elementToConnectTo: ElementRef, config: ContactPickerDialogConfig = {}) {

    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    this.overlayRef = this.createOverlay(elementToConnectTo, dialogConfig);

    // Instantiate remote control
    const dialogRef = new ContactPickerOverlayRef(this.overlayRef);
    this.attachDialogContainer(this.overlayRef, dialogConfig, dialogRef);

    // Subscribe to a stream that emits when the backdrop was clicked
    let sub = this.overlayRef.backdropClick().subscribe(() => {
      dialogRef.cancel(dialogConfig.params.contact);
      sub.unsubscribe();
    });

    return dialogRef;
  }

  private createInjector(config: ContactPickerDialogConfig, dialogRef: ContactPickerOverlayRef): Injector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(ContactPickerOverlayRef, dialogRef);
    injectionTokens.set(CONTACT_PICKER_DIALOG_DATA, config.params);

    // Instantiate new PortalInjector
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: ContactPickerOverlayRef, useValue: dialogRef },
        { provide: CONTACT_PICKER_DIALOG_DATA, useValue: config.params }
      ]
    });
  }

  private createOverlay(elementToConnectTo: ElementRef, config: ContactPickerDialogConfig) {
    const overlayConfig = this.getOverlayConfig(elementToConnectTo, config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ContactPickerDialogConfig, dialogRef: ContactPickerOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ContactPickerOverlayComponent, null, injector);
    const containerRef: ComponentRef<ContactPickerOverlayComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private getOverlayConfig(elementToConnectTo: ElementRef, config: ContactPickerDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(elementToConnectTo)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      } as ConnectedPosition]);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy
    });

    return overlayConfig;
  }
}
