export class ListFilter {
  name: string;
  shortName: string;
  value: number;
  title: string;
  system: string;
  filterItems: ListFilter[];
  selected: boolean;
  allBehavior: boolean;
  selectType: string;
  alwaysOn: boolean;

  constructor() {
    this.filterItems = [];
  }
}
