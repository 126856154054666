export class ChangePacket {
  appKey: number;
  tableName: string;
  tableKeyName: string;
  fieldName: string;
  fieldDesc: string;
  itemKey: number;
  oldValue: string;
  newValue: string;
  NewValueToLog: string;
  dataType: string;
  dataLength: number;
  userName: string;
  changeDt: string;
  resetCache: boolean;

  constructor(
    tableName: string,
    fieldName: string,
    fieldDesc: string,
    itemKey: number,
    oldValue: string,
    newValue: string,
    NewValueToLog: string,
    dataType: string,
    dataLength: number,
    resetCache?: boolean
  ) {
    this.tableName = tableName;
    this.fieldName = fieldName;
    this.fieldDesc = fieldDesc;
    this.itemKey = itemKey;
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.NewValueToLog = NewValueToLog;
    this.dataType = dataType;
    this.dataLength = dataLength;
    if (resetCache) {
      this.resetCache = resetCache;
    } else {
      this.resetCache = false;
    }
  }
}
