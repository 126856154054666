import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Contact } from '../../contact/contact';
import { ContactService } from './contact.service';
import { ContactPickerService } from './contact-picker.service';
import { ContactPickerOverlayRef } from './contact-picker-overlay-ref';
import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { ContactEditDialogComponent } from '../contact-edit-dialog/contact-edit-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-contact-picker',
    templateUrl: './contact-picker.component.html',
    styleUrls: ['./contact-picker.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, NgIf, MatInputModule]
})
export class ContactPickerComponent implements OnChanges {

  @ViewChild('contactField', { static: true }) contactField;
  @ViewChild('txtInput', { static: true }) txtInput;
  @Input() placeholder: string;
  @Input() pcKey: number;
  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() itemKey: number;
  pickerShown = false;
  origValue: string;
  myContact: Contact;
  loadingUser = true;

  constructor(
    private contactPickerService: ContactPickerService,
    private contactService: ContactService,
    private saveService: SaveService,
    private dialog: MatDialog) {
    this.myContact = new Contact();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pcKey !== undefined) {
      if (this.pcKey !== undefined) {
        this.toggleLoading(true);
        if (this.pcKey !== null) {
          this.contactService.getContactDetail(this.pcKey).subscribe(c => {
            this.toggleLoading(false);
            this.myContact = c;
            this.txtInput.nativeElement.value = (this.myContact.isPerson ? this.myContact.fullName : this.myContact.companyName);
            this.origValue = (this.myContact.isPerson ? this.myContact.fullName : this.myContact.companyName);
          });
        } else {
          this.toggleLoading(false);
          this.myContact = new Contact();
          this.txtInput.nativeElement.value = '';
          this.origValue = '';
        }
      } else {
        this.origValue = '';
      }
    }
  }

  private toggleLoading(showLoading: boolean) {
    if (showLoading) {
      this.txtInput.nativeElement.value = 'Loading...';
    }
    this.loadingUser = showLoading;
  }

  showDetails(event: any) {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {
      contact: this.myContact
    };
    const dialogRef = this.dialog.open(ContactEditDialogComponent, dialogConfig);
  }

  showPicker(event: any) {
    event.stopPropagation();
    let dialogRef: ContactPickerOverlayRef = this.contactPickerService.open(this.contactField._elementRef, { params: { employeesOnly: false, contact: this.myContact, showDetailsOnly: false } });
    dialogRef.afterSaved().subscribe(contact => {
      this.pcKey = contact.pcKey;
      this.myContact = contact;
      this.toggleLoading(true);
      this.save();
    });
    dialogRef.afterCancelled().subscribe(contact => {
      if (contact.pcKey !== 0) {
        this.txtInput.nativeElement.value = (contact.isPerson ? contact.fullName : contact.companyName);
      }
    });
    dialogRef.onSelection().subscribe(contact => {
      if (contact.pcKey !== 0) {
        this.txtInput.nativeElement.value = (contact.isPerson ? contact.fullName : contact.companyName);
      }
    });
  }

  save(): void {
    let changePacket: ChangePacket;
    let newValue = (this.pcKey === -1 ? '' : this.pcKey.toString());  // check if we cleared the selection
    let valueToLog = (this.myContact.isPerson ? this.myContact.fullName : this.myContact.companyName);
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.placeholder, this.itemKey, this.origValue, newValue, valueToLog, 'int', 32);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        this.toggleLoading(false);
        this.txtInput.nativeElement.value = (this.myContact.isPerson ? this.myContact.fullName : this.myContact.companyName);
        this.origValue = (this.myContact.isPerson ? this.myContact.fullName : this.myContact.companyName);
      })
      .catch(() => {
        this.toggleLoading(false);
        this.txtInput.nativeElement.value = 'Error';
        alert('Error saving contact selection');
      });
  }
}
