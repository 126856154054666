import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { faBirthdayCake, faPartyHorn, faSquareDollar, faClockDesk } from '@fortawesome/pro-light-svg-icons';
import * as moment from 'moment';
import { CalendarObject } from '../portalData';
import { BirthdayPipe } from './birthday.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgFor } from '@angular/common';

@Component({
    selector: 'cub-dashboard-calendar',
    templateUrl: './dashboard-calendar.component.html',
    styleUrls: ['./dashboard-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, FontAwesomeModule, MatTooltipModule, BirthdayPipe]
})
export class DashboardCalendarComponent implements OnInit {

  @Input() calendarObjects: CalendarObject[];
  @ViewChild('day1', { static: true }) day1: ElementRef;
  @ViewChild('day9', { static: true }) day9: ElementRef;

  faBirthdayCake = faBirthdayCake;
  faPartyHorn =faPartyHorn;
  faSquareDollar = faSquareDollar;
  faClockDesk = faClockDesk;

  ngOnInit() {
    console.log('calObjs', this.calendarObjects);
  }

  getDayName(i: number): string {
    let firstDayOfThisWeek = moment().startOf('isoWeek');
    let thisDay = firstDayOfThisWeek.add(i, 'days');
    return thisDay.date().toString();
  }

  isToday(i: number): boolean {
    let firstDayOfThisWeek = moment().startOf('isoWeek');
    let thisDay = firstDayOfThisWeek.add(i, 'days');
    return thisDay.date() === moment().date();
  }

  getCalendarClass(calObj: CalendarObject): string {
    let cssClass = '';
    if (calObj.widthInterval > 1 || this.eventSpansWeeks(calObj)) {
      cssClass = 'cal-event-multiday cal-event' + calObj.calendarKey + (calObj.priorEvent ? ' prior-event' : '');
    } else {
      cssClass = 'cal-event cal-event' + calObj.calendarKey + (calObj.priorEvent ? ' prior-event' : '');
    }
    return cssClass;
  }

  eventSpansWeeks(calObj: CalendarObject): boolean {
    return this.calendarObjects.filter(e => e.eventKey === calObj.eventKey).length > 1;
  }

  getTooltip(calObj: CalendarObject): string {
    return calObj.calendarName + ': ' + calObj.title;
  }

  getCalendarTop(calObj: CalendarObject): number {
    return (((this.day1.nativeElement.offsetHeight * (3 - calObj.week)) - ((calObj.topInterval - 1) * 28 + (calObj.topInterval * 2))) * -1) + 28;
  }

  getCalendarLeft(calObj: CalendarObject): number {
    return (this.day1.nativeElement.offsetWidth * calObj.leftInterval) + 5;
  }

  getCalendarWidth(calObj: CalendarObject): number {
    let width = this.day1.nativeElement.offsetWidth - 10;
    return (width * calObj.widthInterval) + ((calObj.widthInterval - 1) * 10);
  }

  getCalendarTopForCake(calObj: CalendarObject): number {
    return ((this.day1.nativeElement.offsetHeight * (3 - calObj.week)) * -1) + 5;
  }

  getCalendarLeftForCake(calObj: CalendarObject): number {
    return (this.day1.nativeElement.offsetWidth * calObj.leftInterval) + this.day1.nativeElement.offsetWidth - 25;  // 30 = width of cake
  }

  getTitleForCake(calObj: CalendarObject): string {
    return this.calendarObjects.filter(c => c.calendarKey === 4 &&
      c.week === calObj.week &&
      c.leftInterval === calObj.leftInterval).map(c => c.title).join(',');
  }

  getCalendarTopForPartyHorn(calObj: CalendarObject): number {
    return ((this.day1.nativeElement.offsetHeight * (3 - calObj.week)) * -1) + 5;
  }

  getCalendarLeftForPartyHorn(calObj: CalendarObject): number {
    return (this.day1.nativeElement.offsetWidth * calObj.leftInterval) + this.day1.nativeElement.offsetWidth - 55;  // 30 = width of cake
  }

  getTitleForPartyHorn(calObj: CalendarObject): string {
    return 'Anniversary: ' + this.calendarObjects.filter(c => c.calendarKey === 8 &&
      c.week === calObj.week &&
      c.leftInterval === calObj.leftInterval).map(c => c.title).join(',');
  }

  getCalendarDisplayForPartyHorn(calObj: CalendarObject): string {
    let visible = 'visible';
    if (calObj.week > 2) {
      visible = 'hidden';
    }
    return visible;
  }

  getCalendarTopForInv(calObj: CalendarObject): number {
    return ((this.day1.nativeElement.offsetHeight * (3 - calObj.week)) * -1) + 5;
  }

  getCalendarLeftForInv(calObj: CalendarObject): number {
    return (this.day1.nativeElement.offsetWidth * calObj.leftInterval) + this.day1.nativeElement.offsetWidth - 85;  // 30 = width of cake
  }

  getTitleForInv(calObj: CalendarObject): string {
    return 'Closings: ' + this.calendarObjects.filter(c => c.calendarKey === 9 &&
      c.week === calObj.week &&
      c.leftInterval === calObj.leftInterval).map(c => c.title).join(',');
  }

  getCalendarDisplayForInv(calObj: CalendarObject): string {
    let visible = 'visible';
    if (calObj.week > 2) {
      visible = 'hidden';
    }
    return visible;
  }

  loadTestCalendarData() {
    let ne = new CalendarObject();
    ne.cakeEvent = false;
    ne.calendarKey = 1;
    ne.calendarName = 'Marketing';
    ne.eventId = '8989';
    ne.eventKey = 333;
    ne.leftInterval = 1;
    ne.priorEvent = false;
    ne.title = 'Cub Win!';
    ne.topInterval = 1;
    ne.url = '';
    ne.week = 2;
    ne.widthInterval = 2;
    this.calendarObjects.push(ne);
    let ne2 = new CalendarObject();
    ne2.cakeEvent = false;
    ne2.calendarKey = 1;
    ne2.calendarName = 'Marketing';
    ne2.eventId = '898';
    ne2.eventKey = 222;
    ne2.leftInterval = 1;
    ne2.priorEvent = false;
    ne2.title = 'Cub Win!';
    ne2.topInterval = 2;
    ne2.url = '';
    ne2.week = 2;
    ne2.widthInterval = 2;
    this.calendarObjects.push(ne2);
    let ne3 = new CalendarObject();
    ne3.cakeEvent = false;
    ne3.calendarKey = 1;
    ne3.calendarName = 'Marketing';
    ne3.eventId = '56567';
    ne3.eventKey = 111;
    ne3.leftInterval = 1;
    ne3.priorEvent = false;
    ne3.title = 'Cub Win!';
    ne3.topInterval = 3;
    ne3.url = '';
    ne3.week = 2;
    ne3.widthInterval = 2;
    this.calendarObjects.push(ne3);
    let ne4 = new CalendarObject();
    ne4.cakeEvent = false;
    ne4.calendarKey = 1;
    ne4.calendarName = 'Marketing';
    ne4.eventId = 'sff';
    ne4.eventKey = 777;
    ne4.leftInterval = 4;
    ne4.priorEvent = false;
    ne4.title = 'Cub Win!';
    ne4.topInterval = 1;
    ne4.url = '';
    ne4.week = 1;
    ne4.widthInterval = 1;
    this.calendarObjects.push(ne4);
  }
}
