<form [formGroup]="bForm" (ngSubmit)="onSubmit()" novalidate style="overflow-y: scroll; height: 1500px">
  <div
    style="
      display: grid;
      grid-template-columns: 30% 40% 30%;
      padding: 10px 10px 0 10px;
      height: 56px;
    "
  >
    <div id="left">
      <button
        style=""
        tabindex="100"
        mat-stroked-button
        (click)="printBracket()"
        [disabled]="!canEdit"
      >
        <fa-icon
          [icon]="faPrint"
          class="fa-lg"
          style="margin-right: 8px"
        ></fa-icon>
        Print
      </button>
      <button
        style="margin-left: 20px"
        mat-stroked-button
        (click)="delete()"
        [disabled]="!canEdit"
      >
        <fa-icon
          [icon]="faTrashAlt"
          class="fa-lg"
          style="margin-right: 8px"
        ></fa-icon
        >Delete
      </button>
      <mat-checkbox formControlName="paid" style="margin-left: 20px" (change)="togglePaid($event)">Paid</mat-checkbox>
    </div>

    <div id="center" style="text-align: center;">
      <h2 style="cursor: text;" *ngIf="!showRename" (click)="showRename = true; bForm.controls['name'].enable()">{{ bracket.name }}</h2>
      <mat-form-field
        class="example-full-width"
        style="width: 200px; margin-right: 20px; margin-top: -15px;"
        [style.display]="showRename ? 'inline-block' : 'none'"
      >
        <input matInput formControlName="name" style="text-align: center;"  (blur)="showRename = false;" (keyup)="saveName($event)" (keydown)="preventDefault($event)" />
        <mat-error *ngIf="bForm.controls['name'].invalid"
          >You must enter a name</mat-error
        >
      </mat-form-field>
    </div>

    <div id="right">
      <button
        style="float: right;"
        tabindex="0"
        mat-flat-button
        color="primary"
        (click)="saveBracket(true)"
        [disabled]="!canEdit"
      >
        <fa-icon
          [icon]="faSave"
          class="fa-lg"
          style="margin-right: 8px"
        ></fa-icon>
        Save
      </button>
      <div *ngIf="loadedBrackets" style="float: right; margin-right: 40px;">
        <div
          style="
            text-align: center;
            width: 160px;
            margin: auto;
            margin-bottom: 5px;
          "
        >
          Games Picked:
          {{ gamesPicked() }}
          / 63
        </div>
        <div style="margin-bottom: 20px; width: 160px; margin: auto">
          <mat-progress-bar
            mode="determinate"
            [value]="(gamesPicked() / 63) * 100"
          ></mat-progress-bar>
        </div>
      </div>
      <div style="float: right; margin-right: 30px;">
        Total Points: {{ points }}
      </div>
    </div>
  </div>

  <div class="finalFourView" *ngIf="currentRegion === 4">
    <div class="col1">
        <div class="regionPicker region1" (click)="selectRegion(0)">
          <h3>{{ bracket.regions[0].name }}</h3>
          <img
            *ngIf="
              bracket.regions[0].teams.length > 0 &&
              getRegionWinnerLogo(0, 1000) !== ''
            "
            [src]="getRegionWinnerLogo(0, 1000)"
            style="width: 175px;"
          />
          <div
            *ngIf="getRegionWinnerLogo(0, 1000) === '' && loadedBrackets"
            class="region-winner-text"
          >
            Please Fill Out Region
          </div>
          <h5>{{ getRegionWinnerName(0) }}</h5>
        </div>
        <div class="regionPicker region4" (click)="selectRegion(3)">
          <h3>{{ bracket.regions[3].name }}</h3>
          <img
            *ngIf="
              bracket.regions[3].teams.length > 0 &&
              getRegionWinnerLogo(3, 1000) !== ''
            "
            [src]="getRegionWinnerLogo(3, 1000)"
            style="width: 190px;"
          />
          <div
            *ngIf="getRegionWinnerLogo(3, 1000) === '' && loadedBrackets"
            class="region-winner-text"
          >
            Please Fill Out Region
          </div>
          <h5>{{ getRegionWinnerName(3) }}</h5>
        </div>
    </div>
    <div class="col2">
        <div class="final final1" (click)="toggleFinal(1)">
          <img
            *ngIf="
              bracket.regions[4].teams.length > 0 &&
              getLogoOfTeamInGame(4, 1, 0, 1) !== ''
            "
            [src]="getLogoOfTeamInGame(4, 1, 0, 1)"
            style="width: 110px;"
          />
        </div>
        <div class="note">(click to toggle)</div>
    </div>
    <div class="col3">
        <div class="winner" (click)="toggleChamp()">
          <h3>Champion</h3>
          <fa-icon *ngIf="!loadedBrackets" [icon]="faBasketballBall"
                   [spin]="true"
                   style="font-size: 140px; margin-top: 10px;"></fa-icon>
          <img
            *ngIf="loadedBrackets && getChampTeamName() !== ''"
            [src]="getChampLogo()"
            style="width: 190px;"
          />
        </div>
        <div class="note">(click to toggle)</div>
    </div>
    <div class="col4">
        <div class="final final2" (click)="toggleFinal(2)">
          <img
            *ngIf="
              bracket.regions[4].teams.length > 0 &&
              getLogoOfTeamInGame(4, 1, 0, 2) !== ''
            "
            [src]="getLogoOfTeamInGame(4, 1, 0, 2)"
            style="width: 110px;"
          />
        </div>
        <div class="note">(click to toggle)</div>
    </div>
    <div class="col5">
        <div class="regionPicker region2" (click)="selectRegion(1)">
          <h3>{{ bracket.regions[1].name }}</h3>
          <img
            *ngIf="
              bracket.regions[1].teams.length > 0 &&
              getRegionWinnerLogo(1, 1000) !== ''
            "
            [src]="getRegionWinnerLogo(1, 1000)"
            style="width: 190px;"
          />
          <div
            *ngIf="getRegionWinnerLogo(1, 1000) === '' && loadedBrackets"
            class="region-winner-text"
          >
            Please Fill Out Region
          </div>
          <h5>{{ getRegionWinnerName(1) }}</h5>
        </div>
        <div class="regionPicker region3" (click)="selectRegion(2)">
          <h3>{{ bracket.regions[2].name }}</h3>
          <img
            *ngIf="
              bracket.regions[2].teams.length > 0 &&
              getRegionWinnerLogo(2, 1000) !== ''
            "
            [src]="getRegionWinnerLogo(2, 1000)"
            style="width: 190px;"
          />
          <div
            *ngIf="getRegionWinnerLogo(2, 1000) === '' && loadedBrackets"
            class="region-winner-text"
          >
            Please Fill Out Region
          </div>
          <h5>{{ getRegionWinnerName(2) }}</h5>
        </div>
    </div>
  </div>

  <div *ngFor="let r of bracket.regions; let regionIndex = index">
    <div *ngIf="currentRegion === regionIndex" >  <!-- class="bracket-scroll" -->
      <mat-card
        appearance="outlined"
        *ngIf="
          bracket.regions[regionIndex].teams.length > 0 && regionIndex < 4
        "
        class="mat-elevation-z0 bracket-card"
      >
      <div class="regionHeader">
        <div></div>
        <div class="regionHeaderTitle">{{ bracket.regions[regionIndex].name }} Region</div>
        <div>
          <button
            mat-stroked-button
            (click)="selectRegion(4)"
            style="float: right; margin-right: 5px"
          >
            <fa-icon
              [icon]="faArrowTurnDownLeft"
              class="fa-lg"
              style="margin-right: 8px"
            ></fa-icon> Back to Final 4
          </button>
        </div>
      </div>
      <div style="display: grid; grid-template-columns: 350px 350px 350px 350px; padding: 20px;">
        <div class="round">
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[0]"
            [team2]="bracket.regions[regionIndex].teams[1]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 9, 1)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[2]"
            [team2]="bracket.regions[regionIndex].teams[3]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 9, 2)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[4]"
            [team2]="bracket.regions[regionIndex].teams[5]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 10, 1)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[6]"
            [team2]="bracket.regions[regionIndex].teams[7]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 10, 2)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[8]"
            [team2]="bracket.regions[regionIndex].teams[9]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 11, 1)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[10]"
            [team2]="bracket.regions[regionIndex].teams[11]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 11, 2)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[12]"
            [team2]="bracket.regions[regionIndex].teams[13]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 12, 1)"
          ></cub-matchup>
          <cub-matchup
            [team1]="bracket.regions[regionIndex].teams[14]"
            [team2]="bracket.regions[regionIndex].teams[15]"
            [canEdit]="canEdit"
            (selectedTeam)="teamSelected($event, regionIndex, 1, 12, 2)"
          ></cub-matchup>
        </div>
        <div class="round round-2">
          <cub-matchup
            class="game"
            [round]="2"
            [atTop]="true"
            [team1]="getWinningTeam(regionIndex, 2, 9, 1)"
            [team2]="getWinningTeam(regionIndex, 2, 9, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 1, 9)"
            (selectedTeam)="teamSelected($event, regionIndex, 2, 13, 1)"
          ></cub-matchup>
          <cub-matchup
            class="game"
            [round]="2"
            [atTop]="false"
            [team1]="getWinningTeam(regionIndex, 2, 10, 1)"
            [team2]="getWinningTeam(regionIndex, 2, 10, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 1, 10)"
            (selectedTeam)="teamSelected($event, regionIndex, 2, 13, 2)"
          ></cub-matchup>
          <cub-matchup
            class="game"
            [round]="2"
            [atTop]="false"
            [team1]="getWinningTeam(regionIndex, 2, 11, 1)"
            [team2]="getWinningTeam(regionIndex, 2, 11, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 1, 11)"
            (selectedTeam)="teamSelected($event, regionIndex, 2, 14, 1)"
          ></cub-matchup>
          <cub-matchup
            class="game"
            [round]="2"
            [atTop]="false"
            [team1]="getWinningTeam(regionIndex, 2, 12, 1)"
            [team2]="getWinningTeam(regionIndex, 2, 12, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 1, 12)"
            (selectedTeam)="teamSelected($event, regionIndex, 2, 14, 3)"
          ></cub-matchup>
        </div>
        <div class="round round-3">
          <cub-matchup
            class="game"
            [round]="3"
            [atTop]="true"
            [team1]="getWinningTeam(regionIndex, 3, 13, 1)"
            [team2]="getWinningTeam(regionIndex, 3, 13, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 2, 13)"
            (selectedTeam)="teamSelected($event, regionIndex, 3, 15, 1)"
          ></cub-matchup>
          <cub-matchup
            class="game"
            [round]="3"
            [atTop]="false"
            [team1]="getWinningTeam(regionIndex, 3, 14, 1)"
            [team2]="getWinningTeam(regionIndex, 3, 14, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 2, 14)"
            (selectedTeam)="teamSelected($event, regionIndex, 3, 15, 2)"
          ></cub-matchup>
        </div>
        <div class="round round-4">
          <div class="region-winner-logo">
            <div style="margin: 10px; font-weight: bold">Region Winner</div>
            <img
              *ngIf="
                bracket.regions[regionIndex].teams.length > 0 &&
                getRegionWinnerLogo(regionIndex, 1000) !== ''
              "
              [src]="getRegionWinnerLogo(regionIndex, 1000)"
              style="width: 190px; margin-right: 10px"
            />
            <div
              *ngIf="getRegionWinnerLogo(regionIndex, 1000) === ''"
              class="region-winner-text"
            >
              Please Select Region Winner
            </div>
          </div>
          <cub-matchup
            class="game"
            [round]="4"
            [atTop]="true"
            [team1]="getWinningTeam(regionIndex, 4, 15, 1)"
            [team2]="getWinningTeam(regionIndex, 4, 15, 2)"
            [canEdit]="canEdit"
            [wrongPicks]="getWrongPicks(regionIndex, 3, 15)"
            (selectedTeam)="teamSelected($event, regionIndex, 0, 61, 1); selectRegion(4)"
          ></cub-matchup>
        </div>
      </div>
      </mat-card>
      <mat-card
        appearance="outlined"
        *ngIf="
          bracket.regions[regionIndex].teams.length > 0 && regionIndex === 4 && isGod
        "
        class="mat-elevation-z8"
        style="
          width: 90%;
          margin: 0 auto;
          margin-top: 30px;
          overflow-x: auto;
          margin-bottom: 30px;
        "
      >
        <div style="width: 1400px">
          <div class="round">
            <cub-matchup
              [team1]="bracket.regions[regionIndex].teams[0]"
              [team2]="bracket.regions[regionIndex].teams[3]"
              [canEdit]="canEdit"
              [wrongPicks]="getWrongPicks(regionIndex, 0, 62)"
              (selectedTeam)="teamSelected($event, regionIndex, 1, 63, 1)"
            ></cub-matchup>
            <cub-matchup
              [team1]="bracket.regions[regionIndex].teams[1]"
              [team2]="bracket.regions[regionIndex].teams[2]"
              [canEdit]="canEdit"
              [wrongPicks]="getWrongPicks(regionIndex, 0, 61)"
              (selectedTeam)="teamSelected($event, regionIndex, 1, 63, 2)"
            ></cub-matchup>
          </div>
          <div class="round round-2">
            <cub-matchup
              class="game"
              [round]="2"
              [atTop]="true"
              [team1]="getWinningTeam(regionIndex, 2, 63, 1)"
              [team2]="getWinningTeam(regionIndex, 2, 63, 2)"
              [canEdit]="canEdit"
              [wrongPicks]="getWrongPicks(regionIndex, 1, 63)"
              (selectedTeam)="teamSelected($event, regionIndex, 2, 64, 1)"
            ></cub-matchup>
          </div>
          <img
            *ngIf="getChampLogo() !== ''"
            [src]="getChampLogo()"
            style="width: 200px; margin-right: 10px"
          />
        </div>
      </mat-card>
    </div>
  </div>

  <mat-card
    appearance="outlined"
    *ngIf="isGod"
    class="mat-elevation-z6"
    style="
      width: 90%;
      overflow: elipses;
      margin: 0 auto;
      margin-top: 30px;
    "
  >
    <h5>API data</h5>
    <pre>
      {{ bracket | json }}
    </pre>
  </mat-card>
</form>
