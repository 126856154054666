export class TransferedData {

  listKey: number;
  propertyId: string;
  propertyIdCalpers: string;
  hideProperty: boolean;
  sendGLData: boolean;
  lastAccrualSent: string;
  lastAccrualFilename: string;
  lastDepreciationSent: string;
  lastDepreciationFilename: string;
  lastUID: string;
  lastDt: string;
  warnProperty: boolean;
  areisId: string;
  bucketName: string;

  constructor() { }
}

export class GLCodeMaintenance {

  mappingKey: number;
  glAcctNum: string;
  glAcctDesc: string;
  calPersGLAcctNum: string;
  calPersGLAcctDesc: string;
  isDepreciation: boolean;
  plugAcctNum: string;
  warnGL: boolean;

  constructor() { }
}

export class GlTransfer {

  book = 0;
  month = 0;
  year = 0;
  fileType = '';

  glSentList = [new TransferedData()];
  glCodeList = [new GLCodeMaintenance()];

  constructor() { }
}

export class GLTransferResult {

  success: boolean;
  messages: string[] = [];

  constructor() { }
}
