import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { CurrencyPipe, PercentPipe, NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Investment, InvestmentService } from '..';
import { Constants } from '../../core/constants';
import { ListService } from '../../core/list.service';
import { LoggerService } from '../../core/logger.service';
import { MenuService } from '../../core/menu.service';
import { ListOptions, ListView } from '../../shared/list-control';
import { ListGroup } from '../../shared/list-group/listGroup';
import { ListItem } from '../../shared/list-item/listItem';
import { NumWithCommasPipe } from '../../shared/num-with-commas.pipe';
import { User } from '../../user/user';
import { InvestmentGroupPipe } from './investment-group.pipe';
import { InvestmentListPipe } from './investment-list.pipe';
import { ListFooterComponent } from '../../shared/list-footer/list-footer.component';
import { ListResultComponent } from '../../shared/list-result/list-result.component';
import { ListControlComponent } from '../../shared/list-control/list-control.component';
import { ListControlTopComponent } from '../../shared/list-control-top/list-control-top.component';

@Component({
    selector: 'cub-investment-home',
    templateUrl: './investment-home.component.html',
    styleUrls: ['./investment-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('showingListing', [
            state('true', style({
                width: '319px'
            })),
            state('false', style({
                width: '0'
            })),
            transition('true => false', animate('500ms ease-in')),
            transition('false => true', animate('500ms ease-out'))
        ]),
        trigger('showingListing2', [
            state('true', style({
                gridTemplateColumns: '319px auto'
            })),
            state('false', style({
                gridTemplateColumns: '0 auto'
            })),
            transition('true => false', animate('500ms ease-in')),
            transition('false => true', animate('500ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [ListControlTopComponent, ListControlComponent, NgIf, ListResultComponent, ListFooterComponent, RouterOutlet]
})
export class InvestmentHomeComponent implements OnInit {

  invList: Investment[];
  groups: ListGroup[];
  listItems: ListItem[];
  listViews: ListView[] = [];
  isLoading: boolean;
  currentListOptions: ListOptions;
  myScrollTop: number;
  screenWidth: number;
  showListing = true;
  system = 'investment';
  title = 'Investments';
  optionName = 'Investment';
  footerLabel = 'investments';
  listWidth = Constants.GeneralListWidth;
  showListingComponents = true;

  constructor(
    private investmentService: InvestmentService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private loggerService: LoggerService,
    private listService: ListService,
    private pp: PercentPipe,
    private np: NumWithCommasPipe,
    private cp: CurrencyPipe
  ) {
    this.isLoading = true;
    this.groups = [];
    this.listItems = [];
    this.myScrollTop = 0;
    this.screenWidth = (window.innerWidth);
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.listWidth = 50;
      this.showListing = false;
      this.showListingComponents = false;
    }
  }

  ngOnInit() {
    this.loggerService.logPageVisit('inv home', '');
    this.getViews(this.system);

    setTimeout(() => {
      this.initScreen();
    }, 300);
  }

  onAnimation(event: AnimationEvent) {
    if (event.phaseName === 'done') {
      if (this.showListing) {
        this.showListingComponents = true;
      } else {
        this.showListingComponents = false;
      }
    }
  }

  getViews(system: string) {
    let user: User = JSON.parse(localStorage.getItem('user')) as User;
    this.listViews = user.listViews.filter(v => v.system === system);
    this.currentListOptions = this.listViews.filter(v => v.selected)[0].listOptions; // get selected view's list options
    this.listService.listUpdated(this.currentListOptions);
    console.log('inv getViews', this.currentListOptions);
  }

  initScreen(): void {
    this.getInvestments();
    this.listService.listSettings$.subscribe(listOptions => {
      if (listOptions.name === this.optionName) {
        this.updateList(listOptions);
      }
    });
    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showListing = result;
      if (!this.showListing) {
        this.showListingComponents = false;
      }
    });
  }

  getInvestments(): void {
    this.investmentService.investments$.subscribe(investments => {
      this.invList = investments;
      // this.invList = new InvestmentListPipe().transform(investments, this.invListOptions, '', '');
      this.isLoading = false;
      this.tranformItemsToList();
    });
  }

  goToDetail(item: ListItem): void {
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.menuService.toggleSystemMenu(false);
      setTimeout(() => {
        this.router.navigate([item.itemId], { relativeTo: this.route });
      }, 600);
    } else {
      this.router.navigate([item.itemId], { relativeTo: this.route });
    }
  }

  updateList(listSettings: ListOptions) {
    this.currentListOptions = listSettings;
    this.tranformItemsToList();
  }

  // scroll to item in list
  onScroll(scrollTop: number) {
    setTimeout(() => {
      this.myScrollTop = scrollTop;
    }, 500);
  }

  tranformItemsToList() {
    this.groups = new InvestmentGroupPipe().transform(this.invList, this.currentListOptions);
    let invItems = new InvestmentListPipe().transform(this.invList, this.currentListOptions);
    this.listItems = invItems.map(i => {
      let item = new ListItem();
      item.itemId = i.key.toString();
      item.title1 = i.name;
      item.title2 = this.np.transform(i.totalSF);
      item.title3 = i.location;
      item.title4 = this.cp.transform(i.tac, 'USD', 'symbol', '1.0-0');
      item.active = i.activeItem;
      item.scrollIndex = i.scrollIndex;
      return item;
    });
  }


}
