import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BadDataTicket } from '../bad-data-ticket/badDataTicket';
import { BadDataTicketComponent } from '../../shared/bad-data-ticket/bad-data-ticket.component';
import { BadDataService } from '../bad-data-ticket/bad-data.service';
import { LoggerService } from '../../core/logger.service';
import { faPlus, faBullhorn } from '@fortawesome/pro-light-svg-icons';  // faUsersClass - need to reference pro license to do this
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'cub-bad-data',
    templateUrl: './bad-data.component.html',
    styleUrls: ['./bad-data.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, FormsModule, MatTableModule, MatSortModule, MatButtonModule, DatePipe]
})
export class BadDataComponent implements OnInit {
  tickets: BadDataTicket[] = [];
  filteredTickets: BadDataTicket[] = [];
  displayedColumns: string[] = ['ticketKey', 'systemDesc', 'location', 'description', 'creatorName', 'assignedList', 'creatorDt', 'statusDesc'];
  displayedColumnsLg: string[] = ['ticketKey', 'systemDesc', 'location', 'description', 'creatorName', 'assignedList', 'creatorDt', 'statusDesc'];
  displayedColumnsSm: string[] = ['ticketKey', 'description', 'creatorName', 'assignedList', 'creatorDt', 'statusDesc'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<BadDataTicket>;
  showTableLg = false;
  showResolved = false;
  faPlus = faPlus;
  faBullhorn = faBullhorn;
  mode = '';
  tableKey = 0;


  constructor(
    public dialogRef: MatDialogRef<BadDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, tableKey: number },
    public loadingDialog: MatDialog,
    public dialog: MatDialog,
    private badDataService: BadDataService,
    private loggerService: LoggerService
  ) {
    let getResolvedBit = localStorage.getItem('badDataShowResolved');
    if (getResolvedBit !== null) {
      this.showResolved = (getResolvedBit === 'true');
    }
  }

  ngOnInit() {
    // if (/Android|webOS|iPhone|iPad|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //   this.dialogRef.updateSize('1200px', '700px');  // w,h
    // } else {
    //   this.dialogRef.updateSize('1200px', '700px');  // w,h
    //   // this.dialogRef.updatePosition({ top: '250px', left: '250px' });
    //   // this.dialogRef.updatePosition({ top: '250px'});
    // }

    this.loggerService.logPageVisit('bad data', '');
    this.tableKey = this.data.tableKey;
    this.mode = this.data.mode;
    this.showTheTable();
    this.getTickets();
  }

  applyFilter(filterValue: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showTheTable() {
    if (this.data.mode === 'Property' || this.data.mode === 'Lease') {
      this.displayedColumns = Object.assign([], this.displayedColumnsSm);
    } else {
      this.displayedColumns = Object.assign([], this.displayedColumnsLg);
    }
  }

  getTickets() {
    if (this.tableKey !== undefined && this.tableKey > 0) {
      this.badDataService.getTicketsForSystem(this.mode, this.tableKey).subscribe(result => {
        this.tickets = result;
        this.filterResolved();
      });
    } else {
      this.badDataService.getTickets().subscribe(result => {
        this.tickets = result;
        this.filterResolved();
      });
    }
  }

  filterResolved() {
    if (this.showResolved) {
      this.filteredTickets = this.tickets;
    } else {
      this.filteredTickets = this.tickets.filter(t => t.statusKey === 1);
    }

    this.dataSource = new MatTableDataSource(this.filteredTickets);
    this.dataSource.sort = this.sort;
  }

  editTicket(id: number) {
    let t = this.tickets.find(t => t.ticketKey === id);

    let dialogRef = this.dialog.open(BadDataTicketComponent, {
      width: '675px',
      maxHeight: '700px',
      disableClose: true,
      position: { top: '200px' },
      data: { ticket: t }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'saved') {
        this.getTickets();  // it was resolved so reload the tickets
      }
    });
  }

  toggleResolved(evt: any) {
    this.filterResolved();
    localStorage.setItem('badDataShowResolved', this.showResolved.toString());
  }

  close() {
    this.dialogRef.close();
  }

}
