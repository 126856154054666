import { Component, OnInit, OnChanges, SimpleChanges, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecimalPipe, NgIf, NgFor } from '@angular/common';

import { Investment, InvestmentProperty } from '..';
import { Address } from 'src/app/shared/address';
import { InvestmentService } from '../investment.service';
import { Lookup } from '../../shared/select/lookup';
import { LookupService } from '../../core/lookup.service';
import { MapService } from '../../shared/map/map.service';
import { AlertService } from '../../core/alert.service';
import { SpinnerDialogComponent } from '../../shared/spinner-dialog/spinner-dialog.component';
import { Constants } from '../../core/constants';
import { zoomValidator } from '../../shared/validators/zoom.validator';
import { integerValidator } from '../../shared/validators/integer.validator';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MapComponent } from '../../shared/map/map.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'cub-investment-prop-edit',
    templateUrl: './investment-prop-edit.component.html',
    styleUrls: ['./investment-prop-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatDatepickerModule, MapComponent, MatProgressBarModule, MatButtonModule]
})
export class InvestmentPropEditComponent implements OnInit, OnChanges {

  @Input() investment: Investment;
  @Input() property: InvestmentProperty;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  propForm: UntypedFormGroup;
  oldProp: InvestmentProperty;
  oldPropAddress: Address;

  states: Lookup[] = [];
  greenSt: Lookup[] = [];
  markets: Lookup[] = [];
  regions: Lookup[] = [];
  structureTypes: Lookup[] = [];
  calpersMarkets: Lookup[] = [];
  msas: Lookup[] = [];

  // mapping stuff
  zoomLevel = 18;
  mapStyle = 'satellite';
  mapZoomLevel = 18;
  mapLatitude = 0;
  mapLongitude = 0;
  zoomProgress = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dp: DecimalPipe,
    private lookupService: LookupService,
    private investmentService: InvestmentService,
    private alertService: AlertService,
    private mapService: MapService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.loadLookups();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.oldProp = Object.assign({}, this.property);
    this.oldPropAddress = Object.assign({}, this.property.address);
    this.createForm();
  }

  loadLookups() {
    this.regions = this.lookupService.getTableValues('regions');
    this.greenSt = this.lookupService.getTableValues('greenSt');
    this.markets = this.lookupService.getTableValues('markets');
    this.msas = this.lookupService.getTableValues('msa');
    this.states = this.lookupService.getTableValues('states');
    this.structureTypes = this.lookupService.getTableValues('structureTypes');
    this.calpersMarkets = this.lookupService.getTableValues('calpersMarket');
  }

  createForm() {
    if (this.property.msaKey === 0) {
      this.property.msaKey = null;
    }
    if (this.property.greenStreetRatingKey === 0) {
      this.property.greenStreetRatingKey = null;
    }
    this.propForm = this.fb.group({
      street: new UntypedFormControl(this.property.address.address1, [Validators.required]),
      city: new UntypedFormControl(this.property.address.city, [Validators.required]),
      state: new UntypedFormControl({ value: this.property.address.stateKey, disabled: this.investment.closed }, [Validators.required]),
      zipCode: new UntypedFormControl(this.property.address.zipCode, [Validators.required]),
      market: new UntypedFormControl({ value: this.property.marketKey, disabled: this.investment.closed }, [Validators.required]),
      region: new UntypedFormControl({ value: this.property.regionKey, disabled: this.investment.closed }, [Validators.required]),
      greenSt: new UntypedFormControl({ value: this.property.greenStreetRatingKey, disabled: this.investment.closed }, [Validators.required]),
      zoom: new UntypedFormControl(this.zoomProgress, [Validators.required, zoomValidator]),
      sf: new UntypedFormControl({ value: this.dp.transform(this.property.leasableSF, '1.0-0'), disabled: (this.property.key !== 0 && this.property.unitCount > 1) }, [Validators.required, integerValidator]),
      tac: new UntypedFormControl(this.dp.transform(this.property.tac, '1.0-0'), [Validators.required, integerValidator]),
      purchasePrice: new UntypedFormControl(this.dp.transform(this.property.purchasePrice, '1.0-0'), [Validators.required, integerValidator]),
      type: new UntypedFormControl({ value: this.property.structureTypeKey, disabled: this.investment.closed }, [Validators.required]),
      calpersMarket: new UntypedFormControl(this.property.calpersMarketKey, [Validators.required]),
      msa: new UntypedFormControl({ value: this.property.msaKey, disabled: this.investment.closed }, [Validators.required]),
      closingDt: new UntypedFormControl({ value: (this.property.closingDt === null ? this.investment.closingDt : this.property.closingDt), disabled: this.investment.closed }, [Validators.required])
    });

    console.log('msa', this.property.msaKey);
    if (this.property.key === 0) {
      this.property.address.latitude = Constants.HQLatitude;
      this.property.address.longitude = Constants.HQLongitude;
      this.mapLatitude = Constants.HQLatitude;
      this.mapLongitude = Constants.HQLongitude;
      this.mapStyle = 'roadmap';
      this.zoomLevel = 5;
      this.mapZoomLevel = 5;
    } else {
      console.log('map', this.property.address);
      this.mapLatitude = this.property.address.latitude;
      this.mapLongitude = this.property.address.longitude;
      this.mapZoomLevel = 18;
      this.zoomLevel = 18;
      this.updateZoomProgress();
      this.mapStyle = 'SATELLITE';
    }
    this.zoomProgress = parseInt((parseFloat(this.mapZoomLevel.toString()) / 18.0 * 100.0).toString(), 10);
  }

  delete() {
    let ok = confirm('Are you sure you want to delete this property?');
    if (ok) {
      this.investmentService.deleteInvestmentProperty(this.property.key).subscribe(b => {
        if (b) {
          this.onDelete.emit();
        } else {
          this.alertService.error('Error deleting property');
        }
      });
    }
  }

  cancel() {
    this.oldProp.address = this.oldPropAddress;
    this.onCancel.emit({ prop: this.oldProp });
  }

  updateStateName(id: any) {
    // if (this.property.key !== 0) {
    this.property.address.stateID = this.states.filter(s => s.id === id.value)[0].name;
    // }
  }

  updateMarketName(id: any) {
    // if (this.property.key !== 0) {
    this.property.marketDesc = this.markets.filter(s => s.id === id.value)[0].name;
    // }
  }

  updateRegionName(id: any) {
    // if (this.property.key !== 0) {
    this.property.regionDesc = this.regions.filter(s => s.id === id.value)[0].name;
    // }
  }

  updateGreenStName(id: any) {
    // if (this.property.key !== 0) {
    this.property.greenStreetRatingKeyName = this.greenSt.filter(s => s.id === id.value)[0].name;
    // }
  }

  goToProp() {
    this.router.navigate(['/properties/' + this.property.id], { relativeTo: this.route });
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null ? '' : c2?.toString());
  }

  onMapChange(data: any) {
    this.mapLatitude = data.latitude;
    this.mapLongitude = data.longitude;
    this.mapZoomLevel = data.zoomLevel;
    this.zoomLevel = data.zoomLevel;
    this.updateZoomProgress();
  }

  updateZoomProgress() {
    this.zoomProgress = parseInt((parseFloat(this.mapZoomLevel.toString()) / 18.0 * 100.0).toString(), 10);
    this.propForm.controls.zoom.setValue(this.zoomProgress);
  }

  formToObject() {  // oh yeah we old school
    this.property.desc = this.propForm.controls.street.value;
    this.property.address.address1 = this.propForm.controls.street.value;
    this.property.address.city = this.propForm.controls.city.value;
    this.property.address.stateKey = this.propForm.controls.state.value;
    this.property.address.zipCode = this.propForm.controls.zipCode.value;
    this.property.address.latitude = this.mapLatitude;
    this.property.address.longitude = this.mapLongitude;
    this.property.marketKey = this.propForm.controls.market.value;
    this.property.regionKey = this.propForm.controls.region.value;
    this.property.greenStreetRatingKey = this.propForm.controls.greenSt.value;
    this.property.leasableSF = parseInt(this.propForm.controls.sf.value?.replace(/,/g, ''), 10);
    this.property.tac = parseInt(this.propForm.controls.tac.value?.replace(/,/g, ''), 10);
    this.property.purchasePrice = parseInt(this.propForm.controls.purchasePrice.value?.replace(/,/g, ''), 10);
    this.property.structureTypeKey = this.propForm.controls.type.value;
    this.property.calpersMarketKey = this.propForm.controls.calpersMarket.value;
    this.property.msaKey = this.propForm.controls.msa.value;
    this.property.closingDt = this.propForm.controls.closingDt.value;
  }

  locateOnMap() {
    this.formToObject();
    if (this.property.key === 0) {
      if (this.property.address.address1.length > 0 && this.property.address.city.length > 0) {
        this.findLocation();
      }
    }
  }

  findLocation() {
    this.mapService.findLocation(this.property.address.address1, this.property.address.city, this.property.address.stateID, 'US').subscribe(data => {
      if (data.resourceSets.length > 0) {
        if (data.resourceSets[0].resources.length > 0) {
          this.property.address.latitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
          this.property.address.longitude = data.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
          this.mapLatitude = this.property.address.latitude;
          this.mapLongitude = this.property.address.longitude;
          this.mapZoomLevel = 18;
          this.zoomLevel = 18;
          this.updateZoomProgress();
          this.mapStyle = 'satellite';
        }
      }
    });
  }

  onSubmit() {
    this.formToObject();
    if (this.propForm.valid) {

      let dialogRef = this.dialog.open(SpinnerDialogComponent, {
        disableClose: true,
        width: '450px',
        data: { mode: 'bar', title: 'Saving the Property', content: 'This may take a few seconds please be patient.' }
      });

      this.investmentService.updateInvestmentProperty(this.property).subscribe(newKey => {
        if (newKey > 0) {
          dialogRef.close();
          this.property.key = newKey;
          this.onSave.emit();
        } else {
          this.alertService.error('Error updating property');
          dialogRef.close();
        }
      });
    }
  }

  onTacBlur() {
    let val = this.dp.transform(parseInt(this.propForm.controls.tac.value.replace(/,/g, ''), 10), '1.0-0');
    this.propForm.controls.tac.setValue(val);
  }

  onPurchasePriceBlur() {
    let val = this.dp.transform(parseInt(this.propForm.controls.purchasePrice.value.replace(/,/g, ''), 10), '1.0-0');
    this.propForm.controls.purchasePrice.setValue(val);
  }

  onSfBlur() {
    let val = this.dp.transform(parseInt(this.propForm.controls.sf.value.replace(/,/g, ''), 10), '1.0-0');
    this.propForm.controls.sf.setValue(val);
  }
}
