import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { ListOptions } from '../shared/list-control/listOptions';
import { UserService } from './user.service';


@Injectable()
export class ListService {

  private mainUrl = environment.cubCloudUrl + 'users/views';

  public listSettings: ListOptions;

  private selectedId = new ReplaySubject<string>(1);
  selectedId$ = this.selectedId.asObservable();

  private _listSettings = new ReplaySubject<ListOptions>(1);
  listSettings$ = this._listSettings.asObservable();

  private currentTab = new ReplaySubject<any>(1);
  currentTab$ = this.currentTab.asObservable();

  public scrollToItemInList = true;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  setAsSelected(id: string): void {
    this.selectedId.next(id);
    this.scrollToItemInList = true;
  }

  listUpdated(listData: ListOptions): void {
    this._listSettings.next(listData);
    this.listSettings = listData;
  }

  tabChanged(tab: any): void {
    this.currentTab.next(tab);
  }

  deleteView(id: number): Observable<boolean> {
    const url = `${this.mainUrl}/${id}`;
    return this.http.delete<boolean>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
