
<mat-card appearance="outlined" class="widget-indicator">
  <mat-card-title>
      <div class="indicator" matTooltip="shows how it compares with the rest of the (entire) portfolio">
        <div class="bar"></div>
        <div class="value" [style.marginLeft.px]="calculateLeft()"></div>
      </div>
    {{ header }}
  </mat-card-title>
  <mat-card-subtitle class="color-blue">
    <span *ngIf="valueType === 'string'">{{ valueToShow }}</span>
    <span *ngIf="valueType === 'number'">{{ valueToShow | number:numberFormat }}</span>
    <span *ngIf="valueType === 'currency'">{{ valueToShow | currency:'USD':'symbol':numberFormat }}</span>
    <span *ngIf="unit !== ''" class="unit">{{ unit }}</span>
    <span *ngIf="valueToShow2 !== ''" class="valueMinor">{{ valueToShow2 }}</span>
  </mat-card-subtitle>
</mat-card>
