import { CurrencyPipe, DecimalPipe, NgFor } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Color, HEX } from '../../shared/colors';

@Component({
    selector: 'cub-widget-bar-graph-with-details',
    templateUrl: './widget-bar-graph-with-details.component.html',
    styleUrls: ['./widget-bar-graph-with-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor]
})
export class WidgetBarGraphWithDetailsComponent implements OnChanges {

  @ViewChild('barChart', { static: true }) barChart: ElementRef;
  @Input() title: string;
  @Input() label: string;
  @Input() hexColor: string;
  @Input() data: any[];
  @Input() dataType: string;
  @Input() url: string;

  barHeight = 100;
  dataMax = 0;
  chartWidth = 250;
  dataToShow: string;
  currentBarIndex = 0;
  color: Color;
  highlightColor = '';

  constructor(
    private router: Router,
    private dp: DecimalPipe,
    private cp: CurrencyPipe
  ) { }

  ngOnChanges() {
    if (this.data.length > 0) {
      this.dataMax = this.data.reduce((max, p) => p.value > max ? p.value : max, this.data[0].value);
      this.showOriginal();
    }
    if (this.hexColor !== undefined && this.hexColor !== '') {
      this.color = new Color(new HEX(this.hexColor));
      this.highlightColor = this.color.lighten(50).toString(false);
    }
  }


  getBarSpaceAbove(bar: any): number {
    let ht = 0;
    ht = this.barHeight - ((bar.value / this.dataMax) * this.barHeight);
    return ht;
  }

  getBarHeight(bar: any): number {
    let ht = 0;
    ht = bar.value / this.dataMax * this.barHeight;
    return ht;
  }

  showBarDetails(bar: any, index: number) {
    if (this.dataType === 'currency') {
      this.dataToShow = this.cp.transform(bar.value, 'USD', 'symbol', '1.1-1');
    } else {
      this.dataToShow = this.dp.transform(bar.value, '1.1-1');
    }
    this.currentBarIndex = index;
  }

  showOriginal() {
    if (this.dataType === 'currency') {
      this.dataToShow = this.cp.transform(this.data[0].value, 'USD', 'symbol', '1.1-1');
    } else {
      this.dataToShow = this.dp.transform(this.data[0].value, '1.1-1');
    }
    this.currentBarIndex = 0;
  }

  getHighlightColor(index: number): string {
    return (index === this.currentBarIndex ? this.highlightColor : this.hexColor);
  }

  goToUrl(bar: any): void {
    if (this.url.startsWith('http')) {
      window.open(this.url);
    } else {
      if (this.title === 'Lease Expirations') {
        this.router.navigate([this.url + '20' + bar.label]);
      } else {
        this.router.navigate([this.url]);
      }
    }
  }


}
