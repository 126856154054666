

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-list-control-dialog',
    templateUrl: './list-control-dialog.component.html',
    styleUrls: ['./list-control-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class ListControlDialogComponent implements OnInit {

  viewName: string;
  onlyView = false;

  constructor(
    public dialogRef: MatDialogRef<ListControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.type === 'rename') {
      this.viewName = this.data.view.name;
    }
    if (this.data.type === 'delete') {
      this.viewName = this.data.views.filter(v => v.selected)[0].name;
      if (this.data.views.length === 1) {
        this.onlyView = true;
      } else {
        this.onlyView = false;
      }
    }
  }

  deleteView() {
    this.dialogRef.close({ conclusion: 'deleteSelected' });
  }

  saveNewView() {
    this.dialogRef.close({ conclusion: 'saved', newName: this.viewName });
  }

  renameView() {
    this.dialogRef.close({ conclusion: 'renamed', newName: this.viewName });
  }

  cancel() {
    this.dialogRef.close({ conclusion: 'cancelled' });
  }
}
