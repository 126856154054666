import { Property } from './property';

export class Unit {
  unitKey: number;
  buildingKey: number;
  typeDesc: string;
  commodityRating: string;
  statusDesc: string;
  stateID: string;
  classKey: number;
  propertyKey: number;
  unitID: string;
  propertyID: string;
  propertyId: string;
  formerUnitID: string;
  oldCUBID: string;
  unitDesc: string;
  showingCount: number;
  formerLeaseCount: number;
  pendingLeaseCount: number;
  grossSpace: number;
  leasableSpace: number;
  leasedSpace: number;
  warehouseSpace: number;
  officeSpace: number;
  retailSpace: number;
  otherSpace: number;
  excludableSpace: number;
  excludableNotes: string;
  bayLength: number;
  bayWidth: number;
  extDocks: number;
  intDocks: number;
  clearHt: number;
  driveInDoors: number;
  rail: boolean;
  leasableEqualsGross: boolean;
  excluded: boolean;
  powerAvailability: string;
  sprinklers: string;
  addressKey: number;
  address1: string;
  city: string;
  stateKey: number;
  zipCode: string;
  county: string;
  country: string;
  latitude: number;
  longitude: number;
  creatorUID: string;
  creatorDt: string;
  lastUID: string;
  lastDt: string;
  railDoors: number;
  listOnWeb: boolean;
  typeKey: number;
  statusKey: number;
  fireSprinklers: boolean;
  emergencyLighting: boolean;
  craneCount: number;
  craneSpan: number;
  craneHeight: number;
  craneCapacity: number;
  askingRent: number;
  budgetRent: number;
  onSiteMgrPcKey: number;
  reExecPcKey: number;
  wiredTo: string;
  reportNotes: string;
  expirationNotes: string;
  onSiteMgrName: string;
  reExecName: string;
  arrayHVAC: string[];
  yardiScode: string;
  yardiHMY: number;
  inServiceDt: string;
  outServiceDt: string;
  downTimeMonths: number;
  tripleTrapBasin: boolean;
  isDepartment: boolean;
  tenantKey: number;
  tenantName: string;
  leaseID: string;
  leaseEffectiveDt: string;
  leaseExpirationDt: string;
  lightingTypeKey: number;
  occupancyTypeKey: number;
  occupancyNotes: string;
  suppressListOnWeb: boolean;
  lockboxCode: boolean;
  lightingTypeDesc: string;
  electricalDrawingsFound: boolean;

  // property level fields - need to run a local function to load these into place (used for table view/grid view)
  // propertyDesc: string;
  // categoryDesc: string;
  // entityDesc: string;
  // subEntityDesc: string;
  // ownershipPct: number;
  // propertyTypeDesc: string;
  // groupDesc: string;
  // anchorTenant: string;
  // invTypeDesc: string;
  // commercialClassDesc: string;
  // geoRegionDesc: string;
  //
  // applyPropFields(p: Property) {
  //   this.categoryDesc = p.categoryDesc;
  //   this.entityDesc = p.entityDesc;
  //   this.subEntityDesc = p.subEntityDesc;
  //   this.ownershipPct = p.ownershipPct;
  //   this.propertyTypeDesc = p.typeDesc;
  //   this.groupDesc = p.groupDesc;
  //   this.anchorTenant = p.tenant;
  //   this.invTypeDesc = p.invTypeDesc;
  //   this.commercialClassDesc = p.commercialClassDesc;
  //   this.geoRegionDesc = p.geoRegionDesc;
  // }

  constructor() { }

}
