<form class="brokerPCForm" [formGroup]="fgBroker" autocomplete="off">
  <div>
    <h4>{{ formHeader }}</h4>
    <div class="brokerGridWrapper">

      <div class="brokerFirstName">
        <mat-form-field>
				  <mat-label>First name</mat-label>
          <input matInput  formControlName="firstName" [(ngModel)]="broker.firstName">
          <mat-error *ngIf="!fgBroker.controls.firstName.valid">
            A first name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="brokerLastName">
        <mat-form-field>
				  <mat-label>Last name</mat-label>
          <input matInput  formControlName="lastName" [(ngModel)]="broker.lastName">
          <mat-error *ngIf="!fgBroker.controls.lastName.valid">
            A last name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="brokerCompanyName">
        <mat-form-field>
				  <mat-label>Commpany name</mat-label>
          <input matInput  formControlName="companyName" [(ngModel)]="broker.companyName">
        </mat-form-field>
      </div>

      <div class="brokerAddress">
        <mat-form-field >
				  <mat-label>Enter an address</mat-label>
          <input matInput formControlName="address" [(ngModel)]="broker.companyAddress">
        </mat-form-field>
      </div>

      <div class="brokerCity">
        <mat-form-field>
				  <mat-label>City</mat-label>
          <input matInput  formControlName="city" [(ngModel)]="broker.companyCity">
        </mat-form-field>
      </div>

      <div class="brokerState">
        <mat-form-field >
				  <mat-label>State</mat-label>
          <mat-select  formControlName="state" [(ngModel)]="broker.companyState">
            <mat-option *ngFor="let state of states" [value]="state.name" >{{ state.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="brokerZip">
        <mat-form-field>
				  <mat-label>Zip code</mat-label>
          <input matInput  formControlName="zipCode" [(ngModel)]="broker.companyZip">
        </mat-form-field>
      </div>

      <div class="brokerActions">
          <button mat-raised-button (click)="clickClose()">Close</button>
          <button mat-raised-button color="primary" align="end" [disabled]="!fgBroker.valid" (click)="clickSave()">Save</button>
      </div>
    </div>
  </div>
</form>
