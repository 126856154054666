import { Property } from './../../property/property';
import { CorpEntityService } from './../../corp-entity/corp-entity.service';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../core/alert.service';
import { Lookup } from '../../core/lookup';
import { CorporateEntityProperty } from './../../corp-entity/corp-entity-property';
import { MatButtonModule } from '@angular/material/button';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'cub-prop-picker',
    templateUrl: './prop-picker.component.html',
    styleUrls: ['./prop-picker.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, MatIconModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgClass, MatButtonModule, NgIf]
})
export class PropPickerComponent implements OnInit {
  fg: UntypedFormGroup;
  allProps: CorporateEntityProperty[] = [];
  filteredProps: CorporateEntityProperty[];
  selectedPropertyId: string;
  selectedPropertyKey: number;
  classSearchMode: number;   // 1=operating, 2=devs, 3=inv prospects
  searchTerm$ = new Subject<string>();
  ClassModeVals: Lookup[] = [];
  searchText: string;
  myScrollTop: number;
  isSearchMode: boolean;
  matchingProperty: CorporateEntityProperty;
  showInvestments: boolean;
  showSold: boolean;
  showDevelopments: boolean;


  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<string>,
    private corpEntityService: CorpEntityService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isSearchMode = data.isSearchMode;
    this.showInvestments = data.showInvestments;
    this.showSold = data.showSold;
    this.showDevelopments = data.showDevelopments;
  }


  ngOnInit() {
    this.searchText = '';
    this.myScrollTop = 0;
    this.selectedPropertyKey = 0;
    this.classSearchMode = 1;
    this.buildPropertyList();
    this.createForm();
    this.loadLookupValues();
    this.onClassModeChange();
    this.onSearchTermChange();
  }

  buildPropertyList() {
    this.corpEntityService.getProperties().subscribe(result => {
      this.allProps = result;
      let arr = this.allProps;
      this.filterProps();
    });
  }

  createForm() {
    this.fg = this.fb.group({
      searchText: new UntypedFormControl(this.searchText),
      selProperty: new UntypedFormControl(this.selectedPropertyKey),
      classSearchMode: new UntypedFormControl(this.classSearchMode)
    });

    this.fg.controls.classSearchMode.setValue(this.classSearchMode.toString(), { onlySelf: true });
  }

  loadLookupValues() {
    this.ClassModeVals = [];
    this.ClassModeVals.push(new Lookup('1', 'Operating Props'));
    if (this.showDevelopments) {
      this.ClassModeVals.push(new Lookup('2', 'Developments'));
    }
    if (this.showInvestments) {
      this.ClassModeVals.push(new Lookup('3', 'Investment Prospects'));
    }
    if (this.showSold) {
      this.ClassModeVals.push(new Lookup('4', 'Sold Properties'));
    }
  }

  onPropertySelect(propKey: number) {
    this.selectedPropertyKey = propKey;
  }

  onClassModeChange(): void {
    this.fg.get('classSearchMode').valueChanges.subscribe(val => {
      this.classSearchMode = +val;
      this.filterProps();
    });
  }

  onSearchTermChange(): void {
    this.fg.get('searchText').valueChanges.subscribe(val => {
      this.searchText = val;
      this.filterProps();
    });
  }

  filterProps(): void {
    let arr = this.allProps;
    // operating properties
    switch (this.classSearchMode) {
      case 1: {
        arr = arr.filter(p => p.classKey === 1006 || p.classKey === 3005);
        break;
      }
      case 2: {
        arr = arr.filter(p => p.classKey === 3000 || p.classKey === 3001 || p.classKey === 3002 || p.classKey === 3003 || p.classKey === 3004);
        break;
      }
      case 3: {
        arr = arr.filter(p => p.classKey === 1002 || p.classKey === 1003 || p.classKey === 1004 || p.classKey === 1005 || p.classKey === 1009);
        break;
      }
      case 4: {
        arr = arr.filter(p => p.classKey === 1008 || p.classKey === 3007);
        break;
      }
    }

    if (this.searchText !== null && this.searchText.length > 0) {
      arr = arr.filter(p =>
        p.propertyAddress.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1 ||
        p.propertyId.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1);
    }
    this.filteredProps = arr;

  }

  clickClose() {
    if (!this.isSearchMode) {
      this.dialogRef.close({ saved: false, propertyKey: 0 });
    } else {
      this.dialogRef.close({ saved: false, propertyId: '' });
    }

  }

  clickSave() {
    if (!this.isSearchMode) {
      this.dialogRef.close({ saved: true, propertyKey: this.selectedPropertyKey.toString() });
    } else {
      let propId = this.findMatchingPropertyId();
      this.dialogRef.close({ saved: true, propertyId: propId });
    }
  }

  findMatchingPropertyId(): string {
    let propId = '';
    for (const p of this.filteredProps) {
      if (p.propertyKey === this.selectedPropertyKey) {
        propId = p.propertyId;
        break;
      }
    }
    return propId;
  }


}
