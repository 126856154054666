
import { of as observableOf ,  Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


@Injectable()
export class SignalRService {

  public data : string;
  public connectionId : string;
  public bradcastedData: string;

  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl('https://localhost:5001/user')  //https://cubcloud-qa.azurewebsites.net/user
                            .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .then(() => this.getConnectionId())
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addTransferChartDataListener = () => {
    this.hubConnection.on('transferchartdata', (data) => {
      this.data = data;
      console.log(data);
    });
  }

  public getConnectionId = () => {
    this.hubConnection.invoke('getconnectionid').then(
      (data) => {
          console.log('getConnectionId', data);
          this.connectionId = data;
        }
    );
  }

  public broadcastAzureAuthCode = () => {
    this.hubConnection.invoke('broadcastAzureAuthCode', this.data, this.connectionId)
    .catch(err => console.error(err));
  }

  public addBroadcastAzureAuthCodeListener = () => {
    this.hubConnection.on('broadcastAzureAuthCode', (data) => {
      this.bradcastedData = data;
    })
  }

}
