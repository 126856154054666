import { Component } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    standalone: true,
})
export class HomeComponent {
  username: string;
  accountInfo: AccountInfo;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.accountInfo = this.authService.getActiveAccount();
    this.username = this.accountInfo.name;
  }


}
