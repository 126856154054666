<div id="leaseBarneyMetricDefinitions">
  <h2>{{ data.title }}</h2>
  <!--****************************-->
  <!--****************************-->
  <!--BBR Calc Proof here-->
  <!--****************************-->
  <!--****************************-->
  <div *ngIf="data.type === 'BBR'" class="definitionTable">
    <!--blank spacer row -->
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <!-- IRR Definition Row -->
    <div style="font-size: 14px; grid-column-start: span 5;">
      BBR = IRR(Entire Cash Flow, including initial expenses, free rent, collected rent and residual value)
    </div>
    <!--blank spacer row -->
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <!-- Row 1 - Labels -->
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div style="font-weight: bold; text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">Proposed</ng-container>
    </div>
    <div style="font-weight: bold; text-align: right;">Budget</div>
    <div style="font-weight: bold; text-align: right;">Market</div>
    <!-- Row 2 - Appraised Value -->
    <div>&nbsp;</div>
    <div>Prorated Appraised Value ({{ data.leaseBarney.marketQuarterSource }})</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">
        {{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
      </ng-container>
    </div>
    <div style="text-align: right;">
      {{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
    </div>
    <div style="text-align: right;">
      {{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
    </div>
    <!-- Row 3 Commissions -->
    <div>+</div>
    <div>Commissions</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.commissionsActual | number:'1.0-0' | numWithCommas }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.commissionsBudget  | number:'1.0-0' | numWithCommas }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.ttlCommissionsMarket | number:'1.0-0' | numWithCommas }}</div>
    <!-- Row 4 TI -->
    <div>+</div>
    <div>Improvements</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.totalImprovementsActual | number:'1.0-0' | numWithCommas }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.totalImprovementsBudget  | number:'1.0-0' | numWithCommas }}
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.totalImprovementsMarket | number:'1.0-0' | numWithCommas }}
    </div>
    <!-- Row 5 Total Initial Expenses -->
    <div>&nbsp;</div>
    <div style="font-weight: bold;">Total Initial Expenses</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">
        {{ (data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
        data.leaseBarney.commissionsActual +
        data.leaseBarney.totalImprovementsActual) | number:'1.0-0' | numWithCommas }}
      </ng-container>
    </div>
    <div style="text-align: right;">
      {{
        (data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
        data.leaseBarney.commissionsBudget +
        data.leaseBarney.totalImprovementsBudget)  | number:'1.0-0' | numWithCommas }}
    </div>
    <div style="text-align: right;">{{
      (data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
      data.leaseBarney.ttlCommissionsMarket +
      data.leaseBarney.totalImprovementsMarket) | number:'1.0-0' | numWithCommas }}
    </div>
    <!-- Row 6 - blank spacer row -->
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <!-- Row 7 - free rent info -->
    <div>&nbsp;</div>
    <div>Months Free Rent</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.rentAbatementMonthsActual }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.rentAbatementMonthsBudget }}</div>
    <div style="text-align: right;" *ngIf="data.leaseBarney.newOrRenewalLease === 1">{{ data.leaseBarney.newRentAbatementMonthsMarket }}</div>
    <div style="text-align: right;" *ngIf="data.leaseBarney.newOrRenewalLease === 2">{{ data.leaseBarney.renewalRentAbatementMonthsMarket }}</div>
    <!-- Row 8 - initial rent info -->
    <div>&nbsp;</div>
    <div>Initial Rent ($/psf/yr)</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.initialNetRentActual | number:'1.2-2' }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.initialNetRentBudget | number:'1.2-2' }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.initialNetRentMarket | number:'1.2-2' }}</div>
    <!-- Row 9 - lease term info -->
    <div>&nbsp;</div>
    <div>Lease Term (months)*</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.leaseTermMonths | number:'1.0-0' }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.leaseTermMonths | number:'1.0-0' }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.leaseTermMonths | number:'1.0-0' }}</div>
    <!-- Row 9a - downtime info -->
    <ng-container *ngIf="data.leaseBarney.isDowntimeAnalysis === true && data.leaseBarney.isWhatIf === true">
      <div>&nbsp;</div>
      <div>Downtime (months)*</div>
      <div style="text-align: right;">
        <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.downtimeAnalysisActualMonths | number:'1.0-0' }}</ng-container>
      </div>
      <div style="text-align: right;">{{ data.leaseBarney.downtimeAnalysisBudgetMonths | number:'1.0-0' }}</div>
      <div style="text-align: right;">{{ data.leaseBarney.downtimeAnalysisMarketMonths | number:'1.0-0' }}</div>
    </ng-container>
    <!-- Row 10 - rent bump info -->
    <div>&nbsp;</div>
    <div>Bumps (%)</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">see rent schedule</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.budgetedRentGrowth | number:'1.2-2' }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.marketRentEsc | number:'1.2-2' }}</div>
    <!-- Row 11 - residual value info -->
    <div>&nbsp;</div>
    <div>Residual Value**</div>
    <div *ngIf="data.leaseBarney.residualValueActual !== data.leaseBarney.residualValueBudget" style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.residualValueActual | number:'1.0-0' }}&dagger;</ng-container>
    </div>
    <div *ngIf="data.leaseBarney.residualValueActual === data.leaseBarney.residualValueBudget" style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.residualValueActual | number:'1.0-0' }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.residualValueBudget | number:'1.0-0' }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.residualValueMarket | number:'1.0-0' }}</div>
    <!-- Row 12 - BBR -->
    <div>&nbsp;</div>
    <div style="font-weight: bold;">BBR (%)</div>
    <div style="text-align: right;">
      <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.baseBuildingReturnActual | number:'1.2-2' }}</ng-container>
    </div>
    <div style="text-align: right;">{{ data.leaseBarney.baseBuildingReturnBudget | number:'1.2-2' }}</div>
    <div style="text-align: right;">{{ data.leaseBarney.baseBuildingReturnMarket | number:'1.2-2' }}</div>
    <!-- blank spacer row -->
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <!-- Row * - lease term explanation -->
    <div style="font-size: 14px; grid-column-start: span 5;">
      * When calculating BBR for proposed, budget and market, the proposed lease term is used for all three cash flows.
    </div>
    <!-- Row ** - residual value explanation -->
    <div style="font-size: 14px; grid-column-start: span 5;">
      ** Residual Value is calculated using prorated appraised value, growing at 2% per year
    </div>
    <!-- Row dagger - actual residual value !== budget residual value -->
    <div *ngIf="data.leaseBarney.residualValueActual !== data.leaseBarney.residualValueBudget" style="font-size: 14px; grid-column-start: span 5;">
      &dagger; If proposed TI < budgeted TI, the difference is deducted from proposed residual value </div> </div> <!--****************************-->
        <!--****************************-->
        <!--NER Calc Proof here-->
        <!--****************************-->
        <!--****************************-->
        <div *ngIf="data.type === 'EffectiveNetRent'" class="definitionTable">
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- IRR Definition Row -->
          <div style="font-size: 14px; grid-column-start: span 5;">
            <ng-container *ngIf="data.leaseBarney.isWhatIf === false">
              NER = ((Total Net Rent - Improvements - Commissions) / Lease Term) * 12 / Unit Leasable SF
            </ng-container>
            <ng-container *ngIf="data.leaseBarney.isWhatIf === true">
              <ng-container *ngIf="data.leaseBarney.isDowntimeAnalysis === true">
                NER = ((Total Net Rent - Improvements - Commissions) / (Lease Term + Downtime)) * 12 / Unit Leasable SF
              </ng-container>
              <ng-container *ngIf="data.leaseBarney.isDowntimeAnalysis === false">
                NER = ((Total Net Rent - Improvements - Commissions) / Lease Term) * 12 / Unit Leasable SF
              </ng-container>
            </ng-container>
          </div>


          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 1 - Labels -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div style="font-weight: bold; text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">Proposed</ng-container>
          </div>
          <div style="font-weight: bold; text-align: right;">Budget*</div>
          <div style="font-weight: bold; text-align: right;">Market</div>
          <!-- Row 2 - Total Net Rent -->
          <div>&nbsp;</div>
          <div>Total Rent Including Abatements</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.totalRentActual | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.totalRentBudget | number:'1.0-0' | numWithCommas }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.totalRentMarket | number:'1.0-0' | numWithCommas }}</div>
          <!-- Row 3 Improvements -->
          <div>-</div>
          <div>Total Improvements</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.totalImprovementsActual | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            {{ data.leaseBarney.totalImprovementsBudget  | number:'1.0-0' | numWithCommas }}</div>
          <div style="text-align: right;">
            {{ data.leaseBarney.totalImprovementsMarket | number:'1.0-0' | numWithCommas }}</div>
          <!-- Row 4 Commissions -->
          <div>-</div>
          <div>Total Commissions</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.commissionsActual | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            {{ data.leaseBarney.totalNERCommissionsBudget  | number:'1.0-0' | numWithCommas }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.ttlCommissionsMarket | number:'1.0-0' | numWithCommas }}
          </div>
          <!-- Row 5 Total Net Rent -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Total Net Rent</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">
              ${{(data.leaseBarney.totalRentActual -
              data.leaseBarney.commissionsActual -
              data.leaseBarney.totalImprovementsActual) | number:'1.0-0' | numWithCommas }}
            </ng-container>
          </div>
          <div style="text-align: right;">${{(data.leaseBarney.totalRentBudget -
        data.leaseBarney.totalNERCommissionsBudget -
        data.leaseBarney.totalImprovementsBudget)  | number:'1.0-0' | numWithCommas }}
          </div>
          <div style="text-align: right;">${{(data.leaseBarney.totalRentMarket -
      data.leaseBarney.ttlCommissionsMarket -
      data.leaseBarney.totalImprovementsMarket) | number:'1.0-0' | numWithCommas }}
          </div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 6 - lease term -->
          <div>&nbsp;</div>
          <div>Lease Term</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.leaseTermMonths | number:'1.0-0' }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.leaseTermNERBudget | number:'1.0-0' }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.leaseTermMonths | number:'1.0-0' }}</div>
          <!-- Row 6 - lease term -->
          <div>&nbsp;</div>
          <div>Annualize</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">12</ng-container>
          </div>
          <div style="text-align: right;">12</div>
          <div style="text-align: right;">12</div>
          <!-- Row 8 - unit leasable sf -->
          <div>&nbsp;</div>
          <div>Leasable SF</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 9 - NER -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Net Effective Rent</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">${{ data.leaseBarney.nerActual | number:'1.2-2' }}</ng-container>
          </div>
          <div style="text-align: right;">${{ data.leaseBarney.nerBudget | number:'1.2-2' }}</div>
          <div style="text-align: right;">${{ data.leaseBarney.nerMarket | number:'1.2-2' }}</div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row * - lease term explanation -->
          <div style="font-size: 14px; grid-column-start: span 5;">
            * When calculating Net Effective Rent for Budget, the system will always use the budgeted lease term.
          </div>
        </div>

        <!--****************************-->
        <!--****************************-->
        <!--Net Effective Yield Calc Proof here-->
        <!--****************************-->
        <!--****************************-->
        <div *ngIf="data.type === 'NERYield'" class="definitionTable">
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- IRR Definition Row -->
          <div style="font-size: 14px; grid-column-start: span 5;">
            Net Effective Yield = Net Effective Rent x Leasable SF / Prorated Appraised Value
          </div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 1 - Labels -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div style="font-weight: bold; text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">Proposed</ng-container>
          </div>
          <div style="font-weight: bold; text-align: right;">Budget</div>
          <div style="font-weight: bold; text-align: right;">Market</div>
          <!-- Row 1 - NER -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Net Effective Rent</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">${{ data.leaseBarney.nerActual | number:'1.2-2' }}</ng-container>
          </div>
          <div style="text-align: right;">${{ data.leaseBarney.nerBudget | number:'1.2-2' }}</div>
          <div style="text-align: right;">${{ data.leaseBarney.nerMarket | number:'1.2-2' }}</div>
          <!-- Row 2 - leasable sf -->
          <div>&nbsp;</div>
          <div>Leasable SF</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <!-- Row 3 - Appraised Value -->
          <div>&nbsp;</div>
          <div>Prorated Appraised Value ({{ data.leaseBarney.marketQuarterSource }})</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">${{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            ${{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
          </div>
          <div style="text-align: right;">
            ${{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
          </div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 9 - Net Effective Yield -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Net Effective Yield</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.nerYieldActual | number:'1.2-2' }}%</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.nerYieldBudget | number:'1.2-2' }}%</div>
          <div style="text-align: right;">{{ data.leaseBarney.nerYieldMarket | number:'1.2-2' }}%</div>

        </div>
        <!--****************************-->
        <!--****************************-->
        <!--Initial Rent Calc Proof here-->
        <!--****************************-->
        <!--****************************-->
        <div *ngIf="data.type === 'InitialRent'" class="definitionTable">
          <!-- Row * - initial rent explanation -->
          <div style="font-size: 14px; grid-column-start: span 5;">
            Initial rent is calculated by finding the first month where rent is collected. Free rent months are not
            counted.
          </div>
        </div>
        <!--****************************-->
        <!--****************************-->
        <!--Initial Yield Calc Proof here-->
        <!--****************************-->
        <!--****************************-->
        <div *ngIf="data.type === 'InitialYield'" class="definitionTable">
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- IRR Definition Row -->
          <div style="font-size: 14px; grid-column-start: span 5;">
            Initial Yield = Annualized Initial Rent / Total Initial Expenses
          </div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 1 - Labels -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div style="font-weight: bold; text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">Proposed</ng-container>
          </div>
          <div style="font-weight: bold; text-align: right;">Budget</div>
          <div style="font-weight: bold; text-align: right;">Market</div>
          <!-- Row 2 - Initial Net Rent -->
          <div>&nbsp;</div>
          <div>Initial Net Rent (psf/yr)</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">${{ data.leaseBarney.initialNetRentActual | number:'1.2-2' }}</ng-container>
          </div>
          <div style="text-align: right;">${{ data.leaseBarney.initialNetRentBudget | number:'1.2-2' }}</div>
          <div style="text-align: right;">${{ data.leaseBarney.initialNetRentMarket | number:'1.2-2' }}</div>
          <!-- Row 3 - leasable sf -->
          <div>x</div>
          <div>Leasable SF</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <div style="text-align: right;">{{ data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <!-- Row 4 - annualized initial rent -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Annualized Initial Rent</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6"> ${{ data.leaseBarney.initialNetRentActual * data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</ng-container>
          </div>
          <div style="text-align: right;">
            ${{ data.leaseBarney.initialNetRentBudget * data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <div style="text-align: right;">
            ${{ data.leaseBarney.initialNetRentMarket * data.leaseBarney.unitLeasableSF | number:'1.0-0' }}</div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 5 - Appraised Value -->
          <div>&nbsp;</div>
          <div>Prorated Appraised Value ({{ data.leaseBarney.marketQuarterSource }})</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            {{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
          </div>
          <div style="text-align: right;">
            {{ data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) | number:'1.0-0' | numWithCommas }}
          </div>
          <!-- Row 6 Commissions -->
          <div>+</div>
          <div>Commissions</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.commissionsActual | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.commissionsBudget  | number:'1.0-0' | numWithCommas }}
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.ttlCommissionsMarket | number:'1.0-0' | numWithCommas }}
          </div>
          <!-- Row 7 TI -->
          <div>+</div>
          <div>Improvements</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.totalImprovementsActual | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            {{ data.leaseBarney.totalImprovementsBudget  | number:'1.0-0' | numWithCommas }}</div>
          <div style="text-align: right;">
            {{ data.leaseBarney.totalImprovementsMarket | number:'1.0-0' | numWithCommas }}</div>
          <!-- Row 8 Total Initial Expenses -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Total Initial Expenses</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">${{(data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
              data.leaseBarney.commissionsActual +
              data.leaseBarney.totalImprovementsActual) | number:'1.0-0' | numWithCommas }}</ng-container>
          </div>
          <div style="text-align: right;">
            ${{(data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
            data.leaseBarney.commissionsBudget +
            data.leaseBarney.totalImprovementsBudget)  | number:'1.0-0' | numWithCommas }}
          </div>
          <div style="text-align: right;">
            ${{(data.leaseBarney.appraisedMarketValue * (data.leaseBarney.unitLeasableSF / data.leaseBarney.propertyRentableSF) +
            data.leaseBarney.ttlCommissionsMarket +
            data.leaseBarney.totalImprovementsMarket) | number:'1.0-0' | numWithCommas }}
          </div>
          <!--blank spacer row -->
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <!-- Row 9 - Initial Yield -->
          <div>&nbsp;</div>
          <div style="font-weight: bold;">Initial Yield</div>
          <div style="text-align: right;">
            <ng-container *ngIf="data.leaseBarney.statusKey !== 6">{{ data.leaseBarney.initialYieldOnMarketValueActual | number:'1.2-2' }}%</ng-container>
          </div>
          <div style="text-align: right;">{{ data.leaseBarney.initialYieldOnMarketValueBudget | number:'1.2-2' }}%</div>
          <div style="text-align: right;">{{ data.leaseBarney.initialYieldOnMarketValueMarket | number:'1.2-2' }}%</div>


        </div>

    </div>
