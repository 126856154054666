import { Pipe, PipeTransform } from '@angular/core';
import { ListGroupOption, ListOptions, ListSortOption } from '../../shared/list-control';
import { Investment } from '../investment';
import { User } from '../../user/user';
import { Constants } from '../../core/constants';
import * as moment from 'moment';

@Pipe({
    name: 'invListPipe',
    standalone: true
})
export class InvestmentListPipe implements PipeTransform {

  tmp: Investment[] = [];
  transform(invs: Investment[], listOptions: ListOptions): Investment[] {
    this.tmp.length = 0;
    if (listOptions === null) {
      this.tmp.push(...invs);
    } else {
      if (invs != null) {

        // filter operating (this is now being handled in the property list control by sending a different array for operating vs sold)
        let arr = invs; // props.filter(p => listOptions.filters[1].filterItems[0].selected);

        // filter by region
        if (listOptions.filters[0].selected) {
          if (!listOptions.filters[0].filterItems[0].selected) {
            let selRgns = [];
            listOptions.filters[0].filterItems.forEach(filter => {
              if (filter.selected) {
                selRgns.push(filter.name);
              }
            });
            arr = arr.filter(function(i) {
              let found = false;
              i.regions.split(',').forEach(function(ir) {
                selRgns.forEach(function(sr) {
                  if (ir === sr) {
                    found = true;
                    return;
                  }
                });
              });
              return found;
            });
          }
        }

        // filter by status
        if (listOptions.filters[1].selected && !listOptions.filters[1].filterItems[0].selected) { // if filtering by status and all is not selected, then filter
          arr = arr.filter(acq =>
            (listOptions.filters[1].filterItems[1].selected && !acq.archived && acq.statusKey !== Constants.AcqStatusClosed) ||
                    (listOptions.filters[1].filterItems[2].selected && acq.archived) ||
                    (listOptions.filters[1].filterItems[3].selected && acq.statusKey === Constants.AcqStatusClosed && this.getFiscalYear(moment(acq.closingDt).format('MM/DD/YYYY')) === this.getFiscalYear(moment().format('MM/DD/YYYY'))) ||
                    (listOptions.filters[1].filterItems[4].selected && acq.statusKey === Constants.AcqStatusClosed && this.getFiscalYear(moment(acq.closingDt).format('MM/DD/YYYY')) !== this.getFiscalYear(moment().format('MM/DD/YYYY'))));
        }

        // filter whether it's on a pipeline
        if (listOptions.filters[2].selected) {
          arr = arr.filter(p => p.onReportCalEast || p.onReportRevenue || p.onReportITC);
        }

        // filter by my stuff
        if (listOptions.filters[3].selected) {
          if (localStorage.getItem('user') !== null) {
            let me = JSON.parse(localStorage.getItem('user')) as User;
            arr = arr.filter(p => p.cntContacts.filter(c => c === me.pcKey).length > 1);
          }
        }

        // filter by search term
        if (listOptions.searchText !== undefined && listOptions.searchText !== null && listOptions.searchText.length > 0) {
          arr = arr.filter(p => p.name.toLocaleLowerCase().indexOf(listOptions.searchText.toLocaleLowerCase()) !== -1 ||
                                p.location.toLocaleLowerCase().indexOf(listOptions.searchText.toLocaleLowerCase()) !== -1 ||
                                p.broker.toLocaleLowerCase().indexOf(listOptions.searchText.toLocaleLowerCase()) !== -1 ||
                                p.tenant.toLocaleLowerCase().indexOf(listOptions.searchText.toLocaleLowerCase()) !== -1 ||
                                p.projectDesc.toLocaleLowerCase().indexOf(listOptions.searchText.toLocaleLowerCase()) !== -1);
        }

        let mySortField: ListSortOption = listOptions.sortBy.filter(s => s.selected)[0];
        let myGroupField: ListGroupOption = listOptions.groupBy.filter(s => s.selected)[0];
        let currentFiscalYr = (moment().month() <= 5 ? moment().year() : moment().year() + 1).toString();

        if (myGroupField) {
          if (listOptions.selectedGroup && listOptions.selectedGroup.toString().length > 0) { // filter by selected group
            if (myGroupField.fieldName === 'closingDt') {
              arr = arr.filter(property => this.getFiscalYear(property[myGroupField.fieldName]) === listOptions.selectedGroup.toString() ||
                                                                (listOptions.selectedGroup.toString() === currentFiscalYr && property[myGroupField.fieldName] === ''));
            } else {
              arr = arr.filter(p => p[myGroupField.fieldName] === listOptions.selectedGroup);
            }
          }
        }

        // sort - sort by grouping field first (if selected), then sorting field
        arr = arr.sort((item1: any, item2: any) => {
          return this.orderByComparator(item1, item2, mySortField, myGroupField);
        });

        // get index for each property after sorting
        arr.map(function(x, i, a) {
          x.scrollIndex = i;
          return x;
        });

        this.tmp.push(...arr);  // spread syntax https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator

      }
    }
    return this.tmp;
  }

  getFiscalYear(dt: string): string {
    return (moment(dt, 'MM/DD/YYYY').month() <= 5 ? moment(dt, 'MM/DD/YYYY').year() : moment(dt, 'MM/DD/YYYY').year() + 1).toString();
  }

  orderByComparator(a: any, b: any, sortField: ListSortOption, groupField: ListGroupOption): number {
    if (groupField && groupField.fieldName !== '') {
      let o1 = this.getFieldValue(a, groupField.fieldName);
      let o2 = this.getFieldValue(b, groupField.fieldName);
      let p1;
      let p2;
      if (sortField.asc) {
        p1 = this.getFieldValue(a, sortField.fieldName);
        p2 = this.getFieldValue(b, sortField.fieldName);
      } else {
        p1 = this.getFieldValue(b, sortField.fieldName);
        p2 = this.getFieldValue(a, sortField.fieldName);
      }

      if (o1 < o2) { return -1; }
      if (o1 > o2) { return 1; }
      if (p1 < p2) { return -1; }
      if (p1 > p2) { return 1; }
      return 0;
    } else {
      let o1;
      let o2;
      o1 = this.getFieldValue(a, sortField.fieldName);
      o2 = this.getFieldValue(b, sortField.fieldName);

      if (sortField.asc) {
        if (o1 < o2) { return -1; }
        if (o1 > o2) { return 1; }
      } else {
        if (o2 < o1) { return -1; }
        if (o2 > o1) { return 1; }
      }
      return 0;
    }
  }

  getFieldValue(a: Investment, fieldName: string): any {
    if (fieldName === 'totalSF') {
      return a.totalSF;
    } else if (fieldName === 'tac') {
      return a.tac;
    } else if (fieldName === 'statusKey') {
      return a.statusKey;
    } else if (fieldName === 'closingDt') {
      return moment(a.closingDt, 'MM/DD/YYYY').year();
    } else {
      return a[fieldName].toLowerCase();
    }
  }
}
