import { TenantCompanyLease } from './../../tenant-company/tenant-company-lease';
import { Component, OnInit, Inject } from '@angular/core';
import { TenantCompanyService } from '../../tenant-company/tenant-company.service';
import { TenantCompany } from '../../tenant-company/tenant-company';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../core/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NumWithCommasPipe } from "../num-with-commas.pipe";
import { MatButtonModule } from '@angular/material/button';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'cub-tenant-company-parent-lookup-dialog',
    templateUrl: './tenant-company-parent-lookup-dialog.component.html',
    styleUrls: ['./tenant-company-parent-lookup-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatInputModule, MatIconModule, MatFormFieldModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgClass, MatButtonModule, NumWithCommasPipe]
})
export class TenantCompanyParentLookupDialogComponent implements OnInit {
  fg: UntypedFormGroup;
  allCompanies: TenantCompany[] = [];
  filteredCompanies: TenantCompany[] = [];
  selectedCompanyKey: number;
  selectedCompanyName: string;
  searchTerm$ = new Subject<string>();
  searchText: string;
  myScrollTop: number;
  title: string;
  allowCreate: boolean;
  targetLease: TenantCompanyLease;

  constructor(
    public readonly dialogRef: MatDialogRef<TenantCompanyParentLookupDialogComponent>,
    private router: Router,
    private fb: UntypedFormBuilder,
    private tenantCompanyService: TenantCompanyService,
    private np: NumWithCommasPipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.searchText = '';
    this.myScrollTop = 0;
    this.selectedCompanyKey = 0;
    this.title = this.data.title;
    this.allowCreate = this.data.allowCreate;
    if (this.data.targetLease !== undefined) {
      this.targetLease = this.data.targetLease;
    } else {
      this.targetLease = new TenantCompanyLease();
      this.targetLease.tenantName = '';
    }

    this.buildCompanyList();
    this.createForm();
    this.onControlChanges();
  }

  buildCompanyList() {
    this.tenantCompanyService.getTenantCompanies();
    setTimeout(() => {
      this.tenantCompanyService.tenantCompanies$.subscribe(result => {
        this.allCompanies = result;
        this.filterCompanies();
      });
    }, 1000);
  }

  createForm() {
    this.fg = this.fb.group({
      searchText: new UntypedFormControl(this.searchText)
    });
  }

  onControlChanges() {
    this.fg.get('searchText').valueChanges.subscribe(val => {
      this.searchText = val;
      this.filterCompanies();
    });
  }

  onParentSelect(c: TenantCompany) {
    this.selectedCompanyKey = c.key;
    this.selectedCompanyName = c.companyName;
  }

  createTenantCompany() {
    let name = this.searchText;
    if (confirm('Are you sure you want to create a new company named `' + name + '`?')) {
      this.tenantCompanyService.createTenantCompany(name).subscribe(tcKey => {
        if (tcKey !== 0) {
          this.selectedCompanyKey = tcKey;
          this.selectedCompanyName = name;
          this.buildCompanyList();
        } else {
          alert('Sorry, but an error has occurred while creating your company. Please contact MIS.');
        }
      });
    }
  }

  filterCompanies(): void {
    let arr = this.allCompanies;
    if (this.searchText !== null && this.searchText.length > 0) {
      arr = arr.filter(c =>
        c.companyName.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) !== -1
      );
    }
    this.filteredCompanies = arr;
    this.sortList('companyName');
  }

  sortList(col: string) {
    this.filteredCompanies = this.filteredCompanies.sort((item1: any, item2: any) => {
      return this.orderByComparator(item1, item2, col, true);
    });
  }

  orderByComparator(a: any, b: any, sortField: string, asc: boolean): number {

    let o1;
    let o2;
    if (asc) {
      o1 = this.getFieldValue(a, sortField);
      o2 = this.getFieldValue(b, sortField);
    } else {
      o1 = this.getFieldValue(b, sortField);
      o2 = this.getFieldValue(a, sortField);
    }

    if (o1 < o2) { return -1; }
    if (o1 > o2) { return 1; }
    return 0;

  }

  getFieldValue(a: any, fieldName: string): any {
    if (a[fieldName]) {
      return a[fieldName].toLowerCase();
    }
  }

  clickSave() {
    this.dialogRef.close({ saved: true, companyKey: this.selectedCompanyKey, companyName: this.selectedCompanyName });
  }

  clickClose() {
    this.dialogRef.close({ saved: false, companyKey: 0, companyName: '' });
  }

  viewDetails() {
    if (this.selectedCompanyKey !== 0) {
      let url = this.router.createUrlTree(['./tenantCompanies/', this.selectedCompanyKey]);
      window.open(url.toString(), '_blank');
    } else {
      alert('You must select a company before viewing details.');
    }
  }

}
