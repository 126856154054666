
<div>
  <span>{{ seed }}</span>
  <mat-form-field class="full-width">
    <mat-label>Pick a team</mat-label>
    <input type="text"  aria-label="Number" matInput
      [matAutocomplete]="auto" [formControl]="myControl">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optSelected($event)">
      <mat-option *ngFor="let t of filteredTeams | async" [value]="t.name">
        {{t.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
