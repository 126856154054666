
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Contact, ContactSearchParameters } from '../../contact/contact';
import { UserService } from 'src/app/core/user.service';


// This service has to be located here because the picker uses it and we get an error unless we import the contact module.
// But if we import the contact module, then the routing gets all fucked up.  There's probably a better way to solve this but works for now.
@Injectable()
export class ContactService {

  private mainUrl = environment.cubCloudUrl + 'contacts';

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  searchContacts(searchParams: ContactSearchParameters): Observable<Contact[]> {
    const url = `${this.mainUrl}/search`;
    return this.http.post<Contact[]>(url, JSON.stringify(searchParams), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  searchContactsOld(terms: string): Observable<Contact[]> {
    const url = `${this.mainUrl}/search/${terms}`;
    return this.http.get<Contact[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getContactDetail(pcKey: number): Observable<Contact> {
    const url = `${this.mainUrl}/${pcKey}`;
    return this.http.get<Contact>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveNewContact(contact: Contact): Observable<Contact> {
    const url = `${this.mainUrl}/new`;
    return this.http.post<Contact>(url, JSON.stringify(contact), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveContact(contact: Contact): Observable<Contact> {
    const url = `${this.mainUrl}/save`;
    return this.http.post<Contact>(url, JSON.stringify(contact), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
