import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    standalone: true,
    imports: [MatButtonModule]
})
export class PopupComponent {

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>
  ) { }

  done() {
    this.dialogRef.close();
  }
}
