import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumWithCommasPipe } from '../shared/num-with-commas.pipe';
import { SharedModule } from '../shared/shared.module';
import { InvestmentDashboardComponent } from './investment-dashboard/investment-dashboard.component';
import { InvestmentDateSummaryComponent } from './investment-date-summary/investment-date-summary.component';
import { InvestmentDetailComponent } from './investment-detail/investment-detail.component';
import { InvestmentGroupPipe } from './investment-home/investment-group.pipe';
import { InvestmentHomeComponent } from './investment-home/investment-home.component';
import { InvestmentListPipe } from './investment-home/investment-list.pipe';
import { InvestmentNewDialogComponent } from './investment-new-dialog/investment-new-dialog.component';
import { InvestmentNewComponent } from './investment-new/investment-new.component';
import { InvestmentPipelineSummaryComponent } from './investment-pipeline-summary/investment-pipeline-summary.component';
import { InvestmentPropEditComponent } from './investment-prop-edit/investment-prop-edit.component';
import { InvestmentPropListComponent } from './investment-prop-list/investment-prop-list.component';
import { InvestmentRoutingModule } from './investment-routing.module';
import { InvestmentService } from './investment.service';
import { InvestmentRenameComponent } from './investment-rename/investment-rename.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        InvestmentRoutingModule,
        InvestmentDashboardComponent,
        InvestmentHomeComponent,
        InvestmentListPipe,
        InvestmentGroupPipe,
        InvestmentDetailComponent,
        InvestmentPropListComponent,
        InvestmentPropEditComponent,
        InvestmentNewComponent,
        InvestmentNewDialogComponent,
        InvestmentDateSummaryComponent,
        InvestmentPipelineSummaryComponent,
        InvestmentRenameComponent
    ],
    providers: [
        InvestmentService,
        CurrencyPipe,
        DecimalPipe,
        PercentPipe,
        NumWithCommasPipe
    ],
})
export class InvestmentModule { }
