<div class="cub-sys-home">
  <div class="list-control-top">
    <cub-list-control-top [title]="title"
                          [system]="system"
                          [dashboardUrl]="'investments'"
                          [showDashboardIcon]="true"
                          [listViews]="listViews"> </cub-list-control-top>
  </div>
  <div class="cub-sys-main"
       [@showingListing2]="showListing"
       (@showingListing2.start)="onAnimation($event)"
       (@showingListing2.done)="onAnimation($event)">
    <div id="root"
         class="cub-sys-list"
         [@showingListing]="showListing"
         (@showingListing.start)="onAnimation($event)"
         (@showingListing.done)="onAnimation($event)"
         [style.width.px]="listWidth">
      <cub-list-control #control
                        id="header"
                        class="sys-list-header"
                        [dashboardUrl]="'investments'"
                        [listOptions]="currentListOptions"
                        [listViews]="listViews"
                        [system]="system"
                        [title]="title"
                        (controlUpdate)="updateList($event)">
      </cub-list-control>
      <cub-list-result *ngIf="showListingComponents"
                       class="sys-list-container"
                       [items]="listItems"
                       [groups]="groups"
                       [searchText]="currentListOptions.searchText"
                       [isLoading]="isLoading"
                       [scrollTop]="myScrollTop"
                       (onScrollTop)="onScroll($event)"
                       (onSelection)="goToDetail($event)">
      </cub-list-result>
      <cub-list-footer *ngIf="showListingComponents"
                       [items]="listItems"
                       [type]="footerLabel"></cub-list-footer>
    </div>
    <div class="cub-sys-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
