import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Contact, ContactType, PhoneNumber } from '../../contact/contact';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { ContactService } from './../contact-picker/contact.service';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'cub-contact-edit-phone',
    templateUrl: './contact-edit-phone.component.html',
    styleUrls: ['./contact-edit-phone.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, MatButtonModule, FontAwesomeModule]
})
export class ContactEditPhoneComponent implements OnInit {

  @Input() phoneNumber: PhoneNumber;
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() removed: EventEmitter<any> = new EventEmitter();
  originalTypeKey = 0;
  originalPhone = '';
  phoneForm: UntypedFormGroup;
  phoneTypes: Lookup[] = [];
  faTrashAlt = faTrashAlt;
  faEdit = faEdit;
  faSave = faSave;
  confirmDelete = false;

  constructor(
    private lookupService: LookupService,
    private fb: UntypedFormBuilder
    ) {
    }

  ngOnInit() {
    this.loadLookups();
    this.createForm();
  }

  createForm() {
    this.phoneForm = this.fb.group({
      phoneType: new UntypedFormControl(this.phoneNumber.typeKey, [Validators.required]),
      phoneNumber: new UntypedFormControl(this.phoneNumber.number, [Validators.required])
    });
  }

  formToObject() {
    this.phoneNumber.typeKey = this.phoneForm.controls.phoneType.value;
    this.phoneNumber.number = this.phoneForm.controls.phoneNumber.value;
  }

  editItem() {
    this.phoneNumber.editMode = true;
    this.phoneForm.controls.phoneType.setValue(this.phoneNumber.typeKey);
    this.phoneForm.controls.phoneNumber.setValue(this.phoneNumber.number);
    this.originalTypeKey = this.phoneNumber.typeKey;
    this.originalPhone = this.phoneNumber.number;
  }

  cancelItem() {
    this.formToObject();
    if (this.phoneNumber.number.length === 0 && this.phoneNumber.phoneKey <= 0) {
      this.deleteItem();
    } else {
      this.phoneNumber.editMode = false;
      this.phoneNumber.typeKey = this.originalTypeKey;
      this.phoneNumber.number = this.originalPhone;
    }
  }

  deleteItem() {
    this.confirmDelete = false;
    this.removed.emit(this.phoneNumber);
  }

  cancelDelete() {
    this.confirmDelete = false;
  }

  confirmDeletion() {
    this.confirmDelete = true;
  }

  saveItem() {
    if (this.phoneForm.valid) {
      this.phoneNumber.editMode = false;
      this.formToObject();
      this.saved.emit(this.phoneNumber);
    }
  }

  loadLookups() {
    this.phoneTypes = this.lookupService.getTableValues('phoneTypes');
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null || c2 === undefined ? '' : c2.toString());
  }

  getPhoneTypeDesc(): string {
    return this.phoneTypes.filter(t => t.id.toString() === this.phoneNumber.typeKey.toString())[0].name;
  }
}
