import { Component, Input } from '@angular/core';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons/faWarehouse';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent';
import { faMoneyBillAlt } from '@fortawesome/pro-light-svg-icons/faMoneyBillAlt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, DecimalPipe, PercentPipe, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'cub-budget-market-widget',
    templateUrl: './budget-market-widget.component.html',
    styleUrls: ['./budget-market-widget.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, FontAwesomeModule, DecimalPipe, PercentPipe, CurrencyPipe]
})
export class BudgetMarketWidgetComponent {

  @Input() header: string;
  @Input() headerIcon: string;
  @Input() budgetValue: number;
  @Input() marketValue: number;
  @Input() marketVariance: number;
  @Input() priorValue: number;
  @Input() priorVariance: number;
  @Input() valueType = 'string';
  @Input() numberFormat = '';

  faWarehouse = faWarehouse;
  faSackDollar = faSackDollar;
  faChartLine = faChartLine;
  faPercent = faPercent;
  faMoneyBillAlt = faMoneyBillAlt;

}
