import * as moment from 'moment';

export class Broker {
  brokerKey: number;
  firstName: string;
  lastName: string;
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
  isChecked: boolean;
  isDirty: boolean;

  constructor(key?: number) {
    this.brokerKey = 0;
    this.firstName = '';
    this.lastName = '';
    this.companyName = '';
    this.companyAddress = '';
    this.companyCity = '';
    this.companyState = '';
    this.companyZip = '';
    this.isChecked = false;
    this.isDirty = false;
  }
}
