import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sumArray',
    standalone: true
})

export class SumArrayPipe implements PipeTransform {
  transform(items: any[], fieldName: string): any {
    let sum = 0;

    if (items) {
      if (items.length > 0) {
        for (let i of items) {
          // having an issue with rounding so I am multiplying by 100 here
          sum = sum + parseFloat(i[fieldName].replace(/,/g, '').replace(/\$/g, '')) * 100;
        }

        // and dividing by 100 here
        sum = sum / 100;
      }
    }
    return sum;
  }
}
