<form
  id="frmEditContact"
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <div style="margin-top: -10px">
    <div style="display: inline-block; width: 10%">
      <fa-icon [icon]="faUser"
        *ngIf="contact.isPerson"
        class="fa-3x"
        style="vertical-align: sub"
      ></fa-icon>
      <fa-icon
        [icon]="faCopyright"
        *ngIf="!contact.isPerson"
        class="fa-3x"
        style="vertical-align: sub"
      ></fa-icon>
    </div>
    <div style="display: inline-block; width: 90%">
      <h3 title="key: {{ contact.pcKey }}">{{ getTitleName() }}</h3>
    </div>
  </div>
  <hr />
  <div
    *ngIf="contact.isPerson && contact.pcKey > 0"
    style="font-size: 0.9rem; color: #777"
  >
    <span *ngIf="contact.jobTitle !== null && contact.jobTitle !== ''"
      >{{ contact.jobTitle }} at</span
    >
    {{ contact.companyName }}
  </div>
  <hr *ngIf="contact.isPerson && contact.pcKey > 0" />
  <mat-tab-group
    [dynamicHeight]="false"
    [(selectedIndex)]="tabIndex" [mat-stretch-tabs]="false"
    style="margin-top: 20px"
  >
    <mat-tab label="General">
      <div class="tabContent mainGrid">
        <mat-form-field *ngIf="contact.isPerson" class="example-full-width">
				  <mat-label>Salutation</mat-label>
          <mat-select
            [compareWith]="selectCompare"

            formControlName="salutation"
          >
            <mat-option *ngFor="let s of salutations" [value]="s.id">
              {{ s.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactForm.controls['salutation'].invalid"
            >You must enter a salutation</mat-error
          >
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style=""
        >
				  <mat-label>First Name</mat-label>
          <input
            matInput

            formControlName="firstName"
          />
          <mat-error *ngIf="contactForm.controls['firstName'].invalid"
            >You must enter a first name</mat-error
          >
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style=""
        >
				  <mat-label>Middle Name</mat-label>
          <input
            matInput

            formControlName="middleName"
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style=""
        >
				  <mat-label>Last Name</mat-label>
          <input matInput  formControlName="lastName" />
          <mat-error *ngIf="contactForm.controls['lastName'].invalid"
            >You must enter a last name</mat-error
          >
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style="grid-column: 1 / 3"
        >
				  <mat-label>Job Title</mat-label>
          <input matInput  formControlName="jobTitle" />
        </mat-form-field>
        <mat-form-field
          *ngIf="!contact.isPerson"
          class="example-full-width"
          style="grid-column: 1 / 3"
        >
				  <mat-label>Company Name</mat-label>
          <input
            matInput

            formControlName="companyName"
          />
          <mat-error *ngIf="contactForm.controls['companyName'].invalid"
            >You must enter a company name</mat-error
          >
        </mat-form-field>
        <mat-form-field style="grid-column: 3 / 5">
				  <mat-label>Types</mat-label>
          <mat-select

            formControlName="pcTypes"
            multiple
            [compareWith]="comparePCTypes"
          >
            <mat-option *ngFor="let type of pcTypes" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style="grid-column: 1 / 3"
        >
				  <mat-label>Company</mat-label>
          <input
            type="text"

            matInput
            [formControl]="autoCompleteControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let company of filteredCompanies | async"
              [value]="company"
            >
              <span>{{ company.companyName }}</span>
              <small>{{ showLocation(company) }}</small>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="contactForm.controls['company'].invalid"
            >You typed in the box, but didn't select a company</mat-error
          >
          <mat-hint
            >type to search, then select an item in the list</mat-hint
          >
        </mat-form-field>
        <mat-form-field
          *ngIf="contact.isPerson"
          class="example-full-width"
          style="grid-column: 3 / 5"
        >
				  <mat-label>Notes</mat-label>
          <input matInput  formControlName="notes" />
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab label="Addresses {{ getAddressCount() }}">
      <div class="tabContent address-list">
        <div>
          <div>Type</div>
          <div>Street</div>
          <div>City, State</div>
          <div>Zip</div>
          <div>&nbsp;</div>
        </div>
        <div
          *ngFor="let address of contact.addresses"
          (click)="editAddress(address)"
        >
          <div>{{ address.type }}</div>
          <div>{{ address.address1 }}</div>
          <div>{{ address.city }}, {{ address.stateID }}</div>
          <div>{{ address.zipCode }}</div>
          <div style="text-align: right">
            <button
              mat-icon-button
              type="button"
              (click)="removeAddress(address)"
            >
              <fa-icon [icon]="faTrashAlt" class="fa-lg"></fa-icon>
            </button>
          </div>
        </div>
        <button
          mat-raised-button
          type="button"
          (click)="addAddress()"
          style="margin-top: 20px; margin-left: 5px"
        >
          <fa-icon [icon]="faPlus" class="fa-lg"></fa-icon> &nbsp;Add
        </button>
      </div>
    </mat-tab>
    <mat-tab label="Emails {{ getEmailCount() }}">
      <div class="tabContent">
        <cub-contact-edit-email
          *ngFor="let email of contact.emailAddresses"
          [emailAddress]="email"
          (saved)="updateEmailAddress($event)"
          (removed)="removeEmailAddress($event)"
        >
        </cub-contact-edit-email>

        <button
          mat-raised-button
          type="button"
          (click)="addEmail()"
          style="margin-top: 20px; margin-left: 5px"
        >
          <fa-icon [icon]="faPlus" class="fa-lg"></fa-icon> &nbsp;Add
        </button>
      </div>
    </mat-tab>
    <mat-tab label="Phone Numbers {{ getPhoneCount() }}">
      <div class="tabContent">
        <cub-contact-edit-phone
          *ngFor="let phone of contact.phoneNumbers"
          [phoneNumber]="phone"
          (saved)="updatePhone($event)"
          (removed)="removePhone($event)"
        >
        </cub-contact-edit-phone>

        <button
          mat-raised-button
          type="button"
          (click)="addPhone()"
          style="margin-top: 20px; margin-left: 5px"
        >
          <fa-icon [icon]="faPlus" class="fa-lg"></fa-icon> &nbsp;Add
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
  <hr />
  <div style="margin-top: 20px">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="contactForm.pristine"
      class="button-standard"
      style="float: right"
    >
      Save
    </button>
  </div>
</form>
