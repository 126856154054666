import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Contact, ContactType, EmailAddress } from '../../contact/contact';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { ContactService } from './../contact-picker/contact.service';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'cub-contact-edit-email',
    templateUrl: './contact-edit-email.component.html',
    styleUrls: ['./contact-edit-email.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatInputModule, MatButtonModule, FontAwesomeModule]
})
export class ContactEditEmailComponent implements OnInit {

  @Input() emailAddress: EmailAddress;
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() removed: EventEmitter<any> = new EventEmitter();
  originalTypeKey = 0;
  originalEmail = '';
  emailForm: UntypedFormGroup;
  emailTypes: Lookup[] = [];
  faTrashAlt = faTrashAlt;
  faEdit = faEdit;
  faSave = faSave;
  confirmDelete = false;

  constructor(
    private lookupService: LookupService,
    private fb: UntypedFormBuilder
    ) {
    }

  ngOnInit() {
    this.loadLookups();
    this.createForm();
  }

  createForm() {
    this.emailForm = this.fb.group({
      emailType: new UntypedFormControl(this.emailAddress.typeKey, [Validators.required]),
      emailAddress: new UntypedFormControl(this.emailAddress.email, [Validators.required, Validators.email])
    });
  }

  formToObject() {
    this.emailAddress.typeKey = this.emailForm.controls.emailType.value;
    this.emailAddress.email = this.emailForm.controls.emailAddress.value;
  }

  editItem() {
    this.emailAddress.editMode = true;
    this.emailForm.controls.emailType.setValue(this.emailAddress.typeKey);
    this.emailForm.controls.emailAddress.setValue(this.emailAddress.email);
    this.originalTypeKey = this.emailAddress.typeKey;
    this.originalEmail = this.emailAddress.email;
  }

  cancelItem() {
    this.formToObject();
    if (this.emailAddress.email.length === 0 && this.emailAddress.emailKey <= 0) {
      this.deleteItem();
    } else {
      this.emailAddress.editMode = false;
      this.emailAddress.typeKey = this.originalTypeKey;
      this.emailAddress.email = this.originalEmail;
    }
  }

  deleteItem() {
    this.confirmDelete = false;
    this.removed.emit(this.emailAddress);
  }

  cancelDelete() {
    this.confirmDelete = false;
  }

  confirmDeletion() {
    this.confirmDelete = true;
  }

  saveItem() {
    if (this.emailForm.valid) {
      this.emailAddress.editMode = false;
      this.formToObject();
      this.saved.emit(this.emailAddress);
    }
  }

  loadLookups() {
    this.emailTypes = this.lookupService.getTableValues('emailTypes');
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === (c2 === null || c2 === undefined ? '' : c2.toString());
  }

  getEmailTypeDesc(): string {
    return this.emailTypes.filter(t => t.id.toString() === this.emailAddress.typeKey.toString())[0].name;
  }
}
