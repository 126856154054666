<div id="sysSubMenu" [@showState]="animationState" style="z-index: 997;"
      [style.top.px]="topY"
      [style.marginLeft.px]="leftX"
      [style.visibility]="visible"
      [style.height.px]="menuHeight" >
      <div *ngIf="level > 0" (click)="backMenu()" class="menuBack">
        <fa-icon [icon]="faCaretLeft" style="margin-right: 8px;"></fa-icon> Back to Menu
      </div>
  <div *ngFor="let item of menuShowing">
    <div>
      <a *ngIf="item.external && item.subMenu.length === 0" href="#" target="_new" (click)="logLink(item);goToUrl(item.link, false);">
        {{ item.name }}
        <i class="fa fa-external-link"></i>
      </a>
      <a *ngIf="!item.external && item.subMenu.length === 0" [routerLinkActive]="['active']" [routerLink]="item.link" href="#" (click)="hideMenu(item)" >
        {{ item.name }}
      </a>
      <a href="#" *ngIf="!item.separator && item.subMenu.length > 0 && !item.isCustom"
              class="menu-main"
              (click)="menuClick(item)"
              style="cursor: pointer;">
        <div class="icon-center">
          <!-- <fa-icon [icon]="getIcon(menuItem.icon)"
                  class="fas fa-fw"></fa-icon> -->
        </div>
        <span>{{ item.name }}</span>
        <i class="fa fa-caret-right" style="font-size: 15px; margin-left: 14px; margin-top: -4px;"></i>
      </a>
    </div>
  </div>
</div>
