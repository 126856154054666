import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { merge, Observable, ReplaySubject, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ContactService } from '../contact-picker/contact.service';
import { ContactSearchParameters, Contact } from '../../contact/contact';
import { SelectionModel } from '@angular/cdk/collections';

import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-contact-search',
    templateUrl: './contact-search.component.html',
    styleUrls: ['./contact-search.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, NgIf, MatTableModule, MatSortModule, MatCheckboxModule, MatButtonModule, MatMenuModule, FontAwesomeModule]
})
export class ContactSearchComponent implements AfterViewInit {

  displayedColumns: string[] = ['select', 'contact'];
  private searchTerm = new ReplaySubject<string>(1);
  searchTerm$ = this.searchTerm.asObservable();
  data: Contact[] = [];
  searchParams: ContactSearchParameters;
  selection = new SelectionModel<Contact>(false);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filterText = '';
  faCaretDown = faCaretDown;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ContactSearchComponent>,
    private http: HttpClient,
    private contactService: ContactService) { }


  ngAfterViewInit() {
    this.searchParams = new ContactSearchParameters();
    this.searchParams.take = 50;
    this.searchParams.mostRecent = true;
    this.searchParams.includePeople = true;
    this.searchParams.includeCompanies = false;

    merge(this.sort.sortChange, this.searchTerm$)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.searchTerm$.subscribe(s => {
            this.searchParams.text = s;
          });
          return this.contactService.searchContacts(this.searchParams);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.length;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  applyFilter(filterValue: string) {
    this.searchTerm.next(filterValue.trim().toLowerCase());
  }

  cancel(): void {
    this.dialogRef.close({ status: 'cancelled' });
  }

  newContact(isPerson: boolean): void {
    let c = new Contact();
    c.isPerson = isPerson;
    this.dialogRef.close({ status: 'new', contact: c });
  }

  selectionMade(): void {
    this.dialogRef.close({ status: 'selected', contact: this.selection.selected[0] });
  }

}
