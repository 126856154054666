<div id="root">
  <h4>Enterprise Search</h4>
</div>
<mat-tab-group [(selectedIndex)]="tabIndex" [mat-stretch-tabs]="false"
               (selectedTabChange)="search()"
               color="accent">
  <mat-tab label="CUB">
    <div class="waiting"
         *ngIf="isSearching">
      <i class="fa fa-circle-o-notch fa-spin fa-fw fa-lg"></i>
    </div>
    <div class="search-result"
         *ngIf="!isSearching">
      <mat-list>
        <mat-list-item *ngFor="let result of cubSearchResults"
                       (click)="itemClick(result)">
          <mat-icon mat-list-icon><i class="fa {{ result.icon }}"></i></mat-icon>
          <h4 matLine>{{ result.description }}</h4>
          <p matLine> {{ result.type }} </p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-tab>
  <mat-tab label="Files">
    <div class="waiting"
         *ngIf="isSearching">
      <i class="fa fa-circle-o-notch fa-spin fa-fw fa-lg"></i>
    </div>
    <div class="search-result"
         *ngIf="!isSearching">
      <mat-nav-list>
        <mat-list-item *ngFor="let result of fileSearchResults"
                       (click)="openFileLink(result.path)">
          <mat-icon mat-list-icon>
            <fa-icon [icon]="getIcon(result.name)"></fa-icon>
          </mat-icon>
          <a matLine
             target="_new"
             [href]="sanitizeAndFormat(result.location)"
             style="font-weight: 500;">{{ result.name }}</a>
          <a matLine
             target="_new"
             [href]="sanitizeAndFormat(result.location)"
             style="font-size: 12px; color: #777;"> {{ result.path }} </a>
          <a matLine
             target="_new"
             [href]="sanitizeAndFormat(result.path)"
             class="snippet"
             [innerHTML]="result.snippet | safeHtml"></a>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-tab>
</mat-tab-group>
