<mat-accordion [multi]="true" [displayMode]="'flat'" class="inv-summary">
  <mat-expansion-panel [expanded]="true" (opened)="closedPanelState = false" (closed)="closedPanelState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Closed YTD</span>
        <span class="">({{closedDataSource.data.length}})</span>
      </mat-panel-title>
      <mat-panel-description>
        <span *ngIf="closedPanelState">{{ closingTotalTAC | currency:'USD':'symbol':'4.0-0' }} / {{ closingTotalSF | numWithCommas }} sf</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table mat-table #closedTable id="closedTable" [dataSource]="closedDataSource">

      <ng-container matColumnDef="inv_name">
        <th mat-header-cell *matHeaderCellDef>Deal</th>
        <td mat-cell *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <fa-icon [icon]="getDealIcon(row.pipelineGroupKey)" class="fas fa-fw" [matTooltip]="getDealTooltip(row.pipelineGroupKey)"></fa-icon>
              <span title="{{ row.name }}">{{ row.name }}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="inv_tac">
        <th mat-header-cell *matHeaderCellDef>TAC</th>
        <td mat-cell *matCellDef="let row">{{ row.tac | currency:'USD':'symbol':'4.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_sf">
        <th mat-header-cell *matHeaderCellDef>SF</th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">{{ row.totalSF | numWithCommas }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_closing">
        <th mat-header-cell *matHeaderCellDef>Closing</th>
        <td mat-cell *matCellDef="let row">{{ formatClosingDt(row.closingDt) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.key)"></tr>
    </table>
    <div class="tableTotal">
      <span>Totals</span>
      <span>{{ closingTotalTAC | currency:'USD':'symbol':'4.0-0' }}</span>
      <span>{{ closingTotalSF | numWithCommas }}</span>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="true" (opened)="underContractPanelState = false" (closed)="underContractPanelState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Under Contract</span>
        <span class="">({{underContractDataSource.data.length}})</span>
      </mat-panel-title>
      <mat-panel-description>
        <span *ngIf="underContractPanelState">{{ underContractTotalTAC | currency:'USD':'symbol':'4.0-0' }} / {{ underContractTotalSF | numWithCommas }} sf</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table mat-table #closedTable id="underContractTable" [dataSource]="underContractDataSource">
      <ng-container matColumnDef="inv_name">
        <th mat-header-cell *matHeaderCellDef>Deal</th>
        <td mat-cell *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <fa-icon [icon]="getDealIcon(row.pipelineGroupKey)" class="fas fa-fw" [matTooltip]="getDealTooltip(row.pipelineGroupKey)"></fa-icon>
              <span title="{{ row.name }}">{{ row.name }}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="inv_tac">
        <th mat-header-cell *matHeaderCellDef>TAC</th>
        <td mat-cell *matCellDef="let row">{{ row.tac | currency:'USD':'symbol':'4.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_sf">
        <th mat-header-cell *matHeaderCellDef>SF</th>
        <td mat-cell *matCellDef="let row">{{ row.totalSF | numWithCommas }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_closing">
        <th mat-header-cell *matHeaderCellDef>Closing</th>
        <td mat-cell *matCellDef="let row">{{ formatClosingDt(row.closingDt) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.key)"></tr>
    </table>
    <div class="tableTotal">
      <span>Totals</span>
      <span>{{ underContractTotalTAC | currency:'USD':'symbol':'4.0-0' }}</span>
      <span>{{ underContractTotalSF | numWithCommas }}</span>
      <span>&nbsp;</span>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="true" (opened)="loiPanelState = false" (closed)="loiPanelState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>LOI</span>
        <span class="">({{loiDataSource.data.length}})</span>
      </mat-panel-title>
      <mat-panel-description>
          <span *ngIf="loiPanelState">{{ loiTotalTAC | currency:'USD':'symbol':'4.0-0' }} / {{ loiTotalSF | numWithCommas }} sf</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table mat-table #closedTable id="loiTable" [dataSource]="loiDataSource">
      <ng-container matColumnDef="inv_name">
        <th mat-header-cell *matHeaderCellDef>Deal</th>
        <td mat-cell *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <fa-icon [icon]="getDealIcon(row.pipelineGroupKey)" class="fas fa-fw" [matTooltip]="getDealTooltip(row.pipelineGroupKey)"></fa-icon>
              <span title="{{ row.name }}">{{ row.name }}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="inv_tac">
        <th mat-header-cell *matHeaderCellDef>TAC</th>
        <td mat-cell *matCellDef="let row">{{ row.tac | currency:'USD':'symbol':'4.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_sf">
        <th mat-header-cell *matHeaderCellDef>SF</th>
        <td mat-cell *matCellDef="let row">{{ row.totalSF | numWithCommas }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_closing">
        <th mat-header-cell *matHeaderCellDef>Closing</th>
        <td mat-cell *matCellDef="let row">{{ formatClosingDt(row.closingDt) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.key)"></tr>
    </table>
    <div class="tableTotal">
      <span>Totals</span>
      <span>{{ loiTotalTAC | currency:'USD':'symbol':'4.0-0' }}</span>
      <span>{{ loiTotalSF | numWithCommas }}</span>
      <span>&nbsp;</span>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="true" (opened)="underReviewPanelState = false" (closed)="underReviewPanelState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Under Review</span>
        <span class="">({{underReviewDataSource.data.length}})</span>
      </mat-panel-title>
      <mat-panel-description>
          <span *ngIf="underReviewPanelState">{{ underReviewTotalTAC | currency:'USD':'symbol':'4.0-0' }} / {{ underReviewTotalSF | numWithCommas }} sf</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table mat-table #closedTable id="underReviewTable" [dataSource]="underReviewDataSource">
      <ng-container matColumnDef="inv_name">
        <th mat-header-cell *matHeaderCellDef>Deal</th>
        <td mat-cell *matCellDef="let row">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              <fa-icon [icon]="getDealIcon(row.pipelineGroupKey)" class="fas fa-fw" [matTooltip]="getDealTooltip(row.pipelineGroupKey)"></fa-icon>
              <span title="{{ row.name }}">{{ row.name }}</span>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="inv_tac">
        <th mat-header-cell *matHeaderCellDef>TAC</th>
        <td mat-cell *matCellDef="let row">{{ row.tac | currency:'USD':'symbol':'4.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_sf">
        <th mat-header-cell *matHeaderCellDef>SF</th>
        <td mat-cell *matCellDef="let row">{{ row.totalSF | numWithCommas }}</td>
      </ng-container>

      <ng-container matColumnDef="inv_closing">
        <th mat-header-cell *matHeaderCellDef>Closing</th>
        <td mat-cell *matCellDef="let row">{{ formatClosingDt(row.closingDt) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.key)"></tr>
    </table>
    <div class="tableTotal">
      <span>Totals</span>
      <span>{{ underReviewTotalTAC | currency:'USD':'symbol':'4.0-0' }}</span>
      <span>{{ underReviewTotalSF | numWithCommas }}</span>
      <span>&nbsp;</span>
    </div>
  </mat-expansion-panel>
</mat-accordion>
