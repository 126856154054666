
import { Component, OnInit } from '@angular/core';
import { UserMenu } from '../../user/user-menu';
import { MenuService } from '../menu.service';
import { UserService } from '../user.service';
import { LeftMenuButtonComponent } from '../left-menu-button/left-menu-button.component';
import { NgFor } from '@angular/common';


@Component({
    selector: 'cub-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
    standalone: true,
    imports: [NgFor, LeftMenuButtonComponent]
})
export class LeftMenuComponent implements OnInit {

  menuList: UserMenu[] = [];

  constructor(
    private userService: UserService,
  ) {
    // initialize menu so it loads faster
    let menu = JSON.parse(localStorage.getItem("userMenu")) as UserMenu;
    if (menu != null)
      this.menuList = menu?.subMenu[0].subMenu;
  }

  ngOnInit() {
    this.getMenu();
  }

  getMenu(): void {
    this.userService.userMenu$.subscribe((menu) => {
      if (menu != null) {
        this.menuList = menu.subMenu[0].subMenu;
      }
    });
  }


}
