import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

interface Frequency {
  letter: string;
  frequency: number;
}
@Component({
    selector: 'cub-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    standalone: true
})
export class BarChartComponent implements OnChanges {

  @Input() dataArray: any[];
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  private width: number;
  private height: number;
  private margin = { top: 5, right: 5, bottom: 5, left: 5 };

  private x: any;
  private y: any;
  private svg: any;
  private g: any;

  constructor(private container: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initSvg();
    this.initAxis();
    this.drawAxis();
    this.drawBars();
  }

  private initSvg() {
    this.svg = d3.select(this.container.nativeElement).select('svg');
    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;
    this.g = this.svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private initAxis() {
    this.x = d3.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.y = d3.scaleLinear().rangeRound([this.height, 0]);
    this.x.domain(this.dataArray.map((d) => d.label));
    this.y.domain([0, d3.max(this.dataArray, (d) => d.value)]);
  }

  private drawAxis() {
    this.g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(this.x));
    this.g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(this.y).ticks(10, '%'));
  }

  private drawBars() {
    this.g.selectAll('.bar')
      .data(this.dataArray)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => this.x(d.label))
      .attr('y', (d) => this.y(d.value))
      .attr('width', this.x.bandwidth())
      .attr('height', (d) => this.height - this.y(d.value));
  }
}
