import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '../../core/list.service';
import { ListOptions } from './../../shared/list-control/listOptions';
import { Investment, InvestmentDate } from '../investment';
import { InvestmentListPipe } from '../investment-home/investment-list.pipe';
import { InvestmentService } from '../investment.service';

import * as moment from 'moment';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-investment-date-summary',
    templateUrl: './investment-date-summary.component.html',
    styleUrls: ['./investment-date-summary.component.scss'],
    standalone: true,
    imports: [NgIf, MatTableModule, MatSortModule]
})
export class InvestmentDateSummaryComponent implements OnInit {

  allInvestments: Investment[];
  investmentDates: InvestmentDate[] = [];
  todayInvestmentDates: InvestmentDate[] = [];
  weekInvestmentDates: InvestmentDate[] = [];
  monthInvestmentDates: InvestmentDate[] = [];
  threeMonthInvestmentDates: InvestmentDate[] = [];
  displayedColumns = ['inv_dt', 'inv_name'];
  todayDataSource = new MatTableDataSource();
  weekDataSource = new MatTableDataSource();
  monthDataSource = new MatTableDataSource();
  threeMonthDataSource = new MatTableDataSource();
  filterSettings: ListOptions;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private investmentService: InvestmentService,
    private listService: ListService
  ) { }

  ngOnInit() {
    this.listService.listSettings$.subscribe(listSettings => {
      if (listSettings.name === 'Investment') {
        this.filterSettings = JSON.parse(JSON.stringify(listSettings)) as ListOptions;
        this.retrieveDates();
      }
    });
    this.investmentService.investments$.subscribe(result => {
      this.allInvestments = result;
      this.retrieveDates();
    });
  }

  retrieveDates() {
    let investments = new InvestmentListPipe().transform(this.allInvestments, this.filterSettings);
    this.investmentDates = [];
    for (let i = 0; i < investments.length; i++) {
      let inv = investments[i];
      this.investmentDates.push(new InvestmentDate(inv.key, inv.aaDt, 'AA Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.itcDt, 'ITC Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.giPreviewDt, 'GI Preview Dat', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.icDt, 'IC Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.boardDt, 'Board Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.closingDt, 'Closing Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.ddStartDt, 'Due Dil Start Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.ddEndDt, 'Due Dil End Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.prelimEsgDt, 'Preliminary ESG Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.finalEsgDt, 'Final ESG Date', inv.name));
      this.investmentDates.push(new InvestmentDate(inv.key, inv.addedToPipelineDt, 'Added to Pipeline Date', inv.name));
    }

    this.todayInvestmentDates = JSON.parse(JSON.stringify(this.investmentDates));
    this.todayInvestmentDates = this.todayInvestmentDates.filter((elem, index, arr) =>
      elem.dateVal !== undefined && moment(elem.dateVal).isAfter(moment().subtract(1, 'days')) && moment(elem.dateVal).isBefore(moment().add(1, 'days'))
    );
    this.todayInvestmentDates.sort((a, b) => {
      let aBefore = moment(a.dateVal).isBefore(b.dateVal);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.todayDataSource.data = this.todayInvestmentDates;

    this.weekInvestmentDates = JSON.parse(JSON.stringify(this.investmentDates));
    this.weekInvestmentDates = this.weekInvestmentDates.filter((elem, index, arr) =>
      elem.dateVal !== undefined && moment(elem.dateVal).isAfter(moment()) && moment(elem.dateVal).isBefore(moment().add(7, 'days'))
    );
    this.weekInvestmentDates.sort((a, b) => {
      let aBefore = moment(a.dateVal).isBefore(b.dateVal);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.weekDataSource.data = this.weekInvestmentDates;

    this.monthInvestmentDates = JSON.parse(JSON.stringify(this.investmentDates));
    this.monthInvestmentDates = this.monthInvestmentDates.filter((elem, index, arr) =>
      elem.dateVal !== undefined && moment(elem.dateVal).isAfter(moment().add(7, 'days')) && moment(elem.dateVal).isBefore(moment().add(30, 'days'))
    );
    this.monthInvestmentDates.sort((a, b) => {
      let aBefore = moment(a.dateVal).isBefore(b.dateVal);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.monthDataSource.data = this.monthInvestmentDates;

    this.threeMonthInvestmentDates = JSON.parse(JSON.stringify(this.investmentDates));
    this.threeMonthInvestmentDates = this.threeMonthInvestmentDates.filter((elem, index, arr) =>
      elem.dateVal !== undefined && moment(elem.dateVal).isAfter(moment().add(30, 'days')) && moment(elem.dateVal).isBefore(moment().add(90, 'days'))
    );
    this.threeMonthInvestmentDates.sort((a, b) => {
      let aBefore = moment(a.dateVal).isBefore(b.dateVal);
      if (aBefore) {
        return -1;
      } else {
        return 1;
      }
    });
    this.threeMonthDataSource.data = this.threeMonthInvestmentDates;
  }

  formatDate(dateString: string): string {
    return moment(dateString).format('MMM Do');
  }

  formatDateAsDay(dateString: string): string {
    return moment(dateString).format('ddd');
  }

  goToDetail(key: number): void {
    this.router.navigate([key], { relativeTo: this.route });
  }
}

