import { Injectable } from '@angular/core';
import { AlertService } from './../core/alert.service';
import { CubFormControl } from './cub-form-control';
import { UntypedFormGroup } from '@angular/forms';
import { ChangePacket } from './changePacket';
import { SaveService } from './save.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { DecimalPipe, DatePipe, PercentPipe, CurrencyPipe } from '@angular/common';

@Injectable()
export class InputHelperService {

constructor(
  private saveService: SaveService,
  private alertService: AlertService,
  private datePipe: DatePipe,
  private decimalPipe: DecimalPipe,
  private currencyPipe: CurrencyPipe,
  private percentPipe: PercentPipe
) { }

  scrubInputValueForSave(val: any, dataType: string) {
    let retVal = null;
    try {
      if (val !== undefined) {
        switch (dataType) {
          case 'percent':
            if (val !== null) {
              let stringVal = val.toString();
              retVal = stringVal.replace(/\D/g, '');
              let numVal = parseFloat(retVal);
              retVal = (numVal / 100).toString();
            } else {
              retVal = 0;
            }
            break;
          case 'money':
          case 'double':
          case 'psfPerYear':
          case 'psfPerMonth':
            if (val !== null) {
              let stringVal = val.toString();
              retVal = stringVal.replace(/\$/g, '').replace(/\,/g, '');
            } else {
              retVal = 0;
            }
            break;
          default:
            retVal = val.toString();
            break;
        }
      }
    } catch (err) {
      // TODO — Handler errros better?
      // console.log(err);
    } finally {
      return retVal;
    }
  }

    saveDirtyControl(controls: any, myFormGroup: UntypedFormGroup) {
      // loop through controls in formgroup to determine which is dirty.
      let list = Object.keys(controls);

      for (const i of list) {
        let myCtrl = myFormGroup.get(i) as CubFormControl;
        if (myCtrl.dirty && myCtrl.valid) {
          let currentValue = myCtrl.value;
          let scrubbedValue = this.scrubInputValueForSave(currentValue, myCtrl._cubDataType);
          this.formatInputtedValue(myCtrl, scrubbedValue);
          this.saveControlChanges(myCtrl, scrubbedValue);
          myCtrl.markAsPristine();
          break;
        }
      }
  }

  formatInputtedValue(myCtrl: CubFormControl, val: string) {
    let formatType = myCtrl._cubDataType;
    let formattedValue = myCtrl.value;
    switch (formatType) {
      case 'int':
        formattedValue = this.decimalPipe.transform(val, '1.0-0');
        break;
      case 'double':
        formattedValue = this.decimalPipe.transform(val, '1.0-2');
        break;
      case 'decimal':
        formattedValue = this.decimalPipe.transform(val, '1.0-6');
        break;
      case 'percent':
        let numVal = parseFloat(val);
        formattedValue = this.percentPipe.transform(numVal / 100, '1.0-2');
        break;
      case 'money':
        formattedValue = this.currencyPipe.transform(val, 'USD');
        break;
      case 'datetime':
        formattedValue = this.datePipe.transform(val, 'M/d/yyyy');
        break;
      case 'psfPerYear':
        formattedValue = this.decimalPipe.transform(val, '1.0-3');
        break;
      case 'psfPerMonth':
        formattedValue = this.decimalPipe.transform(val, '1.0-6');
        break;
      case 'year':
        formattedValue = val;
        break;
    }
    myCtrl.setValue(formattedValue, {emitEvent: false});
  }


  saveControlChanges(ctrl: CubFormControl, val: string): void {
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(ctrl._cubTableName, ctrl._cubFieldName, ctrl._cubFieldDesc, ctrl._cubItemKey, ctrl._cubPreviousValue, val, val, ctrl._cubDataType, ctrl._cubDataLength);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        ctrl._cubPreviousValue = ctrl.value;
      })
      .catch((err) => this.handleError(err, this.alertService));
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + errMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

}
