<div class="user-detail">
  <div [class]="getOfficePicClass()"></div>
  <div class="header">
    <img class="user-photo"
         [src]="getImageUrl()" />
    <div class="user-title">
      <div>{{ user.firstName + ' ' + user.lastName }}</div>
      <div> {{ user.jobTitle }} <button mat-raised-button
                [matMenuTriggerFor]="adminMenu"> Admin <fa-icon [icon]="faCaretDown"
                   style="font-size: 1.0rem; margin-left: 5px; color: #aaa;"></fa-icon>
        </button>
        <mat-menu #adminMenu="matMenu"
                  [yPosition]="'below'"
                  [xPosition]="'before'"
                  [overlapTrigger]="false">
          <button mat-menu-item
                  (click)="reloadPermissions();">Reload Permissions</button>
          <button mat-menu-item
                  (click)="reloadLocalCache();">Reload Local Cache</button>
          <hr style="margin: 0;" />
          <button *ngIf="isTimesheetGod()"
                  mat-menu-item
                  (click)="removeUser();">Remove User</button>
        </mat-menu>
        <button mat-raised-button
                (click)="openWebProfile();">Web Profile</button>
        <button *ngIf="thisIsMe()"
                mat-raised-button
                (click)="openTimesheet();">Timesheet</button>
      </div>
    </div>
  </div>
  <mat-tab-group [selectedIndex]="tabIndex" [mat-stretch-tabs]="false"
                 [mat-stretch-tabs]="false">
    <mat-tab label="Details">
      <div class="user-body">
        <div style="padding: 0;">
          <div class="user-cnt-detail-header">
            <img src="../../../assets/img/cnt-shield-blue-56w.png" />
            <span class="years">{{ formatYear(yearsOfService) }}</span>
            <span class="months"
                  *ngIf="remainderMonthsOfService > 0">{{ getRemainderMonthsOfService() }}</span>
            <span class="mothsTotal"
                  *ngIf="remainderMonthsOfService > 0">12</span>
            <span class="year-label">years of service</span>
          </div>
          <div class="user-cnt-detail-body">
            <div>
              <span>Department:</span>
              <span>{{ user.department }}</span>
            </div>
            <div>
              <span>Office:</span>
              <span>{{ user.officeLocation }}</span>
            </div>
            <div>
              <span>Birthday:</span>
              <span>{{ formatBirthday(user.birthday) }}</span>
            </div>
            <div>
              <span>Hire Dt:</span>
              <span>{{ formatDt(user.hireDt) }}</span>
            </div>
            <div>
              <span>Email:</span>
              <span>{{ user.workEmail }}</span>
            </div>
            <div>
              <span>Phone:</span>
              <span>{{ getPhone() }}</span>
            </div>
            <div>
              <span>Is Employee:</span>
              <mat-checkbox [disabled]="!isTimesheetGod()"
                            [(ngModel)]="user.isRealUser"
                            (change)="changeEmployeeStatus($event)"></mat-checkbox>
            </div>
          </div>
        </div>
        <div>
          <div class="user-panel-title">Today's Schedule</div>
          <div class="user-panel-schedule">
            <div *ngFor="let availabilityCode of userSchedule; let i = index;"
                 class="user-panel-schedule-time">
              <div><span *ngIf="showTime(i)">{{ getTimeForIterator(i) }}</span>
              </div>
              <div [class]="getTimeClass(availabilityCode)"> {{ getTextFromAvailabilityCode(availabilityCode) }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="user.webBio !== ''">
          <div class="user-panel-title">Bio</div>
          <div class="user-bio">{{ user.webBio }}</div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Bonus Calculator"
             *ngIf="canViewBonusCalc()">
      <div style="margin: 20px; margin-top: 35px;">
        <div
             style="display: inline-block; width: 250px; font-size: 14px; margin-right: 20px; vertical-align: top; color: #444;">
          Please enter your salary and bonus percentage in order to calculate your annual bonus amount. </div>
        <mat-form-field appearance="fill"
                        floatLabel="always">
          <mat-label>Salary</mat-label>
          <span matPrefix>$&nbsp;</span>
          <input matInput
                 class="user-salary"
                 [ngModel]="salary | number:'1.0-0'"
                 (ngModelChange)="salaryChanged($event)">
        </mat-form-field>
        <mat-form-field appearance="fill"
                        floatLabel="always"
                        style="margin-left: 30px;">
          <mat-label>Bonus %</mat-label>
          <input matInput
                 class="user-bonus"
                 [(ngModel)]="bonusPct"
                 (ngModelChange)="calculateBonus()">
        </mat-form-field>
      </div>
      <table id="tblBonus"
             class="tbl-bonus mat-elevation-z2"
             style="margin: 20px;">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th colspan="4">FY Goal Metrics</th>
            <th colspan="2">Employee</th>
            <th colspan="2">Based on <span class="headerVal">${{ salary | number:'1.0-0' }}</span> and <span
                    class="headerVal">{{ bonusPct | number:'1.0-0' }}%</span>
            </th>
          </tr>
          <tr>
            <th>Goal Description</th>
            <th>Threshold</th>
            <th>Target</th>
            <th>Maximum</th>
            <th>{{actualOrForecast}}</th>
            <th>Weight</th>
            <th>Bonus Earned</th>
            <th>Target</th>
            <th>{{actualOrForecast}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let goal of user.departmentalGoals | goalSort; let i = index;">
            <td style="text-align: left;">
              <div style="display: inline-block; height: 10px; width: 10px; margin-right: 10px; margin-left: 5px; margin-top: -2px; vertical-align: middle;"
                   [style.background]="colors[i]">&nbsp;</div> {{ goal.description }}
              <span class="goal-category">({{goal.goalMeasure }})</span>
              <!-- <span class="goal-category">({{goal.category }})</span> -->
            </td>
            <td>{{ goal.threshold | number:getFormat(goal.formatString) }}</td>
            <td>{{ goal.target | number:getFormat(goal.formatString)}}</td>
            <td>{{ goal.maximum| number:getFormat(goal.formatString) }}</td>
            <td>{{ goal.forcastedValue| number:getFormat(goal.formatString) }}</td>
            <td>{{ goal.weight * 100 }}%</td>
            <td>{{ goal.bonusEarnedPctTarget * 100 | number:'1.0-0' }}%</td>
            <td [style.background]="getHoverBg('target', i)"
                [style.color]="getHoverColor('target', i)">${{ goal.bonusTarget | number:'1.0-0'}}</td>
            <td [style.background]="getHoverBg('actual', i)"
                [style.color]="getHoverColor('actual', i)">${{ goal.bonusActual | number:'1.0-0'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="6">Totals</th>
            <td>{{ totalBonusEarnedPctTarget | number:'1.0-0' }}%</td>
            <td>${{ totalTarget | number:'1.0-0' }}</td>
            <td>${{ totalActual | number:'1.0-0' }}</td>
          </tr>
        </tfoot>
      </table>
      <div class="mat-elevation-z2"
           style="margin: 20px; border: solid 1px #ddd; padding: 10px;">
        <div class="barGraph">
          <div class="barSectionLabel"> Target </div>
          <ng-container *ngFor="let goal of user.departmentalGoals | goalSort; let i = index;">
            <div class="barSection"
                 [style.background]="colors[i]"
                 [style.width.%]="(goal.bonusTarget / (totalActual * 1.3)) * 100"
                 (mouseover)="setCurrentHover('target' + i.toString())"
                 (mouseleave)="resetCurrentHover()"> ${{ goal.bonusTarget | number:'1.0-0' }} </div>
          </ng-container>
          <div class="barSectionTotal"> ${{ totalTarget | number:'1.0-0' }} </div>
        </div>
        <div class="barGraph"
             style="margin-bottom: 30px;">
          <div class="barSectionLabel"> {{actualOrForecast}} </div>
          <ng-container *ngFor="let goal of user.departmentalGoals | goalSort; let i = index;">
            <div class="barSection"
                 [style.background]="colors[i]"
                 [style.width.%]="(goal.bonusActual / (totalActual * 1.3)) * 100"
                 (mouseover)="setCurrentHover('actual' + i.toString())"
                 (mouseleave)="resetCurrentHover()"> ${{ goal.bonusActual | number:'1.0-0' }} </div>
          </ng-container>
          <div class="barSectionTotal"> ${{ totalActual | number:'1.0-0' }} </div>
        </div>
      </div>
      <div style="margin: 20px;">
        <div style="margin-top: 12px; font-size: 14px; font-style: italic;">
          <p>* Represents an average of the key tasks for a given department, not individual tasks. The score is an
            estimate and is based on a full year of employment. The final score will be updated after the fiscal year
            close.</p>
        </div>
        <div style="margin-top: 12px; font-size: 14px; font-style: italic;">
          <p>Note: <i>Target</i> and <i>actual</i> bonus amounts, which are gross amounts, do not take into account:
            taxes, 401(k) contributions, or any other deductions you may have.</p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
