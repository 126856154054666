<div class="employee-list-container">
  <div class="employee-list-header">
    <div style="display: inline-block;">
      <h3>Employees</h3>
    </div>
    <div class="employee-list-filter">
      <mat-checkbox (change)="employeeOnly($event)"
                    style="padding-right: 8px; margin-right: 20px;"
                    [(ngModel)]="employeesOnly">Employees Only</mat-checkbox>
      <div>
        <mat-form-field>
				  <mat-label>Departments</mat-label>
          <mat-select
                      [(ngModel)]="selDepartment"
                      (selectionChange)="refreshTable();">
            <mat-option [value]="'All'">
              <fa-icon [icon]="faLayerGroup"
                       class=""></fa-icon>All
            </mat-option>
            <mat-option *ngFor="let dep of departments"
                        [value]="dep">
              <fa-icon [icon]="getDeptIcon(dep)"
                       class=""></fa-icon>{{ dep }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
				  <mat-label>Offices</mat-label>
          <mat-select
                      [(ngModel)]="selOffice"
                      (selectionChange)="refreshTable();">
            <mat-option [value]="'All'">
              <fa-icon [icon]="faLayerGroup"
                       class=""></fa-icon>All
            </mat-option>
            <mat-option *ngFor="let office of offices"
                        [value]="office">
              {{ office }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
				  <mat-label>Search</mat-label>
          <input matInput

                 [(ngModel)]="searchText"
                 (keyup)="refreshTable()">
        </mat-form-field>
      </div>
      <div>
        <span style="margin-right: 40px;">Show</span>
        <mat-button-toggle-group name="fontStyle"
                                 appearance="legacy"
                                 aria-label="Font Style"
                                 [(ngModel)]="listMode"
                                 [hideSingleSelectionIndicator]="true"
                                 [hideMultipleSelectionIndicator]="true"
                                 #group="matButtonToggleGroup">
          <mat-button-toggle value="badge"
                             (click)="listMode = 'badge';">
            <fa-icon [icon]="faIdBadge"
                     class="fa-lg"></fa-icon>
          </mat-button-toggle>
          <mat-button-toggle value="list"
                             (click)="listMode = 'list'; refreshTable();">
            <fa-icon [icon]="faListAlt"
                     class="fa-lg"></fa-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>


  <div class="employee-list-badge"
       *ngIf="listMode === 'badge'">
    <cub-user-badge *ngFor="let user of users | userListFilter:selDepartment:selOffice:searchText:employeesOnly"
                    [user]="user"
                    (click)="selectUser(user)"></cub-user-badge>
  </div>

  <table id="tblEmployees"
         *ngIf="listMode === 'list'"
         mat-table
         matSort
         [dataSource]="tblDataSource"
         style="width: 100%;">
    <ng-container matColumnDef="image">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 4%;">
      </th>
      <td mat-cell
          *matCellDef="let user">
        <img [src]="getUrl(user)"
             class="user-image" />
      </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 6%;"
          mat-sort-header>
        First
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ user.firstName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 10%;"
          mat-sort-header>
        Last
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ user.lastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 30%;"
          mat-sort-header>
        Title
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ user.jobTitle }}
      </td>
    </ng-container>
    <ng-container matColumnDef="department">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 20%;"
          mat-sort-header>
        Department
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ user.department }}
      </td>
    </ng-container>
    <ng-container matColumnDef="officeLocation">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 20%;"
          mat-sort-header>
        Office
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ user.officeLocation }}
      </td>
    </ng-container>
    <ng-container matColumnDef="hireDt">
      <th mat-header-cell
          *matHeaderCellDef
          style="width: 10%;"
          mat-sort-header>
        Hired
      </th>
      <td mat-cell
          *matCellDef="let user">
        {{ formatDt(user.hireDt) }}
      </td>
    </ng-container>
    <tr mat-header-row
        *matHeaderRowDef="tblColumns"></tr>
    <tr mat-row
        *matRowDef="let user; columns: tblColumns;"
        (click)="selectUser(user)"></tr>
  </table>

</div>
