export class PortfolioSummary {
  name: string;
  totalNumber: number;
  totalSF: number;
  totalValue: number;
  data: PortfolioSummary[];

  constructor() {
    this.data = [];
  }
}
