
import { UntypedFormControl } from '@angular/forms';

export function integerValidator(input: UntypedFormControl) {
  let isValid = true;

  // custom validator for checking if the value is an integer with optional thousands separator 1234 or 1,234
  // tried ALOT of regex validators and it wasn't working 100% even created my own '\d{1,3}|,\d{3}' still no dice
  if (input.value) {
    // let's remove any commas and then check if it's an integer
    let checkNum = Number(input.value.replace(/,/g, ''));
    isValid = Number.isInteger(checkNum);

    // one caveat (that I know of) to this is 1,,,2 would be the valid number 12
    // so I will compare the input.value to both the formattedNum and the checkNum
    // it has to equal one or the other to be a valid integer of my specifications
    let formattedNum = checkNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (input.value !== formattedNum && input.value !== checkNum.toString()) {
      isValid = false;
    }

    // lastly I don't want any decimal values so if it has a . set to invalid
    // 1 = 1.00 and is a valid integer in ECMAScript 2015
    if (input.value.indexOf('.') > 0) {
      isValid = false;
    }
  }

  // adds error 'IsInvalidNumber' to the control if the condition 'isValid' is false
  return isValid ? null : { IsInteger: true };
}
