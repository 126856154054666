import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-spinner-dialog',
    templateUrl: './spinner-dialog.component.html',
    styleUrls: ['./spinner-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, NgIf, MatProgressSpinnerModule, MatProgressBarModule]
})
export class SpinnerDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}

