<h2 mat-dialog-title>Rename Deal</h2>
<mat-dialog-content>
  <div style="display: grid; grid-template-columns: 60% 20% 20%; column-gap: 20px; width: 510px;">
    <mat-form-field>
      <mat-label>New Name</mat-label>
      <input type="text"
             matInput
             [(value)]="newName"
             (keyup)="keyup($event)">
    </mat-form-field>
    <mat-form-field>
      <mat-label>City</mat-label>
      <input type="text"
             matInput
             [(value)]="city"
             readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>State</mat-label>
      <input type="text"
             matInput
             [(value)]="stateId"
             readonly>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 100%;">
      <mat-label>Directory Name</mat-label>
      <input type="text"
             matInput
             [value]="newDirectoryPath"
             readonly>
      <mat-hint>(special characters will be removed)</mat-hint>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: block; margin-top: 20px; width: 100%;">
  <button mat-button
          mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-flat-button
          color="primary"
          style="float: right;"
          (click)="clickRename()"
          [mat-dialog-close]="false">Rename</button>
</mat-dialog-actions>
