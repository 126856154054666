import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SearchService } from '../search.service';
import { SearchResult, FileSearchResult } from '../searchResult';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { environment } from 'src/environments/environment';

import { faFile, faFileExcel, faFileWord, faFilePdf, faEnvelope, faFileLines } from '@fortawesome/pro-light-svg-icons';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';
@Component({
    selector: 'cub-search-result-popup',
    templateUrl: './search-result-popup.component.html',
    styleUrls: ['./search-result-popup.component.scss'],
    standalone: true,
    imports: [NgIf, MatTabsModule, MatListModule, NgFor, MatIconModule, FontAwesomeModule, MatButtonModule]
})
export class SearchResultPopupComponent implements OnInit {

  show = false;
  cubSearchResults: SearchResult[];
  fileSearchResults: FileSearchResult[];
  spSearchResults: SearchResult[];
  tabIndex: number;
  isSearching: boolean;

  faFile = faFile;
  faFileExcel = faFileExcel;
  faFileWord = faFileWord;
  faFilePdf = faFilePdf;
  faEnvelope = faEnvelope;
  faFileLines = faFileLines;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.tabIndex = 0;
    this.isSearching = false;
  }

  ngOnInit() {
    this.searchService.isSearching$.subscribe(result => {
      this.isSearching = result;
    });

    this.searchService.showSearchPopup$.subscribe(result => {
      if (this.router.url.indexOf('/search') > -1) {  // don't show popup if we are on search page
        this.show = false;
      } else {
        this.show = result;
      }
    });

    this.searchService.searchResults$.subscribe(results => {
      let resultType: string;
      this.cubSearchResults = results;
      this.isSearching = false;
    });

    this.searchService.fileSearchResults$.subscribe(results => {
      this.fileSearchResults = results;
      this.isSearching = false;
    });
  }

  search(event: MatTabChangeEvent) {
    let sys: string;
    switch (this.tabIndex) {
      case 0:
        sys = 'ID';
        break;
      case 1:
        sys = 'File';
        break;
    }
    this.isSearching = true;
    this.searchService.searchBySystem(sys, '')
      .subscribe(results => {
        this.searchService.mapResult(results);
      });
  }

  hideSearch(): void {
    this.searchService.closeSearch();
  }

  itemClick(item: SearchResult) {
    this.searchService.closeSearch();
    if (!item.externalLink) {
      this.router.navigate([item.url], { relativeTo: this.route });
    } else {
      window.open(item.url);
    }
  }

  navigateToSearch() {
    this.searchService.closeSearch();
    this.router.navigate(['search'], { relativeTo: this.route });
  }

  openFileLink(path: string) {
    window.open(environment.egnyteUrl + path.toLowerCase().replace('/shared/', ''));
  }

  getIcon(filename: string) {
    switch (filename.substring(filename.lastIndexOf('.') + 1)) {
      case 'txt':
        return faFile;
        break;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
        break;
      case 'doc':
      case 'docx':
        return faFileWord;
        break;
      case 'pdf':
        return faFilePdf;
        break;
      case 'msg':
        return faEnvelope;
        break;
      case 'txt':
        return faFileLines;
        break;
      default:
        return faFile;
        break;
    }
  }

}
