import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'cub-graph-line',
    templateUrl: './graph-line.component.html',
    styleUrls: ['./graph-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class GraphLineComponent implements OnInit, OnChanges {
  @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
  @Input() data: any[];
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() chartName: string;
  @Input() leftColors: string[] = [];
  @Input() rightColors: string[] = [];
  @Input() yLabelPrefix: string;
  @Input() yLabelSuffix: string;
  @Input() y2LabelPrefix: string;
  @Input() y2LabelSuffix: string;
  @Input() showSecondYAxis: boolean;

  private width: number;
  private height: number;
  private margin: any = { top: 20, bottom: 20, left: 20, right: 20};
  private chart: any;
  private xScale: any;
  private yScale: any;
  private xAxis: any;
  private yAxis: any;
  alreadyLoaded: boolean;

  private svg: any;
  private g: any;

  constructor() { }

  ngOnInit() {
    this.alreadyLoaded = false;
  }

  ngOnChanges(changes: any) {
    this.initMargins();
    if (this.data.length > 0) {
      if (!this.alreadyLoaded || d3.select('#' + this.chartName + ' > *').size() > 0) {
        this.createChart();
      }
      // console.log('data changed', changes, this.data);
    }
  }

  private initMargins() {
    this.margin = {top: 20, right: 80, bottom: 50, left: 100};
  }

  createChart() {
    this.alreadyLoaded = true;
    d3.select('#' + this.chartName + ' > *').remove();

    let parseTime = d3.timeParse('%m/%d/%Y');

    this.data = this.data.map(function(d) {
      return {
        date: d.date.toString().length === 10 ? parseTime(d.date) : d.date,
        val: d.val,
        val2: d.val2
      };
    });

    const element = this.chartContainer.nativeElement;
    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;

    this.svg = d3.select(element)
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 ' + (this.width + this.margin.left + this.margin.left) + ' ' + (this.height + this.margin.top + this.margin.bottom))
      .classed('svg-content', true);

    this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // define X & Y domains and min/max
    const xDomain = [this.data[0].date, this.data[this.data.length - 1].date];
    let minX = d3.min(this.data, d => d3.min(d.val, dv => dv)); // d3.min called twice here because of nested array
    minX = minX - (Math.abs(minX) * .15);
    let x = d3.scaleTime().domain(xDomain).rangeRound([0, this.width]);

    let maxY1 = d3.max(this.data, d => d3.max(d.val, dv => dv)); // d3.max called twice here because of nested array
    maxY1 = maxY1 + (maxY1 * .15);
    const yDomain = [minX, maxY1];
    let y = d3.scaleLinear().domain(yDomain).range([this.height, 0]);

    let y2 = null;
    if (this.showSecondYAxis && this.data[0].val2?.length > 0) {
      let maxY2 = d3.max(this.data, d => d3.max(d.val2, dv => dv)); // d3.max called twice here because of nested array
      maxY2 = maxY2 + (maxY2 * .15);
      const y2Domain = [minX, maxY2];
      y2 = d3.scaleLinear().domain(y2Domain).range([this.height, 0]);
    }

    // make axes
    let quarter = function(date, i) {
      let q = Math.ceil((date.getMonth()) / 3 ) + 1;
      return (q === 1 ? date.getFullYear() : '');
    };

    this.g.append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .call(d3.axisBottom(x).ticks(d3.timeMonth.every(3)).tickFormat(quarter));

    this.g.append('g')
      .call(d3.axisLeft(y).tickFormat(d => this.yLabelPrefix + d + this.yLabelSuffix))
      .attr('class', 'axis axis-y')
      .attr('transform', `translate(0, 0)`);

    if (this.showSecondYAxis && this.data[0].val2?.length > 0) {
      this.g.append('g')
        .call(d3.axisRight(y2).tickFormat(d => this.y2LabelPrefix + d + this.y2LabelSuffix))
        .attr('class', 'axis axis-y')
        .attr('transform', `translate(` + this.width + `, 0)`);
    }

    // make lines (left axis)
    for (let i = 0; i < this.data[0].val.length; i++) {
      let line = d3.line().curve(d3.curveMonotoneX)
        .x(function(d: any) {
          return x(d.date);
        })
        .y(function(d: any) {
          return y(d.val[i]);
        });

      this.g.append('path')
        .datum(this.data)
        .attr('fill', 'none')
        .attr('stroke', this.leftColors[i])
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', .5)
        .attr('d', line);
    }
    // right axis
    if (this.showSecondYAxis && this.data[0].val2?.length > 0) {
      for (let i = 0; i < this.data[0].val2.length; i++) {
        let line = d3.line().curve(d3.curveMonotoneX)
          .x(function(d: any) {
            return x(d.date);
          })
          .y(function(d: any) {
            return y2(d.val2[i]);
          });

        this.g.append('path')
          .datum(this.data)
          .attr('fill', 'none')
          .attr('stroke', this.rightColors[i])
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', .5)
          .attr('d', line);
      }
    }
  }
}
