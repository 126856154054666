import { ListFilter, ListGroupOption, ListSortOption } from '.';

export class ListOptions {
  name: string;
  filterAvailable: boolean;
  searchAvailable: boolean;
  showFilter: boolean;
  showSearch: boolean;
  filters: ListFilter[];
  sortBy: ListSortOption[];
  groupBy: ListGroupOption[];
  searchText: string;
  selectedGroup: string;
  viewName: string;

  constructor() {
    this.filters = [];
    this.sortBy = [];
    this.groupBy = [];
  }
}
