import { faBalanceScaleRight } from '@fortawesome/pro-light-svg-icons/faBalanceScaleRight';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { UserMenu } from '../../user/user-menu';
import { MenuService } from '../menu.service';
import { SubMenuService } from '../sub-menu.service';
import { LoggerService } from '../logger.service';
import { Constants } from '../constants';

import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faShovel } from '@fortawesome/pro-light-svg-icons/faShovel';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faCalculatorAlt } from '@fortawesome/pro-light-svg-icons/faCalculatorAlt';
import { faCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator';
import { faRecycle } from '@fortawesome/pro-light-svg-icons/faRecycle';
import { faUserTag } from '@fortawesome/pro-light-svg-icons/faUserTag';
import { faHandsUsd } from '@fortawesome/pro-light-svg-icons/faHandsUsd';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faAnalytics} from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons/faEllipsisHAlt';
import { faLeaf } from '@fortawesome/pro-light-svg-icons';
import { faFileChartPie } from '@fortawesome/pro-light-svg-icons/faFileChartPie';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faUsdSquare } from '@fortawesome/pro-light-svg-icons/faUsdSquare';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons/faQuestionSquare';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-left-menu-button',
    templateUrl: './left-menu-button.component.html',
    styleUrls: ['./left-menu-button.component.scss'],
    animations: [
        trigger('showingListing', [
            state('true', style({
                transform: 'rotate(0)'
            })),
            state('false', style({
                transform: 'rotate(90deg)'
            })),
            transition('true => false', animate('500ms ease-in')),
            transition('false => true', animate('500ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink, FontAwesomeModule]
})
export class LeftMenuButtonComponent implements OnInit {

  @Input() menuItem: UserMenu;
  showSystemList = true;
  minMaxLeftMenu: boolean;
  screenWidth: number;

  faHome = faHome;
  faBuilding = faBuilding;


  constructor(
    private router: Router,
    private subMenuService: SubMenuService,
    private loggerService: LoggerService,
    private menuService: MenuService
  ) {
    this.screenWidth = (window.innerWidth);
  }

  ngOnInit() {
    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showSystemList = result;
    });
  }

  menuClick(event: MouseEvent) {
    this.subMenuService.updateMenu(this.menuItem.name, event.pageY, 179, this.menuItem.subMenu);
    this.loggerService.logPageVisit('left menu clicked', this.menuItem.name);
  }

  hideMenu() {
    this.loggerService.logPageVisit('left menu clicked', this.menuItem.name);
    this.subMenuService.hideMenu();
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.menuService.toggleLeftMenu(false);
    }
  }

  toggleSystemList(): void {
    this.menuService.toggleSystemMenu(!this.showSystemList);
  }

  getIcon(icon: string): any {
    let fa = faHome;
    switch (icon) {
      case 'faHome':
        fa = faHome;
        break;
      case 'faBuilding':
        fa = faBuilding;
        break;
      case 'faFileContract':
        fa = faFileContract;
        break;
      case 'faShovel':
        fa = faShovel;
        break;
      case 'faForklift':
        fa = faForklift;
        break;
      case 'faCalculator':
        fa = faCalculator;
        break;
      case 'faRecycle':
        fa = faRecycle;
        break;
      case 'faUserTag':
        fa = faUserTag;
        break;
      case 'faHandsUsd':
        fa = faHandsUsd;
        break;
      case 'faUser':
        fa = faUser;
        break;
      case 'faAnalytics':
        fa = faAnalytics;
        break;
      case 'faEllipsisHAlt':
        fa = faEllipsisHAlt;
        break;
      case 'faLeaf':
        fa = faLeaf;
        break;
      case 'faFileChartPie':
        fa = faFileChartPie;
        break;
      case 'faLink':
        fa = faLink;
        break;
      case 'faBooks':
        fa = faBooks;
        break;
      case 'faUsdSquare':
        fa = faUsdSquare;
        break;
      case 'faQuestionSquare':
        fa = faQuestionSquare;
        break;

      default:
        break;
    }
    return fa;
  }

  goToUrl(url: string, localRoute: boolean): void {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = url.replace('http://cub/', 'https://cub.centerpoint.com/');
      newUrl = newUrl.replace('http://cubapi/', 'https://cub.centerpoint.com/');
    }
    if (localRoute) {
      this.router.navigate([newUrl]);
    } else {
      window.open(newUrl);
    }
  }
}
