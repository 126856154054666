
import { Contact } from '../../contact/contact';

import { OverlayRef } from '@angular/cdk/overlay';
import { Subject ,  Observable } from 'rxjs';

export class ContactPickerOverlayRef {

  private selectedPcKey = new Subject<Contact>();
  private savedPcKey = new Subject<Contact>();
  private cancelledPcKey = new Subject<Contact>();
  constructor(private overlayRef: OverlayRef) { }

  save(c: Contact): void {
    this.savedPcKey.next(c);
    this.overlayRef.dispose();
  }

  cancel(c: Contact): void {
    this.cancelledPcKey.next(c);
    this.overlayRef.dispose();
  }

  select(c: Contact): void {
    this.selectedPcKey.next(c);
  }

  afterSaved(): Observable<Contact> {
    return this.savedPcKey.asObservable();
  }

  afterCancelled(): Observable<Contact> {
    return this.cancelledPcKey.asObservable();
  }

  onSelection(): Observable<Contact> {
    return this.selectedPcKey.asObservable();
  }
}
