import { Pipe, PipeTransform } from '@angular/core';
import { QuarterlyMetric } from './quarterlyMetric';

@Pipe({
    name: 'mostRecentYear',
    standalone: true
})
export class QtrSystemDataPipe implements PipeTransform {

  transform(qs: QuarterlyMetric[]): any {
    return qs?.sort((a: QuarterlyMetric, b: QuarterlyMetric) => {
      if (a.year < b.year) {
        return 1;
      } else if (a.year > b.year) {
        return -1;
      } else {
        if (a.quarter < b.quarter) {
          return 1;
        } else if (a.quarter > b.quarter) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

}
