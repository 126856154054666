import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Address } from 'src/app/shared/address';
import { UserService } from 'src/app/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private cloudUrl = environment.cubCloudUrl + 'address';

  constructor(
      private http: HttpClient,
      private userService: UserService
      ) { }


  save(address: Address): Observable<Address> {
    const url = `${this.cloudUrl}/${address.addressKey}`;
    return this.http.put<Address>(url, JSON.stringify(address), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
