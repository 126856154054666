<form [formGroup]="newInvForm"
      (ngSubmit)="onSubmit()"
      novalidate>
  <div class="inv-new-header">
    <h3>New Investment</h3>
  </div>
  <div class="inv-new-body">
    <div class="inv-grid">
      <div style="grid-column: 1 / 2;">
        <mat-slide-toggle formControlName="portfolio"
                          [(ngModel)]="isPortfolio"
                          (change)="portToggle()"
                          style="margin-top: 20px;">Is Portfolio</mat-slide-toggle>
        <mat-form-field class="example-full-width"
                        *ngIf="isPortfolio"
                        style="margin-top: 20px;">
				  <mat-label>Portfolio Name</mat-label>
          <input matInput

                 formControlName="name">
          <mat-error *ngIf="newInvForm.controls['name'].invalid">You must enter a value</mat-error>
        </mat-form-field>
        <br />
      </div>
      <mat-card appearance="outlined" style="grid-column: 1 / 2;">
        <mat-card-title> Details <span *ngIf="isPortfolio"
                class="title-warning"> Please enter the information below for the first property in the portfolio. You
            can enter the remaining properties in the following screen. </span>
        </mat-card-title>
        <mat-card-content>
          <div class="content-detail">
            <mat-form-field *ngIf="!isPortfolio"
                            class="example-full-width"
                            style="grid-column: 1 / 5;">
				      <mat-label>Street Address</mat-label>
              <input matInput

                     formControlName="name">
              <mat-error *ngIf="newInvForm.controls['name'].invalid"
                         (blur)="locateOnMap()">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isPortfolio"
                            class="example-full-width"
                            style="grid-column: 1/5;">
				      <mat-label>Street Address</mat-label>
              <input matInput

                     formControlName="street">
              <mat-error *ngIf="newInvForm.controls['street'].invalid"
                         (blur)="locateOnMap()">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1/3;">
				      <mat-label>City</mat-label>
              <input matInput

                     required
                     formControlName="city"
                     (blur)="locateOnMap()">
              <mat-error *ngIf="newInvForm.controls['city'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
				      <mat-label>State</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="state"
                          (selectionChange)="locateOnMap()">
                <mat-option *ngFor="let state of states"
                            [value]="state.id"> {{ state.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['state'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
				      <mat-label>Zip Code</mat-label>
              <input matInput

                     required
                     formControlName="zipCode"
                     (blur)="locateOnMap()">
              <mat-error *ngIf="newInvForm.controls['zipCode'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1/3;">
				      <mat-label>CNT Market</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="market">
                <mat-option *ngFor="let market of markets"
                            [value]="market.id"> {{ market.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['market'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 3/5;">
				      <mat-label>CalPERS Market</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="calpersMarket">
                <mat-option *ngFor="let type of calpersMarkets"
                            [value]="type.id"> {{ type.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['type'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1/3;">
				      <mat-label>MSA</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="msa">
                <mat-option *ngFor="let msa of msas"
                            [value]="msa.id"> {{ msa.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['msa'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 3/5;">
				      <mat-label>Region</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="region">
                <mat-option *ngFor="let region of regions"
                            [value]="region.id"> {{ region.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['region'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 1/3;">
				      <mat-label>Property Type</mat-label>
              <mat-select [compareWith]="selectCompare"

                          formControlName="type">
                <mat-option *ngFor="let type of structureTypes"
                            [value]="type.id"> {{ type.name }} </mat-option>
              </mat-select>
              <mat-error *ngIf="newInvForm.controls['type'].invalid">You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width"
                            style="grid-column: 3/5;">
				      <mat-label>Price</mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input matInput

                     formControlName="price">
              <mat-error *ngIf="newInvForm.controls['price'].invalid">You must enter a number</mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined" style="grid-column: 2 / 3; grid-row: 2 / 3;">
        <mat-card-title> Map <span class="title-warning"
                style="width: 150px;"> Upon entering the address, please confirm the location. </span>
        </mat-card-title>
        <mat-card-content>
          <cub-map [latitude]="invProp.address.latitude"
                   [longitude]="invProp.address.longitude"
                   [zoom]="zoomLevel"
                   [mapStyle]="mapStyle"
                   [mapHeight]="330"
                   (mapViewChange)="onMapChange($event)"></cub-map>
          <br />
          <span class="zoom-note">Please zoom in enough to locate the exact spot</span>
          <mat-progress-bar mode="determinate"
                            value="{{zoomProgress}}"
                            style="margin-bottom: 10px; margin-top: 10px;"></mat-progress-bar>
          <input matInput
                 formControlName="zoom"
                 style="display: none;"
                 required>
          <mat-error *ngIf="newInvForm.controls['zoom'].invalid">You will need to zoom in a little more</mat-error>
          <span class="zoom-note"
                *ngIf="zoomProgress >= 100">You did it!</span>
        </mat-card-content>
      </mat-card>
      <div style="grid-column: 1 / 2; margin-top: 10px;">
        <button mat-button
                color="primary"
                (click)="cancel()">Cancel</button>
        <button mat-raised-button
                color="primary"
                type="submit"
                [disabled]="newInvForm.pristine"
                style="float: right;">Create</button>
      </div>
    </div>
  </div>
</form>
