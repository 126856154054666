
export class ConfirmDialogData {
  title: string;
  message: string;
  cancelText: string;
  submitText: string;

  constructor(t: string, m: string, c: string, s: string) {
    this.title = t;
    this.message = m;
    this.cancelText = c;
    this.submitText = s;
  }
}
