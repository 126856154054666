import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ChangePacket } from '../changePacket';
import { forkJoin } from 'rxjs';
import { SaveService } from '../save.service';
import { DataColumn } from 'src/app/core/dataColumn';
import { LookupService } from 'src/app/core/lookup.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import * as moment from 'moment';
import { CurrencyPipe, DecimalPipe, NgFor, NgIf } from '@angular/common';
import { SpinnerDialogComponent } from '../spinner-dialog/spinner-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'cub-change-prompt',
    templateUrl: './change-prompt.component.html',
    styleUrls: ['./change-prompt.component.scss'],
    standalone: true,
    imports: [NgFor, MatInputModule, FormsModule, NgIf, MatCheckboxModule, MatButtonModule]
})
export class ChangePromptComponent {

  comments: string[] = [];
  applyAll = false;

  constructor(
    private dialogRef: MatDialogRef<ChangePromptComponent>,
    public dialog: MatDialog,
    private dp: DecimalPipe,
    private cp: CurrencyPipe,
    private lookupService: LookupService,
    private saveService: SaveService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('ChangePromptComponent data', data);
    this.data.fields.forEach(ctl => {
      this.comments.push('');
    });
  }

  cancel() {
    this.dialogRef.close('cancelled');
  }

  save() {
    if (this.isValid()) {
      this.saveViaChangePacket();
    } else {
      alert('Please make a comment for each change');
    }
  }

  isValid(): boolean {
    return this.comments.filter(c => c.length === 0).length === 0;
  }

  saveComment(index: number, val: string) {
    this.comments[index] = val;
  }

  applyAllChecked(evt: MatCheckboxChange) {
    if (evt.checked) {
      for (let i = 1; i < this.comments.length; i++) {
        this.comments[i] = this.comments[0];
      }
    }
  }

  getFieldValue(field: DataColumn, obj: any): string {
    let txt = '';
    if (field.linkedLookup !== '') {
      let lookup = this.lookupService.getTableValues(field.linkedLookup);
      let lookupItem = lookup.filter(l => l.id === (obj[field.objectPropertyName] === null ? '' : obj[field.objectPropertyName].toString()));
      if (lookupItem.length > 0) {
        txt = lookupItem[0].name;
      }
    } else {
      if (field.dataType === 'datetime') {
        txt = moment(obj[field.objectPropertyName]).format('MM/DD/YYYY');
      } else if (field.dataType === 'money') {
        txt = this.cp.transform(obj[field.objectPropertyName].toString().replace(/,/g, ''), 'USD', 'symbol', '1.0-0');
      } else if (field.dataType === 'float') {
        txt = this.dp.transform(obj[field.objectPropertyName].toString().replace(/,/g, ''), '1.2-2');
      } else if (field.dataType === 'int') {
        txt = this.dp.transform(obj[field.objectPropertyName]?.toString().replace(/,/g, ''), '1.0-0');
      } else {
        txt = obj[field.objectPropertyName];
      }
    }
    return txt;
  }

  saveViaChangePacket() {
    let reqs: Promise<ChangePacket>[] = [];
    this.data.fields.forEach(field => {
      let changePacket = new ChangePacket(field.tableName, field.dbColumn, field.name, field.key, this.data.origObjs[field.objIndex][field.objectPropertyName],
        this.data.savedObjs[field.objIndex][field.objectPropertyName], this.data.savedObjs[field.objIndex][field.objectPropertyName], field.dataType, 255, field.reloadCache); // todo: should be field.dataTypeLength vs 255
      let req = this.saveService.saveChange(changePacket);
      reqs.push(req);
    });

    if (reqs.length > 0) {
      let waitDlg = this.dialog.open(SpinnerDialogComponent, {
        disableClose: true,
        width: '450px',
        position: { top: '200px' },
        data: { mode: 'bar', title: 'Saving Changes', content: 'This may take a few seconds, please be patient.' }
      });
      forkJoin(reqs).subscribe((packets) => {
        console.log('packets', packets);
        waitDlg.close();
        this.dialogRef.close('saved');
      });
    }
  }
}
