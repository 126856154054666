import { Component, OnInit, Inject, Input, OnChanges, SimpleChange  } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray, UntypedFormBuilder, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerCommission } from '../broker-commission/brokerCommission';
import { Broker } from '../broker-commission/broker';
import { BrokerCommissionService } from '../broker-commission/broker-commission.service';
import { Observable } from 'rxjs';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { LoggerService } from '../../core/logger.service';
import { AlertService } from '../../core/alert.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-broker-commission-pc-dialog',
    templateUrl: './broker-commission-pc-dialog.component.html',
    styleUrls: ['./broker-commission-pc-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatButtonModule]
})
export class BrokerCommissionPcDialogComponent implements OnInit {
  broker: Broker;
  fgBroker: UntypedFormGroup;
  formHeader: String;
  states: Lookup[] = [];

  constructor(
    public dialogRef: MatDialogRef<BrokerCommissionPcDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { broker: Broker },
    private brokerService: BrokerCommissionService,
    private alertService: AlertService,
    private lookupService: LookupService,
    private loggerService: LoggerService,
    private fb: UntypedFormBuilder
  ) {
    this.broker = new Broker();
  }

  ngOnInit() {
    // initialize the broker variable with the data passed from the broker-commission-dialog component
    this.broker = this.data.broker;  // for adding or editing a broker

    // set the header based on the state of broker passed
    if (this.data.broker.brokerKey > 0) {
      this.formHeader = 'Edit Broker';
    } else  {
      this.formHeader = 'Add Broker';
    }

    // initialize the form and the rest of its goodies
    this.dialogRef.updateSize('600px', '500px');
    this.states = this.lookupService.getTableValues('states');
    this.createFormControls();
    this.loggerService.logPageVisit('broker commission pc', this.formHeader.toString());
  }

  clickClose() {
    this.dialogRef.close(this.broker);
  }

  clickSave() {
    this.brokerService.saveBroker(this.broker)
      .subscribe((result: Broker) => {
        this.broker = result;
        this.dialogRef.close(this.broker);
      });
  }

  createFormControls() {
    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    this.fgBroker = this.fb.group({
      firstName: new UntypedFormControl(this.broker.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.broker.lastName, [Validators.required]),
      companyName: new UntypedFormControl({value: this.broker.companyName, disabled: true}),
      address: new UntypedFormControl(this.broker.companyAddress),
      city: new UntypedFormControl(this.broker.companyCity),
      state: new UntypedFormControl(this.broker.companyState),
      zipCode: new UntypedFormControl(this.broker.companyZip),
    });
  }

}
