import { Component, Input, OnChanges } from '@angular/core';

import { SaveService } from '../save.service';
import { ChangePacket } from '../changePacket';

import * as moment from 'moment';
import { NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'cub-change-log',
    templateUrl: './change-log.component.html',
    styleUrls: ['./change-log.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgFor]
})
export class ChangeLogComponent implements OnChanges {

  @Input() tableName: string;
  @Input() itemKey: number;
  changeLog: ChangePacket[] = [];

  constructor(
    private saveService: SaveService
  ) { }

  ngOnChanges() {
    this.getChangeLog();
  }

  getChangeLog() {
    if (this.itemKey) {
      this.saveService.getChangeLog(this.tableName, this.itemKey)
        .subscribe(d => {
          this.changeLog = d;
        });
    }
  }

  formatChangeDt(changeDt: string): string {
    return moment(changeDt, 'M/D/YYYY h:mm:ss a').format('MM/DD/YY');
  }

  formatUserName(userName: string): string {
    return userName.replace('CENTERPOINT-PRO\\', '');
  }

}
