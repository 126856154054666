<div *ngIf="!showOldCUB && !outsideUser"
     class="cub-dashboard">
  <mat-sidenav-container>
    <mat-sidenav [mode]="pointSideNavMode"
                 position="end"
                 [(opened)]="showPoint">
      <div class="point-list"
           style="width: 349px; border-left: solid 1px #ddd;  overflow-y: auto; overflow-x: hidden;">
        <div
            class="point-header-title"
            style="margin-bottom: 10px; margin-left: 15px;">
          <fa-icon [icon]="faCalendar"></fa-icon>My Upcoming Events
        </div>
        <!--
        <div *ngIf="myEventsLoading" style="padding: 10px;">
          Loading events...
        </div>
        -->
        <div *ngIf="myEvents.length === 0 && !myEventsLoading" style="padding: 10px;">
          [No upcoming events found]
        </div>
        <div *ngIf="myEvents.length > 0"
             class="events-container"
             style="margin-bottom: 20px;">
          <div *ngFor="let event of myEvents">
            <div class="personal-event"
                 (click)="openInOutlook(event)"
                 cubEventTooltip
                 [contentTemplate]="personalEventTemplate"
                 matTooltipPosition="left"
                 (mouseover)="selPersonalEvent(event)">
              <div>{{ event.subject }} </div>
              <div>
                <div>{{ getMeetingTimeDesc(event.start.dateTime, event.end.dateTime) }}</div>
                <div><fa-icon *ngIf="event.location.displayName != ''"
                           [icon]="faLocationDot"></fa-icon> {{ event.location.displayName.replace('Microsoft Teams
                  Meeting', 'Teams') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="point-header-title"
             style="display: none; margin-bottom: 10px; margin-left: 15px;">
          <fa-icon [icon]="faFile"></fa-icon>My Recent Stuff
        </div>
        <div class="events-container"
             style="display: none; margin-bottom: 20px;">
          <div *ngFor="let recentItem of officeRecents"
               class="recent-item"
               (onclick)="openRecentItem(recentItem)">
            <div>
              <fa-icon [icon]="getRecentItemIcon(recentItem)"></fa-icon>
            </div>
            <div>
              <div>{{ recentItem.resourceVisualization.title }}</div>
              <div>{{ recentItem.resourceVisualization.containerDisplayName }}</div>
            </div>
          </div>
        </div>
        <div class="point-header-title">
          <fa-icon [icon]="faCalendar"></fa-icon>Corporate Events
        </div>
        <div style=""
             class="events">
          <div *ngIf="eventsToday?.length > 0"
               class="events-header"
               style="margin-top: 0"> Today </div>
          <div [class]="getEventClass(event)"
               *ngFor="let event of eventsToday"
               cubEventTooltip
               [contentTemplate]="template"
               matTooltipPosition="left"
               (mouseover)="selEvent(event)"
               (click)="eventClick(event)">
            <fa-icon [icon]="getEventIcon(event)"></fa-icon>
            <span>{{ event.title }}</span>
          </div>
          <div *ngIf="eventsTomorrow?.length > 0"
               class="events-header"> Tomorrow </div>
          <div [class]="getEventClass(event)"
               *ngFor="let event of eventsTomorrow"
               cubEventTooltip
               [contentTemplate]="template"
               matTooltipPosition="left"
               (mouseover)="selEvent(event)"
               (click)="eventClick(event)">
            <div>
              <fa-icon [icon]="getEventIcon(event)"></fa-icon>
              <span>{{ event.title }}</span>
            </div>
          </div>
          <div *ngIf="eventsRestOfWeek?.length > 0"
               class="events-header"
               class="events-header"> This Week </div>
          <div [class]="getEventClass(event)"
               *ngFor="let event of eventsRestOfWeek"
               cubEventTooltip
               [contentTemplate]="template"
               matTooltipPosition="left"
               (mouseover)="selEvent(event)"
               (click)="eventClick(event)">
            <div>
              <fa-icon [icon]="getEventIcon(event)"></fa-icon>
              <span>{{ event.title }}</span>
            </div>
          </div>
          <div *ngIf="eventsNextWeek?.length > 0"
               class="events-header"
               class="events-header"> Next Week </div>
          <div [class]="getEventClass(event)"
               *ngFor="let event of eventsNextWeek"
               cubEventTooltip
               [contentTemplate]="template"
               matTooltipPosition="left"
               (mouseover)="selEvent(event)"
               (click)="eventClick(event)">
            <div>
              <fa-icon [icon]="getEventIcon(event)"></fa-icon>
              <span>{{ event.title }}</span>
            </div>
          </div>
          <div *ngIf="eventsLater?.length > 0"
               class="events-header"
               class="events-header"> Later </div>
          <div [class]="getEventClass(event)"
               *ngFor="let event of eventsLater"
               cubEventTooltip
               [contentTemplate]="template"
               matTooltipPosition="left"
               (mouseover)="selEvent(event)"
               (click)="eventClick(event)">
            <div>
              <fa-icon [icon]="getEventIcon(event)"></fa-icon>
              <span>{{ event.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="point-list"
           *ngIf="isMarchMadnessTime"
           style="width: 349px; border-left: solid 1px #ddd; height: auto;">
        <div class="point-header-title bracket-header-title"
             style="cursor: pointer; margin-left: 10px">
          <fa-icon [icon]="faBasketballHoop"
                   (click)="gotoMarchMadness()"></fa-icon>
          <span (click)="gotoMarchMadness()">March Madness</span>
          <button mat-stroked-button
                  color="primary"
                  style="
              margin-top: -3px;
              float: right;
              margin-right: -10px;
              padding: 0 10px;
            "
                  (click)="newBracket()">
            <fa-icon [icon]="faPlus"
                     class="fa-lg"></fa-icon>Bracket </button>
        </div>
        <div class="bracket-header"
             (click)="gotoMarchMadness()"
             style="cursor: pointer"> Standings </div>
        <div *ngIf="loadingBrackets" style="text-align: center; margin: 10px;">
          <fa-icon [icon]="faBasketballBall"
                   [spin]="true"
                   style="font-size: 24px;"></fa-icon>
                   <br>
                   Loading brackets...
        </div>
        <div class="bracket-list"
             [style.gridTemplateRows]="getMadnessRows()">
          <div *ngFor="let bracket of brackets; let i = index"
               class="bracket"
               (click)="goToBracket(bracket)"
               [class.bracketMine]="isMyBracket(bracket)">
            <div>{{ bracket.name }}</div>
            <div>{{ bracket.points }}</div>
          </div>
          <div class="bracket-total">{{ brackets.length }} brackets</div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="dashboard-main"
           style="padding: 10px 30px; overflow-y: auto">
        <div class="section-label">
          <span>CenterPoint Portfolio Stats by Quarter</span>
          <div style="float: right">
            <span style="font-size: 12px; margin-right: 6px; color: #888">as of: </span>
            <button mat-stroked-button
                    style="margin-top: -6px; float: right"
                    [matMenuTriggerFor]="menu">
              <span class="indicator-orange"></span> {{ selQuarterName }} <fa-icon [icon]="faCaretDown"
                       title="This is a flux capacitor. It enables you to travel through time..."
                       style="margin-left: 5px; margin-top: -6px; font-size: 14px">
              </fa-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu"
                    xPosition="before">
            <button *ngFor="
                let q of quarterlySystemData | mostRecentYear;
                let i = index
              "
                    (click)="selectQuarter(q, quarterlySystemData.length - i - 1)"
                    mat-menu-item> {{ q.year }} Q{{ q.quarter }} </button>
          </mat-menu>
        </div>
        <div class="section-content">
          <div style="
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              column-gap: 20px;
              row-gap: 20px;
              width: 100%;
            ">
            <cub-widget-line-graph-colored (click)="goToUrl('properties')"
                                           [label]="'Properties'"
                                           [note]="'*CIP and JV'"
                                           [amount]="getAmount('propertyCount', '', '', '')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="propChartLabels"
                                           [chartData]="propChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored (click)="goToUrl('properties')"
                                           [label]="'Sq Ft'"
                                           [note]="'*CIP and JV'"
                                           [amount]="getAmount('squareFeet', '', '', '1.1-1')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="sfChartLabels"
                                           [chartData]="sfChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored (click)="goToUrl('properties/dashboard/vacancy')"
                                           [label]="'Occupancy'"
                                           [note]="'*CIP, no JV'"
                                           [amount]="getAmount('occupancy', '', '%', '1.1-1')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="occChartLabels"
                                           [chartData]="occChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored [label]="'Share Price'"
                                           [amount]="getAmount('sharePrice', '$', '', '')"
                                           [amount]="sharePriceLatest"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="sharePriceChartLabels"
                                           [chartData]="sharePriceChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored (click)="goToUrl('leases')"
                                           [label]="'Leases'"
                                           [note]="'*No CIP or JV'"
                                           [amount]="getAmount('tenantCount', '', '', '')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="leaseChartLabels"
                                           [chartData]="leaseChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored (click)="goToUrl('leases')"
                                           [label]="'Acres'"
                                           [note]="'*JV, no CIP'"
                                           [amount]="getAmount('acres', '', '', '1.0-0')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="acreChartLabels"
                                           [chartData]="acreChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored [label]="'Leverage'"
                                           [amount]="getAmount('leverage', '', '%', '1.1-1')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="leverageChartLabels"
                                           [chartData]="leverageChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
            <cub-widget-line-graph-colored (click)="goToUrl('properties/dashboard/vacancy')"
                                           [label]="'NAV'"
                                           [amount]="getAmount('nav', '$', '', '1.1-1')"
                                           [indexSelected]="selQuarterIndex"
                                           [chartLabels]="navChartLabels"
                                           [chartData]="navChartData"
                                           [color]="'#ededed'"
                                           [colorAccent]="'#21376C'">
            </cub-widget-line-graph-colored>
          </div>
        </div>
        <div class="section-label"
             style="cursor: pointer">
          <span (click)="
              goToUrl(
                'https://cntcloud.sharepoint.com/Lists/CNTCalendar/calendar.aspx',
                false
              )
            ">Corporate Calendar</span>
          <div class="weather-header"></div>
          <div *ngFor="let team of me.sportsTeams"
               class="team-section">
            <img [src]="getTeamImage(team)" />
            <span>{{ team.record }}</span>
          </div>
        </div>
        <div id="calendar">
          <full-calendar #calendarWeek1
                         [options]="calendarOptions">
          </full-calendar>
          <full-calendar #calendarWeek2
                         [options]="calendarOptions">
          </full-calendar>
        </div>
        <div class="section-label"> Goals <button mat-button
                  style="float: right; margin-top: -5px"
                  (click)="goToUrl('users/me/bonus', true)"> Bonus Calculator </button>
        </div>
        <div class="section-content">
          <cub-widget-goals [chartData]="goalData"></cub-widget-goals>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #template>
  <div style="width: 300px; padding: 10px; border-radius: 10px"
       [class]="getTooltipClass(selectedEvent)">
    <div class="calType">
      <div>
        <span>
          <fa-icon [icon]="getEventIcon(selectedEvent)"
                   style="margin-right: 4px"></fa-icon> {{ selectedEvent.calendarName }} </span>
      </div>
      <div>{{ getEventTooltip(selectedEvent) }}</div>
    </div>
    <div style="text-align: center">{{ selectedEvent.title }}</div>
  </div>
</ng-template>
<ng-template #personalEventTemplate>
  <div class="personal-event-tooltip mat-elevation-z4">
    <div class="personalCalType">
      <div style="font-weight: 500;">{{ selectedPersonalEvent.subject }}</div>
      <div><fa-icon [icon]="faClockOneThirty"
                 style="margin-right: 5px;"></fa-icon> {{ getMeetingTimeDesc(selectedPersonalEvent.start.dateTime) }}
      </div>
      <div>
        <fa-icon [icon]="faLocationDot"
                 style="margin-right: 5px;"></fa-icon> {{ selectedPersonalEvent.location.displayName }} <span
              *ngIf="selectedPersonalEvent.location.displayName == ''"
              style="font-style: italic;">no location provided</span>
      </div>
    </div>
    <div style="text-align: center; padding-bottom: 10px;"> {{ getPersonalEventBody(selectedPersonalEvent.bodyPreview)
      }} <span *ngIf="selectedPersonalEvent.bodyPreview == ''"
            style="font-style: italic;">no additional info provided</span>
    </div>
  </div>
</ng-template>
