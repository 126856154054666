<div style="">
  <h5>
    Find Contact
  </h5>
  <mat-form-field style="width: 100%;">
    <mat-label>Filter</mat-label>
    <input matInput
           (keyup)="applyFilter($event.target.value)"

           style="width: 100%;">
  </mat-form-field>

  <div class="example-loading-shade"
       *ngIf="isLoadingResults">
  </div>

  <div class="list-container">
    <table mat-table
           [dataSource]="data"
           class="example-table"
           matSort
           matSortActive="created"
           matSortDisableClear
           matSortDirection="desc"
           style="width: 100%;">
      <ng-container matColumnDef="select">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        style="float: left; margin-top: 5px; margin-left: -12px; margin-right: 4px;">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="contact">
        <td mat-cell
            *matCellDef="let row">
          <div>
            {{row.firstName}} {{row.lastName}}
            <span style="float: right; font-size: 12px; color: #888;">{{ row.mainEmailAddress }}</span>
          </div>
          <div style="font-size: 12px; color: #888;">
            {{row.jobTitle}} {{row.copmanyName}}
          </div>
        </td>
      </ng-container>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)"></tr>
    </table>
  </div>
  <div class="footer">
    <button mat-flat-button
            (click)="cancel()">Cancel</button>
    <button mat-raised-button
            color="primary"
            class="button-standard"
            style="float: right;"
            (click)="selectionMade()">Select</button>
    <button mat-stroked-button
            [matMenuTriggerFor]="contactMenu"
            style="float: right; margin-right: 25px;">
      New Contact
      <fa-icon [icon]="faCaretDown"
               style="margin-left: 5px;color: #888;"></fa-icon>
    </button>
    <mat-menu #contactMenu="matMenu"
              [yPosition]="'above'">
      <button mat-menu-item
              (click)="newContact(true)">Person</button>
      <button mat-menu-item
              (click)="newContact(false)">Company</button>
    </mat-menu>
  </div>
</div>
