import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Team } from './../bracket/bracket';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-matchup',
    templateUrl: './matchup.component.html',
    styleUrls: ['./matchup.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class MatchupComponent implements OnInit {

  @Input() team1: Team;
  @Input() team2: Team;
  @Input() canEdit: boolean;
  @Input() atTop: boolean;
  @Input() round: number;
  @Input() wrongPicks: boolean[] = [];
  @Output() selectedTeam: EventEmitter<Team> = new EventEmitter<Team>();
  selectedTeamIndex: number;

  constructor(
  ) { }

  ngOnInit() {
  }

  getLogo(team: Team): string {
    if (team) {
      return environment.mainUrl + 'assets/img/logos/50/' + team.name + ' (' + team.espnId + ').jpg';
    } else {
      return '';
    }
  }

  teamSelected(index: number) {
    if (this.canEdit) {
      this.selectedTeamIndex = index;
      if (index === 1) {
        this.selectedTeam.emit(this.team1);
      } else {
        this.selectedTeam.emit(this.team2);
      }
    }
  }

  getTopSeed(): string {
    if (this.team1) {
      return this.team1.seed.toString();
    } else {
      return '';
    }
  }

  getTopSeedName(): string {
    if (this.team1) {
      return this.team1.name;
    } else {
      return '';
    }
  }

  getBottomSeed(): string {
    if (this.team2) {
      return this.team2.seed.toString();
    } else {
      return '';
    }
  }

  getBottomSeedName(): string {
    if (this.team2) {
      return this.team2.name;
    } else {
      return '';
    }
  }

  isPickWrong(i: number): boolean {
    if (this.wrongPicks) {
      return this.wrongPicks[i];
    } else {
      return false;
    }
  }

}
