<button mat-icon-button
        [matMenuTriggerFor]="submenu"
        class="filterButton">
  <fa-icon [icon]="faPlus"
           class="fa-2x"
           style="font-size: 1.3rem;"></fa-icon>
</button>
<mat-menu #submenu="matMenu"
          [yPosition]="'below'"
          [xPosition]="'before'"
          [overlapTrigger]="false"
          style="margin-top: 5px;">
  <cub-new-menu-item *ngFor="let item of menuList"
                     [menuItem]="item"></cub-new-menu-item>
</mat-menu>
