export class TenantCompanyLease {
  key: number;
  tenantCompanyKey: number;
  leaseKey: number;
  leaseId: string;
  unitId: string;
  leaseSF: number;
  effectiveDt: Date;
  expirationDt: Date;
  leaseTerm: string;
  tenantName: string;
  propertyId: string;
  address: string;
  city: string;
  stateId: string;
  zipCode: string;
  isDeleted: boolean;
  netRentSF: number;
  sicCodeKey: number;
  sicCodeDesc: string;
  lastUid: string;
  lastDt: Date;
  avgRemainingNetRent: number;
  ownershipPct: number;

  constructor() {

  }
}
