import { Lookup } from '../select/lookup';
import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';

import { SaveService } from '../save.service';
import { ChangePacket } from '../changePacket';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

export function createSelectValidator() {
  return (s: UntypedFormControl) => {
    let err = {
      notSelected: s.value
    };

    return (s.value === '' || +s.value === 0) ? err : null;
  };
}

@Component({
    selector: 'cub-select-auto-save',
    templateUrl: './select-auto-save.component.html',
    styleUrls: ['./select-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectAutoSaveComponent), multi: true }
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class SelectAutoSaveComponent implements OnInit, OnChanges {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedValue') _selectedValue: string;
  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() label: string;
  @Input() itemKey: number;
  @Input() dataType: string;
  @Input() values: Lookup[];
  @Input() appKey: number;
  @Input() resetCache: boolean;
  @Input() disabled;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();
  private origValue = '-1';
  private origStringValue = '';
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    private saveService: SaveService
  ) {
    this.dataType = 'int';  // default to int - can be set by calling component to string
  }

  ngOnInit() {
    this.validateFn = createSelectValidator();
  }

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val.toString();
    this.propagateChange(val.toString());

    if (this.origValue === '-1') {
      this.origValue = val;
      let origLookup = this.values.filter(v => v.id === this.origValue);
      if (origLookup.length > 0) {
        this.origStringValue = origLookup[0].name;
      }
    }
    this.propagateChange(val.toString());
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.validateFn = createSelectValidator();
    if (changes._fieldValue !== undefined) {
      this.origValue = changes._fieldValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
      this.selectedValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // TODO - Do we need this callback?
  registerOnTouched() { }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  selectChange(evt: any) {
    this.save(this._selectedValue.toString());
  }

  save(value: string): void {
    let newStringValue = '';
    let newLookup = this.values.filter(v => v.id === value);
    if (newLookup.length > 0) {
      newStringValue = newLookup[0].name;
    }
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.label, this.itemKey, this.origStringValue, value, newStringValue, this.dataType, 100, this.resetCache);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        this.origValue = value;
      })
      .catch(() => {
        // TODO - Better error handling?
        // this.status = 'error';
      });
    this.emitUpdate();
  }

  emitUpdate() {
    this.controlUpdate.emit({ saved: true });
  }

}
