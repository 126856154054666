import { Inject, Injectable } from '@angular/core';
import { User } from './user/user';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MSAL_INSTANCE } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, PublicClientApplication } from '@azure/msal-browser';
import { Subject, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  private accountInfo = new ReplaySubject<AccountInfo>(1);
  accountInfo$ = this.accountInfo.asObservable();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INSTANCE) private msalInstance: PublicClientApplication,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { }

  updateLoggedInStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  getActiveAccount(): AccountInfo | null {
    return this.authService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount() {
    /**
    * If no active account set but there are accounts signed in, sets first account to active account
    * To use active account set here, subscribe to inProgress$ first in your component
    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    */
    let activeAccount = this.authService.instance.getActiveAccount();
    // console.log('checkAndSetActiveAccount-activeAccount', activeAccount);

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.accountInfo.next(accounts[0]);
      localStorage.setItem('account', JSON.stringify(accounts[0]));

      // this was causing issues after I added powerbi scope
      // but it didn't seem like it was getting called anyway?
      //this.msalInstance.acquireTokenSilent({
      //  scopes: [
      //    'https://graph.microsoft.com/.default',
      //    'https://analysis.windows.net/powerbi/api/.default'
      //  ]
      //})
      //  .then(response => {
      //    console.log('got msal token for graph', response.accessToken);
      //    localStorage.setItem('graphToken', response.accessToken);
      //  });
    }

    // so I moved it down here and separated the calls to get it to work.
    // we're using the powerbi token to connect to the powerbi api to get reports and visuals
    this.msalInstance.acquireTokenSilent({
      scopes: [
        'https://graph.microsoft.com/.default'
      ]
    }).then(response => {
      // console.log('got msal token for graph', response.accessToken);
      localStorage.setItem('graphToken', response.accessToken);
    });

    this.msalInstance.acquireTokenSilent({
      scopes: [
        'https://analysis.windows.net/powerbi/api/.default'
      ]
    }).then(response => {
      // console.log('got msal token for powerbi', response.accessToken);
      localStorage.setItem('powerbiToken', response.accessToken);
    });
  }

  private setLoggedIn() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    // console.log('setLoggedIn', this.authService.instance.getAllAccounts());
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
