<div class="parent"
     *ngIf="show">
  <mat-tab-group [(selectedIndex)]="tabIndex"
                 (selectedTabChange)="search($event)"
                 [mat-stretch-tabs]="false"
                 color="accent">
    <mat-tab label="CUB">
      <div class="waiting"
           *ngIf="isSearching">
        <i class="fa fa-circle-o-notch fa-spin fa-fw fa-lg"></i>
      </div>
      <div class="list"
           *ngIf="!isSearching">
        <mat-list>
          <mat-list-item *ngFor="let result of cubSearchResults"
                         (click)="itemClick(result)">
            <mat-icon mat-list-icon><i class="fa {{ result.icon }}"></i></mat-icon>
            <h6 matLine style="font-weight: 500;">{{ result.description }}</h6>
            <p matLine style="font-size: 12px; color: #777; margin-top: -8px;"> {{ result.type }} </p>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-tab>
    <mat-tab label="Files">
      <div class="waiting"
           *ngIf="isSearching">
        <i class="fa fa-circle-o-notch fa-spin fa-fw fa-lg"></i>
      </div>
      <div class="list"
           *ngIf="!isSearching">
        <mat-list>
          <mat-list-item *ngFor="let result of fileSearchResults"
                         (click)="openFileLink(result.path)">
            <mat-icon mat-list-icon
                      style="margin-top: -15px;color: #555;">
              <fa-icon [icon]="getIcon(result.name)"></fa-icon>
            </mat-icon>
            <h6 matLine
                style="font-weight: 500;">{{ result.name }}</h6>
            <p matLine
               style="font-size: 12px; color: #777; margin-top: -8px;"> {{ result.path }} </p>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="controls">
    <button mat-raised-button
            style="float: left;"
            (click)="hideSearch()">Close</button>
    <button mat-raised-button
            (click)="navigateToSearch()">More Results</button>
  </div>
</div>
