import { Component, OnInit } from '@angular/core';
import { TeamService } from './../team.service';
import { Team, Bracket, Round, Game, BracketObject } from './../bracket/bracket';
import { BracketService } from './../bracket.service';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { TeamPickerComponent } from '../team-picker/team-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-tournament-new',
    templateUrl: './tournament-new.component.html',
    styleUrls: ['./tournament-new.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, TeamPickerComponent]
})
export class TournamentNewComponent implements OnInit {

  user: any;
  teams: Team[] = [];
  bracket: Bracket;
  bracketForm: UntypedFormGroup;
  teamPickerCounter = 0;

  constructor(
    private bracketService: BracketService,
    private teamService: TeamService,
    private fb: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getTeams();
    this.bracket = new Bracket();
    this.bracket.userName = 'Master';
    this.bracket.master = true;
    this.bracket.name = 'Master';
  }

  createForm() {
    this.bracketForm = this.fb.group({
      year: new UntypedFormControl(moment().year(), [Validators.required]),
      region1Name: new UntypedFormControl('', [Validators.required]),
      region2Name: new UntypedFormControl('', [Validators.required]),
      region3Name: new UntypedFormControl('', [Validators.required]),
      region4Name: new UntypedFormControl('', [Validators.required])
    });
  }

  getTeams() {
    return this.teamService.getTeams().subscribe(t => {
      this.teams = t;
    });
  }

  teamSelected(regionNumber: number, team: Team) {
    const foundIndex = this.bracket.regions[regionNumber - 1].teams.findIndex(t => t.seed === team.seed);
    if (foundIndex === -1) {
      this.bracket.regions[regionNumber - 1].teams.push(team);
    } else {
      this.bracket.regions[regionNumber - 1].teams.splice(foundIndex, 1, team);
    }
  }

  pickTeams() {
    this.teamPickerCounter = 0;
    for (let regionIndex = 1; regionIndex < 5; regionIndex++) {
      this.pickTeam(regionIndex, 1);
      this.pickTeam(regionIndex, 16);
      this.pickTeam(regionIndex, 8);
      this.pickTeam(regionIndex, 9);
      this.pickTeam(regionIndex, 5);
      this.pickTeam(regionIndex, 12);
      this.pickTeam(regionIndex, 4);
      this.pickTeam(regionIndex, 13);
      this.pickTeam(regionIndex, 6);
      this.pickTeam(regionIndex, 11);
      this.pickTeam(regionIndex, 3);
      this.pickTeam(regionIndex, 14);
      this.pickTeam(regionIndex, 7);
      this.pickTeam(regionIndex, 10);
      this.pickTeam(regionIndex, 2);
      this.pickTeam(regionIndex, 15);
    }
    console.log('team selected - bracket', this.bracket);
  }

  pickTeam(regionIndex: number, seed: number) {
    let team = JSON.parse(JSON.stringify(this.teams[this.teamPickerCounter]));
    team.seed = seed;
    this.teamSelected(regionIndex, team);
    console.log('picking teams', regionIndex, team.seed, team);
    this.teamPickerCounter++;
  }

  onSubmit() {
    this.formToObject();
    if (this.valid()) {
      this.fixRegions();
      const serverBracket = new BracketObject();
      serverBracket.id = -1;
      serverBracket.bracket = JSON.stringify(this.bracket);
      this.bracketService.saveBracket(serverBracket).subscribe(s => {
        console.log('saved bracket', s);
      });
    }
  }

  formToObject() {
    this.bracket.year = this.bracketForm.controls.year.value;
    this.bracket.regions[0].name = this.bracketForm.controls.region1Name.value;
    this.bracket.regions[1].name = this.bracketForm.controls.region2Name.value;
    this.bracket.regions[2].name = this.bracketForm.controls.region3Name.value;
    this.bracket.regions[3].name = this.bracketForm.controls.region4Name.value;
  }

  fixRegions() {
    for (let i = 0; i < 4; i++) {
      this.bracket.regions[i].rounds.push(new Round('Round 1'));
      this.bracket.regions[i].rounds.push(new Round('Round 2'));
      this.bracket.regions[i].rounds.push(new Round('Sweet 16'));
      this.bracket.regions[i].rounds.push(new Round('Elite 8'));

      this.bracket.regions[i].rounds[0].games.push(new Game(1, this.bracket.regions[i].teams.filter(t => t.seed === 1)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 16)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(2, this.bracket.regions[i].teams.filter(t => t.seed === 8)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 9)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(3, this.bracket.regions[i].teams.filter(t => t.seed === 5)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 12)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(4, this.bracket.regions[i].teams.filter(t => t.seed === 4)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 13)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(5, this.bracket.regions[i].teams.filter(t => t.seed === 6)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 11)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(6, this.bracket.regions[i].teams.filter(t => t.seed === 3)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 14)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(7, this.bracket.regions[i].teams.filter(t => t.seed === 7)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 10)[0].id));
      this.bracket.regions[i].rounds[0].games.push(new Game(8, this.bracket.regions[i].teams.filter(t => t.seed === 2)[0].id,
        this.bracket.regions[i].teams.filter(t => t.seed === 15)[0].id));

      this.bracket.regions[i].rounds[1].games.push(new Game(9));
      this.bracket.regions[i].rounds[1].games.push(new Game(10));
      this.bracket.regions[i].rounds[1].games.push(new Game(11));
      this.bracket.regions[i].rounds[1].games.push(new Game(12));

      this.bracket.regions[i].rounds[2].games.push(new Game(13));
      this.bracket.regions[i].rounds[2].games.push(new Game(14));

      this.bracket.regions[i].rounds[3].games.push(new Game(15));
    }
    this.bracket.regions[4].rounds[0].games.push(new Game(61));
    this.bracket.regions[4].rounds[0].games.push(new Game(62));
    this.bracket.regions[4].rounds[1].games.push(new Game(63));
    this.bracket.regions[4].rounds[2].games.push(new Game(64));
  }

  valid(): boolean {
    let allGood = true;

    allGood = allGood;  // && each region has 16 teams

    return allGood;
  }

}
