import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3 from 'd3-selection';
import * as d3Shape from 'd3-shape';
import { NgIf } from '@angular/common';


export interface Margin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

@Component({
    selector: 'cub-graph-bar-stacked',
    templateUrl: './graph-bar-stacked.component.html',
    styleUrls: ['./graph-bar-stacked.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf]
})
export class GraphBarStackedComponent implements OnInit, OnChanges {

  @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
  @Input() header: string;
  @Input() dataArray: any;
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() showHeader: boolean;
  @Input() showLegend: boolean;
  @Input() xAxisFieldName: string;
  @Input() colors: string[] = [];
  @Input() chartName: string;

  private margin: Margin;
  private width: number;
  private height: number;
  private barPadding = 10;

  private svg: any;     // TODO replace all `any` by the right type
  private x: any;
  private y: any;
  private z: any;
  private g: any;
  alreadyLoaded: boolean;

  constructor(private container: ElementRef) {
  }

  ngOnInit() {
    this.alreadyLoaded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataArray.length > 0) {
      if (!this.alreadyLoaded || d3.select('#' + this.chartName + ' > *').size() > 0) {
        d3.select('#' + this.chartName + ' > *').remove();
        this.initMargins();
        this.initSvg();
        this.drawChart();
      }
    }
  }

  private initMargins() {
    this.margin = {top: 20, right: 80, bottom: 30, left: 40};
  }

  private initSvg() {
    const element = this.chartContainer.nativeElement;

    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;

    this.svg = d3.select(element)
                .append('svg')
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr('viewBox', '0 0 ' + (this.width + this.margin.left + this.margin.left) + ' ' + (this.height + this.margin.top + this.margin.bottom))
                .classed('svg-content', true);

    this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.x = d3Scale.scaleBand()
      .rangeRound([0, this.width])
      .paddingInner(0.20)
      .align(0.1);
    this.y = d3Scale.scaleLinear()
      .rangeRound([this.height, 0]);
    if (this.colors.length === 0) {
      this.z = d3Scale.scaleOrdinal()
        .range(['#F44336', '#2196F3', '#4CAF50', '#FF9800', '#673AB7']);
    } else {
      this.z = d3Scale.scaleOrdinal()
        .range(this.colors);
    }
  }

  private drawChart() {
    this.alreadyLoaded = true;
    if (this.dataArray.length > 0) {
      let keys = Object.getOwnPropertyNames(this.dataArray[0]).slice(1);

      let totals = JSON.parse(JSON.stringify(this.dataArray));  // copy obj
      totals = totals.map(v => {
          v.total = keys.map(key => v[key]).reduce((a, b) => a + b, 0);
          return v;
      });
      this.x.domain(this.dataArray.map((d: any) => d[this.xAxisFieldName]));
      this.y.domain([0, d3Array.max(totals, (d: any) => d.total)]).nice();
      this.z.domain(keys);

      this.g.append('g')
        .selectAll('g')
        .data(d3Shape.stack().keys(keys)(this.dataArray))
        .enter().append('g')
        .attr('fill', d => this.z(d.key))
        .selectAll('rect')
        .data(d => d)
        .enter().append('rect')
        .attr('x', d => this.x(d.data[this.xAxisFieldName]) + 10)
        .attr('y', d => this.y(d[1]))
        .attr('height', d => this.y(d[0]) - this.y(d[1]))
        .attr('width', this.x.bandwidth());

      this.g.append('g')
        .attr('class', 'axis axis-x')
        .attr('transform', 'translate(10,' + this.height + ')')
        .call(d3Axis.axisBottom(this.x));


      this.g.append('g')
        .attr('class', 'axis')
        .call(d3Axis.axisLeft(this.y).ticks(null, 's'));
        // .append('text')
        // .attr('x', 15)
        // .attr('y', this.y(this.y.ticks().pop()) + 0.5)
        // .attr('dy', '0.32em')
        // .attr('fill', '#000')
        // .attr('font-weight', 'bold')
        // .attr('text-anchor', 'start');
        // .text('Square Feet');

      if (this.showLegend) {
        let legend = this.g.append('g')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 10)
          .attr('text-anchor', 'end')
          .selectAll('g')
          .data(keys.slice().reverse())
          .enter().append('g')
          .attr('transform', (d, i) => 'translate(0,' + i * 24 + ')');

        legend.append('rect')
          .attr('x', this.graphWidth - this.margin.right + 20)
          .attr('width', 10)
          .attr('height', 19)
          .attr('fill', this.z);

        legend.append('text')
          .attr('x', this.graphWidth - this.margin.right + 14)
          .attr('y', 9.5)
          .attr('dy', '0.32em')
          .text(d => d);
      }
    }
  }
}
