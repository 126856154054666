export class TableAction {
  name: string;
  icon: string;
  tooltip: string;
  title: string;
  columnKeys?: number[];

  constructor(name: string, title: string, cols?: number[]) {
    this.name = name;
    this.title = title;
    if (cols) {
      this.columnKeys = cols;
    }
  }
}

export class TableActionEvent {
  action: TableAction;
  object: any;

  constructor(action: TableAction, object: any) {
    this.action = action;
    this.object = object;
  }
}
