
  <mat-card appearance="outlined" class="cub-table-card">
    <mat-card-header>
      <mat-card-title>
        <span>Change Log</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <table id="changeLog" class="table" style="table-layout: fixed;">
        <colgroup>
          <col style="width: 20%;">
          <col style="width: 20%;">
          <col style="width: 20%;">
          <col style="width: 20%;">
          <col style="width: 10%;">
          <col style="width: 10%;">
        </colgroup>
        <thead>
          <tr>
            <th>Object</th>
            <th>Field</th>
            <th>New Value</th>
            <th>Old Value</th>
            <th>User</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let changePacket of changeLog;" >
            <td >{{ changePacket.fieldDesc }}</td>
            <td>{{ changePacket.fieldName }}</td>
            <td>{{ changePacket.newValue }}</td>
            <td>{{ changePacket.oldValue }}</td>
            <td>{{ formatUserName(changePacket.userName) }}</td>
            <td>{{ formatChangeDt(changePacket.changeDt) }}</td>
          </tr>
        </tbody>
      </table>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
