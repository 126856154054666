import { Component, OnInit, Injectable, Inject, ViewEncapsulation } from '@angular/core';
import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faChevronDown, faChevronRight, faFolderOpen, faFolderClosed, faSpinner, faFileAlt
} from '@fortawesome/pro-light-svg-icons';
import { FileService } from 'src/app/core/file.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: string,
    public path: string,
    public level = 1,
    public expandable = false,
    public expanded = false,
    public isLoading = false,
    public isFile = false
  ) { }
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: 'root' })
export class DynamicDatabase {
  dataMap = new Map<string, string[]>([]);

  rootLevelNodes: string[] = [];

  constructor(
    private _fileService: FileService,
  ) {
  }

  /** Initial data from database */
  initialData(rootNodes: string[]): DynamicFlatNode[] {
    this.rootLevelNodes = rootNodes;
    let webCalls: Observable<any>[] = [];
    this.rootLevelNodes.forEach(n => {
      this.dataMap.set(n, []);
      webCalls.push(this._fileService.getFiles(n));
    });

    forkJoin(webCalls).subscribe(d => {
      d.forEach(folder => {
        let folderPath = folder.path.substring(1);
        console.log('forkjoin', folder);
        let subFolderNames = folder.folders.map(f => folderPath + f.name);
        this.dataMap.set(folderPath, subFolderNames);
        subFolderNames.forEach(sfName => {
          this.dataMap.set(sfName, []);
        });
      });
    });
    return this.rootLevelNodes.map(name => new DynamicFlatNode(name, name, 0, true, true, false));
  }

  getChildren(node: DynamicFlatNode): string[] | undefined {
    return this.dataMap.get(node.path);
  }

  setChildren(name: string, children: string[]) {
    this.dataMap.set(name, children);
    console.log('setChildren', this.dataMap);
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }
}
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _fileService: FileService,
    private _database: DynamicDatabase,
  ) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    node.isLoading = true;
    let currentChildren = this._database.getChildren(node);
    if (expand) {
      if (currentChildren === undefined || currentChildren?.length === 0) {
        this._fileService.getFiles(node.path).subscribe(subfolders => {
          this._database.setChildren(node.path, subfolders.folders.map(sf => node.path + '/' + sf.name));
          const nodes = [
            ...subfolders.folders.map(sf => new DynamicFlatNode(sf.name.replace(node.path + '/', ''), node.path + '/' + sf.name, node.level + 1, true, false, false, false)),
            ...subfolders.files.map(file => new DynamicFlatNode(file.name, node.path + '/' + file.name, node.level + 1, false, false, false, true))
          ];
          this.data.splice(index + 1, 0, ...nodes);
          node.isLoading = false;
          node.expanded = true;
          this.dataChange.next(this.data);  // notify the change
          console.log('toggleNode - expand - no children', node, currentChildren, this.data);
        });
      } else {
        const nodes = currentChildren.map(sf => new DynamicFlatNode(sf.replace(node.path + '/', ''), sf, node.level + 1, true));
        this.data.splice(index + 1, 0, ...nodes);
        node.isLoading = false;
        node.expanded = true;
        this.dataChange.next(this.data); // notify the change
        console.log('toggleNode - expand - children', node, currentChildren, this.data);
      }
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) { }
      this.data.splice(index + 1, count);
      node.isLoading = false;
      node.expanded = false;
      this.dataChange.next(this.data);  // notify the change
      console.log('toggleNode - collapse', node, currentChildren, this.data);
    }
  }
}

@Component({
    selector: 'cub-folder-picker',
    templateUrl: './folder-picker.component.html',
    styleUrls: ['./folder-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatTreeModule, MatButtonModule, NgIf, FontAwesomeModule]
})
export class FolderPickerComponent {

  treeControl: FlatTreeControl<DynamicFlatNode>;
  faFolderOpen = faFolderOpen;
  faFolderClosed = faFolderClosed;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faSpinner = faSpinner;
  faFileAlt = faFileAlt;
  currentPath = 'Shared';

  constructor(
    database: DynamicDatabase,
    private _fileService: FileService,
    public dialogRef: MatDialogRef<FolderPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, _fileService, database);

    this.dataSource.data = database.initialData(data.rootFolders);
    this.dataSource.toggleNode(this.dataSource.data[0], true);
    this.dataSource.dataChange.subscribe(d => {
      //console.log('data source changes', d);
    })
  }

  dataSource: DynamicDataSource;

  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable;
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  clickNode(node: DynamicFlatNode) {
    this.currentPath = node.path;
  }

  cancel() {
    this.dialogRef.close({ saved: false });
  }

  done() {
    this.dialogRef.close({ saved: true, path: this.currentPath });
  }
}
