<h4>Data Change Comments</h4>
<div style="margin-top: 20px; max-height: 300px; overflow-y: auto; border-bottom: solid 1px #ddd;">
  <table id="changeLog"
         class="table"
         style="table-layout: fixed; width: 100%; border-bottom: solid 1px #ddd;">
    <colgroup>
      <col style="width: 30%;">
      <col style="width: 20%;">
      <col style="width: 20%;">
      <col style="width: 30%;">
    </colgroup>
    <thead>
      <tr>
        <th>Field</th>
        <th>Old Value</th>
        <th>New Value</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of data.fields; let i = index;">
        <td>{{ field.name }}</td>
        <td>{{ getFieldValue(field, data.origObjs[field.objIndex]) }}</td>
        <td>{{ getFieldValue(field, data.savedObjs[field.objIndex]) }}</td>
        <td>
          <input matInput
                 [disabled]="i > 0 && applyAll"
                 [(ngModel)]="comments[i]"
                 (keyup)="saveComment(i, $event.target.value)">
          <div *ngIf="i === 0 && data.fields.length > 1"
               style="margin-top: 10px;">
            <mat-checkbox [(ngModel)]="applyAll"
                          (change)="applyAllChecked($event)">Apply this comment to all</mat-checkbox>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-top: 20px;">
  <button mat-raised-button
          color="primary"
          class="button-standard"
          style="float: right;"
          [disabled]="!isValid()"
          (click)="save()">Save</button>
  <button mat-stroked-button
          class="button-standard"
          style=""
          (click)="cancel()">Cancel</button>
</div>
