import { Lookup } from '../select/lookup';
import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';

import { SaveService } from '../save.service';
import { ChangePacket } from '../changePacket';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';

export function createSelectValidator() {
  return (s: UntypedFormControl) => {
    let err = {
      notSelected: s.value
    };

    return (s.value === '' || +s.value === 0) ? err : null;
  };
}

@Component({
    selector: 'cub-checkbox-auto-save',
    templateUrl: './checkbox-auto-save.component.html',
    styleUrls: ['./checkbox-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => CheckboxAutoSaveComponent), multi: true }
    ],
    standalone: true,
    imports: [NgIf, MatCheckboxModule, FormsModule, MatSlideToggleModule]
})
export class CheckboxAutoSaveComponent implements OnInit, OnChanges, ControlValueAccessor {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedValue') _selectedValue: boolean;
  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() label: string;
  @Input() itemKey: number;
  @Input() useSlideToggle: boolean;
  @Input() disabled;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();
  private origValue: boolean = null;
  indeterminate = false;
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    private saveService: SaveService
  ) {
    this.useSlideToggle = false;
  }

  ngOnInit() {
    this.validateFn = createSelectValidator();
  }

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val;
    if (this.origValue === null) {
      this.origValue = val;
    }

    // this is for setting a checkbox to indeterminate based on whether the initial value is null
    if (this._selectedValue !== undefined) {
      if (this._selectedValue !== null) {
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
    }

    this.propagateChange(val);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.validateFn = createSelectValidator();
    if (changes._selectedValue !== undefined) {
      this.origValue = changes._selectedValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value !== undefined) {
      this.selectedValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() { }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  selectChange(evt: any) {
    this.save(this._selectedValue.toString());
  }

  save(value: string): void {
    let changePacket: ChangePacket;
    changePacket = new ChangePacket(this.tableName, this.fieldName, this.fieldName, this.itemKey, this.origValue.toString(), value, value, 'boolean', 32);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        // TODO - This is a dangerous pattern to force the observerable to "complete"
      })
      .catch(() => {
      });

    this.emitUpdate();
  }

  emitUpdate() {
    this.controlUpdate.emit({ saved: true });
  }
}
