import { Component, OnInit } from '@angular/core';
import { TeamService } from './../team.service';
import { Bracket, BracketObject } from './../bracket/bracket';
import { Team, Game } from './../bracket/bracket';
import { BracketService } from './../bracket.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faTrashAlt, faSave, faExclamationCircle, faPrint, faSpinner, faBasketballBall, faICursor, faClose, faArrowTurnDownLeft } from '@fortawesome/pro-light-svg-icons';
import * as moment from 'moment';
import { MarchMadnessConstants } from '../contants';
import { User } from 'src/app/user/user';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { MatchupComponent } from '../matchup/matchup.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, JsonPipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-bracket',
    templateUrl: './bracket.component.html',
    styleUrls: ['./bracket.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatButtonModule, FontAwesomeModule, MatCheckboxModule, NgIf, MatFormFieldModule, MatInputModule, MatProgressBarModule, NgFor, MatCardModule, MatchupComponent, JsonPipe]
})
export class BracketComponent implements OnInit {

  user: User;
  teams: Team[] = [];
  brackets: Bracket[] = [];
  bracket: Bracket;
  masterBracket: Bracket;
  bracketId = 0;
  points: number;
  canEdit = true;
  canView = false;
  isGod = false;
  bForm: UntypedFormGroup;
  faTrashAlt = faTrashAlt;
  faSave = faSave;
  faExclamationCircle = faExclamationCircle;
  faBasketballBall = faBasketballBall;
  faClose = faClose;
  faArrowTurnDownLeft = faArrowTurnDownLeft;
  faICursor = faICursor;
  faSpinner = faSpinner;
  faPrint = faPrint;
  loadedBrackets = false;
  currentRegion = 4;
  showRename = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bracketService: BracketService,
    private fb: UntypedFormBuilder,
    private teamService: TeamService
  ) {
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this.isGod = this.user.workEmail === 'kgerman@centerpoint.com' || this.user.workEmail === 'rokrasinski@centerpoint.com' || this.user.workEmail === 'ccaveney@centerpoint.com';
    this.bracket = new Bracket();
  }

  ngOnInit() {
    this.createForm();

    this.route.params.subscribe(params => {
      console.log('got params', params);
      this.bracketId = +params['id'];

      let bracketObs = this.bracketService.getBrackets();
      let teamObs = this.teamService.getTeams();
      forkJoin([bracketObs, teamObs]).subscribe(results => {
        this.brackets = results[0];
        this.teams = results[1];
        if (!this.loadedBrackets) {
          this.loadedBrackets = true;
          console.log('loading bracket', this.bracketId, this.user, this.isGod);
          if (this.bracketId === 0) {
            this.bracket = JSON.parse(JSON.stringify(this.brackets.filter(br => br.year === moment(MarchMadnessConstants.CutOffDt).year() && br.master)))[0];
            this.bracket.id = 0;
            this.bracket.master = false;
            this.bracket.userName = this.user.workEmail;
            this.bracket.paid = false;
            this.bracket.name = this.user.firstName + '\'s Bracket';
            this.bracket.year = moment(MarchMadnessConstants.CutOffDt).year();
          }
          this.getBracket();
          console.log('load new bracket');
        }
      });
    });
  }

  createForm() {
    this.bForm = this.fb.group({
      name: new UntypedFormControl({value: this.bracket.name, disabled: !this.canEdit}, [Validators.required]),
      paid: new UntypedFormControl({value: this.bracket.paid, disabled: !this.isGod})
    });
  }

  printBracket() {
    window.open('/brackets/print/' + this.bracket.id);
  }

  preventDefault(evt: any) {
    console.log('preventDefault', evt);
    if (evt.keyCode === 13) { // Ignore the Enter key
      evt.preventDefault(); // Prevents the default action if necessary
      return; // Stops further processing
    }
  }

  saveName(evt: any) {
    this.bracket.name = evt.target.value;
    console.log('saveName', this.bracket.name, evt);
    if (evt.keyCode === 13) { // Ignore the Enter key
      evt.preventDefault(); // Prevents the default action if necessary
      return; // Stops further processing
    }
  }

  selectRegion(region: number) {
    this.currentRegion = region;
  }

  getBracket() {
    const brackets = this.brackets.filter(br => br.id === this.bracketId);
    if (brackets.length > 0) {  // get bracket from list if not new
      this.bracket = brackets[0];
    }
    this.canEdit = this.isGod ||
      (this.user.workEmail === this.bracket.userName && moment().isBefore(moment(MarchMadnessConstants.CutOffDt)));
    this.canView = this.isGod || this.user.workEmail === this.bracket.userName || moment().isAfter(moment(MarchMadnessConstants.CutOffDt));
    if (!this.canView) {
      //console.log('why no peeking', this.isGod, this.user, this.bracket);
      //alert('no peeking');
      this.router.navigate(['marchmadness/nopeeking']);
    }
    if (!this.canEdit) {
      this.bForm.disable();
    }
    console.log('this bracket', this.bracket, this.bracket.regions[0].name);

    const masterBrackets = this.brackets.filter(br => br.year === this.bracket.year && br.master);
    if (masterBrackets.length > 0) {
      this.masterBracket = masterBrackets[0];
      this.points = Bracket.getPoints(this.bracket, this.masterBracket);
      console.log('master bracket', this.masterBracket);
    }
    this.bForm.controls.name.setValue(this.bracket.name);
    this.bForm.controls.paid.setValue(this.bracket.paid);
    console.log('getBracket finished');
  }

  getLogoUrlByEspnId(id: number) {
    let url = '';
    if (this.teams.length > 0) {
      let team: Team;
      team = this.teams.filter(t => t.espnId === id)[0];
      url = environment.mainUrl + 'assets/img/logos/50/' + team.name + ' (' + team.espnId + ').jpg';
    }
    return url;
  }

  getRegionWinnerLogo(regionIndex: number, size: number): string {
    let logo = '';
    let teamId: number;
    if (regionIndex < 4) {
      const region = this.bracket.regions[4];
      const round = region.rounds[0];
      const game = round.games.filter(g => g.id === (regionIndex === 1 || regionIndex === 2 ? 61 : 62))[0];
      if (regionIndex <= 1) {
        if (game) {
          teamId = game.team1Id;
        }
      } else {
        if (game) {
          teamId = game.team2Id;
        }
      }
      const team = this.bracket.regions[regionIndex].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = environment.mainUrl + 'assets/img/logos/' + size.toString() + '/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getRegionWinnerName(regionIndex: number): string {
    let name = '';
    let teamId: number;
    if (regionIndex < 4) {
      const region = this.bracket.regions[4];
      const round = region.rounds[0];
      const game = round.games.filter(g => g.id === (regionIndex === 1 || regionIndex === 2 ? 61 : 62))[0];
      if (regionIndex <= 1) {
        if (game) {
          teamId = game.team1Id;
        }
      } else {
        if (game) {
          teamId = game.team2Id;
        }
      }
      const team = this.bracket.regions[regionIndex].teams.filter(t => t.id === teamId)[0];
      name = team?.name;
    }
    return name;
  }

  getChampLogo(): string {
    let logo = '';
    if (this.bracket.regions[4].rounds[2].games[0]) {
      const teamId = this.bracket.regions[4].rounds[2].games[0].team1Id;
      const team = this.bracket.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = environment.mainUrl + 'assets/img/logos/500/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getLogoOfTeamInGame(region: number, round: number, game: number, teamNum: number): string {
    let logo = '';
    if (this.bracket.regions[region].rounds[round].games[game]) {
      const teamId = this.bracket.regions[region].rounds[round].games[game]['team' + teamNum.toString() + 'Id'];
      const team = this.bracket.regions[region].teams.filter(t => t.id === teamId)[0];
      if (team !== undefined && team !== null && team.name !== '') {
        logo = environment.mainUrl + 'assets/img/logos/500/' + team.name + ' (' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getChampTeamName(): string {
    let name = '';
    if (this.bracket.regions[4].rounds[2].games[0]) {
      const teamId = this.bracket.regions[4].rounds[2].games[0].team1Id;
      const team = this.bracket.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        name = team.name;
      }
    }
    return name;
  }

  toggleFinal(champTeamIndex: number) {
    let teams = [];
    let currentTeamSelected = new Team();

    if (this.canEdit) {

      // if the regional champions are already selected, then toggle them for the final game
      if ((champTeamIndex === 1 && this.getRegionWinnerLogo(0, 1000) !== '' && this.getRegionWinnerLogo(3, 1000) !== '') ||
        (champTeamIndex === 2 && this.getRegionWinnerLogo(1, 1000) !== '' && this.getRegionWinnerLogo(2, 1000) !== '')) {
        if (champTeamIndex === 1) {
          teams.push(this.bracket.regions[4].teams[0]);
          teams.push(this.bracket.regions[4].teams[3]);
          currentTeamSelected = this.getWinningTeam(4, 2, 63, champTeamIndex);
        } else {
          teams.push(this.bracket.regions[4].teams[1]);
          teams.push(this.bracket.regions[4].teams[2]);
          currentTeamSelected = this.getWinningTeam(4, 2, 63, champTeamIndex);
        }
        console.log('toggleFinal', teams, champTeamIndex, currentTeamSelected);
        if (currentTeamSelected === null || currentTeamSelected === undefined) {
          this.selectChampGameTeam(teams[0], champTeamIndex);
        } else if (currentTeamSelected.id === teams[0].id) {
          this.selectChampGameTeam(teams[1], champTeamIndex);
        } else {
          this.selectChampGameTeam(teams[0], champTeamIndex);
        }
        this.saveBracket(false);
      } else {
        alert('You must select the regional champions before you can choose the participants in the championship game.');
      }
    }
  }

  toggleChamp() {
    let teams = [];

    if (this.canEdit) {
      // if the finalists are already selected, then toggle them for the champion
      if (this.getLogoOfTeamInGame(4, 1, 0, 1) !== '' && this.getLogoOfTeamInGame(4, 1, 0, 2) !== '') {
        teams.push(this.bracket.regions[4].rounds[1].games[0].team1Id);
        teams.push(this.bracket.regions[4].rounds[1].games[0].team2Id);
        let currentTeamSelected = this.getWinningTeam(4, 3, 64, 1);
        console.log('toggleChamp', teams, currentTeamSelected);
        if (currentTeamSelected === null || currentTeamSelected === undefined) {
          this.selectChampion(teams[0]);
        } else if (currentTeamSelected.id === teams[0]) {
          this.selectChampion(teams[1]);
        } else {
          this.selectChampion(teams[0]);
        }
        this.saveBracket(false);
      }else {
        alert('You must select the finalists before you can choose the champion.');
      }
    }
  }

  selectChampGameTeam(team: Team, teamIndex: number) {
    console.log('selectChampGameTeam', team, teamIndex);
    this.bracket.regions[4].rounds[1].games[0]['team' + teamIndex.toString() + 'Id'] = team.id;
  }

  selectChampion(teamId: number) {
    console.log('selectChampGameTeam', teamId);
    this.bracket.regions[4].rounds[2].games[0].team1Id = teamId;
  }

  teamSelected(selTeam: Team, regionIndex: number, roundIndex: number, nextGameId: number, teamIndex: number) {
    let game: Game;
    let oldTeamId = 0;
    if (roundIndex === 0) {  // if elite eight winner - determine final four game based on region index
      const finalFourRegion = this.bracket.regions[4];
      game = this.getFinalFourGame(regionIndex);
      console.log('elite 8 winner', game, regionIndex);
      if (regionIndex <= 1) {
        game.team1Id = selTeam.id;
      } else {
        game.team2Id = selTeam.id;
      }
      // update the teams array in the final four
      while (finalFourRegion.teams.length < 4) {  // make sure there are 4 teams in array
        finalFourRegion.teams.push(new Team());
      }
      finalFourRegion.teams.splice(regionIndex, 1, selTeam);
    } else {  // just a regular game from first few rounds (not elite 8 winner)
      const region = this.bracket.regions[regionIndex];
      const round = region.rounds[roundIndex];
      game = round.games.filter(g => g.id === nextGameId)[0];
      if (teamIndex === 1) {
        oldTeamId = game.team1Id;
        game.team1Id = selTeam.id;
      } else {
        oldTeamId = game.team2Id;
        game.team2Id = selTeam.id;
      }
      // remove from future rounds in this region
      region.rounds.forEach((rd, i) => {
        if (i > roundIndex) {
          console.log('checking future rounds', i);
          rd.games.forEach(g => {
            console.log('checking future games', oldTeamId, g.team1Id, g.team2Id);
            if (g.team1Id === oldTeamId) {
              delete g.team1Id;
            } else if (g.team2Id === oldTeamId) {
              delete g.team2Id;
            }
          });
        }
      });
      // remove from final four
      let finalFourGame = this.getFinalFourGame(regionIndex);
      const finalFourRegion = this.bracket.regions[4];
      if (regionIndex <= 1) {  // which side of final four
        if (finalFourGame.team1Id === oldTeamId) {
          delete finalFourGame.team1Id;
        }
      } else {
        if (finalFourGame.team2Id === oldTeamId) {
          delete finalFourGame.team2Id;
        }
      }
      // update the teams array in the final four
      while (finalFourRegion.teams.length < 4) {  // make sure there are 4 teams in array
        finalFourRegion.teams.push(new Team());
      }
      finalFourRegion.teams.splice(regionIndex, 1, new Team());
      // todo: remove from championship game as well
    }
    this.saveBracket(false);
  }

  getFinalFourGame(regionIndex: number): Game {
    const region = this.bracket.regions[4];
    const round = region.rounds[0];
    let game = round.games.filter(g => g.id === (regionIndex === 1 || regionIndex === 2 ? 61 : 62))[0];
    return game;
  }

  getWinningTeam(regionIndex: number, roundIndex: number, gameId: number, teamIndex: number): Team {
    try {
      const region = this.bracket.regions[regionIndex];
      const round = region.rounds[roundIndex - 1];
      const game = round.games.filter(g => g.id === gameId)[0];
      if (teamIndex === 1 && game.team1Id) {
        return this.bracket.regions[regionIndex].teams.filter(t => t.id === game.team1Id)[0];
      } else if (teamIndex === 2 && game.team2Id) {
        return this.bracket.regions[regionIndex].teams.filter(t => t.id === game.team2Id)[0];
      } else {
        return null;
      }
    }
    catch (e) {
      console.log('error getting winning team', regionIndex, roundIndex, gameId, teamIndex, e);
      return null;
    }
  }

  gamesPicked(): number {
    let ctr = 0;
    this.bracket.regions.forEach(rgn => {
      rgn.rounds.forEach((rnd, i) => {
        if (i > 0 || (rgn.name === 'Final Four')) {
          rnd.games.forEach(gm => {
            if (gm.team1Id) {
              ctr++;
            }
            if (gm.team2Id) {
              ctr++;
            }
          });
        }
      });
    });
    return ctr;
  }

  onSubmit() {
  }

  delete() {
    if (confirm('Are you sure you want to delete this bracket?')) {
      console.log('deleting bracket', this.bracket);
      this.bracketService.deleteBracket(this.bracket).subscribe(b => {
        console.log('deleted bracket', b);
        //if (b) {
          this.bracketService.refreshBrackets();
          this.router.navigate(['marchmadness']);
        //}
      });
    }
  }

  togglePaid(evt: any) {
    if (evt.checked) {
      this.bForm.controls.paid.setValue(true);
    } else {
      this.bForm.controls.paid.setValue(false);
    }
  }

  getWrongPicks(rgn: number, round: number, gameId: number): boolean[] {
    let wrongPicks = [];
    if (this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team1Id === undefined) {
      wrongPicks.push(false);
    } else {
      wrongPicks.push(this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team1Id !== this.bracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team1Id);
    }
    if (this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team2Id === undefined) {
      wrongPicks.push(false);
    } else {
      wrongPicks.push(this.masterBracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team2Id !== this.bracket.regions[rgn].rounds[round].games.filter(g => g.id === gameId)[0].team2Id);
    }
    return wrongPicks;
  }

  saveBracket(goHome: boolean) {
    let save = true;
    const games = this.gamesPicked();
    if (goHome && games < 63) {
      save = confirm('You have only saved ' + games.toString() + ' game(s) out of 63.  Are you sure you want to save?');
    }
    if (save) {
      this.bracket.name = this.bForm.controls['name'].value;
      this.bracket.paid = this.bForm.controls['paid'].value;
      console.log('saving bracket', this.bracket);
      const serverBracket = new BracketObject();
      serverBracket.id = this.bracketId;
      serverBracket.bracket = JSON.stringify(this.bracket);
      this.bracketService.saveBracket(serverBracket).subscribe(b => {
        this.bracketId = b.id;
        this.bracket.id = b.id;
        console.log('saved bracket', b);
        if (goHome) {
          this.bracketService.refreshBrackets();
          this.router.navigate(['marchmadness']);
        }
      });
    }
  }
}
