<div id="add-cub-user"
     mat-dialog-content>
  <div class="inv-new-header">
    <h3>Add CUB User</h3>
  </div>
  <form [formGroup]="cubUserForm"
        novalidate>
    <div class="cub-user-grid">
      <mat-form-field style="grid-column: 1/2;">
				<mat-label>First Name</mat-label>
        <input matInput
               formControlName="firstName">
        <mat-error *ngIf="cubUserForm.controls['firstName'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 2/2;">
				<mat-label>Last Name</mat-label>
        <input matInput
               formControlName="lastName">
        <mat-error *ngIf="cubUserForm.controls['lastName'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 1/2;">
				<mat-label>Account Name</mat-label>
        <input matInput
               formControlName="accountName">
        <mat-error *ngIf="cubUserForm.controls['accountName'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 2/2;">
				<mat-label>Property List (External Users)</mat-label>
        <mat-select
                    formControlName="yardiPropertyListHmy">
          <mat-option *ngFor="let prop of propertiesList"
                      [value]="prop.id">
            {{ prop.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="grid-column: 1/2;">
				<mat-label>Department (Bonus)</mat-label>
        <mat-select
                    formControlName="department">
          <mat-option *ngFor="let dept of departments"
                      [value]="dept.name">
            {{ dept.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="cubUserForm.controls['department'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 2/2;">
				<mat-label>PTO Hours</mat-label>
        <input matInput
               formControlName="pTOHoursGiven">
        <mat-error *ngIf="cubUserForm.controls['pTOHoursGiven'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 1/2;">
				<mat-label>Hire Dt</mat-label>
        <input matInput
               type="date"
               formControlName="hireDt">
        <mat-error *ngIf="cubUserForm.controls['hireDt'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <mat-form-field style="grid-column: 2/2;">
				<mat-label>Birth Dt</mat-label>
        <input matInput
               type="date"
               formControlName="birthday">
        <mat-error *ngIf="cubUserForm.controls['birthday'].invalid">You must enter a value</mat-error>
      </mat-form-field>
      <div style="grid-column: 1/2;">
        <mat-checkbox formControlName="birthdayIsPublic"
                      id="birthdayIsPublic">
          Show Birthday
        </mat-checkbox>
      </div>
      <div style="grid-column: 2/2;">
        <mat-checkbox formControlName="useNewPortal"
                      id="useNewPortal">
          Show New Portal
        </mat-checkbox>
      </div>
      <div style="grid-column: 1/2;">
        <mat-checkbox formControlName="isPropertyMgr"
                      id="isPropertyMgr">
          Is Property Manager
        </mat-checkbox>
      </div>
      <!-- <div style="grid-column: 2/2;">
        <mat-checkbox formControlName="useNewPortal">
          Is Exempt
        </mat-checkbox>
      </div> -->
    </div>
    <div>
      <button mat-button
              color="primary"
              class="button-standard"
              (click)="onCancel($event)">Cancel</button>
      <button mat-raised-button
              color="primary"
              type="submit"
              class="button-standard"
              style="float: right;"
              (click)="onSubmit($event)">Save</button>
    </div>
  </form>
</div>
