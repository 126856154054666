
<div class="dashboard-calendar">
  <table class="calendar-table">
    <colgroup>
      <col style="width:20%;" />
      <col style="width:20%;" />
      <col style="width:20%;" />
      <col style="width:20%;" />
      <col style="width:20%;" />
    </colgroup>
    <thead>
      <tr>
        <th>Mon</th>
        <th>Tue</th>
        <th>Wed</th>
        <th>Thu</th>
        <th>Fri</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td #day1 [class.today]="isToday(0)">
          <span>{{ getDayName(0) }}</span>
        </td>
        <td #day2 [class.today]="isToday(1)">
          <span>{{ getDayName(1) }}</span>
        </td>
        <td #day3 [class.today]="isToday(2)">
          <span>{{ getDayName(2) }}</span>
        </td>
        <td #day4 [class.today]="isToday(3)">
          <span>{{ getDayName(3) }}</span>
        </td>
        <td #day5 [class.today]="isToday(4)">
          <span>{{ getDayName(4) }}</span>
        </td>
      </tr>
      <tr>
        <td #day6>
          <span>{{ getDayName(7) }}</span>
        </td>
        <td #day7>
          <span>{{ getDayName(8) }}</span>
        </td>
        <td #day8>
          <span>{{ getDayName(9) }}</span>
        </td>
        <td #day9>
          <span>{{ getDayName(10) }}</span>
        </td>
        <td #day10>
          <span>{{ getDayName(11) }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div style="position: relative">
    <div *ngFor="let calObj of calendarObjects | birthday:false:false:false"
      [class]="getCalendarClass(calObj)"
      [style.top.px]="getCalendarTop(calObj)"
      [style.left.px]="getCalendarLeft(calObj)"
      [style.width.px]="getCalendarWidth(calObj)"
      [title]="getTooltip(calObj)">
      {{ calObj.title }}
    </div>
    <div *ngFor="let calObj of calendarObjects | birthday:true:false:false"
      class="cal-event4"
      [style.top.px]="getCalendarTopForCake(calObj)"
      [style.left.px]="getCalendarLeftForCake(calObj)">
      <fa-icon [icon]="faBirthdayCake" [matTooltip]="getTitleForCake(calObj)"></fa-icon>
    </div>
    <div *ngFor="let calObj of calendarObjects | birthday:false:true:false"
      class="cal-event8"
      [style.visibility]="getCalendarDisplayForPartyHorn(calObj)"
      [style.top.px]="getCalendarTopForPartyHorn(calObj)"
      [style.left.px]="getCalendarLeftForPartyHorn(calObj)">
      <fa-icon [icon]="faClockDesk" [matTooltip]="getTitleForPartyHorn(calObj)"></fa-icon>
    </div>
    <div *ngFor="let calObj of calendarObjects | birthday:false:false:true"
      class="cal-event9"
      [style.visibility]="getCalendarDisplayForInv(calObj)"
      [style.top.px]="getCalendarTopForInv(calObj)"
      [style.left.px]="getCalendarLeftForInv(calObj)">
      <fa-icon [icon]="faSquareDollar" [matTooltip]="getTitleForInv(calObj)"></fa-icon>
    </div>
  </div>
</div>
