import { LeaseBarneyUnitPickerComponent } from './lease-barney-unit-picker/lease-barney-unit-picker.component';
import { LeaseBarneyDialogComponent } from './../lease-barney/lease-barney-dialog/lease-barney-dialog.component';
import { LeaseBarneyLookupDialogComponent } from './lease-barney-lookup-dialog/lease-barney-lookup-dialog.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BrokerCommissionDialogComponent } from './broker-commission-dialog/broker-commission-dialog.component';
import { BrokerCommissionPcDialogComponent } from './broker-commission-pc-dialog/broker-commission-pc-dialog.component';
import { BrokerCommissionComponent } from './broker-commission/broker-commission.component';
import { BrokerCommissionService } from './broker-commission/broker-commission.service';
import { CertificateUploadComponent } from './certificate-upload/certificate-upload.component';
import { CertificateUploadService } from './certificate-upload/certificate-upload.service';
import { ChangeLogComponent } from './change-log/change-log.component';
import { CheckboxAutoSaveComponent } from './checkbox-auto-save/checkbox-auto-save.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ContactPickerOverlayComponent } from './contact-picker/contact-picker-overlay.component';
import { ContactPickerComponent } from './contact-picker/contact-picker.component';
import { ContactPickerService } from './contact-picker/contact-picker.service';
import { ContactService } from './contact-picker/contact.service';
import { DateAutoSaveComponent } from './date-auto-save/date-auto-save.component';
import { DebounceInputComponent } from './debounce-input/debounce-input.component';
import { GraphBarStackedComponent } from './graph-bar-stacked/graph-bar-stacked.component';
import { GraphBarComponent } from './graph-bar/graph-bar.component';
import { GraphDonutComponent } from './graph-donut/graph-donut.component';
import { GraphLineComponent } from './graph-line/graph-line.component';
import { GraphPieComponent } from './graph-pie/graph-pie.component';
import { GraphTreemapComponent } from './graph-treemap/graph-treemap.component';
import { HighlightPipe } from './highlight.pipe';
import { InputAutoSaveCamoComponent } from './input-auto-save-camo/input-auto-save-camo.component';
import { InputAutoSaveComponent } from './input-auto-save/input-auto-save.component';
import { ListControlDialogComponent } from './list-control-dialog/list-control-dialog.component';
import { ListFilterSelectedPipe } from './list-control-filter/list-control-filter-selected.pipe';
import { ListControlFilterComponent } from './list-control-filter/list-control-filter.component';
import { ListFilterPipe } from './list-control-filter/list-control-filter.pipe';
import { ListGroupSortPipe } from './list-control-group-sort/list-group-sort.pipe';
import { ListControlGroupSortComponent } from './list-control-group-sort/list-control-group-sort.component';
import { ListControlSearchComponent } from './list-control-search/list-control-search.component';
import { ListControlViewComponent } from './list-control-view/list-control-view.component';
import { ListControlComponent } from './list-control/list-control.component';
import { ListControlTabsComponent } from './list-control-tabs/list-control-tabs.component';
import { ListFooterComponent } from './list-footer/list-footer.component';
import { ListGroupComponent } from './list-group/list-group.component';
import { ListItemComponent } from './list-item/list-item.component';
import { ListResultComponent } from './list-result/list-result.component';
import { MapComponent } from './map/map.component';
import { MapMultipleComponent } from './map-multiple/map-multiple.component';
import { MapService } from './map/map.service';
import { NumWithCommasPipe } from './num-with-commas.pipe';
import { PromoteAssetComponent } from './promote-asset/promote-asset.component';
import { SaveService } from './save.service';
import { SelectAutoSaveComponent } from './select-auto-save/select-auto-save.component';
import { SelectMultipleAutoSaveComponent } from './select-multiple-auto-save/select-multiple-auto-save.component';
import { SelectComponent } from './select/select.component';
import { SpinnerDialogComponent } from './spinner-dialog/spinner-dialog.component';
import { StandAloneTestComponent } from './stand-alone-test/stand-alone-test.component';
import { SumArrayPipe } from './sum-array.pipe';
import { TextareaAutoSaveComponent } from './textarea-auto-save/textarea-auto-save.component';
import { TextboxComponent } from './textbox/textbox.component';
import { ToNumberPipe } from './to-number.pipe';
import { WidgetIndicatorComponent } from './widget-indicator/widget-indicator.component';

import { BudgetActualWidgetComponent } from './budget-actual-widget/budget-actual-widget.component';
import { BudgetMarketWidgetComponent } from './budget-market-widget/budget-market-widget.component';
import { InputHelperService } from './input-helper.service';
import { MinusToParensPipe } from './minus-to-parens.pipe';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { ContactEditDialogComponent } from './contact-edit-dialog/contact-edit-dialog.component';
import { ContactEditEmailComponent } from './contact-edit-email/contact-edit-email.component';
import { ContactEditPhoneComponent } from './contact-edit-phone/contact-edit-phone.component';
import { AddressEditDialogComponent } from './address-edit-dialog/address-edit-dialog.component';
import { ContactSearchComponent } from './contact-search/contact-search.component';
import { BadDataComponent } from './bad-data/bad-data.component';
import { BadDataTicketComponent } from './bad-data-ticket/bad-data-ticket.component';
import { BadDataService } from './bad-data-ticket/bad-data.service';
import { ListControlTopComponent } from './list-control-top/list-control-top.component';
import { TableComponent } from './table/table.component';
import { AddressEditPropertyDialogComponent } from './address-property-edit-dialog/address-edit-property-dialog.component';
import { ChangePromptComponent } from './change-prompt/change-prompt.component';
import { TenantCompanyParentLookupDialogComponent } from './tenant-company-parent-lookup-dialog/tenant-company-parent-lookup-dialog.component';
import { PropPickerComponent } from './prop-picker/prop-picker.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GraphGaugeComponent } from './graph-gauge/graph-gauge.component';
import { DragdropDirective } from './dragdrop.directive';
import { AddressService } from './address-edit-dialog/address.service';
import { FolderPickerComponent } from './folder-picker/folder-picker.component';
import { PowerbiReportComponent } from './powerbi-report/powerbi-report.component';
import { PowerBiVisualComponent } from './powerbi-visual/powerbi-visual.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { BuildingPickerComponent } from './building-picker/building-picker.component';

@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    GoogleMapsModule,
    PowerBIEmbedModule,
    TextboxComponent,
    SelectComponent,
    InputAutoSaveCamoComponent,
    DebounceInputComponent,
    ListControlFilterComponent,
    ListFilterSelectedPipe,
    ListFilterPipe,
    ListGroupSortPipe,
    ListControlSearchComponent,
    HighlightPipe,
    NumWithCommasPipe,
    ListControlComponent,
    MapComponent,
    MapMultipleComponent,
    ToNumberPipe,
    GraphBarComponent,
    GraphLineComponent,
    GraphPieComponent,
    SelectAutoSaveComponent,
    CheckboxAutoSaveComponent,
    InputAutoSaveComponent,
    DateAutoSaveComponent,
    StandAloneTestComponent,
    SelectMultipleAutoSaveComponent,
    ChangeLogComponent,
    GraphBarStackedComponent,
    TextareaAutoSaveComponent,
    SumArrayPipe,
    GraphDonutComponent,
    BarChartComponent,
    SpinnerDialogComponent,
    BrokerCommissionComponent,
    BrokerCommissionDialogComponent,
    BrokerCommissionPcDialogComponent,
    MessageDialogComponent,
    ConfirmationDialogComponent,
    ContactPickerComponent,
    ContactPickerOverlayComponent,
    PromoteAssetComponent,
    CertificateUploadComponent,
    GraphTreemapComponent,
    WidgetIndicatorComponent,
    BudgetActualWidgetComponent,
    BudgetMarketWidgetComponent,
    ListControlDialogComponent,
    ListItemComponent,
    ListControlViewComponent,
    ListControlGroupSortComponent,
    ListControlTabsComponent,
    ListGroupComponent,
    ListResultComponent,
    ListFooterComponent,
    ListControlTopComponent,
    MinusToParensPipe,
    ContactEditDialogComponent,
    ContactEditEmailComponent,
    ContactEditPhoneComponent,
    AddressEditDialogComponent,
    ContactSearchComponent,
    BadDataTicketComponent,
    BadDataComponent,
    TableComponent,
    AddressEditPropertyDialogComponent,
    ChangePromptComponent,
    TenantCompanyParentLookupDialogComponent,
    LeaseBarneyLookupDialogComponent,
    PropPickerComponent,
    LeaseBarneyDialogComponent,
    LeaseBarneyUnitPickerComponent,
    GraphGaugeComponent,
    DragdropDirective,
    FolderPickerComponent,
    PowerbiReportComponent,
    PowerBiVisualComponent,
    BuildingPickerComponent
],
    exports: [
    TextboxComponent,
    SelectComponent,
    InputAutoSaveCamoComponent,
    DebounceInputComponent,
    ListControlFilterComponent,
    ListControlSearchComponent,
    HighlightPipe,
    NumWithCommasPipe,
    ListControlComponent,
    MapComponent,
    MapMultipleComponent,
    ToNumberPipe,
    GraphBarComponent,
    GraphBarStackedComponent,
    GraphLineComponent,
    GraphPieComponent,
    SelectAutoSaveComponent,
    CheckboxAutoSaveComponent,
    InputAutoSaveComponent,
    DateAutoSaveComponent,
    StandAloneTestComponent,
    SelectMultipleAutoSaveComponent,
    ChangeLogComponent,
    TextareaAutoSaveComponent,
    SumArrayPipe,
    GraphDonutComponent,
    BarChartComponent,
    SpinnerDialogComponent,
    BrokerCommissionComponent,
    BrokerCommissionDialogComponent,
    BrokerCommissionPcDialogComponent,
    ContactPickerComponent,
    CertificateUploadComponent,
    GraphTreemapComponent,
    WidgetIndicatorComponent,
    BudgetActualWidgetComponent,
    BudgetMarketWidgetComponent,
    ListControlDialogComponent,
    ListItemComponent,
    ListControlViewComponent,
    ListControlGroupSortComponent,
    ListControlTabsComponent,
    ListGroupComponent,
    ListResultComponent,
    ListFooterComponent,
    ListControlTopComponent,
    MessageDialogComponent,
    ConfirmationDialogComponent,
    MinusToParensPipe,
    ContactEditDialogComponent,
    ContactEditEmailComponent,
    ContactEditPhoneComponent,
    AddressEditDialogComponent,
    ContactSearchComponent,
    BadDataTicketComponent,
    BadDataComponent,
    TableComponent,
    BaseChartDirective,
    LeaseBarneyDialogComponent,
    LeaseBarneyUnitPickerComponent,
    GraphGaugeComponent,
    DragdropDirective,
    FolderPickerComponent,
    PowerbiReportComponent,
    PowerBiVisualComponent,
    BuildingPickerComponent
],
    providers: [
        SaveService,
        BrokerCommissionService,
        ContactPickerService,
        ContactService,
        DecimalPipe,
        CertificateUploadService,
        MapService,
        InputHelperService,
        ListGroupSortPipe,
        BadDataService,
        AddressService
    ],
})
export class SharedModule { }
