<div style="margin: 20px">
  <div>
    <h2>New Tournament</h2>
  </div>
  <form [formGroup]="bracketForm" (ngSubmit)="onSubmit()" novalidate>
    <div>
      <mat-form-field class="full-width">
				<mat-label>Year</mat-label>
        <input matInput  formControlName="year" />
      </mat-form-field>
      <button mat-raised-button color="primary" style="margin-left: 30px">
        Create
      </button>
      <button
        mat-raised-button
        color="primary"
        style="margin-left: 30px"
        type="button"
        (click)="pickTeams()"
      >
        Pick Teams
      </button>
    </div>
    <div class="region">
      <div>Region 1</div>
      <mat-form-field class="full-width">
				<mat-label>Name</mat-label>
        <input matInput  formControlName="region1Name" />
      </mat-form-field>
      <br />
      <cub-team-picker
        [teams]="teams"
        [seed]="1"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="16"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="8"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="9"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="5"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="12"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="4"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="13"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="6"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="11"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="3"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="14"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="7"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="10"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="2"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="15"
        (selectedTeam)="teamSelected(1, $event)"
      ></cub-team-picker>
    </div>
    <div class="region">
      <div>Region 2</div>
      <mat-form-field class="full-width">
				<mat-label>Name</mat-label>
        <input matInput  formControlName="region2Name" />
      </mat-form-field>
      <br />
      <cub-team-picker
        [teams]="teams"
        [seed]="1"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="16"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="8"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="9"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="5"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="12"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="4"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="13"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="6"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="11"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="3"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="14"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="7"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="10"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="2"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="15"
        (selectedTeam)="teamSelected(2, $event)"
      ></cub-team-picker>
    </div>
    <div class="region">
      <div>Region 3</div>
      <mat-form-field class="full-width">
				<mat-label>Name</mat-label>
        <input matInput  formControlName="region3Name" />
      </mat-form-field>
      <br />
      <cub-team-picker
        [teams]="teams"
        [seed]="1"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="16"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="8"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="9"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="5"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="12"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="4"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="13"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="6"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="11"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="3"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="14"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="7"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="10"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="2"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="15"
        (selectedTeam)="teamSelected(3, $event)"
      ></cub-team-picker>
    </div>
    <div class="region">
      <div>Region 4</div>
      <mat-form-field class="full-width">
				<mat-label>Name</mat-label>
        <input matInput  formControlName="region4Name" />
      </mat-form-field>
      <br />
      <cub-team-picker
        [teams]="teams"
        [seed]="1"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="16"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="8"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="9"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="5"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="12"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="4"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="13"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="6"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="11"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="3"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="14"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="7"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="10"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="2"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
      <cub-team-picker
        [teams]="teams"
        [seed]="15"
        (selectedTeam)="teamSelected(4, $event)"
      ></cub-team-picker>
    </div>
  </form>
</div>
