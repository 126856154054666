<div class="list-control-header">
  <!--
  <button *ngIf="showSystemList"
    mat-flat-button
    (click)="goToDashboard()"
    style="float: right; border-radius: 0px; height: 48px; "
    [class.dashSelected]="dashboardSelected"
    [class.dashNotSelected]="!dashboardSelected">
    <fa-icon [icon]="faTachometerAlt" class="fa-lg" title="Go to the dashboard"></fa-icon>
  </button>
  -->
  <div id="listTitle"
       style="">
    <fa-icon [icon]="faBars"
             class="fa-sm"
             style="float: left; margin-top: 6px; margin-right: 10px; cursor: pointer;  color: #fff; font-size: 25px;"
             [style.marginLeft]="'10px'"
             (click)="toggleSystemList()"></fa-icon>
    <button mat-button
            [disabled]="!canEditFilters"
            [matMenuTriggerFor]="menu"
            class="system-toolbar-button"
            style="display: inline-block; padding-left: 0; min-width: 0; max-width: 300px; background-color: #3B5286; color: #fff; margin-top: 2px;">
      <!--<span class="view-label">View:</span>-->
      <span class="view-name">
        {{ getCurrentViewName() }}
      </span>
      <fa-icon [icon]="faCaretDown"
               class="caret" style="margin-top: 10px; float: right;"></fa-icon>
    </button>
    <mat-menu class="menu-view"
              #menu="matMenu"
              [yPosition]="'below'"
              [xPosition]="'after'"
              [overlapTrigger]="false">
      <span class="list-filter-menu-label">My Views</span>
      <button *ngFor="let view of listViews"
              mat-menu-item
              (click)="selectView(view)">
        <fa-icon [icon]="faCheck"
                 *ngIf="view.selected"></fa-icon>
        <span *ngIf="!view.selected"
              class="spacer">&nbsp;</span>
        <span>{{ view.name }}</span>
        <fa-icon [icon]="faBookmark"
                 *ngIf="view.defaultView"
                 style="margin-left: 10px; color: #666;"></fa-icon>
      </button>
      <button class="separator menu-control"
              mat-menu-item></button>
      <span class="list-filter-menu-label menu-control">Edit this View</span>
      <button mat-menu-item
              class="menu-control"
              (click)="saveView()">
        <fa-icon [icon]="faSave"></fa-icon>
        <span>Save to this View</span>
      </button>
      <button mat-menu-item
              class="menu-control"
              (click)="showSaveAs()">
        <fa-icon [icon]="faPlus"></fa-icon>
        <span>Save as New View</span>
      </button>
      <button mat-menu-item
              class="menu-control"
              (click)="setViewAsDefault()">
        <fa-icon [icon]="faBookmark"></fa-icon>
        <span>Set as Default View</span>
      </button>
      <button mat-menu-item
              class="menu-control"
              (click)="showRename()">
        <fa-icon [icon]="faICursor"></fa-icon>
        <span>Rename this View</span>
      </button>
      <button mat-menu-item
              class="menu-control"
              (click)="deleteView()">
        <fa-icon [icon]="faTrashAlt"></fa-icon>
        <span>Delete this View</span>
      </button>
      <button class="separator menu-control"
              mat-menu-item></button>
      <button mat-menu-item
              class="menu-control"
              (click)="toggleSearch()">
        <span class="spacer"
              *ngIf="!listOptions.showSearch">&nbsp;</span>
        <fa-icon [icon]="faCheck"
                 *ngIf="listOptions.showSearch"></fa-icon>
        <span>Show Search</span>
      </button>
      <button mat-menu-item
              class="menu-control"
              (click)="toggleFilter()">
        <!--  style="border-bottom: solid 1px #eee;" -->
        <span class="spacer"
              *ngIf="!listOptions.showFilter">&nbsp;</span>
        <fa-icon [icon]="faCheck"
                 *ngIf="listOptions.showFilter"></fa-icon>
        <span>Show Filter</span>
      </button>
      <button class="separator menu-control"
              mat-menu-item></button>
      <button mat-menu-item
              class="menu-control"
              (click)="showHelp()">
        <fa-icon [icon]="faQuestionSquare"></fa-icon>
        <span>Help with Views</span>
      </button>
    </mat-menu>
  </div>
</div>
<!--
<fa-icon [icon]="faQuestionCircle"
         style="float: right; margin-right: 20px; margin-top: 8px; cursor: pointer; color: #fff; font-size: 1.6em"
         (click)="showHelp()"
         matTooltip="Help with Views"></fa-icon>
        -->
