import { Component, Input } from '@angular/core';
import { ListItem } from '../list-item/listItem';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'cub-list-footer',
    templateUrl: './list-footer.component.html',
    styleUrls: ['./list-footer.component.scss'],
    standalone: true,
    imports: [DecimalPipe]
})
export class ListFooterComponent {

  @Input() items: ListItem[];
  @Input() type: string;
}
