import { Lookup } from './lookup';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

export function createSelectValidator() {
  return (s: UntypedFormControl) => {
    let err = {
      notSelected: s.value
    };

    return (s.value === '' || +s.value === 0) ? err : null;
  };
}

@Component({
    selector: 'cub-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectComponent), multi: true }
    ],
    standalone: true,
    imports: [FormsModule, NgFor]
})
export class SelectComponent implements OnInit, ControlValueAccessor {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedValue') _selectedValue: number;
  @Input() values: Lookup[];
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor() { }

  ngOnInit() {
    this.validateFn = createSelectValidator();
  }

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val;
    this.propagateChange(val);
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
      this.selectedValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() { }


  toNumber() {
    this.selectedValue = +this.selectedValue;
  }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }
}
