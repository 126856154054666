

import { Component, OnInit, ViewEncapsulation, Input, OnChanges, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { RGB, HEX, Color } from '../../shared/colors';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import 'chartjs-plugin-annotation';

@Component({
    selector: 'cub-widget-line-graph-with-details',
    templateUrl: './widget-line-graph-with-details.component.html',
    styleUrls: ['./widget-line-graph-with-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgChartsModule]
})
export class WidgetLineGraphWithDetailsComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('lineChart', { static: true }) lineChart: ElementRef;
  @ViewChild(BaseChartDirective, { static: true }) baseChart;
  @Input() title: string;
  @Input() label: string;
  @Input() label2: string;
  @Input() hexColor: string;
  @Input() hexColor2: string;
  @Input() data: any[];
  @Input() dataType: string;
  @Input() chartLabels: string[];
  @Input() chartData = [];
  @Input() dataToShow: string;
  @Input() dataToShow2: string;
  labelClass = 'label';
  valueClass = 'value';
  barHeight = 100;
  dataMax = 0;
  dataMax2 = 0;
  chartWidth = 0;
  currentBarIndex = 0;
  color: Color;
  highlightColor = '';
  public chartType = 'line';
  chartVisible = 'hidden';

  public chartOptions = {

    layout: {
      padding: 5
    },

    tooltips: {
          callbacks: {
              label(tooltipItem, data) {
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                      label += ': ';
                  }
                  if (label === 'SF: ') {
                    label += Math.round(tooltipItem.yLabel * 100) / 100 * 4000;  // need to divide by the normalizing number.  good for now, but will need to update later
                  } else {
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                  }
                  return label;
              }
          }
      },

    scales: {
      yAxes: [{
        gridLines: {
          display: false,
          drawTicks: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        position: 'bottom',
        gridLines: {
          display: false,
          drawTicks: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }]
    },

    elements: {
      point: {
        pointStyle: 'rectRounded',
        radius: 0,
        hitRadius: 10,
        borderWidth: 0
      },
      line: {
        tension: 0.4,
        fill: false
      }
    },

    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],

    animation: {
      duration: 0, // general animation time
    },

    lineOnHover: {
      enabled: true,
      lineColor: '#006D15',
      lineWidth: 1
    },
    hover: {
      mode: 'nearest',
      intersect: true // duration of animations when hovering an item
    },
    defaultColor: '#006D15',
    // responsiveAnimationDuration: 0, // animation duration after a resize
    responsive: true
  };

  constructor(
    private dp: DecimalPipe,
    private cp: CurrencyPipe
    ) { }

  ngOnInit() {
    this.chartWidth = this.lineChart.nativeElement.offsetWidth;
    setTimeout(() => {
      this.updateChart();
    }, 30);
  }

  ngOnChanges() {
    this.chartWidth = this.lineChart.nativeElement.offsetWidth;

    if (this.hexColor !== undefined && this.hexColor !== '') {
      this.color = new Color(new HEX(this.hexColor));
      this.highlightColor = this.color.lighten(50).toString(false);
    }
    this.chartVisible = 'visible';
    if (this.baseChart.chart !== undefined) {
      this.updateChart();
    }
  }

  updateChart() {
    if (this.chartData.length > 0) {
      this.chartData[0]['borderColor'] = this.hexColor;
      this.chartData[0]['backgroundColor'] = this.hexColor;
    }
    if (this.chartData.length > 1) {
      this.chartData[1]['borderColor'] = this.hexColor2;
      this.chartData[1]['backgroundColor'] = this.hexColor2;
    }
    this.baseChart.chart.config.data.labels = this.chartLabels;
    this.baseChart.chart.config.data.datasets = this.chartData;

    // set min and max of graph within 10% of data
    let minVal = this.chartData[0]['data'].reduce((accumulator, currentValue, currentIndex, array) => (currentValue < accumulator ? currentValue : accumulator), 0);
    let maxVal = this.chartData[0]['data'].reduce((accumulator, currentValue, currentIndex, array) => (currentValue > accumulator ? currentValue : accumulator), 0);
    this.baseChart.chart.config.options.scales.yAxes[0].ticks.suggestedMin = minVal - (minVal * .10);
    this.baseChart.chart.config.options.scales.yAxes[0].ticks.suggestedMax = maxVal + (maxVal * .10);
    // this.baseChart.chart.config.options.elements.point.borderColor = this.hexColor;
    // this.baseChart.chart.config.options.elements.point.backgroundColor = this.hexColor2;
    // this.baseChart.chart.config.options.elements.line.borderColor = this.hexColor;
    // this.baseChart.chart.config.options.elements.line.backgroundColor = this.hexColor;
    this.baseChart.chart.update();
    this.chartVisible = 'visible';
  }

  ngAfterViewInit() {
    this.chartWidth = this.lineChart.nativeElement.offsetWidth;
  }

  getBarSpaceAbove(bar: any): number {
    let ht = 0;
    ht = this.barHeight - ((bar.value / this.dataMax) * this.barHeight);
    return ht;
  }

  getBarHeight(bar: any): number {
    let ht = 0;
    ht = bar.value / this.dataMax * this.barHeight;
    return ht;
  }

  getHighlightColor(index: number): string {
    return (index === this.currentBarIndex ? this.highlightColor : this.hexColor);
  }

  loadedData(e: any) {
    // TODO - This is an event handler in the template - Do we need this?
  }

  chartHover(e: any) {
    // TODO - This is an event handler in the template - Do we need this?
  }

  mouseover(e: any) {
    this.valueClass = 'value-hide';
    this.labelClass = 'label-hide';
    this.baseChart.chart.config.options.elements.point.radius = 3;
    this.baseChart.chart.update();
  }

  mouseout(e: any) {
    this.valueClass = 'value';
    this.labelClass = 'label';
    this.baseChart.chart.config.options.elements.point.radius = 0;
    this.baseChart.chart.update();
  }

  chartClicked(e: any) {
    // TODO - This is an event handler in the template - Do we need this?
  }


}
