import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { ReplaySubject } from 'rxjs';
import { BadDataTicket } from '../shared/bad-data-ticket/badDataTicket';

@Injectable()
export class MenuService {

  private showTopMenu = new ReplaySubject<boolean>(1);
  private showLeftMenu = new ReplaySubject<boolean>(1);
  private showSystemMenu = new ReplaySubject<boolean>(1);
  private minMaxLeftMenu = new ReplaySubject<boolean>(1);
  private showPoint = new ReplaySubject<boolean>(1);
  private badDataPayload = new ReplaySubject<BadDataTicket>(1);
  showingTopMenu$ = this.showTopMenu.asObservable();
  showingLeftMenu$ = this.showLeftMenu.asObservable();
  showingSystemMenu$ = this.showSystemMenu.asObservable();
  minMaxLeftMenu$ = this.minMaxLeftMenu.asObservable();
  showPoint$ = this.showPoint.asObservable();
  badDataPayload$ = this.badDataPayload.asObservable();

  screenWidth: number;

  constructor() {
    this.screenWidth = (window.innerWidth);
    this.showTopMenu.next(true);
    this.showLeftMenu.next(true);
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.showPoint.next(false);
    } else {
      this.showPoint.next(true);
    }
  }

  toggleTopMenu(show: boolean) {
    this.showTopMenu.next(show);
  }

  toggleLeftMenu(show: boolean) {
    this.showLeftMenu.next(show);
  }

  toggleMinMaxLeftMenu(show: boolean) {
    this.minMaxLeftMenu.next(show);
  }

  toggleSystemMenu(show: boolean) {
    this.showSystemMenu.next(show);
  }

  togglePoint(show: boolean) {
    this.showPoint.next(show);
  }

  sendBadDataPayload(payload: BadDataTicket) {
    this.badDataPayload.next(payload);
  }

}
