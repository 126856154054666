<div class="main">
  <div class="input-placeholder">
    <fa-icon [icon]="faSearch"
             class="fas"
             style="font-size: 17px;"></fa-icon>
    <input #listSearchBox
           id="listSearchBox"
           name="searchBox"
           class=""
           type="text"
           placeholder="Search list"
           (keyup)="search(listSearchBox.value)"
           [(ngModel)]="searchTerm" />
    <fa-icon *ngIf="listSearchBox.value.length > 0"
             [icon]="faTimes"
             class="fas"
             style="margin-left: 10px; font-size: 17px; cursor: pointer; "
             (click)="listSearchBox.value = '';search('')"></fa-icon>
  </div>
</div>
