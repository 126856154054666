import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { faCaretLeft } from '@fortawesome/pro-light-svg-icons/faCaretLeft';
import { UserMenu } from '../../user/user-menu';
import { LoggerService } from '../logger.service';
import { SubMenuService } from '../sub-menu.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'cub-side-sub-menu',
    templateUrl: './side-sub-menu.component.html',
    styleUrls: ['./side-sub-menu.component.scss'],
    animations: [
        trigger('showState', [
            state('inactive', style({
                opacity: '0',
                visibility: 'hidden'
            })),
            state('active', style({
                opacity: '1',
                visibility: 'visible'
            })),
            transition('inactive => active', animate('400ms ease-in-out')),
            transition('active => inactive', animate('400ms ease-in-out'))
        ]),
    ],
    standalone: true,
    imports: [NgIf, FontAwesomeModule, NgFor, RouterLinkActive, RouterLink]
})
export class SideSubMenuComponent implements OnInit {

  @Input() topY: number;
  @Input() clickedY: number;
  @Input() leftX: number;
  @Input() show: boolean;
  @Input() visible: boolean;
  @Input() bgColor: string;
  @Input() menuHeight: number;
  @Input() animationState: string;

  faCaretLeft = faCaretLeft;

  _menuList: UserMenu[] = [];
  @Input()
  set menuList(value: UserMenu[]) {
    this._menuList = value;
    this.menuShowing = value;
  }

  menuShowing: UserMenu[] = [];
  level = 0;

  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private menuService: SubMenuService
  ) { }

  ngOnInit() {
    this.show = false;
    this.animationState = 'inactive';
    this.menuHeight = 250;
    this.clickedY = 250;
    this.leftX = 179;

    this.menuService.topY$.subscribe(result => {
      this.clickedY = result;
    });

    this.menuService.leftX$.subscribe(result => {
      this.leftX = result;
    });

    this.menuService.toggleView$.subscribe(result => {
      this.show = result;
      this.animationState = (this.show ? 'active' : 'inactive');
      if (!this.show) {
        this.level = 0;
      }
    });

    this.menuService.menu$.subscribe(result => {
      this._menuList = result;
      this.menuShowing = result;
      if (this._menuList != null) {
        this.menuHeight = this.menuShowing.length * 35;
        this.topY = this.clickedY - (this.menuHeight / 2);
      }
    });
  }

  hideMenu(item: UserMenu) {
    this.loggerService.logPageVisit('left menu clicked', item.name);
    this.menuService.hideMenu();
  }

  menuClick(item: UserMenu) {
    this.level++;
    this.menuShowing = item.subMenu;
    this.menuHeight = (this.menuShowing.length + 1) * 35;
    this.topY = this.clickedY - (this.menuHeight / 2);
    console.log('menu', item);
    return false;
  }

  backMenu() {
    this.level--;
    this.menuShowing = this._menuList;
    this.menuHeight = this.menuShowing.length * 35;
    this.topY = this.clickedY - (this.menuHeight / 2);
    console.log('backMenu');
  }

  logLink(item: UserMenu) {
    this.loggerService.logPageVisit('left menu clicked', item.name);
  }

  goToUrl(url: string, localRoute: boolean): void {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = url.replace('http://cub/', 'https://cub.centerpoint.com/');
      newUrl = newUrl.replace('http://cubapi/', 'https://cub.centerpoint.com/');
    }
    if (localRoute) {
      this.router.navigate([newUrl]);
    } else {
      window.open(newUrl);
    }
  }

}
