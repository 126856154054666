import { DecimalPipe, NgIf, CurrencyPipe } from '@angular/common';
import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { faBox, faBoxOpen, faFolderOpen, faICursor } from '@fortawesome/pro-light-svg-icons';

import { Investment } from '..';
import { environment } from '../../../environments/environment';
import { Constants } from '../../core/constants';
import { ListService } from '../../core/list.service';
import { LoggerService } from '../../core/logger.service';
import { LookupService } from '../../core/lookup.service';
import { Lookup } from '../../shared/select/lookup';
import { InvestmentService } from '../investment.service';
import { ChangePacket } from 'src/app/shared/changePacket';
import { SaveService } from 'src/app/shared/save.service';
import { PropertyRenameComponent } from 'src/app/property/property-rename/property-rename.component';
import { SpinnerDialogComponent } from 'src/app/shared/spinner-dialog/spinner-dialog.component';
import { AlertService } from 'src/app/core/alert.service';
import { InvestmentRenameComponent } from '../investment-rename/investment-rename.component';
import { NumWithCommasPipe } from '../../shared/num-with-commas.pipe';
import { ChangeLogComponent } from '../../shared/change-log/change-log.component';
import { InvestmentPropListComponent } from '../investment-prop-list/investment-prop-list.component';
import { DateAutoSaveComponent } from '../../shared/date-auto-save/date-auto-save.component';
import { TextareaAutoSaveComponent } from '../../shared/textarea-auto-save/textarea-auto-save.component';
import { SelectMultipleAutoSaveComponent } from '../../shared/select-multiple-auto-save/select-multiple-auto-save.component';
import { SelectAutoSaveComponent } from '../../shared/select-auto-save/select-auto-save.component';
import { InputAutoSaveComponent } from '../../shared/input-auto-save/input-auto-save.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { CheckboxAutoSaveComponent } from '../../shared/checkbox-auto-save/checkbox-auto-save.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-investment-detail',
    templateUrl: './investment-detail.component.html',
    styleUrls: ['./investment-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }, { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }],
    standalone: true,
    imports: [NgIf, MatButtonModule, FontAwesomeModule, MatTooltipModule, CheckboxAutoSaveComponent, FormsModule, MatTabsModule, MatExpansionModule, InputAutoSaveComponent, SelectAutoSaveComponent, SelectMultipleAutoSaveComponent, TextareaAutoSaveComponent, DateAutoSaveComponent, InvestmentPropListComponent, ChangeLogComponent, CurrencyPipe, NumWithCommasPipe]
})
export class InvestmentDetailComponent implements OnInit {
  public inv: Investment;
  invKey: number;
  loadedCount = 0;
  regions: string;
  systemTable = 'acq_prospect';
  acqStatus: Lookup[] = [];
  dealMakers: Lookup[] = [];
  pipelineGroups: Lookup[] = [];
  invStrategy: Lookup[] = [];
  esgMatrix: Lookup[] = [];
  acqBidStatus: Lookup[] = [];
  chromeExtInstalled = false;

  faBox = faBox;
  faBoxOpen = faBoxOpen;
  faFolderOpen = faFolderOpen;
  faICursor = faICursor;

  constructor(
    private investmentService: InvestmentService,
    private route: ActivatedRoute,
    private dp: DecimalPipe,
    private lookupService: LookupService,
    private loggerService: LoggerService,
    private alertService: AlertService,
    private listService: ListService,
    private saveService: SaveService,
    public dialog: MatDialog,
    private renderer2: Renderer2,
    private spinnerDialog: MatDialog,
    private titleService: Title
  ) {
    this.inv = new Investment();
  }

  ngOnInit() {
    this.loadLookups();
    this.route.params.subscribe(params => {
      this.invKey = +params['id']; // (+) converts string 'id' to a number
      this.loadScreen();
      this.listService.setAsSelected(this.invKey.toString());
      this.loggerService.logPageVisit('inv detail', params['id']);
    });
    this.configureLinksForExtension();
  }

  loadScreen(): void {
    this.loadedCount = 0;
    this.getInvestment();
  }

  loadLookups() {
    this.acqStatus = this.lookupService.getTableValues('acqStatus');
    this.dealMakers = this.lookupService.getTableValues('dealMakers');
    this.pipelineGroups = this.lookupService.getTableValues('pipelineGroups');
    this.invStrategy = this.lookupService.getTableValues('invStrategy');
    this.esgMatrix = this.lookupService.getTableValues('esgMatrix');
    this.acqBidStatus = this.lookupService.getTableValues('acqBidStatus');
  }

  getInvestment(): void {
    this.investmentService.getInvestment(this.invKey).subscribe(i => {
      this.inv = i;
      if (this.inv.aaDt) { this.inv.aaDt = moment(i.aaDt).toDate(); }
      if (this.inv.icDt) { this.inv.icDt = moment(i.icDt).toDate(); }
      if (this.inv.ddStartDt) { this.inv.ddStartDt = moment(i.ddStartDt).toDate(); }
      if (this.inv.ddEndDt) { this.inv.ddEndDt = moment(i.ddEndDt).toDate(); }
      if (this.inv.dealStartDt) { this.inv.dealStartDt = moment(i.dealStartDt).toDate(); }
      if (this.inv.archivedDt) { this.inv.archivedDt = moment(i.archivedDt).toDate(); }
      if (this.inv.boardDt) { this.inv.boardDt = moment(i.boardDt).toDate(); }
      if (this.inv.createdDt) { this.inv.createdDt = moment(i.createdDt).toDate(); }
      if (this.inv.updatedDt) { this.inv.updatedDt = moment(i.updatedDt).toDate(); }
      if (this.inv.contractDt) { this.inv.contractDt = moment(i.contractDt).toDate(); }
      if (this.inv.goFirmDt) { this.inv.goFirmDt = moment(i.goFirmDt).toDate(); }
      if (this.inv.closingDt) { this.inv.closingDt = moment(i.closingDt).toDate(); }
      if (this.inv.builtDt) { this.inv.builtDt = moment(i.builtDt).toDate(); }
      if (this.inv.itcDt) { this.inv.itcDt = moment(i.itcDt).toDate(); }
      if (this.inv.giPreviewDt) { this.inv.giPreviewDt = moment(i.giPreviewDt).toDate(); }
      if (this.inv.prelimEsgDt) { this.inv.prelimEsgDt = moment(i.prelimEsgDt).toDate(); }
      if (this.inv.finalEsgDt) { this.inv.finalEsgDt = moment(i.finalEsgDt).toDate(); }
      if (this.inv.ddInspectionDt) { this.inv.ddInspectionDt = moment(i.ddInspectionDt).toDate(); }
      if (this.inv.addedToPipelineDt) { this.inv.addedToPipelineDt = moment(i.addedToPipelineDt).toDate(); }

      this.titleService.setTitle('CUB - Investment - ' + this.inv.name);

      this.inv.calpersShare = parseInt(this.inv.calpersShare.toString(), 10);
      this.regions = this.inv.properties.map(r => ' ' + r.regionDesc).toString();
    });
  }

  calpersReportToggle() {
    if (this.inv.onReportCalEast) {
      this.inv.addedToPipelineDt = moment().toDate();
      let changePacket = new ChangePacket('acq_prospect', 'addedToPipelineDt', 'Date Added To Pipeline', this.inv.key, '', moment(this.inv.addedToPipelineDt).format('MM/DD/YYYY'), moment(this.inv.addedToPipelineDt).format('MM/DD/YYYY'), 'datetime', 0);
      this.saveService.saveChange(changePacket)
        .then(returnVal => {
          // nothing;
        })
        .catch(() => { });
    } else {
      this.inv.addedToPipelineDt = null;
      let changePacket = new ChangePacket('acq_prospect', 'addedToPipelineDt', 'Date Added To Pipeline', this.inv.key, '', '', '', 'datetime', 0);
      this.saveService.saveChange(changePacket)
        .then(returnVal => {
          // nothing;
        })
        .catch(() => { });
    }
  }

  dateToString(dt: Date): string {
    let dateString = 'n/a';
    if (dt !== undefined && dt !== null) {
      dateString = moment(dt).format('L');
    }
    return dateString;
  }

  archive() {
    if (confirm('Are you sure you want to ' + (this.inv.archived ? 'bring back' : 'archive') + ' this prospect?')) {
      this.investmentService.toggleProspectArchival(this.inv.key, !this.inv.archived).subscribe(success => {
        if (success) {
          this.inv.archived = !this.inv.archived;
        } else {
        }
      });
    }
  }

  openFolder() {
    let url = '';
    this.loggerService.logPageVisit('open folder - inv detail', environment.egnyteUrl, this.inv.fullDirectoryPath.replace('shared', '\\\\egnytedrive\\allshare').replace('/', '\\'));
    if (this.chromeExtInstalled) {
      window.postMessage({ type: 'OpenFileExplorer', text: this.inv.fullDirectoryPath.replace('shared', '\\\\egnytedrive\\allshare').replace('/', '\\') }, '*');
    } else {
      url = environment.egnyteUrl + 'Acquisitions/Deals/' + this.inv.directoryPath;
      window.open(url, '_files');
    }
    return url;
  }

  makeCalculations() {
    this.inv.calpersShare = parseInt((parseInt(this.inv.tac.toString().replace(/,/g, ''), 10) * Constants.InvOwnershipPct).toString(), 10);
    this.inv.debtNew = (parseInt(this.inv.purchasePrice.toString().replace(/,/g, ''), 10) * this.inv.ltv) / 100;
    this.inv.debtTotal = this.inv.debtNew + this.inv.debtAssumed;
  }

  calculateAcres() {
    this.inv.acres = +this.dp.transform(+this.inv.landSF.toString().replace(/,/g, '') / 43560.0, '1.2-2');

    this.calculateCoverageRatio();
  }

  calculateCoverageRatio() {
    // calculate the coverageRatio
    if (this.inv.landSF.toString().length > 0 && this.inv.totalSF.toString().length > 0) {
      let landSf = parseFloat(this.inv.landSF.toString().replace(/,/g, ''));
      let totalSf = parseFloat(this.inv.totalSF.toString().replace(/,/g, ''));

      if (totalSf > 0 && landSf > 0) {
        this.inv.coverageRatio = totalSf / landSf;
      }
    }
  }

  closingDtSaved() {
    // need to update the closing of each property as well
    this.inv.properties.forEach(prop => {
      let changePacket = new ChangePacket('PROPERTY', 'closing_dt', 'Closing Date', prop.key, '', this.inv.closingDt.toString(), this.inv.closingDt.toString(), 'datetime', 0);
      this.saveService.saveChange(changePacket)
        .then(returnVal => {
          // nothing;
        })
        .catch(() => { });
    });
  }

  configureLinksForExtension() {
    let global = this.renderer2.listen('window', 'message', evt => {
      if (evt.data.type && evt.data.type === 'CUBExtIsInstalled') {
        this.chromeExtInstalled = true;
      }
    });
    setTimeout(() => {
      window.postMessage({ type: 'testCUBExtInstalled', text: '' }, '*');
    }, 500);
    // this is where the page is trying to determine if the CUB extension is installed.  the extension is listening for this message.
    if (!document.location.href.startsWith('https')) {
    }
  }

  displayTAC(tac: number): number {
    let num = tac;
    if (tac === undefined || tac === null || tac.toString() === '') {
      num = 0;
    }
    return num;
  }

  renameClick() {
    let dialogRef = this.dialog.open(InvestmentRenameComponent, {
      width: '650px',
      position: { top: '200px' },
      data: { name: this.inv.name, path: this.inv.directoryPath.replace(environment.invDirPath, ''), city: this.inv.properties[0].address.city, stateId: this.inv.properties[0].address.stateID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'renamed') {
        let spinnerRef = this.spinnerDialog.open(SpinnerDialogComponent, {
          disableClose: true,
          width: '450px',
          data: { mode: 'spinner', title: 'Renaming the property and the folder', content: 'This may take a few seconds - please be patient.' }
        });
        console.log('rename', this.inv.key, environment.invDirPath + this.inv.directoryPath, result.newName, environment.invDirPath + result.newPath);
        this.investmentService.rename(this.inv.key, this.inv.directoryPath, result.newName, result.newPath).subscribe(success => {
          spinnerRef.close();
          if (success) {
            this.alertService.success('CUB successfully renamed this investment!  The screen will now be refreshed to reflect the new changes.');
            setTimeout(() => {
              this.loadScreen();
            }, 2000);
          }
          else {
            this.alertService.error('Sorry, CUB was unable to rename this investment.');
          }
        });
      }
    });
  }
}
