import { Address } from 'src/app/shared/address';

export class Contact {
  pcKey: number;
  isPerson: boolean;
  isEmployee: boolean;
  accountName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  companyName: string;
  fullName: string;
  salutationKey: number;
  salutation: string;
  mainAddress: string;
  mainEmailAddress: string;
  mainPhoneNumber: string;
  jobTitle: string;
  name: string;
  companyKey: number;
  contactTypes: ContactType[];
  emailAddresses: EmailAddress[];
  phoneNumbers: PhoneNumber[];
  addresses: Address[];
  relationships: Relationship[];
  lastDt: Date;
  notes: string;

  constructor() {
    this.pcKey = 0;
    this.fullName = '';
    this.companyName = '';
    this.contactTypes = [];
    this.emailAddresses = [];
    this.phoneNumbers = [];
    this.addresses = [];
    this.relationships = [];
  }
}

export class EmailAddress {
  emailKey: number;
  email: string;
  typeKey: number;
  type: string;

  // local
  editMode: boolean;

  constructor() {
    this.emailKey = 0;
    this.typeKey = 1;
    this.email = '';
    this.type = '';
    this.editMode = false;
  }
}

export class PhoneNumber {
  phoneKey: number;
  number: string;
  typeKey: number;
  type: string;

  // local
  editMode: boolean;

  constructor() {
    this.phoneKey = 0;
    this.typeKey = 1;
    this.number = '';
    this.type = '';
    this.editMode = false;
  }
}

export class ContactType {
  typeKey: number;
  type: string;

  constructor(key?: number, name?: string) {
    if (key) {
      this.typeKey = key;
    }
    if (key) {
      this.type = name;
    }
  }
}

export class ContactSearchParameters {
  text: string;
  includePeople: boolean;
  includeCompanies: boolean;
  includeEmployees: boolean;
  mostRecent: boolean;
  take: number;
  pcTypeKeys: number[];

  constructor(text?: string) {
    if (text) {
      this.text = text;
    } else {
      this.text = '';
    }
    this.includePeople = true;
    this.includeCompanies = true;
    this.includeEmployees = true;
    this.mostRecent = false;
    this.take = 50;
    this.pcTypeKeys = [];
  }
}

export class Relationship {
  type: string;
  typeKey: number;
  systemTypeDesc: string;
  objectKey: number;
  objectID: string;
  objectDesc: string;
  objectUrl: string;
}
