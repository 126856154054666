<div class="graph-pie"
     [style.width.px]="chartSize"
     [style.height.px]="chartSize"
     style="width: 50%; margin: 0 auto;">
  <canvas baseChart
          [data]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [legend]="showLegend"
          [type]="barChartType"
          [height]="chartSize"
          [width]="chartSize"
          (chartClick)="chartClicked($event)"
          class="graph">
  </canvas>
</div>
