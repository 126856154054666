import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray, UntypedFormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { AlertService } from '../../core/alert.service';
import { LoggerService } from '../../core/logger.service';
import { FileService } from '../../core/file.service';
import { Investment } from '../investment';
import { SpinnerDialogComponent } from '../../shared/spinner-dialog/spinner-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-investment-rename',
    templateUrl: './investment-rename.component.html',
    styleUrls: ['./investment-rename.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class InvestmentRenameComponent implements OnInit {

  newName: string;
  newDirectoryPath: string;
  city: string;
  stateId: string;

  constructor(
    public dialogRef: MatDialogRef<InvestmentRenameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string, path: string, city: string, stateId: string },
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private fileService: FileService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    // initialize the property variable with a copy of the data passed from the calling component via deep copy
    this.newDirectoryPath = this.data.path;
    this.newName = this.data.name;
    this.city = this.data.city;
    this.stateId = this.data.stateId;

    this.loggerService.logPageVisit('inv rename', '');
    this.dialogRef.updateSize('600px', '');
  }

  keyup(evt: any) {
    this.newName = evt.target.value;
    this.newDirectoryPath = this.fixNameForPath(evt.target.value) + ', ' + this.city + ', ' + this.stateId;
  }

  fixNameForPath(name: string): string {
    let newPath = name;

    // remove
    newPath = newPath.split('\\').join('');
    newPath = newPath.split('/').join('');
    newPath = newPath.split('/').join('');
    newPath = newPath.split('.').join('');
    newPath = newPath.split(':').join('');
    newPath = newPath.split('*').join('');
    newPath = newPath.split('?').join('');
    newPath = newPath.split('\'').join('');
    newPath = newPath.split('<').join('');
    newPath = newPath.split('>').join('');
    newPath = newPath.split('|').join('');
    newPath = newPath.split('#').join('');
    newPath = newPath.split('%').join('');
    newPath = newPath.split('$').join('');
    newPath = newPath.split('`').join('');

    // replace
    newPath = newPath.split('&').join('-');
    newPath = newPath.trim();

    return newPath;
  }

  clickClose() {
    this.dialogRef.close({ status: 'closed' });
  }

  clickRename() {
    // this will just return the new data, have the calling screen save the data since it will be different for props vs inv vs devs
    let returnData = {
      status: 'renamed',
      newName: this.newName,
      newPath: this.newDirectoryPath
    };
    this.dialogRef.close(returnData);
  }
}
