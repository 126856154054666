
<div class="list-group-sort" style="display: grid; grid-template-columns: 1fr 1fr; border-bottom: solid 1px #ddd; background-color: #f4f4f4;">
  <div>
    <button mat-button [matMenuTriggerFor]="groupMenu" title="Grouping by {{ getSelectedGroupName() }}" *ngIf="listOptions.groupBy.length > 0" style="font-weight: 400; font-size: 13px;">
      <fa-icon [icon]="getGroupIcon()" style="font-size: 15px; font-weight: 400;"></fa-icon>
       &nbsp;
       <span *ngIf="selectedGroup.name !== 'Nothing'" class="groupSortLabel" style="font-weight: 400;">{{ getSelectedGroupName() }}</span>
       <span *ngIf="selectedGroup.name === 'Nothing'" style="font-weight: 400;">Group By</span>
       <fa-icon [icon]="faCaretDown"  style="font-size: 12px; color: #aaa; margin-left: 5px; "></fa-icon>
    </button>
    <mat-menu #groupMenu="matMenu" [yPosition]="'below'" [xPosition]="'after'" [overlapTrigger]="false" title="Group By">
      <button class="list-group-sort-menu-button" mat-menu-item *ngFor="let groupField of listOptions.groupBy | filterBySystem:system" (click)="onGroupChange(groupField);" >
        <span class="spacer" *ngIf="!groupField.selected">&nbsp;</span>
        <fa-icon [icon]="faCheck" *ngIf="groupField.selected"></fa-icon>
        <span>{{ groupField.name }}</span>
      </button>
    </mat-menu>
  </div>
  <div style="text-align: right;">
    <button mat-button [matMenuTriggerFor]="sortMenu" title="Sorting by {{ getSelectedSortName() + (sortingAsc ? ' ascending' : ' descending') }}" *ngIf="listOptions.sortBy.length > 0" style="font-weight: 400; font-size: 13px;">
      <fa-icon [icon]="getSortIcon()" style="font-size: 15px; font-weight: 400;"></fa-icon>
      &nbsp;
      <span class="groupSortLabel" style="font-weight: 400;">{{ getSelectedSortName() }}</span>
      <fa-icon [icon]="faCaretDown"  style="font-size: 12px; color: #aaa; margin-left: 5px; "></fa-icon>
    </button>
    <mat-menu #sortMenu="matMenu" [yPosition]="'below'" [xPosition]="'before'" [overlapTrigger]="false" title="Sort By">
      <button class="list-group-sort-menu-button" mat-menu-item *ngFor="let sortField of listOptions.sortBy | filterBySystem:system" (click)="onSortChange(sortField)">
        <span class="spacer" *ngIf="!sortField.selected">&nbsp;</span>
        <fa-icon [icon]="getSortDirectionIcon(sortField)" *ngIf="sortField.selected"></fa-icon>
        <span>{{ sortField.name }}</span>
      </button>
    </mat-menu>
  </div>
</div>
