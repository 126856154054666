
<div matRipple
  [matRippleColor]="rippleColor"
  *ngIf="mode === 'twoCol'"
  class="list-item-container list-item-container-two-col"
  (click)="clickedItem()"
  (contextmenu)="contextMenu($event)"
  [class.active]="_active"
  [class.nonActive]="!_active">
  <div>
    <div [innerHTML]="title1 | highlight:searchTerm"></div>
    <div [innerHTML]="title3 | highlight:searchTerm"></div>
  </div>
  <div>
    <div [innerHTML]="title2 | highlight:searchTerm"></div>
    <div [innerHTML]="title4 | highlight:searchTerm"></div>
  </div>
</div>

<div matRipple
  [matRippleColor]="rippleColor"
  *ngIf="mode === 'twoColLease'"
  class="list-item-container list-item-container-lease list-item-container-two-col"
  (click)="clickedItem()"
  (contextmenu)="contextMenu($event)"
  [class.active]="_active"
  [class.nonActive]="!_active">
  <div>
    <div [innerHTML]="title1 | highlight:searchTerm"></div>
    <div [innerHTML]="title3 | highlight:searchTerm"></div>
  </div>
  <div>
    <div [innerHTML]="title2 | highlight:searchTerm"></div>
    <div [innerHTML]="title4 | highlight:searchTerm"></div>
  </div>
</div>

<div matRipple
  [matRippleColor]="rippleColor"
  *ngIf="mode === 'oneCol'"
  class="list-item-container list-item-container-one-col"
  (click)="clickedItem()"
  [class.active]="_active"
  [class.nonActive]="!_active">
  <div>
    <div [innerHTML]="title1 | highlight:searchTerm"></div>
  </div>
  <div>
    <div [innerHTML]="title2 | highlight:searchTerm"></div>
  </div>
</div>
