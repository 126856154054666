<h2 style="text-align: center;">New Feature Title</h2>
<div style="text-align: center; margin: 25px;">
  <img src="assets/img/popups/cubs.png"
       style="width: 100px;">
</div>
<p style="height: 200px;">
  Text here
</p>
<div style="margin: 20px;">
  <button mat-raised-button
          class="button-standard"
          style="float: right;"
          color="primary"
          (click)="done()">Thanks</button>
</div>
