import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray, UntypedFormBuilder, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BadDataTicket } from './badDataTicket';
import { BadDataService } from '../bad-data-ticket/bad-data.service';
import { LoggerService } from '../../core/logger.service';
import { AlertService } from '../../core/alert.service';
import { Constants } from '../../core/constants';

import * as moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-bad-data-ticket',
    templateUrl: './bad-data-ticket.component.html',
    styleUrls: ['./bad-data-ticket.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class BadDataTicketComponent implements OnInit {
  ticket: BadDataTicket;
  fgBadData: UntypedFormGroup;
  hasResolution = false;
  badDataTitle = 'Describe what’s wrong and and what the value should be.';
  resolutionTitle = 'Describe how the issue was resolved.';
  statusTitle = 'Open Issue';
  systemTitle = 'Open Issue';
  createdBy = '';
  resolvedBy = '';

  constructor(
    public dialogRef: MatDialogRef<BadDataTicketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ticket: BadDataTicket },
    private fb: UntypedFormBuilder,
    public loadingDialog: MatDialog,
    private ticketService: BadDataService,
    private alertService: AlertService,
    private loggerService: LoggerService
  ) {
    this.ticket = new BadDataTicket();
  }

  ngOnInit() {
    // if (/Android|webOS|iPhone|iPad|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //   this.dialogRef.updateSize('675px', '600px');  // w,h
    //   // this.dialogRef.updatePosition({ top: '80px', left: '175px' });
    // } else {
    //   this.dialogRef.updateSize('675px', '600px');  // w,h
    //   // this.dialogRef.updatePosition({ top: '300px', left: '500px' });
    //   // this.dialogRef.updatePosition({ top: '300px'});
    // }

    this.loggerService.logPageVisit('bad data ticket', '');
    this.ticket = this.data.ticket;
    // console.log('passed ticket: ', this.ticket);

    this.initializeVariables();
    this.createFormControls();
  }

  initializeVariables() {
    // set the createdBy/resolvedBy values
    if (this.ticket.ticketKey > 0 && this.ticket.statusKey === Constants.BadDataStatusResolved) {
      this.createdBy = 'Bad data reported on ' + moment(this.ticket.creatorDt).format('MM/DD/YY') + ' by ' + this.ticket.creatorName;
      this.resolvedBy = 'Resolved on ' + moment(this.ticket.resolutionDt).format('MM/DD/YY') + ' by ' + this.ticket.resolutionName;
    } else if (this.ticket.ticketKey > 0 && this.data.ticket.statusKey === Constants.BadDataStatusOpen) {
      this.createdBy = 'Bad data reported on ' + moment(this.ticket.creatorDt).format('MM/DD/YY') + ' by ' + this.ticket.creatorName;
      this.resolvedBy = '';
    } else {
      this.createdBy = '';
      this.resolvedBy = '';
    }

    // set the placeholder for the status
    if (this.ticket.statusKey === Constants.BadDataStatusOpen) {
      this.statusTitle = 'Open Issue';
    } else {
      this.statusTitle = 'Resolved Issue';
    }

    // set the placeholder for the description/resolution
    if (this.ticket.statusKey > 0) {
      this.badDataTitle = 'Bad Data Description';
      this.resolutionTitle = 'Resolution';
    }
  }

  createFormControls() {
    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    if (this.ticket.ticketKey === 0) {
      this.fgBadData = this.fb.group({
        location: new UntypedFormControl(this.ticket.location, [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        resolution: new UntypedFormControl({ value: '', disabled: true }),
        assignees: new UntypedFormControl({ value: this.ticket.assignees.map(a => a.fullName).join(', '), disabled: true })
      });
    } else {
      this.fgBadData = this.fb.group({
        location: new UntypedFormControl(this.ticket.location, [Validators.required]),
        description: new UntypedFormControl({ value: this.ticket.description, disabled: true }, [Validators.required]),
        resolution: new UntypedFormControl(this.ticket.resolution),
        assignees: new UntypedFormControl({ value: this.ticket.assignees.map(a => a.fullName).join(', '), disabled: true })
      });
    }
  }

  clickClose() {
    if (this.fgBadData.dirty) {
      if (confirm('This bad data ticket has changed. Are you sure you want to close without saving?')) {
        this.dialogRef.close({ status: 'closed' });
      }
    } else {
      this.dialogRef.close({ status: 'closed' });
    }
  }

  clickReportBadData() {
    this.ticket.location = this.fgBadData.controls.location.value;
    this.ticket.description = this.fgBadData.controls.description.value;

    this.ticketService.openTicket(this.ticket)
      .subscribe((result: BadDataTicket) => {

        if (result.ticketKey > 0) {
          this.ticket = result;
          this.sendEmail();
          this.dialogRef.close({ status: 'saved' });
        } else {
          this.alertService.error('Sorry but there was a problem opening this ' + this.ticket.systemDesc + ' Bad Data Ticket. Please contact MIS.');
        }
      });
  }

  clickResolve() {
    this.ticket.resolution = this.fgBadData.controls.resolution.value;
    this.ticketService.resolveTicket(this.ticket.ticketKey.toString(), this.ticket).subscribe((result: BadDataTicket) => {
      if (result.resolutionDt !== null) {
        this.ticket = result;
        this.sendResolvedEmail();
        this.dialogRef.close({ status: 'saved' });
      } else {
        this.alertService.error('Sorry but there was a problem resolving this ' + this.ticket.systemDesc + ' Bad Data Ticket. Please contact MIS.');
      }
    });
  }

  sendEmail() {
    let uri = '';
    let cc = this.buildAdditionalRecipients();
    let recipients = this.ticket.assignees.map(a => a.fullName).join('; ');
    let subject = 'Bad Data: ' + this.ticket.location;
    let body = '** BAD DATA NOTIFICATION **\r\n';

    body += '\r\n' + this.ticket.systemDesc + ': ' + this.ticket.location;
    body += '\r\n' + 'Description: ' + this.ticket.description;
    body += '\r\n' + 'Opened by: ' + this.ticket.creatorName + ' on: ' + moment(this.ticket.creatorDt).format('MM/DD/YYYY');

    if (this.ticket.route !== '' && this.ticket.route !== null) {
      body += '\r\n\r\n' + 'Link: ' + this.ticket.route;
    }

    uri = 'mailto:' + recipients + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body) + cc;
    window.location.href = uri;
  }

  sendResolvedEmail() {
    let uri = '';
    let cc = this.buildAdditionalRecipients();
    let recipients = this.ticket.assignees.map(a => a.fullName).join('; ');
    let subject = 'Bad Data: ' + this.ticket.location;
    let body = '** BAD DATA RESOLUTION **\r\n';

    body += '\r\n' + this.ticket.systemDesc + ': ' + this.ticket.location;
    body += '\r\n' + 'Resolution: ' + this.ticket.resolution;
    body += '\r\n' + 'Resolved by: ' + this.ticket.resolutionName + ' on: ' + moment(this.ticket.resolutionDt).format('MM/DD/YYYY');

    if (this.ticket.route !== '' && this.ticket.route !== null) {
      body += '\r\n\r\n' + 'Link: ' + this.ticket.route;
    }

    uri = 'mailto:' + recipients + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body) + cc;
    window.location.href = uri;
  }


  buildAdditionalRecipients() {
    let recipients = this.ticket.assignees.map(a => a.fullName).join('; ');
    let cc = '';

    // add the person who created the ticket if not in the list already
    if (this.ticket.creatorUID !== '') {
      let creatorEmail = this.ticket.creatorUID.toLowerCase().replace('centerpoint-pro\\', '') + '@centerpoint.com';
      if (recipients.search(creatorEmail) === -1) {
        cc = creatorEmail + '; ';
      }
    }

    // add the person who resolved the ticket if not in the list already
    if (this.ticket.resolutionUID !== '') {
      let resolverEmail = this.ticket.resolutionUID.toLowerCase().replace('centerpoint-pro\\', '') + '@centerpoint.com';
      if (recipients.search(resolverEmail) === -1) {
        if (cc !== '') {
          cc = '; ' + resolverEmail;
        } else {
          cc = resolverEmail;
        }
      }
    }

    // add the parameter to cc for the mailto
    if (cc !== '') {
      cc = '&cc=' + cc;
    }

    return cc;
  }

  blurResolution() {
    if (this.fgBadData.controls.resolution.value !== '') {
      this.hasResolution = true;
    } else {
      this.hasResolution = false;
    }
  }

}
