import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../user';

@Pipe({
    name: 'userListFilter',
    standalone: true
})
export class UserListFilterPipe implements PipeTransform {

  transform(items: User[], department: string, office: string, searchText: string, employeesOnly: boolean): any {
    let arr = items;
    if (!items || !department) {
      return items;
    } else {

      if (department !== 'All') {
        arr = arr.filter(u => u.department.toLowerCase().trim() === department.toLowerCase().trim());
      }
      if (office !== 'All') {
        arr = arr.filter(u => u.officeLocation.toLowerCase().trim() === office.toLowerCase().trim());
      }
      if (searchText.length > 0) {
        arr = arr.filter(u => u.firstName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0 ||
          u.lastName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0 ||
          u.jobTitle.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0);
      }
      if (employeesOnly) {
        arr = arr.filter(u => u.isRealUser);
      }
    }
    return arr;
  }
}

