import { Component } from '@angular/core';

@Component({
    selector: 'cub-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
    standalone: true
})
export class TextboxComponent {

}
