export class Lookup {
  id: string;
  name: string;
  selected: boolean;
  parentId: string;

  constructor(id: string, name: string, parentId?: string) {
    this.id = id;
    this.name = name;
    this.parentId = parentId;
    this.selected = false;
  }
}
