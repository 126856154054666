import { Component, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';

import * as d3 from 'd3';
import * as d3Sel from 'd3-selection';
declare var d4: any;

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
    selector: 'cub-graph-treemap',
    templateUrl: './graph-treemap.component.html',
    styleUrls: ['./graph-treemap.component.scss'],
    standalone: true
})
export class GraphTreemapComponent implements OnChanges {

  @Input() header: string;
  @Input() treemapData: any;
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  private width: number;
  private height: number;

  private margin: Margin;

  private svg: any;
  private treemap: any;
  color: any;
  private g: any;
  private root: any;
  private stratify: any;
  private format: any;
  private ratio = 2;

  constructor(private container: ElementRef) { }

  private initMargins() {
    this.margin = { top: 40, right: 40, bottom: 40, left: 40 };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initMargins();
    this.initSvg();
    this.drawChart();
  }

  private initSvg() {
    d3.select('#treemap > *').remove();
    this.svg = d3Sel.select(this.container.nativeElement).select('svg');

    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;

    this.color = d3.scaleOrdinal()
      .range(d3.schemeCategory10
        .map(function (c: any) { c = d3.rgb(c); c.opacity = 0.6; return c; }));


    this.stratify = d3.stratify().parentId(function (d: any) { return d.id.substring(0, d.id.lastIndexOf('.')); });

    this.treemap = d3.treemap()
      .tile(d3.treemapSquarify.ratio(1))
      .size([this.width / this.ratio, this.height]);

    this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private drawChart() {
    let node = this.root = this.treemapData;

    if (this.treemapData) {
      let root = this.stratify(this.treemapData)
        .sum((d) => d.value)
        .sort((a, b) => b.height - a.height || b.value - a.value);

      this.g
        .selectAll('.node')
        .data(root.leaves())
        .enter().append('div')
        .attr('class', 'node')
        .attr('title', function (d) { return d.id + '\n' + d.value; })
        .style('left', function (d) { return 100 + 'px'; })
        .style('top', function (d) { return 200 + 'px'; })
        .style('width', function (d) { console.log('kg width', d, d.x1, d.x0); return 500 + 'px'; })
        .style('height', function (d) { console.log('kg height', d, d.y1, d.y0); return 200 + 'px'; })
        .style('background', function (d) { while (d.depth > 1) { d = d.parent; } return d3.rgb(40, 40, 40); })
        .append('div')
        .attr('class', 'node-label')
        .text(function (d) { return d.id.substring(d.id.lastIndexOf('.') + 1); })
        .append('div')
        .attr('class', 'node-value')
        .text(function (d) { return d.value; });
    }
  }
}
