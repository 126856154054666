
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DecimalPipe, NgIf, NgFor } from '@angular/common';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  fal, faFileExcel, faColumns, faEnvelope, faDownload, faChevronRight, faChevronDown, faClock, faFluxCapacitor, faEllipsisV
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCaretDown, faColumns as faColumns2, faSortUp, faSortDown, faBinoculars } from '@fortawesome/pro-solid-svg-icons';
import { DataColumn } from 'src/app/core/dataColumn';
import { TableAction, TableActionEvent } from './tableAction';
import * as moment from 'moment';
import * as XLSX from '@sheet/core';
import { DataColumnView } from 'src/app/core/dataColumnView';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';


interface ColumnNode {
  columnData?: DataColumn;
  children?: ColumnNode[];
}

@Component({
    selector: 'cub-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, FontAwesomeModule, MatFormFieldModule, MatInputModule, NgIf, NgFor, MatSidenavModule, MatSelectModule, MatOptionModule, MatTreeModule, MatCheckboxModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, MatMenuModule]
})
export class TableComponent {

  @Input()
  set listItems(value: any[]) {
    this._listItems = value;
    this.allListItems = value;
    this.loadViewByKey(this._selectedViewKey);
  }
  @Input()
  set views(value: DataColumnView[]) {
    this._views = value;
    this.loadViewByKey(this._selectedViewKey);
  }
  @Input()
  set selectedViewKey(value: number) {
    this._selectedViewKey = value;
    this.loadViewByKey(this._selectedViewKey);
  }
  @Input()
  set gridHeightPercent(value: number) {
    this._mainHeight = value.toString() + 'vh';
  }

  @Input() actions: TableAction[];
  @Input() title: string;
  @Output() actionSelected: EventEmitter<TableActionEvent> = new EventEmitter();

  _listItems: any[] = [];
  _columns: DataColumn[] = [];
  columnTreeData: ColumnNode[] = [];
  _views: DataColumnView[] = [];
  _selectedViewKey: number;
  _mainHeight = '100vh'

  mainTableScrollLeft = 0;
  sortColumn: DataColumn;
  sortDirection = 'desc';
  displayedColumnHeaders: DataColumn[] = [];
  stickyColumns: DataColumn[] = [];
  nonStickyColumns: DataColumn[] = [];
  filterText = '';
  allListItems: any[] = [];

  faCaretDown = faCaretDown;
  faFileExcel = faFileExcel;
  faColumns = faColumns;
  faColumns2 = faColumns2;
  faEnvelope = faEnvelope;
  faDownload = faDownload;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faClock = faClock;
  faFluxCapacitor = faFluxCapacitor;
  faEllipsisV = faEllipsisV;
  faBinoculars = faBinoculars;

  treeControl = new NestedTreeControl<ColumnNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ColumnNode>();

  constructor(
    private dp: DecimalPipe,
    library: FaIconLibrary
  ) {
  }

  hasChild = (_: number, node: ColumnNode) => !!node.children && node.children.length > 0;

  loadColumnsIntoTree() {
    this.columnTreeData = [];
    if (this._columns !== undefined) {
      this._columns.forEach(c => {
        let newNode = { columnData: c, children: [] };
        this.columnTreeData.push(newNode);
      });
      this.dataSource.data = this.columnTreeData;
    }
  }

  viewChange(evt: MatSelectChange) {
    this.loadViewByKey(evt.value);
  }

  loadViewByKey(key: number) {
    console.log('view change, loadViewByKey: ', this._views, this._selectedViewKey);
    if (this._views && key) {
      let view = this._views.filter(v => v.key.toString() === key.toString())[0];
      if (view) {
        view.fields.forEach(f => {
          f.selected = true;
        });
        this._columns = view.fields;
        this.loadColumnsIntoTree();
        this.refreshColumns();
      }
    }
  }

  columnChanged(node: ColumnNode) {
    node.columnData.selected = !node.columnData.selected;
    this.columnTreeData.forEach(parent => {
      if (parent.children.filter(c => c.columnData.key === node.columnData.key).length === 1) {
        if (!node.columnData.selected) { // if this node not selected, deselect parent
          parent.columnData.selected = false;
        } else if (parent.children.filter(c => c.columnData.selected).length === parent.children.length) { // if all children are selected, select parent
          parent.columnData.selected = true;
        }
      }
    });
    this.refreshColumns();
  }

  parentColumnChanged(node: ColumnNode) {
    node.columnData.selected = !node.columnData.selected;
    this.columnTreeData.filter(n => n.columnData.name === node.columnData.name)[0].children.forEach(c => {
      c.columnData.selected = node.columnData.selected;
    });
    this.refreshColumns();
  }

  refreshColumns() {
    this.displayedColumnHeaders = this._columns.filter(c => c.selected);
    this.stickyColumns = this._columns.filter(c => c.selected && c.fixedInTable);
    this.nonStickyColumns = this._columns.filter(c => c.selected && !c.fixedInTable);
    this.sortColumn = this.stickyColumns[0];
    // console.log('column refresh', this.displayedColumnHeaders, this.stickyColumns, this.nonStickyColumns, this.sortColumn);
  }

  applyFilter(filter: any) {
    this.filterText = filter;
    if (this.filterText && this.filterText.length > 0) {
      this._listItems = [];
      this.allListItems.forEach(listItem => {
        let found = false;  // if we've already found the text in a field for the item, we don't have to keep searching
        this._columns.filter(c => c.selected).forEach(col => {
          if (!found && listItem[col.objectPropertyName] !== null && (listItem[col.objectPropertyName]).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1) {
            this._listItems.push(listItem);
            found = true;
          }
        });
      });
    } else {
      this._listItems = Object.assign([], this.allListItems);
    }
  }

  actionClick(action: TableAction, listItem: any) {
    this.actionSelected.emit(new TableActionEvent(action, listItem));
  }

  columnClick(col: DataColumn, listItem: any) {
    this.actions.forEach(a => {
      if (a.columnKeys && a.columnKeys.includes(col.key)) {
        this.actionClick(a, listItem);
      }
    });
  }

  isClickable(col: DataColumn, listItem: any): boolean {
    let clickable = false;
    this.actions.forEach(a => {
      if (a.columnKeys && a.columnKeys.includes(col.key)) {
        clickable = true;
      }
    });
    return clickable;
  }

  onScroll(evt: any) {
    this.mainTableScrollLeft = evt.target.scrollLeft;
  }

  isNumericColumn(col: DataColumn): boolean {
    return col.dataType === 'int' || col.dataType === 'float' || col.dataType === 'percent' || col.dataType === 'number' || col.dataType === 'currency' || col.dataType === 'money';
  }

  getColWidthTotal(): number {
    return this.displayedColumnHeaders.reduce((p, c) => p + c.minWidth, 0) + 30;  // 5 for padding I think, 30 for width of ellipse button
  }

  isLastFixedCol(col: DataColumn): boolean {
    return this.stickyColumns[this.stickyColumns.length - 1].key === col.key;
  }

  getLeftForFixedColumn(col: DataColumn, i: number): number {
    let left = 0;
    if (col.fixedInTable) {
      left = 30;
      for (let j = 0; j < i; j++) {
        left += this.stickyColumns[j].minWidth;
      }
    }
    return left;
  }

  getPrefix(col: DataColumn): string {
    let val = '';
    if (col.dataType === 'currency' || col.dataType === 'money') {
      val = '$';
    }
    return val;
  }

  getSuffix(col: DataColumn): string {
    let val = '';
    if (col.dataType === 'percent') {
      val = '%';
    }
    return val;
  }

  getFooterValue(col: DataColumn): string {
    let pre = this.getPrefix(col);
    let post = this.getSuffix(col);
    if (col.footer === 'Total') {
      return col.footer;
    } else if (col.footer === 'sum' && this._listItems) {
      let sum = this._listItems.reduce((a, c) => a + c[col.objectPropertyName], 0);
      return pre + this.dp.transform(sum, col.jsonFormat) + post;
    } else if (col.footer === 'avg' && this._listItems) {
      let sum = this._listItems.reduce((a, c) => a + (c[col.objectPropertyName] === null ? 0 : c[col.objectPropertyName]), 0);
      return pre + this.dp.transform(sum / this._listItems.length, col.jsonFormat) + post;
    } else {
      return '';
    }
  }

  getFormattedValue(valToFormat: any, col: DataColumn, listItem: any): string {
    let val = '';
    if (valToFormat !== null && valToFormat !== undefined) {
      if (col.dataType === 'string') {
        val = valToFormat;
      } else if (col.dataType === 'datetime' || col.dataType === 'date') {
        if (valToFormat !== undefined && valToFormat !== null && valToFormat !== '')
          val = moment(valToFormat).format('MM/DD/YY');
        else
          val = '';
      } else if (col.dataType === 'int' || col.dataType === 'float' || col.dataType === 'number' || col.dataType === 'percent' || col.dataType === 'currency' || col.dataType === 'money') {
        if (col.jsonFormat === '') {
          val += valToFormat;
        } else {
          if (valToFormat !== 'Infinity') {
            val += this.dp.transform(valToFormat, col.jsonFormat);
          }
        }
        if (col.dataType === 'percent') {
          val = this.dp.transform((parseFloat(valToFormat)), col.jsonFormat);
        }
      } else if (col.dataType === 'bit' || col.dataType === 'boolean') {
        val = (valToFormat ? 'Yes' : 'No');
      }
    } else {
      val = valToFormat;
    }
    return val;
  }

  sortByColumn(c: DataColumn) {
    let oldSortColumn = this.sortColumn;
    this.sortColumn = c;
    if (oldSortColumn) {
      if (oldSortColumn.objectPropertyName === this.sortColumn.objectPropertyName) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortDirection = 'asc';
    }
    this._listItems = [...this._listItems.sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a[c.objectPropertyName] < b[c.objectPropertyName] ? -1 : 1;
      } else {
        return a[c.objectPropertyName] > b[c.objectPropertyName] ? -1 : 1;
      }
    })];
  }

  download() {
     const newWorkbook = XLSX.utils.book_new();

     // load columns
     let propSummaryHeaders = this.displayedColumnHeaders.map(c => c.name);
     let wsSummary = XLSX.utils.aoa_to_sheet([propSummaryHeaders]);
     let propSummaryRef = XLSX.utils.decode_range(wsSummary['!ref']);
     let propSummaryHeaderRange = XLSX.utils.encode_range({
       s: { // starting cell
         r: propSummaryRef.e.r, // last row up to this point
         c: 0,
       },
       e: {
         r: propSummaryRef.e.r,
         c: propSummaryHeaders.length - 1 // columns are zero-indexed
       }
     });
     XLSX.utils.sheet_set_range_style(wsSummary, propSummaryHeaderRange, { bold: true });

     // add props
     let aoaProps = [];
     this._listItems.forEach(item => {
       let propArray = [];
       this.displayedColumnHeaders.forEach(col => {
         if (col.dataType === 'percent') {
           propArray.push(item[col.objectPropertyName]);
         } else {
           propArray.push(item[col.objectPropertyName]);
         }
       });
       aoaProps.push(propArray);
     });
     XLSX.utils.sheet_add_aoa(wsSummary, aoaProps, { origin: -1 });

     // adjust columns (width)
     wsSummary['!cols'] = this.displayedColumnHeaders.map(c => ({ wch: c.minWidth / 5 }));

     // format columns
     let colIndex = 0;
     this.displayedColumnHeaders.forEach(col => {
       let format = '';
       if (col.dataType === 'currency' || col.dataType === 'money') {
         format = '$#,##0.00';
       } else if (col.dataType === 'int') {
         format = '#,##0';
       } else if (col.dataType === 'float') {
         format = '#,##0.00';
       } else if (col.dataType === 'percent') {
         format = '#,##0.00%';
       } else if (col.dataType === 'datetime') {
         format = '';
       }
       this.formatColumn(this._listItems, wsSummary, format, colIndex);
       colIndex++;
     });

     // write
     XLSX.utils.book_append_sheet(newWorkbook, wsSummary, this.title.replace(/\//g, ''));
     XLSX.writeFile(newWorkbook, 'download.xlsx', { cellStyles: true });
  }

  formatColumn(itemList: any[], ws: any, format: string, colIndex: number) {
    let propSummaryHeaderRange = XLSX.utils.encode_range({
      s: { // starting cell
        r: 1, // last row up to this point
        c: colIndex,
      },
      e: {
        r: itemList.length,
        c: colIndex // columns are zero-indexed
      }
    });
    XLSX.utils.sheet_set_range_style(ws, propSummaryHeaderRange, { z: format });
  }
}
