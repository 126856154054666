import { Component } from '@angular/core';

@Component({
    selector: 'cub-promote-asset',
    templateUrl: './promote-asset.component.html',
    styleUrls: ['./promote-asset.component.scss'],
    standalone: true
})
export class PromoteAssetComponent {
}
