import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogData } from 'src/app/shared/confirmation-dialog/confirmDialogData';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'cub-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,) { }

  ngOnInit() {
    console.log('data', this.data);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(true);
  }

}

