import { Component, OnInit, OnChanges, ViewEncapsulation, Input } from '@angular/core';
import { Investment, InvestmentProperty } from '..';
import { Lookup } from '../../shared/select/lookup';
import { LookupService } from '../../core/lookup.service';
import { NumWithCommasPipe } from '../../shared/num-with-commas.pipe';
import { InvestmentPropEditComponent } from '../investment-prop-edit/investment-prop-edit.component';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'cub-investment-prop-list',
    templateUrl: './investment-prop-list.component.html',
    styleUrls: ['./investment-prop-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatCardModule, MatButtonModule, NgFor, NgIf, InvestmentPropEditComponent, NumWithCommasPipe]
})
export class InvestmentPropListComponent implements OnInit, OnChanges {

  @Input() investment: Investment;
  selectedProperty: InvestmentProperty;
  showEditScreen = false;
  states: Lookup[] = [];
  newCount = 0;

  constructor(
    private lookupService: LookupService
  ) { }

  ngOnInit() {
    this.selectedProperty = null;
    this.loadLookups();
  }

  loadLookups() {
    this.states = this.lookupService.getTableValues('states');
  }

  ngOnChanges() {
    this.showEditScreen = false;
  }

  addProp() {
    this.selectedProperty = new InvestmentProperty();
    this.selectedProperty.address.stateID = 'IL';
    this.selectedProperty.address.stateKey = +this.states.filter(s => s.name === 'IL')[0].id;
    this.selectedProperty.prospectKey = this.investment.key;
    this.selectedProperty.leasableSF = 0;
    this.selectedProperty.tac = 0;
    this.selectedProperty.key = this.newCount;
    this.investment.properties.push(this.selectedProperty);
    this.showEditScreen = true;
    this.newCount--;
  }

  selectProperty(prop: InvestmentProperty) {
    this.selectedProperty = prop;
    this.showEditScreen = true;
  }

  onPropSave() {
    this.showEditScreen = false;
    this.selectedProperty = null;
  }

  onCancel(data: any) {
    this.showEditScreen = false;
    this.selectedProperty = null;
    if (data.prop.key === 0) {
      this.investment.properties = this.investment.properties.filter(p => p.key !== 0);
    } else {
      let foundItem = this.investment.properties.find(p => p.key === data.prop.key);
      let foundIndex = this.investment.properties.indexOf(foundItem);
      this.investment.properties[foundIndex] = Object.assign([], data.prop);
    }
  }

  onPropDelete() {
    this.investment.properties = this.investment.properties.filter(p => p.key !== this.selectedProperty.key);
    this.showEditScreen = false;
    this.selectedProperty = null;
  }
}
