import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListService } from '../../core/list.service';
import { HighlightPipe } from '../highlight.pipe';
import { MatRippleModule } from '@angular/material/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    standalone: true,
    imports: [NgIf, MatRippleModule, HighlightPipe]
})
export class ListItemComponent {

  @Input() itemId: string;
  @Input() title1: string;
  @Input() title2: string;
  @Input() title3: string;
  @Input() title4: string;
  @Input() searchTerm: string;

  _active: boolean;
  @Input()  // this is for passing the component a lease id vs just always getting it from the route
  set active(value: boolean) {
    this._active = value;
  }

  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenu: EventEmitter<any> = new EventEmitter();
  @Input() mode: string;
  @Input() system: string;
  active2: boolean;
  rippleColor: string;

  constructor(
    private listService: ListService
  ) {
    this.rippleColor = 'rgba(33, 56, 108, 0.3)';
  }

  clickedItem() {
    setTimeout(() => {
      this.onSelection.emit();
    }, 300);
  }

  contextMenu(event: MouseEvent) {
    this.onContextMenu.emit({ event, itemId: this.itemId });
    event.preventDefault();
  }
}
