
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { } from 'bingmaps';

import { Observable } from 'rxjs';
import { Constants } from '../../core/constants';

@Injectable()
export class MapService {

  private baseUrl = 'https://dev.virtualearth.net/REST/v1/';

  constructor(
    private http: HttpClient
  ) { }

  findLocation(street: string, city: string, state: string, country: string): Observable<BingMapResponse> {
    let mapKey = Constants.MapKey;
    let url = `${this.baseUrl}/Locations?query=${street},${city},${state},${country}&key=${mapKey}`;
    return this.http
      .get<BingMapResponse>(url);
  }
}

export class BingMapResponse {

  resourceSets: any[];

  constructor() {
  }
}
