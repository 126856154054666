<h3>Edit Address</h3>
<hr />
<form [formGroup]="addressForm"
      novalidate
      style="display: grid; grid-template-columns: 60% 40%">
  <div class="address-grid">
    <mat-form-field style="grid-column: 1/4;">
      <mat-label>Street</mat-label>
      <input matInput
             formControlName="street"
             (blur)="formToObject();">
      <mat-error *ngIf="addressForm.controls['street'].invalid">You must enter an address</mat-error>
    </mat-form-field>
    <mat-form-field style="grid-column: 1/3;">
      <mat-label>City</mat-label>
      <input matInput
             formControlName="city"
             (blur)="formToObject();">
      <mat-error *ngIf="addressForm.controls['city'].invalid">You must enter a city</mat-error>
    </mat-form-field>
    <mat-form-field style="grid-column: 3/4;">
      <mat-label>State</mat-label>
      <mat-select [compareWith]="selectCompare"
                  formControlName="state"
                  (selectionChange)="formToObject();">
        <mat-option *ngFor="let s of states"
                    [value]="s.id">
          {{ s.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressForm.controls['state'].invalid">You must enter a state</mat-error>
    </mat-form-field>
    <mat-form-field style="grid-column: 1/2;">
      <mat-label>Zip Code</mat-label>
      <input matInput
             formControlName="zip"
             (blur)="formToObject();">
      <mat-error *ngIf="addressForm.controls['zip'].invalid">You must enter a zip code</mat-error>
    </mat-form-field>
    <mat-form-field style="grid-column: 2/3;">
      <mat-label>County</mat-label>
      <input matInput
             formControlName="county"
             (blur)="formToObject();">
    </mat-form-field>
    <mat-form-field style="grid-column: 3/4;">
      <mat-label>Country</mat-label>
      <input matInput
             formControlName="country"
             (blur)="formToObject();">
    </mat-form-field>
    <mat-form-field style="">
      <mat-label>Type</mat-label>
      <mat-select [compareWith]="selectCompare"
                  formControlName="addressType">
        <mat-option *ngFor="let t of addressTypes"
                    [value]="t.id">
          {{ t.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressForm.controls['addressType'].invalid">You must enter a type</mat-error>
    </mat-form-field>
    <mat-form-field style="">
      <mat-label>Latitude</mat-label>
      <input matInput
             formControlName="latitude">
    </mat-form-field>
    <mat-form-field style="">
      <mat-label>Longitude</mat-label>
      <input matInput
             formControlName="longitude">
    </mat-form-field>
    <div style="grid-column: 3/4;">
      <button mat-raised-button
              type="button"
              (click)="findAddress()"
              class="button-standard"
              style="float: right;">
        <fa-icon [icon]="faMapMarker"
                class="fa-lg"></fa-icon> &nbsp;&nbsp;Map Address
      </button>
    </div>
  </div>
  <div style="">
    <div style="border: solid 1px #ccc; min-height: 350px;">
      <cub-map [street]="address.address1"
              [city]="address.city"
              [state]="address.stateID"
              [country]="address.country"
              [latitude]="address.latitude"
              [longitude]="address.longitude"
              [markerTitle]="address.address1"
              [mapHeight]="350"
              [zoom]="12"
              (foundLatLong)="foundLatLong($event)"
              (mapViewChange)="mapViewChanged($event)">
      </cub-map>
    </div>
      <mat-checkbox class="example-margin"
                    formControlName="updateLatLong"
                    style="grid-column: 2/4; margin-top: 10px;"> Update Lat/Long when I move the marker</mat-checkbox>
  </div>
  <div style="grid-column: 1/3; margin: 20px 0 0 0; border-top: solid 1px #ddd; padding: 20px 0 0 0;">
    <button mat-button
            (click)="cancelItem()">
      Cancel
    </button>
    <button mat-raised-button
            (click)="saveItem()"
            color="primary"
            class="button-standard"
            style="float: right;">
      <fa-icon [icon]="faSave"
               class="fa-lg"></fa-icon> &nbsp;&nbsp;Save
    </button>
  </div>
</form>
