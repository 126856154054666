
<cdk-virtual-scroll-viewport *ngIf="showList()" itemSize="60" class="sys-list-container" [scrollTop]="myScrollTop">
  <div class="sys-list-group-title" *ngIf="groupSelected()" (click)="backToList()">
    <i class="fa fa-arrow-left" title="Back to grouping"></i>
    <span (click)="backToList()" >{{ selectedGroupName }}</span>
  </div>
  <h5 *ngIf="isLoading"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></h5>
  <cub-list-item #filteredList *cdkVirtualFor="let item of items"
    (onSelection)="selectItem(item)"
    (onContextMenu)="contextMenu($event)"
    [itemId]="item.itemId"
    [title1]="item.title1"
    [title2]="item.title2"
    [title3]="item.title3"
    [title4]="item.title4"
    [active]="item.active"
    [searchTerm]="searchText"
    [system]="system"
    [mode]="itemMode">
  </cub-list-item>
</cdk-virtual-scroll-viewport>
<cub-list-group *ngIf="showGroups()" [groups]="groups" ></cub-list-group>
