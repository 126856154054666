import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import 'chartjs-plugin-annotation';
import { ChartConfiguration } from 'chart.js';

@Component({
    selector: 'cub-widget-line-graph-colored',
    templateUrl: './widget-line-graph-colored.component.html',
    styleUrls: ['./widget-line-graph-colored.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgChartsModule]
})
export class WidgetLineGraphColoredComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: true }) baseChart;
  @ViewChild('chartCanvas', { static: true }) public chartCanvas: ElementRef;
  @Input() chartLabels: string[];
  @Input() chartData = [];
  @Input() label: string;
  @Input() amount: string;
  @Input() color: string;
  @Input() colorAccent: string;
  @Input() indexSelected: number;
  @Input() note: string;

  public barChartType = 'line';
  public barChartLegend = false;
  labelClass = 'label';
  valueClass = 'value';
  public data: any;
  chartVisible = 'hidden';

  lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: '',
        backgroundColor: '#EA9721',
        borderColor: '#EA9721',
        pointBackgroundColor: '#EA9721',
        pointBorderColor: '#EA9721',
        pointHoverBackgroundColor: '#EA9721',
        pointHoverBorderColor: '#EA9721'
      },
      {
        data: [],
        label: '',
        backgroundColor: '#eee',
        borderColor: '#eee',
        pointBackgroundColor: '#eee',
        pointBorderColor: '#eee',
        pointHoverBackgroundColor: '#eee',
        pointHoverBorderColor: '#21376C',
        fill: 'origin',
      }
    ],
  }

  public lineChartOptions: ChartConfiguration['options'] = {
    layout: {
      padding: 5
    },
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          display: false,
        }
      },
      x: {
        position: 'bottom',
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false
        },
        border: {
          display: false,
        }
      },
    },
    elements: {
      line: {
        tension: 0.4,
        spanGaps: true
      },
      point: {
        pointStyle: 'circle',
        hitRadius: 10,
        radius: 2
      }
    },

    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],

    animation: {
      duration: 0 // general animation time
    },
    hover: {
      mode: 'nearest',
      intersect: true // duration of animations when hovering an item
    },

    responsive: true
  };

  ngOnInit() {
    setTimeout(() => {
      this.updateChart();
    }, 30);
  }

  ngOnChanges() {
    if (this.baseChart.chart !== undefined) {
      this.updateChart();
    }
  }

  updateChart() {
    this.lineChartData.labels = this.chartLabels;
    if (this.indexSelected && this.chartData) {
      let nullArr = []; // the amount of nulls to add to reach selected index
      for (let i = 0; i < this.indexSelected; i++) {
        // insert nulls
        nullArr.push(null);
      }
      let selVal = this.chartData[this.chartData.length > 1 ? 1 : 0].data[this.indexSelected];
      nullArr.push(selVal);
      if (this.chartData.length > 1) {
        this.chartData = this.chartData.slice(1, 2);
      }
      this.lineChartData.datasets[0].data = nullArr;
      this.lineChartData.datasets[0].label = this.label;

      this.lineChartData.datasets[1].data = this.chartData[0].data;
      this.lineChartData.datasets[1].label = this.label;
    }

    // set min and max of graph within 10% of data
    let minVal = Math.min(...this.chartData[0].data) ?? 0;
    let maxVal = Math.max(...this.chartData[0].data) ?? 0;
    this.lineChartOptions.scales.y.suggestedMin = minVal - minVal * 0.01;
    this.lineChartOptions.scales.y.suggestedMax = maxVal + maxVal * 0.3;
    this.baseChart.chart.update();
    this.chartVisible = 'visible';
  }

  mouseover() {
    this.valueClass = 'value-hide';
    this.labelClass = 'label-hide';
    this.baseChart.chart.update();
  }

  mouseout() {
    this.valueClass = 'value';
    this.labelClass = 'label';
    this.baseChart.chart.update();
  }
}
