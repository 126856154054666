import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'cub-user-home',
    templateUrl: './user-home.component.html',
    styleUrls: ['./user-home.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class UserHomeComponent {
}
