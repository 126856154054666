
import { map, startWith } from 'rxjs/operators';
import { Component, OnInit, Inject, Input, OnChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray, UntypedFormBuilder, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerCommission } from '../broker-commission/brokerCommission';
import { Broker } from '../broker-commission/broker';
import { BrokerCommissionService } from '../broker-commission/broker-commission.service';
import { BrokerCommissionPcDialogComponent } from '../broker-commission-pc-dialog/broker-commission-pc-dialog.component';
import { SpinnerDialogComponent } from '../spinner-dialog/spinner-dialog.component';
import { Location, NgFor, NgIf, AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Lookup } from '../select/lookup';
import { LookupService } from '../../core/lookup.service';
import { LoggerService } from '../../core/logger.service';
import { AlertService } from '../../core/alert.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-broker-commission-dialog',
    templateUrl: './broker-commission-dialog.component.html',
    styleUrls: ['./broker-commission-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatDatepickerModule, MatAutocompleteModule, MatCardModule, MatButtonModule, MatCheckboxModule, AsyncPipe]
})
export class BrokerCommissionDialogComponent implements OnInit {
  brokerCommission: BrokerCommission;
  fgCommission: UntypedFormGroup;
  brokerageFirmCtrl: UntypedFormControl; // we have to have a placeholder for the mat-autocomplete in the templateUrl so the DOM knows of the control
  brokerageFirms: String[] = [];
  filteredFirms: Observable<any[]>;
  brokersByFirm: Broker[] = [];
  brokerTypes: Lookup[] = [];
  payTypes: Lookup[] = [];

  constructor(
    public dialogRef: MatDialogRef<BrokerCommissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { brokerCommission: BrokerCommission },
    private fb: UntypedFormBuilder,
    public loadingDialog: MatDialog,
    public pcDialog: MatDialog,
    private brokerService: BrokerCommissionService,
    private alertService: AlertService,
    private lookupService: LookupService,
    private loggerService: LoggerService
  ) {
    this.brokerCommission = new BrokerCommission();
    this.brokerageFirmCtrl = new UntypedFormControl(this.data.brokerCommission.brokerageFirm, [Validators.required]);  // initialize the placeholder with a required validator

    this.loadLookups();
    this.getBrokerageFirmsForLookup();

    // populates the autocomplete with the filtered data
    this.filteredFirms = this.brokerageFirmCtrl.valueChanges.pipe(
      startWith(null),
      map(f => f ? this.filterBrokerageFirms(f) : this.brokerageFirms.slice()));
  }

  ngOnInit() {
    this.loggerService.logPageVisit('broker commission', '');

    // initialize the brokerCommission variable with the data passed from the broker-commission component
    this.brokerCommission = this.data.brokerCommission;

    this.dialogRef.updateSize('600px', '720px');
    this.createFormControls();
  }

  clickClose() {
    if (this.fgCommission.dirty) {
      if (confirm('This broker commission has changed. Are you sure you want to close without saving?')) {
        this.dialogRef.close(this.brokerCommission);
      }
    } else {
      this.dialogRef.close(this.brokerCommission);
    }
  }

  clickSave() {
    this.formToObject();

    this.brokerService.saveBrokerCommission(this.brokerCommission)
      .subscribe((result: BrokerCommission) => {
        this.brokerCommission = result;
        this.dialogRef.close(this.brokerCommission);
      });
  }

  blurBrokerageFirm() {
    // clears out the property/unit info when no property is selected
    if (!this.fgCommission.controls.brokerageFirm.valid) {

      this.brokersByFirm = []; // re-initialze this array everytime we pick a new property
      this.brokerCommission.brokers = []; // re-initialze this array everytime we pick a new property

      this.setBrokerForValidation();
    }
  }

  createFormControls() {
    this.getBrokersForFirm(this.brokerCommission.brokerageFirm);
    let paidAmt = this.brokerCommission.paidAmt.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    let payType = '';
    if (this.brokerCommission.payTypeKey > 0) {
      payType = this.brokerCommission.payTypeKey.toString();
    }

    let brokerType = '';
    if (this.brokerCommission.relationshipTypeKey > 0) {
      brokerType = this.brokerCommission.relationshipTypeKey.toString();
    }

    // in reactive forms we're required to create a FormGroup to hold a mirror of the controls in the templateUrl
    this.fgCommission = this.fb.group({
      paidDt: new UntypedFormControl(this.brokerCommission.paidDt, [Validators.required]),
      // ok jsyk regex sucks and getting them to work here is mildly finicky it does not work if you set a varaible to this value and pass it
      paidAmt: new UntypedFormControl(paidAmt, [Validators.required, Validators.pattern(/^\$?-?(((\d{1,3},?)(\d{3},?)+|\d{1,3})|\d+)(\.\d{1,2})?$/)]),
      payType: new UntypedFormControl(payType, [Validators.required]),
      brokerType: new UntypedFormControl(brokerType, [Validators.required]),
      brokerageFirm: this.brokerageFirmCtrl,
      brokers: new UntypedFormControl('', [Validators.required])  // this is a dummy control to hold my required validation error we must have at least one broker selected
    });

    this.setBrokerForValidation();
  }

  formToObject() {
    // oh yeah we old school
    this.brokerCommission.payTypeKey = parseInt(this.fgCommission.controls.payType.value, 10);
    let payIndex = this.payTypes.findIndex(i => i.id === this.fgCommission.controls.payType.value);
    this.brokerCommission.payTypeDesc = this.payTypes[payIndex].name;
    this.brokerCommission.relationshipTypeKey = parseInt(this.fgCommission.controls.brokerType.value, 10);
    let brokerIndex = this.brokerTypes.findIndex(i => i.id === this.fgCommission.controls.brokerType.value);
    this.brokerCommission.relationshipTypeDesc = this.brokerTypes[brokerIndex].name;
    this.brokerCommission.paidDt = this.fgCommission.controls.paidDt.value;
    this.brokerCommission.paidAmt = this.fgCommission.controls.paidAmt.value.toString().replace('$', '').replace(',', '');
    this.brokerCommission.brokerageFirm = this.fgCommission.controls.brokerageFirm.value;
  }

  getBrokerageFirmsForLookup(): void {
    this.brokerService.getBrokerageFirms().subscribe(firms => {
      this.brokerageFirms = firms;
    });
  }

  filterBrokerageFirms(search: string) {
    return this.brokerageFirms.filter(f => f.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  loadLookups() {
    // load the lookups
    this.brokerTypes = this.lookupService.getTableValues('brokerType');
    this.payTypes = this.lookupService.getTableValues('brokerPayType');
  }

  toggleBroker(broker: Broker, evt: any) {
    // handles the adding or removing of selected brokers from the brokerCommission object's brokers[]
    if (evt.checked) {
      this.brokerCommission.brokers.push(broker);
    } else {
      let removeIndex = this.brokerCommission.brokers.map(function (item) { return item.brokerKey; }).indexOf(broker.brokerKey);
      this.brokerCommission.brokers.splice(removeIndex, 1);
    }

    this.brokerageFirmCtrl.markAsTouched();
    this.setBrokerCheckedFlag();
    this.setBrokerForValidation();
  }

  setBrokerForValidation() {
    // sets the dummy 'brokers' control we created in fgCommission for validation
    if (this.brokerCommission.brokers.length > 0) {
      this.fgCommission.controls.brokers.setValue(this.brokerCommission.brokers.length.toString() + ' brokers(s) selected!');
    } else {
      // the brokerCommission object's brokers[] doesn't have any selected brokers so clear the 'brokers' FormControl which is used for validation
      this.fgCommission.controls.brokers.setValue('');
    }
  }

  onSelectFirm(firm: string) {
    this.getBrokersForFirm(firm); // get the brokers for the selected firm
    this.brokerCommission.brokers = []; // re-initialze this array everytime we pick a new firm
  }

  getBrokersForFirm(firm: string): void {
    if (firm.length > 0) {
      // open a spinner dialog because sometimes this call hangs and the dialog will distract them with spinny shit
      let spinnerRef = this.loadingDialog.open(SpinnerDialogComponent, {
        disableClose: true,
        width: '450px',
        data: { mode: 'spinner', title: 'Loading Brokers', content: 'This may take a minute please be patient.' }
      });

      this.brokerService.getBrokersForFirm(firm).subscribe(brokers => {
        this.brokersByFirm = brokers;
        this.setBrokerCheckedFlag();
        spinnerRef.close(); // close the dialog we just opened
      });
    }
  }

  setBrokerCheckedFlag(): void {
    // uncheck all brokers in the list
    for (let firmBroker of this.brokersByFirm) {
      firmBroker.isChecked = false;
    }

    // check the brokers in the list where the broker is a broker of record in the commission
    for (let commissionBroker of this.brokerCommission.brokers) {
      for (let firmBroker of this.brokersByFirm) {
        if (commissionBroker.brokerKey === firmBroker.brokerKey) {
          firmBroker.isChecked = true;
        }
      }
    }
  }

  addBroker() {
    let newBroker = new Broker();
    newBroker.companyName = this.fgCommission.controls.brokerageFirm.value;

    let addDialog = this.pcDialog.open(BrokerCommissionPcDialogComponent, {
      disableClose: true,
      width: '450px',
      data: { broker: newBroker }
    });

    addDialog.afterClosed().subscribe(result => {
      newBroker = result;

      // if the broker failed to save don't do anything
      if (newBroker.brokerKey > 0) {
        this.fgCommission.markAsDirty();
        this.updateBrokerInList(newBroker);
      }
    });
  }

  editBroker(editBroker: Broker) {
    let editDialog = this.pcDialog.open(BrokerCommissionPcDialogComponent, {
      disableClose: true,
      width: '450px',
      data: { broker: editBroker }
    });

    editDialog.afterClosed().subscribe(result => {
      editBroker = result;
      this.updateBrokerInList(editBroker);
    });
  }

  updateBrokerInList(broker: Broker) {
    let itemIndex = this.brokerCommission.brokers.findIndex(b => b.brokerKey === broker.brokerKey);

    // find the broker by key and replace it with the passed 'broker' or add it to the array if it doesn't exist
    if (itemIndex > -1) {
      this.brokerCommission.brokers[itemIndex] = broker;
    } else {
      this.brokerCommission.brokers.push(broker);  // add it array of brokers for this commission
    }

    this.setBrokerForValidation();
    this.getBrokersForFirm(this.fgCommission.controls.brokerageFirm.value); // reload the list brokers by firm
  }

  popupSpinner() {
    // open a spinner dialog because sometimes this call hangs and the dialog will distract them with spinny shit
    let popupDialog = this.loadingDialog.open(SpinnerDialogComponent, {
      disableClose: true,
      width: '450px',
      data: { mode: 'spinner', title: 'Loading Brokers', content: 'This may take a minute please be patient.' }
    });
  }

  selectCompare(c1: string, c2: number): boolean {
    return c1 === c2.toString();
  }
}
