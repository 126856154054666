<form [formGroup]="phoneForm" novalidate>
  <div *ngIf="phoneNumber.editMode" class="editMode">
    <mat-form-field>
      <mat-label>Phone Type</mat-label>
      <mat-select [compareWith]="selectCompare"  formControlName="phoneType">
        <mat-option *ngFor="let type of phoneTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>phone</mat-label>
      <input matInput  formControlName="phoneNumber">
      <mat-error *ngIf="phoneForm.controls['phoneNumber'].invalid">You must enter a valid number</mat-error>
    </mat-form-field>
    <button mat-button (click)="cancelItem()" >
      Cancel
    </button>
    <button mat-raised-button (click)="saveItem()" color="primary" >
      <fa-icon [icon]="faSave" class="fa-lg"></fa-icon> &nbsp;Save
    </button>
  </div>
  <div *ngIf="!phoneNumber.editMode" class="viewMode">
    <fa-icon [icon]="faEdit" class="fa-lg" (click)="editItem()" ></fa-icon>
    <span class="type" (click)="editItem()">{{ getPhoneTypeDesc() }}</span>
    <span class="phone" (click)="editItem()">{{ phoneNumber.number }}</span>

    <button mat-icon-button *ngIf="!confirmDelete" (click)="confirmDeletion()">
      <fa-icon [icon]="faTrashAlt" class="fa-lg"></fa-icon>
    </button>
    <button mat-raised-button *ngIf="confirmDelete" (click)="deleteItem()" >
      <fa-icon [icon]="faTrashAlt" class="fa-lg"></fa-icon> &nbsp;Confirm
    </button>
    <button mat-button *ngIf="confirmDelete" (click)="cancelDelete()">
      Cancel
    </button>
  </div>
</form>
