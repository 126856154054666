import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toNumber',
    standalone: true
})
export class ToNumberPipe implements PipeTransform {
    transform(value: string): any {
        let retNumber = Number(value);
        return isNaN(retNumber) ? null : retNumber;
    }
}
