<form [formGroup]="fg" novalidate autocomplete="off">
  <div class="leaseBarneyPickerWrapper">
    <div class="leaseBarneyPickerContent">
      <mat-form-field>
				<mat-label>Tenant Name</mat-label>
        <input formControlName="tenantName" matInput >
      </mat-form-field>
    </div>
    <div class="leaseBarneyPickerContent">
      <mat-form-field>
				<mat-label>search for property</mat-label>
        <input formControlName="searchText" matInput >
        <mat-icon matSuffix><i class="fa fa-search"></i></mat-icon>
      </mat-form-field>
    </div>
    <span>Select a Property</span><br />
    <cdk-virtual-scroll-viewport class="leaseBarneyPropList" style="height:200px; width:100%; border: solid 1px #a9a9a9;" itemSize=12 [scrollTop]="myScrollTop">
      <div *cdkVirtualFor="let prop of filteredProps" id="prop.propertyId" [ngClass]="{'selectedClass': prop.propertyKey===selectedPropertyKey, 'deselectedClass': prop.propertyKey!==selectedPropertyKey }" (click)="onPropertySelect(prop.propertyId)" style="cursor: pointer;">
        <span>{{ prop.propertyId }} -
          {{ prop.propertyDesc }}&nbsp;{{prop.city}}&nbsp;{{prop.stateId}}&nbsp;{{prop.leasableSF}}</span>
      </div>
    </cdk-virtual-scroll-viewport>
    <br />
    <div class="propPickerList">
      <span>Select a Unit</span><br />
      <select style="width:100%;" formControlName="selUnitList" size="8">
        <option *ngFor="let unit of propUnits" id="unit.unitId" (click)="onUnitSelect(unit.unitKey)" [value]="unit.unitKey" style="cursor: pointer;">{{ unit.unitID }} -
          {{ unit.leasableSpace | numWithCommas }}&nbsp;sf&nbsp;-&nbsp;{{ unit.tenantName === '' ? 'Vacant' : unit.tenantName }}&nbsp; <ng-container *ngIf="unit.tenantName !== ''">({{ unit.leaseEffectiveDt }} -
            {{ unit.leaseExpirationDt }})</ng-container>
        </option>
      </select>

    </div>
  </div>
  <div style="margin: 5px 0px 4px 8px;">
    <button mat-raised-button (click)="clickClose()">Close</button>
    <button mat-raised-button color="primary" align="end" (click)="clickSave()" style="float: right;"><i class="fa fa-save"></i> Save</button>
  </div>
</form>
