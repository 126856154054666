<form class="fgGlData"
      [formGroup]="fgGlData"
      novalidate
      autocomplete="off">
  <div class="grid-container">
    <div>
      <div class="grid-col-icon">
        <button mat-icon-button
                (click)="fileInputPrior.click()">
          <i class="fa fa-file-excel-o fa-lg"
             matTooltip="Select prior month trial balance"></i>
        </button>
      </div>
      <div class="grid-col-input">
        <mat-form-field>
				  <mat-label>Accrual Book Prior Month File</mat-label>
          <input matInput
                 formControlName="priorInput"
                  />
        </mat-form-field>
        <input hidden
               type="file"
               #fileInputPrior
               formControlName="priorHidden"
               [accept]="'.csv'"
               (change)="handleFileInput($event.target.files, 'prior')" />
      </div>
    </div>
    <div>
      <div class="grid-col-icon">
        <button mat-icon-button
                (click)="fileInputCurrent.click()">
          <i class="fa fa-file-excel-o fa-lg"
             matTooltip="Select current month trial balance"></i>
        </button>
      </div>
      <div class="grid-col-input">
        <mat-form-field>
				  <mat-label>Accrual Book Current Month File</mat-label>
          <input matInput
                 formControlName="currentInput"
                  />
        </mat-form-field>
        <input hidden
               type="file"
               #fileInputCurrent
               formControlName="currentHidden"
               [accept]="'.csv'"
               (change)="handleFileInput($event.target.files, 'current')" />
      </div>
    </div>
    <div class="grid-error"
         *ngIf="!uploadSuccess || !transferSuccess">
      <mat-error *ngIf="!uploadSuccess">
        <small *ngFor="let message of uploadResults.messages">{{ message }}</small>
      </mat-error>
      <mat-error *ngIf="!transferSuccess">
        <small *ngFor="let message of uploadResults.messages">{{ message }}</small>
      </mat-error>
    </div>
    <div style="margin-top: 20px">
      <div style="width: 50%;
        display: inline-block;
        padding-top: 15px;">
        <div *ngIf="uploading"> <fa-icon [icon]="faSpinner"
                   [spin]="true"></fa-icon> &nbsp;Uploading... </div>
        <div *ngIf="!uploading && uploaded && uploadSuccess"> Done! Email Sent! </div>
        <div *ngIf="!uploading && uploaded && !uploadSuccess"> Sorry - there were errors. </div>
      </div>
      <div class="grid-col-button">
        <button mat-stroked-button
                style=""
                color="primary"
                (click)="createJournalEntry()"
                [disabled]="disabled"
                matTooltip="Create Journal Entry">
          <fa-icon [icon]="faWandSparkles"></fa-icon> &nbsp;Create Journal Entry </button>
      </div>
    </div>
    <!--
    <div class="grid-col-anchor">
      <a target="_blank" href="{{ prelimUrl }}" download="{{ prelimFile }}" [class.disabled]="disabled || !uploadSuccess">
        <i class="{{ prelimIcon }}" matTooltip="Preview file export(s)"></i>
      </a>
    </div>
    <div class="grid-col-button">
      <button mat-raised-button color="primary" [disabled]="disabled || !uploadSuccess" (click)="clickTransfer()" matTooltip="Create the export file(s) for CalPERS and copy to the extranet.">
        <i class="{{ transferIcon }}"></i> &nbsp;Export
      </button>
    </div>
    <div class="grid-col-icon">
      <button mat-icon-button style="padding-right: 15px" [disabled]="disabled || !transferSuccess" (click)="fileInputCurrent.click()">
        <i class="fa fa-cloud fa-lg" matTooltip="Open File Export Location (Local)"></i>
      </button>
    </div>
    -->
  </div>
</form>
