  <div class="approvedLeaseBarneyPickerWrapper">
    <div id="1-2" style="height: 40px; text-align: center; border-bottom: 1px solid #ddd;">
      <span style="font-size: 20px; font-weight: bold;">{{ title }}</span>
    </div>
    <div>
      &nbsp;
    </div>
    <div class="BarneyResultsHeader">
      <div>Tenant Name</div>
      <div>Start Date</div>
      <div>Lease sf</div>
      <div>Appr Date</div>
      <div>Appr By</div>
    </div>
    <cdk-virtual-scroll-viewport class="approvedLeaseBarneyList"
      style="height: 400px; width: 100%; border: solid 1px #a9a9a9;" itemSize="12" [scrollTop]="myScrollTop">
      <div *cdkVirtualFor="let l of approvedLeaseBarneys" id="p.key" [ngClass]="{
          selectedItem: l.leaseBarneyKey === selectedLeaseBarneyKey,
          deselectedItem: l.leaseBarneyKey !== selectedLeaseBarneyKey,
          BarneyResults: 1 === 1
        }" (click)="onSelect(l)" style="cursor: pointer;">
        <div>
          <span title="{{ l.tenantName }}">{{ l.tenantName }}</span>
        </div>
        <div>
          <span title="{{ l.leaseStartDate | date:'MM/dd/yyyy' }}">
            {{ l.leaseStartDate | date:'MM/dd/yyyy' }}</span>
        </div>
        <div>
          <span title="{{ l.leaseSf | numWithCommas }}">{{ l.leaseSf | numWithCommas }}</span>
        </div>
        <div>
          <span title="{{ l.approvedDate | date:'MM/dd/yyyy' }}"> {{ l.approvedDate | date:'MM/dd/yyyy' }} </span>
        </div>
        <div>
          <span title="{{ l.approvedBy }}"> {{ l.approvedBy }} </span>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <br>
    <button mat-raised-button color="accent" style="float:left;" (click)="viewDetails()">
      View Lease Barney Details
    </button>
  </div>

  <div class="bottomButtons">
    <div>
      <button mat-raised-button style="float: left;" (click)="clickClose()">
        Close
      </button>
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="clickSave()" style="float: right;">
        Select
      </button>
    </div>
  </div>
