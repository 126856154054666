import { LeaseBarneyFileAttachment } from './lease-barney-file-attachment';
import { LeaseBarneySensitivity } from './lease-barney-sensitivity';
import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LeaseBarneyBrief } from './lease-barney-brief';
import { LeaseBarneyFull } from './lease-barney-full';
import { LeaseBarneyApproved } from './../shared/lease-barney-lookup-dialog/lease-barney-approved';
import { environment } from '../../environments/environment';
import { AlertService } from '../core/alert.service';
import { LeaseBarneyRentStep } from './lease-barney-rent-step';
import { LeaseBarneyImprovement } from './lease-barney-improvement';
import { LeaseBarneyImprovementClassificationType } from './lease-barney-improvement-classification-type';
import { LoggerService } from '../core/logger.service';
import { LeaseBarneyCommissionScheduleItem } from './lease-barney-commission-schedule-item';
import { LeaseCommissionTemplate } from './lease-barney-commission-template';
import { UserService } from '../core/user.service';
import { ReturnMessage } from '../shared/returnMessage';

@Injectable()
export class LeaseBarneyService {
  private leaseBarneyUrl = environment.cubCloudUrl + 'leaseBarneys';
  private leaseBarneyList = new ReplaySubject<LeaseBarneyBrief[]>(1);
  private rentStepList = new ReplaySubject<LeaseBarneyRentStep[]>(1);
  private tempLeaseBarneyList: LeaseBarneyBrief[] = []; // just to show subscribe features
  leaseBarneys$ = this.leaseBarneyList.asObservable(); // $ is convention to indicate observable.

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private loggerService: LoggerService,
    private userService: UserService) {
    this.getLeaseBarneys();
  }

  getLeaseBarneys() {
    const url = `${this.leaseBarneyUrl}/getleasebarneys`;
    this.http.get<LeaseBarneyBrief[]>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(leaseBarneys => {
      this.leaseBarneyList.next(leaseBarneys);
      this.tempLeaseBarneyList = leaseBarneys;
    });
  }

  refreshCache() {
    const url = `${this.leaseBarneyUrl}/refreshcache`;
    this.http.get<LeaseBarneyBrief[]>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(leaseBarneys => {
      this.leaseBarneyList.next(leaseBarneys);
      this.tempLeaseBarneyList = leaseBarneys;
    });
  }

  getLeaseBarneyVersions(id: number) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/versions`);
    return this.http.get<LeaseBarneyBrief[]>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseBarney(id: string) {
    const url = `${this.leaseBarneyUrl}/${id}`;
    return this.http.get<LeaseBarneyFull>(this.formatUrl(url), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  getUnitMarketData(propertyKey: number, unitId: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/getunitmarketdata`);
    return this.http
      .post<number>(url, { propertyKey, unitId }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .catch(this.handleError2);
  }

  getApprovedLeaseBarneys() {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/approvedList`);
    return this.http.get<LeaseBarneyApproved[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  getRentSteps(id: number) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/rentsteps`);
    return this.http.get<LeaseBarneyRentStep[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  createRentStep(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/createrentstepforbarney/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  deleteRentStep(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/deleterentstep/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  saveRentSchedule(steps: LeaseBarneyRentStep[]): Observable<LeaseBarneyRentStep[]> {
    let d = JSON.stringify(steps);
    const url = this.formatUrl(`${this.leaseBarneyUrl}/saverentschedule`);

    return this.http.post<LeaseBarneyRentStep[]>(url, d, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  updateLeaseBarneyLeaseKey(leaseKey: number, leaseBarneyKey: number): Observable<boolean> {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/updateleasebarneyleasekey`);
    return this.http.post<boolean>(url, { leaseKey, leaseBarneyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  calculatePmt(intRate: string, periods: string, pv: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${intRate}/${periods}/${pv}/calcpmt`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  getCashFlow(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/getcashflow`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  validateBarney(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/validatebarney`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  exportMonthlyCashFlow(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/exportmonthlycashflow`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  exportWhatIfMonthlyCashFlow(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/exportwhatifmonthlycashflow`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  exportYearlyCashFlow(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/exportyearlycashflow`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  buildYearlyCashFlow(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/buildyearlycashflow`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  calculateMetrics(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/calculateMetrics`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  calculateWhatIf(id: number) {
    return this.http.get<LeaseBarneyFull>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/calculateWhatIf`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  createFromWhatIf(id: number, name: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/createFromWhatIf`);
    return this.http
      .post<ReturnMessage>(url, { id, name }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .catch(this.handleError2);

  }

  archiveLeaseBarneys(leaseBarneyKeys: number[]) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/archiveleasebarneys`);
    return this.http
      .post<boolean>(url, leaseBarneyKeys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .catch(this.handleError2);
  }

  autoBuildCommissionsSchedule(id: number) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/${id}/autobuildcommissionschedule`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  getImprovements(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/improvements`);
    return this.http.get<LeaseBarneyImprovement[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseBarneyFiles(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/getleasebarneyfiles`);
    return this.http.get<LeaseBarneyFileAttachment[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getSensitivities(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/sensitivities`);
    return this.http.get<LeaseBarneySensitivity[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  createImprovement(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/createimprovementforbarney/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  createRentSchedule(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/createRentSchedule/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  deleteImprovement(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/deleteimprovement/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  archiveLeaseBarney(id: string, toarchive: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/archiveleasebarney/${id}/${toarchive}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  copyLeaseBarney(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/copyleasebarney/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  updateArchiveFlagInList(id: number, isArchived: boolean) {
    let myList: LeaseBarneyBrief[] = [];
    myList = this.tempLeaseBarneyList;
    for (let lb of this.tempLeaseBarneyList) {
      if (lb.leaseBarneyKey === id) {
        lb.isArchived = isArchived;
      }
    }
    this.leaseBarneyList.next(myList);
  }

  updateDeleteFlagInList(id: number, isDeleted: boolean) {
    let myList: LeaseBarneyBrief[] = [];
    myList = this.tempLeaseBarneyList;
    for (let lb of this.tempLeaseBarneyList) {
      if (lb.leaseBarneyKey === id) {
        lb.isArchived = isDeleted;
      }
    }
    this.leaseBarneyList.next(myList);
  }

  getCommissions(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/commissions`);
    return this.http.get<LeaseBarneyCommissionScheduleItem[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  createCommission(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/createcommissionitemforbarney/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  deleteCommission(id: string) {
    return this.http.get<ReturnMessage>(this.formatUrl(`${this.leaseBarneyUrl}/deletecommission/${id}`), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  getClassificationTypes(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/classificationtypes`);
    return this.http.get<LeaseBarneyImprovementClassificationType[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getCommissionTemplates(id: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/${id}/commissiontemplates`);
    return this.http.get<LeaseCommissionTemplate[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveSelectedPropertyAndUnit(leaseBarneyKey: number, propKey: number, unitKey: number, tenantName: string): Promise<number> {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/savepickerinfo`);
    if (leaseBarneyKey === undefined) {
      leaseBarneyKey = -1;
    }
    return this.http
      .post<number>(url, { leaseBarneyKey, propertyKey: propKey, unitKey, tenantName }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .catch(this.handleError2);
  }

  createLeaseBarneyForBudgetLeasing(budgetLeasingKey: number, commissionTemplateKey: number): Promise<number> {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/createleasebarneyforbudgetleasing`);

    return this.http
      .post<number>(url, { budgetLeasingKey, commissionTemplateKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .catch(this.handleError2);
  }

  updateLeaseBarneyStatus(statusKey: string, leaseBarneyKey: string) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/updateleasebarneystatus`);
    return this.http
      .post<ReturnMessage>(url, { statusKey, leaseBarneyKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  cleanupLeaseBarneyWorkflow(leaseBarneyKey: number, isEncumbered: boolean, COOSignoffRequired: boolean, isBudgetedRevenueImprovementsDataDirty: boolean, propertyKey: number) {
    const url = this.formatUrl(`${this.leaseBarneyUrl}/workflowcleanup`);
    return this.http
      .post<ReturnMessage>(url, { leaseBarneyKey: leaseBarneyKey.toString(), isEncumbered: isEncumbered.toString(), COOSignoffRequired: COOSignoffRequired.toString(), isBudgetedRevenueImprovementsDataDirty: isBudgetedRevenueImprovementsDataDirty.toString(), propertyKey: propertyKey.toString() }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise();
  }

  private handleError2(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  uploadFiles(fileList: FileList, leaseBarneyKey: number, fileTypeKey: number): Observable<boolean> {
    const url = `${this.leaseBarneyUrl}/upload/` + leaseBarneyKey.toString() + `/` + fileTypeKey.toString();

    let formData: FormData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('fileUpload', fileList[i]);
    }

    return this.http.post<boolean>(this.formatUrl(url), formData, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  private formatUrl(url: string): string {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = newUrl.replace('http://cub/', 'https://cub.centerpoint.com/');
    }
    return newUrl;
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService, alertMsg: string) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + alertMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }
}
