import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamService } from './../team.service';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Bracket } from './../bracket/bracket';
import { Team } from './../bracket/bracket';
import { BracketService } from './../bracket.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'cub-team-picker',
    templateUrl: './team-picker.component.html',
    styleUrls: ['./team-picker.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule, NgFor, MatOptionModule, AsyncPipe]
})
export class TeamPickerComponent implements OnInit {

  @Input() teams: Team[] = [];
  @Input() seed: number;
  @Output() selectedTeam: EventEmitter<Team> = new EventEmitter<Team>();
  myControl = new UntypedFormControl();
  filteredTeams: Observable<Team[]>;

  constructor(
    private bracketService: BracketService,
    private teamService: TeamService
    ) { }

  ngOnInit() {
    this.filteredTeams = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(team: string): Team[] {
    const filterValue = team.toLowerCase();
    return this.teams.filter(t => t.name.toLowerCase().includes(filterValue));
  }

  optSelected(option: any) {
    const team = this.teams.filter(t => t.name === option.option.value)[0];
    team.seed = this.seed;
    console.log('team', team);
    this.selectedTeam.emit(team);
  }

}

