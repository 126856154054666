import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, NgIf, MatButtonModule]
})
export class MessageDialogComponent {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public readonly dialogRef: MatDialogRef<MessageDialogComponent> ) { }


  ok() {
    this.dialogRef.close(true);
  }
}
