import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListService } from '../../core/list.service';
import { ListOptions } from '../list-control/listOptions';
import { ListGroup } from './listGroup';
import { NgFor } from '@angular/common';

@Component({
    selector: 'cub-list-group',
    templateUrl: './list-group.component.html',
    styleUrls: ['./list-group.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class ListGroupComponent implements OnInit {

  @Input() groups: ListGroup[];
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  listOptions: ListOptions;

  constructor(
    private listService: ListService
  ) {
    this.groups = [];
  }

  ngOnInit() {
    this.listService.listSettings$.subscribe(listSettings => {
      this.listOptions = listSettings;
    });
  }

  selectGroup(group: ListGroup) {
    let selectedGroupName = '';
    if (group !== null) {
      selectedGroupName = group.name;
    }
    this.listOptions.selectedGroup = selectedGroupName;
    this.listService.listUpdated(this.listOptions);
  }
}
