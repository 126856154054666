import { Injectable } from '@angular/core';
import * as XLSX from '@sheet/core';

export class ExcelData {
  sheetName: string;
  sheetData: any[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor() { }

  exportDataToExcel(fileName: string, excelData: ExcelData[], colWidth?: number[]) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    excelData.forEach(sheet => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet.sheetData); // converts a DOM TABLE element to a worksheet
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);

      if (colWidth) {
        ws['!cols'] = colWidth.map(c => ({ wch: c }));
      }
    });
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  removeSpecialCharacters(str: string): string {
    return str.replace(/[^a-zA-Z ]/g, '');
  }
}
