
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { NgIf } from '@angular/common';



@Component({
    selector: 'cub-graph-bar',
    templateUrl: './graph-bar.component.html',
    styleUrls: ['./graph-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf]
})
export class GraphBarComponent implements OnInit, OnChanges {

  @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
  @Input() header: string;
  @Input() dataArray: any[];
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() showHeader: boolean;
  @Input() showLegend: boolean;
  @Input() chartName: string;
  private width: number;
  private height: number;
  private margin = {top: 40, right: 40, bottom: 40, left: 40};

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  alreadyLoaded: boolean;

  constructor(private container: ElementRef) {
  }

  ngOnInit() {
    this.alreadyLoaded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initMargins();
    if (this.dataArray !== undefined) {
      if (!this.alreadyLoaded || d3.select('#' + this.chartName + ' > *').size() > 0) {
        d3.select('#' + this.chartName + ' > *').remove();
        this.initSvg();
        this.drawBars();
      }
    }
  }

  private initMargins() {
    this.margin = {top: 20, right: 20, bottom: 20, left: 60};
  }

  private initSvg() {
    const element = this.chartContainer.nativeElement;
    this.width = this.graphWidth - this.margin.left - this.margin.right;
    this.height = this.graphHeight - this.margin.top - this.margin.bottom;

    this.svg = d3.select(element)
                .append('svg')
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr('viewBox', '0 0 ' + (this.width + this.margin.left + this.margin.left) + ' ' + (this.height + this.margin.top + this.margin.bottom))
                .classed('svg-content', true);

    this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.x = d3.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.y = d3.scaleLinear().rangeRound([this.height, 0]);
    this.x.domain(this.dataArray.map((d) => d.label));
    this.y.domain([0, d3.max(this.dataArray, (d) => d.value)]);
  }

  private drawBars() {
    this.alreadyLoaded = true;

    this.g.append('g')
          .attr('class', 'axis axis--x')
          .attr('transform', 'translate(0,' + this.height + ')')
          .call(d3.axisBottom(this.x));

    this.g.append('g')
          .attr('class', 'axis axis--y')
          .call(d3.axisLeft(this.y).ticks(10));

    this.g.selectAll('.bar')
          .data(this.dataArray)
          .enter().append('rect')
          .attr('class', 'bar')
          .attr('x', (d) => this.x(d.label) )
          .attr('y', (d) => this.y(d.value) )
          .attr('width', this.x.bandwidth())
          .attr('height', (d) => this.height - this.y(d.value) );
  }
}
