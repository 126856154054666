import { TeamService } from './../team.service';
import { Bracket } from './../bracket/bracket';
import { Team } from './../bracket/bracket';
import { BracketService } from './../bracket.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { faDollarSign, faStream, faExclamationSquare, faPlus, faAngleDown } from '@fortawesome/pro-light-svg-icons';
import * as moment from 'moment';
import { MarchMadnessConstants } from '../contants';
import { User } from 'src/app/user/user';
import { parse } from 'path';

@Component({
    selector: 'cub-march-madness-home',
    templateUrl: './march-madness-home.component.html',
    styleUrls: ['./march-madness-home.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class MarchMadnessHomeComponent implements OnInit {

  user: User;
  profile: any;
  apiData: any;
  allBrackets: Bracket[] = [];
  brackets: Bracket[] = [];
  masterBracket: Bracket;
  myBrackets: Bracket[] = [];
  teams: Team[] = [];
  canViewOthers: boolean;
  cutoffDt: Date;
  faDollarSign = faDollarSign;
  faStream = faStream;
  faExclamationSquare = faExclamationSquare;
  faAngleDown = faAngleDown;
  faPlus = faPlus;
  isGod = false;
  years: string[] = [];
  selYear: string;

  constructor(
    private bracketService: BracketService,
    private teamService: TeamService,
    private router: Router,
    protected http: HttpClient) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this.isGod = this.user.workEmail === 'kgerman@centerpoint.com';
    this.getBrackets();
    this.cutoffDt = moment(MarchMadnessConstants.CutOffDt).toDate();
    this.canViewOthers = moment().isAfter(moment(this.cutoffDt));
  }

  getLogoUrlByEspnId(id: number) {
    let url = '';
    if (this.teams.length > 0) {
      let team: Team;
      team = this.teams.filter(t => t.espnId === id)[0];
      url = 'assets/img/logos/50/' + team.name + '(' + team.espnId + ').jpg';
    }
    return url;
  }

  yearChanged(year: string) {
    console.log('year changed', year);
    this.selYear = year;
    this.getBracketsForYear(this.selYear);
  }

  getBrackets() {
    this.bracketService.bracketList$.subscribe(bs => {
      this.years = [...Array.from(new Set(bs.map(br => br.year.toString())))];
      this.years = this.years.sort((a: string, b: string) => parseFloat(b) - parseFloat(a));
      this.selYear = this.years[0];
      this.allBrackets = bs;
      this.getBracketsForYear(this.selYear);
    });
  }

  getBracketsForYear(year: string) {
    this.brackets = this.allBrackets.filter(br => +br.year === +this.selYear && !br.master);
    this.masterBracket = this.allBrackets.filter(br => +br.year === +this.selYear && br.master)[0];
    console.log('getBracketsForYear', this.brackets, this.masterBracket);
    this.brackets.forEach(br => {
      br.points = Bracket.getPoints(br, this.masterBracket);
    });
    this.brackets = this.brackets.sort((a, b) => a.points < b.points ? 1 : a.points > b.points ? -1 : 0);
    this.myBrackets = this.brackets.filter(br => br.userName === this.user.accountName);
    console.log('brackets', this.brackets, this.user.workEmail);
  }

  goToBracket(b: Bracket) {
    if (this.canViewOthers || b.userName === this.user.accountName || this.isGod) {
      this.router.navigate(['/marchmadness/brackets', b.id]);
    }
  }

  goToMaster() {
    this.router.navigate(['/marchmadness/brackets', this.masterBracket.id]);
  }

  newTournament() {
    this.router.navigate(['/marchmadness/newtournament']);
  }

  newBracket() {
      this.router.navigate(['/marchmadness/brackets', 0]);
  }

  getTotalForRound(bracket: Bracket, round: number): number {
    return Bracket.getPointsForRound(bracket, this.masterBracket, round);
  }

  getPotentialPoints(bracket: Bracket): number {
    return Bracket.getPotentialPoints(bracket, this.masterBracket);
  }

  getRegionWinnerLogo(b: Bracket, regionIndex: number): string {
    let logo = '';
    let teamId: number;
    if (regionIndex < 4) {
      const region = b.regions[4];
      const round = region.rounds[0];
      const game = round.games.filter(g => g.id === (regionIndex === 1 || regionIndex === 2 ? 61 : 62))[0];
      if (regionIndex <= 1) {
        if (game) {
          teamId = game.team1Id;
        }
      } else {
        if (game) {
          teamId = game.team2Id;
        }
      }
      const team = b.regions[regionIndex].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = 'assets/img/logos/50/' + team.name + '(' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getChampLogo(b: Bracket): string {
    let logo = '';
    let teamId: number;
    const region = b.regions[4];
    const round = region.rounds[2];
    const game = round.games[0];
    if (game) {
      teamId = game.team1Id;
      const team = b.regions[4].teams.filter(t => t.id === teamId)[0];
      if (team) {
        logo = 'assets/img/logos/50/' + team.name + '(' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getRunnerUpLogo(b: Bracket): string {
    let logo = '';
    let teamId: number;
    const region = b.regions[4];
    const round = region.rounds[2];
    const game = round.games[0];
    if (game) {
      teamId = game.team1Id;
      let runnerUpTeamId = b.regions[4].rounds[1].games[0].team1Id;
      if (teamId === runnerUpTeamId) {
        runnerUpTeamId = b.regions[4].rounds[1].games[0].team2Id;
      }
      const team = b.regions[4].teams.filter(t => t.id === runnerUpTeamId)[0];
      if (team) {
        logo = 'assets/img/logos/50/' + team.name + '(' + team.espnId + ').jpg';
      }
    }
    return logo;
  }

  getFinalFourLoser(b: Bracket, gameIndex: number): string {
    let logo = '';
    let otherTeamId: number;
    let team1Id = b.regions[4].rounds[0].games[gameIndex].team1Id;
    let team2Id = b.regions[4].rounds[0].games[gameIndex].team2Id;
    let champTeamId1 = b.regions[4].rounds[1].games[0].team1Id;
    let champTeamId2 = b.regions[4].rounds[1].games[0].team2Id;
    if (team1Id === champTeamId1 || team1Id === champTeamId2) {
      otherTeamId = team2Id;
    } else {
      otherTeamId = team1Id;
    }
    const team = b.regions[4].teams.filter(t => t.id === otherTeamId)[0];
    if (team) {
      logo = 'assets/img/logos/50/' + team.name + '(' + team.espnId + ').jpg';
    }
    return logo;
  }

  notFinished(bracket: Bracket): boolean {
    let ctr = 0;
    bracket.regions.forEach(rgn => {
      rgn.rounds.forEach((rnd, i) => {
        if (i > 0 || (rgn.name === 'Final Four')) {
          rnd.games.forEach(gm => {
            if (gm.team1Id) {
              ctr++;
            }
            if (gm.team2Id) {
              ctr++;
            }
          });
        }
      });
    });
    return ctr !== 63;
  }
}
