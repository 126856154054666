<div class="widget-line-graph-colored">
  <div [class]="valueClass">{{ amount }}</div>
  <div [class]="labelClass">{{ label }}</div>
  <div class="note">{{ note }}</div>
  <canvas baseChart
          id="chartCanvas"
          #chartCanvas
          [style.visibility]="chartVisible"
          [data]="lineChartData"
          [options]="lineChartOptions"
          [legend]="barChartLegend"
          [type]="barChartType"
          [type]="'line'"
          (mouseover)="mouseover()"
          (mouseout)="mouseout()"
          class="graph">
  </canvas>
</div>
