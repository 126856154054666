import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numWithCommas',
    standalone: true
})
export class NumWithCommasPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return value;
    }
  }

}
