
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';
import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ChangePacket } from '../changePacket';
import { SaveService } from '../save.service';
import { AlertService } from '../../core/alert.service';


import * as moment from 'moment';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'cub-date-auto-save',
    templateUrl: './date-auto-save.component.html',
    styleUrls: ['./date-auto-save.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateAutoSaveComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateAutoSaveComponent), multi: true },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, NgIf]
})
export class DateAutoSaveComponent implements OnChanges, ControlValueAccessor {

  @Input() tableName: string;
  @Input() fieldName: string;
  @Input() itemKey: number;
  @Input() dataLength: number;
  @Input() label: string;
  @Input() readOnly!: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fieldValue') _fieldValue: Date;
  @ViewChild('input') input;
  @Output() controlUpdate: EventEmitter<any> = new EventEmitter();

  private delay = 400;
  public keyUp = new Subject<string>();
  private origValue: Date;
  cssClass = '';
  status = 'normal';
  currentStyles = { color: this.readOnly ? 'red' : 'black' };
  propagateChange: any = () => { };
  validateFn: any = () => { };

  constructor(
    elementRef: ElementRef,
    private saveService: SaveService) {
    const eventStream = observableFromEvent(elementRef.nativeElement, 'keyup').pipe(
      map(() => this._fieldValue))
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged()
      );

    eventStream.subscribe(() => {
      // this.save(input);  //uncomment if we want to use keydown delayed saving
    });
    this.origValue = null;
  }

  get fieldValue() {
    return this._fieldValue;
  }

  set fieldValue(val) {
    this._fieldValue = val;
    if (this.origValue === null) {
      this.origValue = val;
    }
    this.propagateChange(val);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._fieldValue !== undefined) {
      this.origValue = changes._fieldValue.currentValue;
    }
  }

  // ControlValueAccessor
  writeValue(value) {
    if (value) {
      this.fieldValue = value;
    } else {
      this.fieldValue = null;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  validate(s: UntypedFormControl) {
    return this.validateFn(s);
  }

  onFocus() {
    this.status = 'normal';
  }

  onChange(evt: any) {
    this._fieldValue = evt.value;
    this.save(this._fieldValue);
  }

  onBlur() {
    if (this.origValue.toString() !== this._fieldValue.toString()) {
      this.save(this._fieldValue);
    }
  }

  save(value: Date): void {
    let oldDateString = moment(this.origValue).format('MM/DD/YYYY');
    let newDateString = moment(value).format('MM/DD/YYYY');
    if (newDateString === 'Invalid date') {
      newDateString = '';
    }
    if (oldDateString === 'Invalid date') {
      oldDateString = '';
    }
    let changePacket = new ChangePacket(this.tableName, this.fieldName, this.label, this.itemKey, oldDateString, newDateString, newDateString, 'datetime', this.dataLength);
    this.saveService.saveChange(changePacket)
      .then(() => {
        this.status = 'success';
        this.origValue = value;
        this.emitUpdate();
      })
      .catch(() => { this.status = 'error'; });
  }


  emitUpdate() {
    this.controlUpdate.emit({ saved: true });
  }

  // TODO - Do we need this handler?
  registerOnTouched() { }
}
