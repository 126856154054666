import { Component, OnInit, Input } from '@angular/core';
import { User } from '../user';
import { environment } from '../../../environments/environment';

import { faBalanceScaleRight } from '@fortawesome/pro-light-svg-icons/faBalanceScaleRight';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faTrophyAlt } from '@fortawesome/pro-light-svg-icons/faTrophyAlt';
import { faAbacus } from '@fortawesome/pro-light-svg-icons/faAbacus';
import { faDesktopAlt } from '@fortawesome/pro-light-svg-icons/faDesktopAlt';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faMoneyBillAlt } from '@fortawesome/pro-light-svg-icons/faMoneyBillAlt';
import { faBadgeDollar } from '@fortawesome/pro-light-svg-icons/faBadgeDollar';
import { faUserCrown } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faDiceOne } from '@fortawesome/pro-light-svg-icons/faDiceOne';
import { faHandsUsd } from '@fortawesome/pro-light-svg-icons/faHandsUsd';
import { faHandsHelping } from '@fortawesome/pro-light-svg-icons/faHandsHelping';
import { faUnicorn } from '@fortawesome/pro-light-svg-icons/faUnicorn';
import { faShovel } from '@fortawesome/pro-light-svg-icons/faShovel';
import { faDiceSix } from '@fortawesome/pro-light-svg-icons/faDiceSix';
import { faSearchDollar } from '@fortawesome/pro-light-svg-icons/faSearchDollar';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';

import * as moment from 'moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-user-badge',
    templateUrl: './user-badge.component.html',
    styleUrls: ['./user-badge.component.scss'],
    standalone: true,
    imports: [NgIf, FontAwesomeModule]
})
export class UserBadgeComponent implements OnInit {

  @Input() user: User;
  serviceTimeYears = 0;
  faBalanceScaleRight = faBalanceScaleRight;
  faCircle = faCircle;
  faTrophyAlt = faTrophyAlt;
  faAbacus = faAbacus;
  faDesktopAlt = faDesktopAlt;
  faForklift = faForklift;
  faBullseyeArrow = faBullseyeArrow;
  faBuilding = faBuilding;
  faPaperclip = faPaperclip;
  faMoneyBillAlt = faMoneyBillAlt;
  faBadgeDollar = faBadgeDollar;
  faUserCrown = faUserCrown;
  faDiceOne = faDiceOne;
  faHandsUsd = faHandsUsd;
  faHandsHelping = faHandsHelping;
  faUnicorn = faUnicorn;
  faShovel = faShovel;
  faDiceSix = faDiceSix;
  faSearchDollar = faSearchDollar;
  faBriefcase = faBriefcase;

  constructor() { }

  ngOnInit() {
    this.serviceTimeYears = this.getServiceTime();
  }

  getUrl(): string {
    return environment.userBlobStorageUrl + this.user?.accountName?.replace('CENTERPOINT-PRO\\', '') + '.jpg';
  }

  getPhone(phone): string {
    let re = /\-/gi;
    return phone.replace(re, '.');
  }

  getClass(): string {
    let myClass = 'bottom ';
    switch (this.user.officeLocation) {
      case 'Oak Brook':
      case 'Oakbrook':
      case 'Oak Brook Office':
        myClass += 'bottom-oak-brook';
        break;
      case 'Kansas City':
        myClass += 'bottom-kc';
        break;
      case 'Suffolk':
      case 'Suffolk Office':
        myClass += 'bottom-suffolk';
        break;
      case 'Los Angeles':
        myClass += 'bottom-la';
        break;
      case 'New Jersey':
        myClass += 'bottom-nj';
        break;
      case 'Norfolk':
        myClass += 'bottom-norfolk';
        break;
      case 'Oakland':
        myClass += 'bottom-oakland';
        break;
      case 'Florida':
        myClass += 'bottom-fla';
        break;
      default:
        break;
    }
    return myClass;
  }

  formatDt(dt): string {
    let dateRet = '';
    if (dt !== undefined && dt !== null && dt !== '') {
      dateRet = moment(dt).format('MM.DD.YYYY');
    }

    return dateRet;
  }

  formatBirthday(dt): string {
    let dateRet = '';
    if (dt !== undefined && dt !== null && dt !== '') {
      dateRet = moment(dt).format('MM.DD');
    }

    return dateRet;
  }

  showTrophy(): boolean {
    return moment(this.user.hireDt).add(5, 'years') < moment();
  }

  getServiceTime(): number {
    let year = 0;
    if (moment(this.user.hireDt).add(30, 'years') < moment()) {
      year = 30;
    } else if (moment(this.user.hireDt).add(25, 'years') < moment()) {
      year = 25;
    } else if (moment(this.user.hireDt).add(20, 'years') < moment()) {
      year = 20;
    } else if (moment(this.user.hireDt).add(15, 'years') < moment()) {
      year = 15;
    } else if (moment(this.user.hireDt).add(10, 'years') < moment()) {
      year = 10;
    } else if (moment(this.user.hireDt).add(5, 'years') < moment()) {
      year = 5;
    }
    return year;
  }
}
