import { Component, OnInit } from '@angular/core';

import { Alert, AlertType } from '../alert';
import { AlertService } from '../alert.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgFor } from '@angular/common';

@Component({
    selector: 'cub-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('someCoolAnimation', [
            transition('* => fadeIn', [
                style({ opacity: 0, marginTop: -100 }),
                animate(500, style({ opacity: 1, marginTop: 10 }))
            ]),
            transition('* => fadeOut', [
                animate(500, style({ opacity: 0, marginTop: -100 }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgFor]
})

export class AlertComponent implements OnInit {
  alerts: Alert[] = [];
  bindingVar = '';
  // alertToRemove: Alert;

  constructor(
    private alertService: AlertService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array (if not for new version - we're handling that separately)
      if (alert.type === AlertType.Success) {
        this.snackBar.open(alert.message, 'Ok', {
          duration: 2000,
        });
      } else {
        // add alert to array (if not for new version - we're handling that separately)
        if (alert.type !== AlertType.Version) {
          this.alerts.push(alert);
          this.bindingVar = 'fadeIn';
        }
      }
    });
  }

  removeAlert(alert: Alert) {
    this.bindingVar = 'fadeOut';
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert): string {
    if (!alert) {
      return '';
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-success';
      case AlertType.Error:
        return 'alert alert-danger';
      case AlertType.Info:
        return 'alert alert-success';
      case AlertType.Warning:
        return 'alert alert-warning';
      default:
        return '';
    }
  }

  fadeIn() {
    this.bindingVar = 'fadeIn';
  }

  fadeOut() {
    this.bindingVar = 'fadeOut';
  }
}
