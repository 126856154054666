import { UserListFilterPipe } from './user-list/user-list-filter.pipe';
import { UserBadgeComponent } from './user-badge/user-badge.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkListComponent } from './link-list/link-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule } from '../shared/shared.module';
import { GoalPipePipe } from './user-detail/goal-pipe.pipe';
import { CubUserEditDialogComponent } from './cub-user-edit-dialog/cub-user-edit-dialog.component';
import { GldataService } from '../misc/gldata-transfer/gldata.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserRoutingModule,
        SharedModule,
        UserDetailComponent,
        LinkListComponent,
        UserListComponent,
        UserBadgeComponent,
        UserListFilterPipe,
        GoalPipePipe,
        UserHomeComponent,
        CubUserEditDialogComponent
    ],
    exports: [
        UserDetailComponent,
        UserHomeComponent,
        LinkListComponent,
        UserBadgeComponent
    ],
    providers: [
        UserListFilterPipe,
        GoalPipePipe,
        GldataService
    ]
})
export class UserModule { }
