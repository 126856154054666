import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BracketPrintComponent } from './bracket-print/bracket-print.component';
import { BracketComponent } from './bracket/bracket.component';

import { MarchMadnessHomeComponent } from './march-madness-home/march-madness-home.component';
import { MarchMadnessListingComponent } from './march-madness-listing/march-madness-listing.component';
import { NopeekingComponent } from './nopeeking/nopeeking.component';
import { TournamentNewComponent } from './tournament-new/tournament-new.component';

// import { CrisisDetailResolver }   from './crisis-detail-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: MarchMadnessHomeComponent,
    children: [
      {
        path: '',
        component: MarchMadnessListingComponent
      },
      {
        path: 'home',
        component: MarchMadnessListingComponent
      },
      {
        path: 'dashboard',
        component: MarchMadnessListingComponent
      },
      { path: 'newtournament', component: TournamentNewComponent },
      { path: 'brackets/:id', component: BracketComponent },
      { path: 'brackets/print/:id', component: BracketPrintComponent },
      { path: 'brackets', component: BracketComponent, },
      { path: 'nopeeking', component: NopeekingComponent, },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
  // ,
  // providers: [
  //   CrisisDetailResolver
  // ]
})
export class MarchMadnessRoutingModule { }
