export class Assignee {
  pcKey: number;
  fullName: string;
  emailAddress: string;

  constructor(pcKey: number, fullName: string, emailAddress: string) {
    this.pcKey = pcKey;
    this.fullName = fullName;
    this.emailAddress = emailAddress;
  }
}

export class BadDataTicket {

  ticketKey: number;
  propertyKey: number;
  leaseKey: number;
  statusKey: number;
  statusDesc: string;
  systemKey: number;
  systemDesc: string;
  location: string;
  description: string;
  resolution: string;
  resolutionDt = null;
  resolutionUID: string;
  resolutionName: string;
  creatorDt = null;
  creatorUID: string;
  creatorName: string;
  lastDt = null;
  lastUID: string;
  assignedList: string;
  route: string;

  assignees: Assignee[] = [];


  constructor() {
    this.ticketKey = 0;
    this.propertyKey = null;
    this.leaseKey = null;
    this.statusKey = 1;
    this.statusDesc = 'Open';
    this.systemKey = 0;
    this.systemDesc = '';
    this.location = '';
    this.description = '';
    this.resolution = '';
    this.resolutionUID = '';
    this.resolutionName = '';
    this.resolutionDt = null;
    this.creatorUID = '';
    this.creatorName = '';
    this.creatorDt = null;
    this.lastDt = null;
    this.assignedList = '';
    this.route = '';

    this.assignees = [];
  }
}
