<div class='panel panel-primary'
     style="width: 100%;">
  <google-map #googleMap
              width="100%"
              [height]="mapHeight"
              [options]="options"
              (zoomChanged)="zoomChanged()"
              (centerChanged)="centerChanged()"
              (mapClick)="moveMap($event)">
    <map-marker *ngFor="let marker of markers"
                #markerElem="mapMarker"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [icon]="marker.icon"
                [options]="marker.options"
                (mapClick)="openInfo(markerElem, marker.info)">
    </map-marker>
    <map-info-window>
      <h5>{{ mapInfo?.title }}</h5>
      <div style="margin-top: -5px;" [innerHTML]="infoContent"></div>
      <!--
      <div style="margin-top: 5px;">
        <img [src]="infoStatusIcon" style="width: 15px;" /> = {{ infoOcc | number:'1.0-2' }}% Occupied
      </div>
      <br>
      -->
      <a [href]="mapInfo?.link">{{ mapInfo?.linkText }}</a><br>
      <a [href]="mapInfo?.link" [target]="infoLink">{{ mapInfo?.linkText }} in New Tab</a><br>
      <a href="javascript: void(0);" (click)="editAddress()">Edit Address</a>
    </map-info-window>
  </google-map>
</div>
