import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT, NgIf, NgFor } from '@angular/common';
import { environment } from 'src/environments/environment';

import { SearchService } from '../search.service';
import { SearchResult, FileSearchResult } from '../searchResult';

import { faFile, faFileExcel, faFileWord, faFilePdf, faEnvelope, faFileLines } from '@fortawesome/pro-light-svg-icons';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
    selector: 'cub-search-main',
    templateUrl: './search-main.component.html',
    styleUrls: ['./search-main.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NgIf, MatListModule, NgFor, MatIconModule, FontAwesomeModule, SafeHtmlPipe]
})
export class SearchMainComponent implements OnInit {

  cubSearchResults: SearchResult[];
  fileSearchResults: FileSearchResult[];
  spSearchResults: SearchResult[];
  tabIndex: number;
  isSearching: boolean;
  folderValue: string;
  isRemote: boolean;

  faFile = faFile;
  faFileExcel = faFileExcel;
  faFileWord = faFileWord;
  faFilePdf = faFilePdf;
  faEnvelope = faEnvelope;
  faFileLines = faFileLines;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.tabIndex = 0;
    this.isSearching = false;
    this.folderValue = '';
    this.isRemote = this.document.location.href.startsWith('https');
  }


  ngOnInit() {
    switch (this.searchService.lastSystem) {
      case 'File':
        this.tabIndex = 1;
        break;
      default:
        this.tabIndex = 0;
        break;
    }

    this.searchService.isSearching$.subscribe(result => {
      this.isSearching = result;
    });

    this.searchService.searchResults$.subscribe(results => {
      console.log('got search results', results);
      this.cubSearchResults = results;
      this.isSearching = false;
    });

    this.searchService.fileSearchResults$.subscribe(results => {
      console.log('got file search results', results);
      this.fileSearchResults = results;
      this.isSearching = false;
    });
  }

  search() {
    let sys: string;
    switch (this.tabIndex) {
      case 0:
        sys = 'ID';
        break;
      case 1:
        sys = 'File';
        break;
    }
    console.log('searching', sys);
    this.isSearching = true;
    this.searchService.setSystem(sys);
    this.searchService.searchBySystem(sys, this.folderValue.replace(/[\\*]/g, '/'))
      .subscribe(results => {
        this.searchService.mapResult(results);
        console.log('got search results', results);
      });
  }

  itemClick(item: SearchResult) {
    this.searchService.closeSearch();
    if (!item.externalLink) {
      this.router.navigate([item.url], { relativeTo: this.route });
    } else {
      window.open(item.url);
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  sanitizeAndFormat(url: string) {
    let fullUrl: string;
    if (this.isRemote) {
      fullUrl = 'https://cntcloud.egnyte.com/app/index.do#storage/files/1/Shared/' + url;
    } else {
      fullUrl = 'file://allshare/elc/shared/' + url;
    }
    return this.sanitizer.bypassSecurityTrustUrl(fullUrl);
  }

  openFileLink(path: string) {
    window.open(environment.egnyteUrl + path.toLowerCase().replace('/shared/', ''));
  }

  getIcon(filename: string) {
    switch (filename.substring(filename.lastIndexOf('.') + 1)) {
      case 'txt':
        return faFile;
        break;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
        break;
      case 'doc':
      case 'docx':
        return faFileWord;
        break;
      case 'pdf':
        return faFilePdf;
        break;
      case 'msg':
        return faEnvelope;
        break;
      case 'txt':
        return faFileLines;
        break;
      default:
        return faFile;
        break;
    }
  }

}
