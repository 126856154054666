import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Bracket } from './march-madness/bracket';
import { User } from '../user/user';
import { UserService } from '../core/user.service';

@Injectable({
  providedIn: 'root'
})
export class SportsService {

  private url = environment.cubCloudUrl + 'marchMadness';

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getMarchMadnessStandings(year: number): Observable<Bracket[]> {
    const url = `${this.url}/standings/${year}`;
    return this.http.get<Bracket[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
