import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterBuilder } from 'FilterBuilders/filterBuilder';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed, IVisualEmbedConfiguration } from 'powerbi-client';
import { PowerBIReportEmbedComponent, PowerBIVisualEmbedComponent, PowerBIEmbedModule } from 'powerbi-client-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cub-power-bi-visual',
    templateUrl: './powerbi-visual.component.html',
    styleUrls: ['./powerbi-visual.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, PowerBIEmbedModule]
})
export class PowerBiVisualComponent implements OnInit {

  @Input() reportId: string;
  @Input() groupId: string;
  @Input() visualName: string;
  @Input() pageName: string;
  @Input() cssClass: string;

  @ViewChild(PowerBIVisualEmbedComponent) visualObj: PowerBIVisualEmbedComponent;

  isEmbedded = false;
  page: Page;
  embedUrl = '';
  accessToken = '';
  powerBiConfig: IVisualEmbedConfiguration = {
    accessToken: localStorage.getItem('powerbiToken'),
    embedUrl: 'https://app.powerbi.com/reportEmbed',
    id: '',
    pageName: '',
    tokenType: models.TokenType.Aad,
    type: 'visual',
    visualName: '',
    groupId: '',
    permissions: models.Permissions.Read
  };

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.powerBiConfig = {
        accessToken: localStorage.getItem('powerbiToken'),
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        id: this.reportId,
        pageName: this.pageName,
        tokenType: models.TokenType.Aad,
        type: 'visual',
        visualName: this.visualName,
        groupId: this.groupId,
        permissions: models.Permissions.Read
      };
      this.isEmbedded = true;
    }, 2000);
  }

  onVisualLoaded(event) {
    console.log('onVisualLoaded', event);

    if (this.visualName === 'c5effa57230308f4b37d') {
      this.filterByLand();
    }
    if (this.visualName === '3856a3d069897160320c') {
      this.filterByJob();
    }
  }

  filterByJob() {
    const visual = this.visualObj.getVisual();
    let myFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: 1,
        operator: 'In',
        requireSingleSelection: false,
        target: { table: 'dw_construction_job_details', column: 'Job Code' },
        values: ['d117007-1']
      },
    ];
    visual.getFilters().then((filters) => {
      console.log('filters 1', filters);
    });
    visual.updateFilters(models.FiltersOperations.Replace, myFilter, models.FiltersLevel.Report).then((filters) => {
      visual.refresh();
      console.log('filters 2', filters);
    });
    console.log('my visual', visual);
  }

  filterByLand() {
    const visual = this.visualObj.getVisual();
    let myFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: 1,
        operator: 'In',
        requireSingleSelection: false,
        target: { table: 'dw_prop_characterisitcs_by_qtr', column: 'Property Category' },
        values: ['Land']
      },
    ];
    visual.getFilters().then((filters) => {
      //console.log('filters 1', filters);
    });
    visual.updateFilters(models.FiltersOperations.Replace, myFilter, models.FiltersLevel.Report).then((filters) => {
      visual.refresh();
      //console.log('filters 1', filters);
    });
    //console.log('my visual', visual);
  }


  onFiltersApplied(event) {
    console.log('onFiltersApplied', event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportId) {
      this.powerBiConfig.id = changes.reportId.currentValue;
    }
    if (changes.groupId) {
      this.powerBiConfig.groupId = changes.groupId.currentValue;
    }
  }
}
