<mat-card appearance="outlined" class="cub-table-card">
  <mat-card-header>
    <mat-card-title>
      <span>Property List</span>
      <button mat-raised-button style="float: right; margin-left: 20px;" (click)="addProp();">
          <i class="fa fa-plus"></i> &nbsp;Add Property
      </button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table class="table" style="table-layout: fixed;" id="propList">
      <colgroup>
        <col style="width: 30%;" />
        <col style="width: 20%;" />
        <col style="width: 10%;" />
        <col style="width: 10%;" />
        <col style="width: 10%;" />
        <col style="width: 10%;" />
        <col style="width: 10%;" />
      </colgroup>
      <thead>
        <tr>
          <th>Address</th>
          <th>City, State</th>
          <th>Market</th>
          <th>Region</th>
          <th>Green Street</th>
          <th>TAC</th>
          <th>Sq Ft</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let prop of investment.properties" (click)="selectProperty(prop);" [class.selected]="selectedProperty !== null && prop.key === selectedProperty.key">
          <td>{{ prop.desc }}</td>
          <td>{{ prop.address.city }}, {{ prop.address.stateID }}</td>
          <td>{{ prop.marketDesc }}</td>
          <td>{{ prop.regionDesc }}</td>
          <td>{{ prop.greenStreetRatingKeyName }}</td>
          <td>${{ prop.tac | numWithCommas }}</td>
          <td>{{ prop.leasableSF | numWithCommas }}</td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>

<cub-investment-prop-edit [property]="selectedProperty" [investment]="investment" *ngIf="showEditScreen" (onSave)="onPropSave();" (onDelete)="onPropDelete();" (onCancel)="onCancel($event);"></cub-investment-prop-edit>
