
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Investment, InvestmentProperty } from '.';
import { User } from '../user/user';
import { UserService } from '../core/user.service';
import { environment } from '../../environments/environment';

import { Observable, ReplaySubject } from 'rxjs';


@Injectable()
export class InvestmentService {

  private cloudUrl = environment.cubCloudUrl + 'investments';
  private cacheUrl = environment.cubCloudUrl + 'cache/reset/investments';
  private invList = new ReplaySubject<Investment[]>(1);
  investments$ = this.invList.asObservable();   // $ is onnvention to indicate observable
  private selectedKey = new ReplaySubject<number>(1);
  selectedKey$ = this.selectedKey.asObservable();
  private listSettings = new ReplaySubject<any>(1);
  listSettings$ = this.listSettings.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this.getInvestments();
  }

  getInvestments() {
    let url = `${this.cloudUrl}`;
    this.http
      .get<Investment[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .subscribe(list => {
        this.invList.next(list);
      });
  }

  getInvestment(key: number): Observable<Investment> {
    const url = `${this.cloudUrl}/${key}`;
    return this.http.get<Investment>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  toggleProspectArchival(invKey: number, archive: boolean): Observable<boolean> {
    const url = `${this.cloudUrl}/${invKey}/archive`;
    return this.http.post<boolean>(url, JSON.stringify(archive), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  updateAnalysisStatus(invKey: number, oldStatusKey: number, newStatusKey: number): Observable<boolean> {
    const url = `${this.cloudUrl}/${invKey}/archive`;
    return this.http.post<boolean>(url, JSON.stringify(oldStatusKey.toString() + ',' + newStatusKey.toString()), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  updateInvestmentProperty(prop: InvestmentProperty): Observable<number> {
    const url = `${this.cloudUrl}/property/save`;
    return this.http.post<number>(url, JSON.stringify(prop), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveInvestment(inv: Investment): Observable<Investment> {
    const url = `${this.cloudUrl}/`;
    return this.http.post<Investment>(url, JSON.stringify(inv), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  deleteInvestmentProperty(propKey: number): Observable<boolean> {
    const url = `${this.cloudUrl}/property/${propKey}/delete`;
    return this.http.delete<boolean>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  rename(invKey: number, oldPath: string, newName: string, newPath: string): Observable<boolean> {
    const url = `${this.cloudUrl}/${invKey}/rename`;
    return this.http.post<boolean>(url, { newName: newName, oldPath: oldPath, newPath: newPath }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  cacheReset(): Observable<void> {
    return this.http.get<void>(this.cacheUrl, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }
}
