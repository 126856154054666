import { AfterViewInit, Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ListService } from '../../core/list.service';
import { ListOptions, ListView } from '../../shared/list-control';
import { User } from '../../user/user';
import { Investment } from '../investment';
import { InvestmentListPipe } from '../investment-home/investment-list.pipe';
import { InvestmentService } from '../investment.service';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { faQuestionSquare, faPlus, faFileChartPie, faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { TableAction, TableActionEvent } from 'src/app/shared/table/tableAction';
import { Constants } from 'src/app/core/constants';
import { PortalService } from 'src/app/core/portal.service';
import { DataColumnView } from 'src/app/core/dataColumnView';
import { MapInfo } from 'src/app/shared/map-multiple/mapInfo';
import { AddressEditDialogComponent } from 'src/app/shared/address-edit-dialog/address-edit-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Address } from 'src/app/shared/address';
import { AddressService } from 'src/app/shared/address-edit-dialog/address.service';
import { MapMultipleComponent } from '../../shared/map-multiple/map-multiple.component';
import { TableComponent } from '../../shared/table/table.component';
import { InvestmentDateSummaryComponent } from '../investment-date-summary/investment-date-summary.component';
import { MatCardModule } from '@angular/material/card';
import { InvestmentPipelineSummaryComponent } from '../investment-pipeline-summary/investment-pipeline-summary.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-investment-dashboard',
    templateUrl: './investment-dashboard.component.html',
    styleUrls: ['./investment-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, FontAwesomeModule, MatMenuModule, MatTabsModule, InvestmentPipelineSummaryComponent, MatCardModule, InvestmentDateSummaryComponent, TableComponent, MapMultipleComponent]
})
export class InvestmentDashboardComponent implements OnInit, AfterViewInit {
  viewBy = 'list';
  allInvestments: Investment[] = [];
  baseInvestments: Investment[] = [];
  filteredInvestments: Investment[] = [];
  filteredInvestmentsForGrid: Investment[] = [];
  investmentsToShow: Investment[] = [];
  listViews: ListView[] = [];
  invListOptions: ListOptions;
  invDashboardListOptions: ListOptions;
  searchTerm = '';
  groupingBy: string;
  selectedGroup = '';
  filterSettings: any;
  system = 'investment';
  mapHeight = 900;
  mapZoom = 15;
  mapMarkers: any[] = [];
  // grid
  actions: TableAction[] = [];
  tableTitle = 'Deal Grid/Export';
  selTableViewKey = 11;
  tableViews: DataColumnView[] = [];
  mapForceUpdate = false;

  faQuestionSquare = faQuestionSquare;
  faPlus = faPlus;
  faFileChartPie = faFileChartPie;
  faCaretDown = faCaretDown;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private portalService: PortalService,
    private investmentService: InvestmentService,
    private listService: ListService,
    private loggerService: LoggerService,
    private addressService: AddressService,
    public dialog: MatDialog,
    private titleService: Title) {
    this.actions.push(new TableAction('viewInv', 'View Investment', null));
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    console.log('resize', event);
    this.mapHeight = window.innerHeight - 220;
  }

  ngOnInit() {
    this.getListOptions();
    this.getViews();
    this.titleService.setTitle('CUB - Investment Dashboard');
    this.loggerService.logPageVisit('inv dashboard', document.location.href);
    setTimeout(() => {
      this.initScreen();
      this.getScreenSize();
    }, 300);
  }

  initScreen(): void {
    this.getInvestments();
    this.listService.listSettings$.subscribe(listSettings => {
      if (listSettings.name === 'Investment') {
        this.filterSettings = listSettings;
        this.filteredInvestments = new InvestmentListPipe().transform(this.allInvestments, listSettings);
        this.filteredInvestmentsForGrid = new InvestmentListPipe().transform(this.allInvestments, listSettings);
        this.transformData();
      }
    });
  }

  onTableActionSelection(event: TableActionEvent) {
    if (event.action.name === 'viewInv') {
      this.router.navigate([event.object.key], { relativeTo: this.route });
    }
  }

  loadMapMarkers() {
    this.mapMarkers = [];

    const imageBlue = '../../assets/img/dot-blue-20.png';
    const imageRed = '../../assets/img/dot-red-20.png';
    const imageYellow = '../../assets/img/dot-yellow-20.png';

    this.filteredInvestments.forEach(inv => {
      let content = '<div style="margin-bottom: 10px;">';
      switch (inv.statusKey) {
        case Constants.AcqStatusClosed:
          content += '<div style="margin-bottom: 3px;"><img src="' + imageRed + '"> = Closed</div>';
          break;
        case Constants.AcqStatusUnderContract:
          content += '<div style="margin-bottom: 3px;"><img src="' + imageYellow + '"> = Under Contract</div>';
          break;
        case Constants.AcqStatusSignedLOI:
          content += '<div style="margin-bottom: 3px;"><img src="' + imageBlue + '"> = Signed LOI</div>';
          break;
        default:
          break;
      }
      content += '</div>';

      if ((inv.statusKey === Constants.AcqStatusClosed || inv.statusKey === Constants.AcqStatusUnderContract || inv.statusKey === Constants.AcqStatusSignedLOI) &&
        inv.properties.length > 0) {
        let mapInfo = new MapInfo(inv.name, content, 'investments/' + inv.key.toString(), 'Open Deal', inv.properties[0].address.addressKey);

        let lat = inv.properties[0].address.latitude;
        let lng = inv.properties[0].address.longitude;
        let marker = {
          position: {
            lat: lat,
            lng: lng
          },
          label: {
            text: ' '
          },
          icon: this.getImagePath(inv), //p.occupancy === 100 ? imageBlue: (p.occupancy === 0 ? imageRed: imageYellow),
          title: inv.name,
          info: JSON.stringify(mapInfo),
          options: {
            //animation: google.maps.Animation.BOUNCE,
            draggable: false,
          },
          optimized: true
        };
        this.mapMarkers.push(marker);
      }
    });
  }

  getImagePath(inv: Investment): string {
    let path = '../../assets/img/dot-blue-20.png';
    switch (inv.statusKey) {
      case Constants.AcqStatusClosed:
        path = '../../assets/img/dot-red-20.png';
        break;
      case Constants.AcqStatusUnderContract:
        path = '../../assets/img/dot-yellow-20.png';
        break;
      default:
        break;
    }
    return path;
  }

  ngAfterViewInit() {
    this.investmentService.getInvestments();
  }

  getListOptions() {
    let user: User = JSON.parse(localStorage.getItem('user')) as User;
    this.listViews = user.listViews.filter(v => v.system === this.system);
    this.invDashboardListOptions = this.listViews.filter(v => v.selected)[0].listOptions; // get selected view's list options
    this.invListOptions = this.listViews.filter(v => v.selected)[0].listOptions;
  }

  getInvestments() {
    this.investmentService.investments$.subscribe(result => {
      this.allInvestments = result;
      this.baseInvestments = new InvestmentListPipe().transform(result, this.invDashboardListOptions);
      this.filteredInvestments = new InvestmentListPipe().transform(result, this.invListOptions);
      this.filteredInvestmentsForGrid = new InvestmentListPipe().transform(result, this.invListOptions);
      this.transformData();
    });
  }

  transformData(): void {
    // which listing to look at?
    if (this.viewBy === 'portfolio') {
      this.investmentsToShow = this.baseInvestments.slice(0);
    } else {
      this.investmentsToShow = this.filteredInvestments.slice(0);
    }
    this.filteredInvestmentsForGrid.forEach(i => {
      i.occupancyPct = i.occupancyPct * 100;
      i.coverageRatio = i.coverageRatio * 100;
      i.capRate = i.capRate * 100;
      i.stableCapRate = i.stableCapRate * 100;
      i.irrLevered = i.irrLevered * 100;
      i.irrUnlevered = i.irrUnlevered * 100;
      i.straightLine = i.straightLine * 100;
    })
    this.loadMapMarkers();
  }

  tabChange(evt: any) {
    this.mapForceUpdate = !this.mapForceUpdate;
    this.loggerService.logPageVisit('inv ' + evt.tab.textLabel.toLowerCase(), document.location.href);
  }

  runReport(name: string) {
    // log this so we know what reports are being ran
    this.loggerService.logPageVisit('report detail', 'CUBS/' + name);

    let rptName = name;
    if (rptName === 'AcqPipeline-CalEast' || rptName === 'AcqPipeline-ITC' || rptName === 'AcqPipeline-Revenue') {
      rptName += '&FiscalYear=Current';
    }

    window.open(environment.reportUrl + 'CUBS/' + rptName);
  }

  openHelp() {
    this.loggerService.logPageVisit('investments', 'investments help');
    window.open('/assets/files/invHelp.pdf');
  }

  newDeal() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  getViews() {
    this.portalService.getViewsForScreen(Constants.ScreenInvestments).subscribe(v => {
      console.log('getViews', v);
      v[0].fields.forEach(col => {
        col.selected = true;
        col.groups = ['General'];
      });
      this.tableViews = v;
      this.selTableViewKey = this.tableViews[0].key;
    });
  }

  openAddressForm(addressKey: number) {
    let inv = this.filteredInvestments.filter(i => i.properties.filter(p => p.address.addressKey === addressKey).length > 0);
    let address = inv[0].properties[0].address;

    console.log('openAddressForm', address);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = { top: '200px' };
    dialogConfig.width = '900px';
    dialogConfig.data = {
      address: address
    };
    const dialogRef = this.dialog.open(AddressEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(addressReturn => {
      if (addressReturn.saved) {
        this.addressService.save(addressReturn.address).subscribe(updatedAddress => {
          this.investmentService.cacheReset().subscribe(d => { });  // reset cache on server
          // update inv's address with new one so screen will update (don't want to wait for cache to reload)
          let inv = this.filteredInvestments.filter(i => i.properties.filter(p => p.address.addressKey === updatedAddress.addressKey).length > 0);
          if (inv && inv.length > 0)
            this.filteredInvestments.filter(i => i.key === inv[0].key)[0].properties[0].address = JSON.parse(JSON.stringify(addressReturn));
          this.loadMapMarkers();
        });
      }
    });
  }
}
