<!-- regular menu with no submenu -->
<div *ngIf="!menuItem.separator && menuItem.subMenu.length == 0 && !menuItem.external">
  <a class="menu-main"
     [routerLinkActive]="['active']"
     [routerLink]="menuItem.link"
     href="#"
     (click)="hideMenu()">
    <div class="icon-center">
      <fa-icon [icon]="getIcon(menuItem.icon)"
               class="fas fa-fw"></fa-icon>
    </div>
    <span>{{ menuItem.name === 'Home' ? 'Corporate' : menuItem.name }}</span>
  </a>
</div>

<!-- menu that is external link -->
<div *ngIf="!menuItem.separator && menuItem.subMenu.length == 0 && menuItem.external">
  <a class="menu-main"
     target="_ext"
     href="#"
     (click)="hideMenu(); goToUrl(menuItem.link, false)">
    <div class="icon-center">
      <fa-icon [icon]="getIcon(menuItem.icon)"
               class="fas fa-fw"></fa-icon>
    </div>
    <span>{{ menuItem.name === 'Properties' ? 'Assets' : menuItem.name }}</span>
  </a>
</div>

<!-- menu with submenu -->
<div *ngIf="!menuItem.separator && menuItem.subMenu.length > 0 && !menuItem.isCustom">
  <button class="btn-menu-main"
          (click)="menuClick($event)"
          style="cursor: pointer; font-size: 15px;">
    <div class="icon-center">
      <fa-icon [icon]="getIcon(menuItem.icon)"
               class="fas fa-fw"></fa-icon>
    </div>
    <span style="margin-left: 10px;">{{ menuItem.name }}</span>
    <i class="fa fa-caret-right"></i>
  </button>
</div>

<!-- separator -->
<div *ngIf="menuItem.separator && !menuItem.isCustom">
  <div class="nav-separator"
       href="#">
    {{ menuItem.name }}
  </div>
</div>

<!-- custom menu item -->
<div *ngIf="menuItem.name === 'sysListToggle' && menuItem.isCustom" class="menu-parent sys-list-toggle">
  <button class="menu-main"
          (click)="toggleSystemList()"
          title="Show/Hide System List">
    <span class="fa-stack"
          style="display:inline-block; margin-left: -11px; margin-top: -11px;">
      <i class="fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-bars fa-stack-1x"
         style="color: #444;"
         [@showingListing]="showSystemList"></i>
    </span>
  </button>
</div>
