
import { ListView } from '../shared/list-control';
import { UserMenu } from './user-menu';

export class User {
  cubsUserKey: number;
  pcKey: number;
  firstName: string;
  lastName: string;
  fullName: string;
  accountName: string;
  hasPhoto: boolean;
  jobTitle: string;
  department: string;
  officeLocation: string;
  isPointGod: boolean;
  isRegionalMgr: boolean;
  isPropertyMgr: boolean;
  isRegionalAsst: boolean;
  isPropertyAcct: boolean;
  isAcqDealMaker: boolean;
  isDevDealMaker: boolean;
  menu: UserMenu[];
  listViews: ListView[];
  yardiPropertyListHmy: number;
  yardiPropertyIdList: string[];
  hireDt: Date;
  birthday: Date;
  workEmail: string;
  workPhone: string;
  hourlySchedule: UserScheduleInterval[];
  webBio: string;
  webLink: string;
  isRealUser: boolean;
  active: boolean;
  sportsTeams: SportsTeam[];
  azureAuthCode: string;
  birthdayIsPublic: boolean;
  useNewPortal: boolean;
  exemptEmployee: boolean;
  pTOHoursGiven: number;
  departmentalGoals: DepartmentalGoal[];
  // userPreferences: { [key: string]: number; };  // not used any more?

  constructor() {
    this.listViews = [];
    this.hourlySchedule = [];
    this.yardiPropertyIdList = [];
    this.sportsTeams = [];
    this.departmentalGoals = [];
    this.firstName = '';
    this.lastName = '';
  }
}


export class UserScheduleInterval {
  timeInterval: number;
  availabilityKey: number;
  availabilityDesc: string;

  constructor() {
    this.availabilityDesc = '';
  }
}

export class SportsTeam {
  key: number;
  logo: string;
  record: string;
  url: string;
  magicNumber: number;
  isBaseballTeam: boolean;
  league: string;
  division: string;

  constructor() { }
}

export class DepartmentalGoal {
  category: string;
  description: string;
  threshold: number;
  target: number;
  maximum: number;
  forcastedValue: number;
  weight: number;
  bonusEarnedPctTarget: number;
  weightedAvgPctTarget: number;
  bonusTarget: number;
  bonusActual: number;
  formatString: string;
  goalMeasure: string;
}
