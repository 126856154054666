import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User, UserScheduleInterval } from '../user';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import * as moment from 'moment';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { Constants } from 'src/app/core/constants';
import { ChangePacket } from 'src/app/shared/changePacket';
import { SaveService } from 'src/app/shared/save.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { GoalPipePipe } from './goal-pipe.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cub-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, FontAwesomeModule, NgIf, MatTabsModule, MatCheckboxModule, FormsModule, NgFor, MatFormFieldModule, MatInputModule, DecimalPipe, GoalPipePipe]
})
export class UserDetailComponent implements OnInit {

  userId: string;
  user: User;
  yearsOfService: number;
  remainderMonthsOfService: number;
  faCaretDown = faCaretDown;
  numberFormat = '1.2-2';
  // colors = ['#21386C', '#006D15', '#EA9721', '#B22222', 'purple', 'red', 'blue'];
  // colors = ['#21386C', '#3B5286', '#546B9F', '#6E85B9', '#879ED2', '#A0B7EB', '#BAD1FF'];
  colors = ['#006D15', '#1A872F', '#33A048', '#4DBA62', '#66D37B', '#7FEC94', '#99FFAE', '#B3FFC8', '#CCFFE1'];
  salary = 25000;
  bonusPct = 5;
  totalBonusEarnedPctTarget: string;
  totalTarget = 0;
  totalActual = 0;
  currentHover = '';
  tabIndex = 0;
  userSchedule = [];
  actualOrForecast = Constants.BonusStatus;

  constructor(
    private userService: UserService,
    private router: Router,
    private saveService: SaveService,
    private loggerService: LoggerService,
    private route: ActivatedRoute
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      if (params['tab'] && params['tab'] === 'bonus') {
        this.tabIndex = 1;
        this.loggerService.logPageVisit('employee detail -  bonus', this.userId);
      }
      this.userService.getOtherUser(this.userId).subscribe(u => {
        this.loggerService.logPageVisit('employee detail', this.userId);
        this.user = u;
        this.yearsOfService = this.getYearsOfService();
        this.remainderMonthsOfService = this.getRemainderMonthsOfService();
        this.calculateBonus();
        this.getSchedule();
      });
    });
  }

  getTextFromAvailabilityCode(code: string) {
    switch (code) {
      case '0':
        return '';
        break;
      case '1':
        return 'Tentative';
        break;
      case '2':
        return 'Busy';
        break;
      case '3':
        return 'Out of Office';
        break;
      case '4':
        return 'Working Elsewhere';
        break;
    }
    return '';
  }

  getSchedule() {
    this.userService.getGraphToken().subscribe(t => {
      this.userService.getUserSchedule(t, this.user.workEmail, moment().format('YYYY-MM-DD')).subscribe(d => {
        this.userSchedule = d['value'][0]['availabilityView'].split('');
      });
    });
  }

  calculateBonus() {
    let tempPct = 0.00;
    this.totalTarget = 0;
    this.totalActual = 0;
    this.user.departmentalGoals.forEach(goal => {
      goal.bonusTarget = (this.bonusPct / 100) * goal.weight * this.salary;
      goal.bonusActual = goal.weight * goal.bonusEarnedPctTarget * this.salary * (this.bonusPct / 100);
      this.totalTarget += Math.round(goal.bonusTarget); //calculate the target amounts for each goal
      this.totalActual += Math.round(goal.bonusActual); //calculate the actual amounts for each goal
      tempPct += goal.weight * goal.bonusEarnedPctTarget; //calculate the bonus earned % of target for each goal
    });
    this.totalBonusEarnedPctTarget = (tempPct * 100).toFixed(2);
  }

  getFormat(format: string) {
    let newFormat = '1.0-0'; // default
    switch (format) {
      case '#,###':
        newFormat = '1.0-0';
        break;
      case '#,##0.00':
        newFormat = '1.2-2';
        break;
      case '$#,##0.00':
        newFormat = '$1.2-2';
        break;
    }
    return newFormat;
  }

  setCurrentHover(hover: string) {
    this.currentHover = hover;
  }

  resetCurrentHover() {
    this.currentHover = '';
  }

  getHoverBg(id: string, i: number) {
    if (this.currentHover === id + i.toString()) {
      return this.colors[i];
    } else {
      return '#fff';
    }
  }

  getHoverColor(id: string, i: number) {
    if (this.currentHover === id + i.toString()) {
      return '#fff';
    } else {
      return '#000';
    }
  }

  salaryChanged(evt: any) {
    this.salary = evt.replace(/,/g, '');
    this.calculateBonus();
  }

  getImageUrl(): string {
    return environment.userBlobStorageUrl + this.user?.accountName?.replace('CENTERPOINT-PRO\\', '') + '.jpg';
  }

  getYearsOfService(): number {
    let years = 0;
    years = moment().diff(moment(this.user.hireDt), 'years', true);
    return years;
  }

  isTimesheetGod(): boolean {
    return this.userService.hasPermissionKey(Constants.PermUserIsEmployeeStatusEdit, Constants.PermLevel_EDIT);
  }

  changeEmployeeStatus(val: MatCheckboxChange) {
    console.log('status', (!val.checked).toString());

    let changePacket = new ChangePacket('person_company', 'hide', 'Hide', this.user.pcKey, val.checked.toString(),
      (!val.checked).toString(), (!val.checked).toString(), 'boolean', 0, true);
    this.saveService.saveChange(changePacket)
      .then(returnVal => {
        console.log('saveChange packet', returnVal);
      })
      .catch(() => { });
  }

  getRemainderMonthsOfService(): number {
    let remainderMonths = Math.floor((this.yearsOfService - Math.floor(this.yearsOfService)) * 12);
    if (remainderMonths === 12) {
      remainderMonths = 0;
    }
    return remainderMonths;
  }

  formatYear(yearFloat: number): string {
    let ret = '';
    if (yearFloat !== undefined) {
      ret = parseInt(yearFloat.toString(), 10).toString();
    }
    return ret;
  }

  formatDt(dt): string {
    let dateRet = '';
    if (dt !== undefined && dt !== null && dt !== '') {
      dateRet = moment(dt).format('MM/DD/YYYY');
    }

    return dateRet;
  }

  formatBirthday(dt): string {
    let dateRet = '';
    if (dt !== undefined && dt !== null && dt !== '') {
      dateRet = moment(dt).format('M/D');
    }

    return dateRet;
  }

  getPhone(): string {
    let phoneRet = '';
    if (this.user.workPhone !== undefined) {
      let re = /\-/gi;
      phoneRet = this.user.workPhone.replace(re, '.');
    }
    return phoneRet;
  }

  getOfficePicClass(): string {
    let myClass = 'office-header ';
    switch (this.user.officeLocation) {
      case 'Oak Brook':
      case 'Oakbrook':
      case 'Oak Brook Office':
        myClass += 'office-oak-brook';
        break;
      case 'Kansas City':
        myClass += 'office-kc';
        break;
      case 'Suffolk':
      case 'Suffolk Office':
        myClass += 'office-suffolk';
        break;
      case 'Los Angeles':
        myClass += 'office-la';
        break;
      case 'New Jersey':
        myClass += 'office-nj';
        break;
      case 'Norfolk':
        myClass += 'office-norfolk';
        break;
      case 'Oakland':
        myClass += 'office-oakland';
        break;
      case 'Houston':
        myClass += 'office-houston';
        break;
      case 'Florida':
        myClass += 'office-fla';
        break;
      default:
        break;
    }
    return myClass;
  }

  showTime(timeInt: number): boolean {
    return timeInt % 2 == 0;
  }

  formatTime(timeInt: number): string {
    let timeStr = timeInt.toString();
    let pre = timeStr.substr(0, timeStr.length - 2);
    let post = timeStr.substr(timeStr.length - 2);
    if (+pre > 12) {
      pre = (+pre - 12).toString();
    }
    return pre + ':' + post;
  }

  getTimeForIterator(timeInt: number): string {
    let time = '';
    switch (timeInt) {
      case 0:
        time = '7';
        break;
      case 2:
        time = '8';
        break;
      case 4:
        time = '9';
        break;
      case 6:
        time = '10';
        break;
      case 8:
        time = '11';
        break;
      case 10:
        time = '12';
        break;
      case 12:
        time = '1';
        break;
      case 14:
        time = '2';
        break;
      case 16:
        time = '3';
        break;
      case 18:
        time = '4';
        break;
      case 20:
        time = '5';
        break;
      default:
        break;
    }
    return time + ' ' + (timeInt < 10 ? 'am' : 'pm');
  }

  getTimeClass(availCode: string) {
    return 'user-panel-schedule-time-' + availCode;
  }

  getTimeDesc(timeDesc: string): string {
    let timeStr = timeDesc;
    if (timeStr.toLowerCase() === 'available') {
      timeStr = ' ';
    }
    return timeStr;
  }

  removeUser() {
    let sure = confirm('This will remove this user from CUB forever.  Are you sure you want to do this?');
    if (sure) {
      this.userService.removeUser(this.user.accountName.replace('CENTERPOINT-PRO\\', '')).subscribe(ret => {
        alert('User Deleted');
        this.router.navigate(['/users']);
      });
    }
  }

  openTimesheet(): void {
    window.open('https://workforcenow.adp.com/public/index.htm');
  }

  thisIsMe(): boolean {
    let user: User = JSON.parse(localStorage.getItem('user')) as User;
    return this.userId === 'me' || this.userId === user.accountName.replace('CENTERPOINT-PRO\\', '');
  }

  canViewBonusCalc(): boolean {
    let user: User = JSON.parse(localStorage.getItem('user')) as User;
    return this.userId === 'me' || this.userId === user.accountName.replace('CENTERPOINT-PRO\\', '') || user.lastName === 'Tortorici' || user.lastName === 'Okrasinski' || user.lastName === 'German';
  }

  openWebProfile(): void {
    window.open('http://centerpoint.com/people/' + this.user.webLink);
  }

  reloadPermissions(): void {
    this.userService.reloadSecurity();
  }

  reloadLocalCache(): void {
    localStorage.removeItem('lookupTablesTime');
    localStorage.removeItem('userMenuTime');
    localStorage.removeItem('secMenuTime');
    alert('The local cache has been deleted.  Please refresh CUB to get the most recent data.')
  }

}
