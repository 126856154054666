
import {map,  debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fromEvent as observableFromEvent } from 'rxjs';

import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'input-debounce',
    template: '<input type="text" class="form-control" [placeholder]="placeholder" [(ngModel)]="inputValue">',
    standalone: true,
    imports: [FormsModule]
})
export class DebounceInputComponent {
    @Input() placeholder: string;
    @Input() delay = 600;
    @Output() value: EventEmitter<string> = new EventEmitter();

    public inputValue: string;

    constructor(private elementRef: ElementRef) {
        const eventStream = observableFromEvent(elementRef.nativeElement, 'keyup').pipe(
            map(() => this.inputValue))
            .pipe(
              debounceTime(this.delay),
              distinctUntilChanged()
            );

        eventStream.subscribe(input => { this.saveToDB(input); this.value.emit(input); });
    }

  saveToDB(value: string) {
    // TODO - Do we need this method?
    }
}
