export class PropertyBudgetSummary {

  expenseCategory: string;
  currBudget: number;
  lastBudget: number;
  actual12Mo: number;
  actualYr2: number;
  actualYr3: number;
  periodStart: Date;
  periodEnd: Date;

  constructor() {
  }
}
