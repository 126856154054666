<div class="line-graph-details">
  <h3>{{ title }}</h3>
  <div #lineChart
       class="bar-chart">
    <canvas baseChart
            id="chartCanvas"
            #chartCanvas
            [style.visibility]="chartVisible"
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [legend]="false"
            [type]="chartType"
            (loadeddata)="loadedData($event)"
            (mousemove)="chartHover($event)"
            (mouseover)="mouseover($event)"
            (mouseout)="mouseout($event)"
            (chartClick)="chartClicked($event)"
            (chartHover)="chartHover($event)"
            class="graph">
    </canvas>
  </div>
  <table>
    <colgroup>
      <col style="width: 50%;" />
      <col style="width: 50%;" />
    </colgroup>
    <tbody>
      <tr>
        <td>
          <div [style.color]="hexColor">{{ dataToShow }}</div>
          <div>{{ label }}</div>
        </td>
        <td>
          <div [style.color]="hexColor2">{{ dataToShow2 }}</div>
          <div>{{ label2 }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
