<div class="alert-version">
  <h4 style="margin-bottom: 25px; height: 35px;">
    <img src="../../../assets/img/cnt-shield-blue.png" style="float: left; margin-right: 8px; width: 50px;" />
    <span style="float: left; margin-top: 1px;">CUB Instant Upgrade</span>
  </h4>
  <div>
    Your friendly IT department just upgraded the CUB System.  Please refresh to ensure you get the latest and greatest. This update includes:
  </div>
  <br>
  <div style="border: solid 1px #ddd; padding: 10px;">
    {{ message }}
  </div>
  <br>
  <div>
    If you choose not to upgrade now, CUB will automatically upgrade the next time you open it. Thanks.
  </div>
  <br>
  <br>
  <button mat-button color="primary" (click)="cancel()">No thanks, I'll Roll the Dice</button>
  <button mat-raised-button color="primary" style="float: right; margin-right: 20px;" (click)="reload()">Refresh</button>
  <div style="width: 100%; margin-top: 10px;">
    <span style="float: right; margin-right: 20px; font-size: 12px;">The system will auto reload in {{ countdown }} seconds...</span>
  </div>
</div>
