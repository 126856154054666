<div id="topNav">
  <mat-toolbar color="primary">
    <button mat-icon-button
            (click)="toggleMinMaxLeftMenu()"
            style="float: left; width: 35px; margin-top: -2px; margin-left: -9px; margin-right: 8px; color: #ccc; font-size: 20px;">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <span class="navbar-brand"
          style="display: inline-block; width: 115px;"
          (click)="goHome()">
      <img src="assets/img/logo-white-40w.png"
           fetchpriority="high"
           style="width: 45px; margin-top: -3px; margin-right: -5px;" /> &nbsp;CUB </span>
    <span class="fill-space"></span>
    <span *ngIf="daysPasswordExpiresIn() <= 15">
      <button mat-button
              matTooltip="Password Expiring Soon - Click to Update"
              (click)="openPasswordChange()"
              style="margin-right: 10px; color: #f0ad4e;">
        <fa-icon [icon]="faExclamationTriangle"
                 class="fa-lg"
                 style="margin-right: 5px;"></fa-icon> {{ getPasswordMessage() }} </button>
    </span>
    <div style="float: right; margin-right: 50px;">
      <form class="form-inline mt-2 mt-md-0"
            style="margin-right: 5px; margin-left: 10px;">
        <input #searchBox
               id="searchBox"
               name="searchBox"
               class="form-control"
               type="search"
               placeholder="Enterprise Search"
               (mousedown)="openSearch()"
               (keyup)="searchTerm$.next($event.target.value); checkClose($event.target.value);">
      </form>
    </div>
    <div style="float: right; margin-right: 15px; border-right: solid 1px #425582; padding-right: 5px;">
      <button id="btnYardi"
              mat-icon-button
              [matMenuTriggerFor]="yardiMenu"
              matTooltip="Yardi Links"
              style="margin-right: 10px;">
        <img src="../../../assets/img/yardi-white.png"
             style="width: 28px; vertical-align: middle; margin-top: -10px;" />
      </button>
      <mat-menu #yardiMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'after'"
                [overlapTrigger]="false">
        <button mat-menu-item
                (click)="openYardi()">
          <img src="../../../assets/img/yardi-black.png"
               style="width: 18px; vertical-align: middle; margin-right: 12px;" /> Voyager </button>
        <button mat-menu-item
                (click)="openYardiOne()">
          <img src="../../../assets/img/YardiOne-black-32.png"
               style="width: 18px; vertical-align: middle; margin-right: 12px;" /> YardiOne </button>
      </mat-menu>
      <button id="btnPowerBI"
              mat-icon-button
              matTooltip="Open Power BI"
              [matMenuTriggerFor]="powerBiMenu"
              style="margin-right: 10px;">
        <img src="../../../assets/img/powerbi-logo-white.png"
             style="width: 22px; vertical-align: middle; margin-top: -10px;" />
      </button>
      <mat-menu #powerBiMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'before'"
                [overlapTrigger]="false">
        <button mat-menu-item
                (click)="openPowerBI()"> Go to Power BI </button>
        <hr class="menu-separator" />
        <button mat-menu-item
                *ngFor="let workspace of powerBIWorkspaces"
                [matMenuTriggerFor]="workspaceMenu"
                (mouseover)="getReportsInWorkspace(workspace.id)"> {{ workspace.name }} </button>
      </mat-menu>
      <mat-menu #workspaceMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'after'"
                [overlapTrigger]="false">
        <button mat-menu-item
                *ngFor="let report of reportsInWorkspace"
                (click)="openPowerBIReport(report)"> {{ report.name }} </button>
      </mat-menu>
      <button id="btnOffice"
              mat-icon-button
              matTooltip="Open Microsoft 365"
              (click)="openOffice()"
              style="margin-right: 10px;">
        <img src="../../../assets/img/office-logo-white.png"
             style="width: 18px; vertical-align: middle; margin-top: -10px;" />
      </button>
      <button id="btnSharepoint"
              mat-icon-button
              matTooltip="Open SharePoint"
              (click)="openSharePoint()"
              style="margin-right: 10px;">
        <img src="../../../assets/img/sharepoint-logo-white.png"
             style="width: 24px; vertical-align: middle; margin-top: -10px;" />
      </button>
      <button id="btnEgnyte"
              mat-icon-button
              matTooltip="Open Egnyte"
              (click)="openEgnyte()"
              style="margin-right: 10px;">
        <img src="../../../assets/img/egnyte-logo-white.png"
             style="width: 22px; vertical-align: middle; margin-top: -10px;" />
      </button>
    </div>
    <div style="float: right">
      <button id="badData"
              mat-icon-button
              matBadge="{{ badDataCount }}"
              matBadgeHidden="{{ badDataCount === 0 }}"
              matBadgePosition="after"
              matBadgeSize="small"
              matBadgeColor="warn"
              [matMenuTriggerFor]="badDataMenu"
              [disabled]="badDataDisabled"
              matTooltip="Bad Data Button"
              style="margin-right: 10px;">
        <fa-icon [icon]="faBullhorn"
                 class="fa-lg" style="float: right;"></fa-icon>
      </button>
      <mat-menu #badDataMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'after'"
                [overlapTrigger]="false">
        <button mat-menu-item
                (click)="openBadDataTicket()"> Open Bad Data Ticket </button>
        <hr class="menu-separator" />
        <button mat-menu-item
                (click)="openBadDataListing()"> View Bad Data Listing </button>
      </mat-menu>
      <button mat-icon-button
              [matMenuTriggerFor]="recentMenu"
              style="margin-right: 0px;"
              (click)="logClick('Recents');">
        <fa-icon [icon]="faHistory"
                 class="fa-2x"
                 style="font-size: 1.3rem;"></fa-icon>
      </button>
      <mat-menu #recentMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'before'"
                [overlapTrigger]="false">
        <div *ngFor="let item of recents">
          <span class="group-label"
                *ngIf="item.subMenu.length > 0">{{ item.name }}</span>
          <div *ngIf="item.subMenu.length > 0">
            <cub-new-menu-item *ngFor="let item of item.subMenu"
                               [menuItem]="item"></cub-new-menu-item>
          </div>
        </div>
      </mat-menu>
      <cub-new-menu style="margin-right: 15px;"></cub-new-menu>
      <button mat-icon-button
              [matMenuTriggerFor]="userMenu"
              class="filterButton">
        <img *ngIf="user"
             [src]="getPhotoUrl()"
             style="width: 36px; height: 36px; border-radius: 28px; margin-left: -11px; margin-top: -10px;" />
      </button>
      <mat-menu #userMenu="matMenu"
                style="margin-top: 10px;"
                [yPosition]="'below'"
                [xPosition]="'before'"
                [overlapTrigger]="false">
        <cub-new-menu-item *ngFor="let item of userMenuList"
                           [menuItem]="item"></cub-new-menu-item>
        <cub-new-menu-item [menuItem]="logoutMenuItemSep"></cub-new-menu-item>
        <cub-new-menu-item [menuItem]="logoutMenuItem"
                           (menuClicked)="userMenuItemClicked($event)"></cub-new-menu-item>
      </mat-menu>
      <button *ngIf="showPointButton"
              mat-icon-button
              style="margin-right: 10px;"
              (click)="togglePoint()">
        <fa-icon [icon]="faComments"
                 class="fa-2x"
                 style="font-size: 1.3rem;"></fa-icon>
      </button>
    </div>
  </mat-toolbar>
</div>
