<form class="leaseForm" [formGroup]="fgUpload" novalidate autocomplete="off">
  <h4>{{ pageHeader }}</h4>
  <div class="uploadGridWrapper">
    <div class="uploadDesc">
      <mat-form-field >
				<mat-label>{{descLabel}}</mat-label>
        <input matInput formControlName="description" >
      </mat-form-field>
    </div>
    <div class="uploadDate">
      <mat-form-field>
				<mat-label>Document date</mat-label>
        <input matInput formControlName="date" [matDatepicker]="uploadDt" >
        <mat-datepicker-toggle matSuffix [for]="uploadDt"></mat-datepicker-toggle>
        <mat-datepicker #uploadDt></mat-datepicker>
        <mat-error *ngIf="!fgUpload.controls.date.valid">
          A document date is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="uploadPickerButton">
      <button mat-raised-button color="primary" (click)="fileInput.click()">Upload file(s)</button>
    </div>
    <div class="uploadPickerInput">
      <mat-form-field >
				<mat-label>{{fileLabel}}</mat-label>
        <input matInput formControlName="pickerInput" >
        <mat-error *ngIf="!fgUpload.controls.pickerInput.valid">
          A file is required
        </mat-error>
      </mat-form-field>
      <input hidden type="file" #fileInput formControlName="pickerHidden" [accept]="accept" (change)="handleFileInput($event.target.files)">
    </div>
    <div class="uploadComments">
      <mat-form-field >
				<mat-label>Comments</mat-label>
        <input matInput formControlName="comments" >
      </mat-form-field>
    </div>
    <div class="uploadActions">
        <button mat-raised-button (click)="clickClose()">Close</button>
        <button mat-raised-button color="primary" align="end" [disabled]="!fgUpload.valid" (click)="clickUpload()">Upload</button>
    </div>
  </div>
</form>
