export class UserMenu {
    name: string;
    desc: string;
    icon: string;
    link: string;
    permissionKey: number;
    method: string;
    hasPermission: boolean;
    external: boolean;
    separator: boolean;
    subMenu: UserMenu[] = [];
    isNew: boolean;
    dateAdded: string;
    isCustom: boolean;

    constructor(name?: string) {
      this.subMenu = [];
      if (name) {
        this.name = name;
      }
    }
}
